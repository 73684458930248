<template>
  <div>
    <div class="flex flex--justify-content-start flex--align-items-end flex--wrap gap-12 mb-12-c">
      <!-- <div v-if="accountLevel == 'ib' || accountLevel == 'fm' || accountLevel == 'ccp'" class="col-auto mb-2 text-left">
						<FilterClient class="d-inline-block"
							filterId="transaction"
							@changeFilterClient="changeFilterClientTransaction"
							:clients_prop="listClients"
						>
						</FilterClient>
					</div> -->

      <div class="flex--grow-1">
        <ReportsNav />
      </div>

      <div class="flex flex--align-items-end flex--wrap gap-12">
        <div class="text-left">
          <FilterOrderType @changeFilterOrderType="setTransactions">
          </FilterOrderType>
        </div>

        <div class="text-left">
          <FilterDateAndType class="d-inline-block" @changeFilterDateAndType="changeFilterDateAndTypeTransaction"
            :dateOnly_prop="true">
          </FilterDateAndType>
        </div>

        <div class="text-left">
          <FilterContract class="d-inline-block" @changeFilterContract="changeFilterContractTransaction"
            filterId="transaction">
          </FilterContract>
        </div>

        <div class="text-left">
          <ButtonExportToCSV :orderType_prop="orderType_export" :contractsParam_prop="contractsParam_export"
            :startDate_prop="startDate_export" :endDate_prop="endDate_export"
            :selectedClientTransaction_prop="selectedClientTransaction_export"></ButtonExportToCSV>
        </div>
      </div>

      <!-- <div class="col-auto mb-2 text-left">
						<ExportAsButton class="d-inline-block">
						</ExportAsButton>
					</div> -->
    </div>

    <div class="row bg-grey-1">
      <div class="col-12 table-responsive p-0">
        <table class="table table--report text-nowrap">
          <thead>
            <tr class="text-center">
              <th>DIR.</th>
              <th>ORDER <br> TYPE</th>
              <th>DATE</th>
              <th>ORDER <br> ID</th>
              <th>TRANS. <br> ID</th>
              <th>PRODUCT</th>
              <th>HUB</th>
              <th>QTY</th>
              <!-- <th>HIN</th> -->
              <th>TOTAL <br> GRAM</th>
              <th>PRICE <br> PER GRAM</th>
              <th>METAL VALUE <br> OF TRADE</th>
              <th>FEES</th>
              <th>TAX</th>
              <th>SETTLEMENT <br> AMOUNT</th>
              <th>STATUS</th>
              <th>PDF</th>
            </tr>
          </thead>

          <tbody v-if="transactionsStatus == 'loading'">
            <tr>
              <td class="text-center" colspan="14">
                <Loader size_prop="10em"></Loader>
              </td>
            </tr>
          </tbody>

          <tbody v-else-if="transactionsStatus == 'idle' || transactionsStatus == 'success'">
            <tr v-if="transactions.length < 1">
              <td colspan="16" class="text-center">No data available in table</td>
            </tr>

            <template v-else>
              <tr v-for="transaction in transactions" class="c-row-table" :key="transaction._id">
                <td class="text-center">
                  <i :style="`color: ${(transaction.direction == 'buy') ? '#2ec4b6' : '#e71d36'}`"
                    :class="`fas fa-lg fa-arrow-circle-${(transaction.direction == 'buy') ? 'down' : 'up'} mr-6-c`"
                    :title="`${(transaction.direction == 'buy') ? 'BUY' : 'SELL'}`"></i>
                </td>

                <td class="text-center text-capitalize">{{ transaction.orderType }}</td>

                <td class="text-center">{{ transaction.date }}</td>

                <td class="text-center">{{ transaction.orderId }}</td>

                <!-- <td class="text-center">
                  <Loader v-if="transaction.hin == 'loadingTemp'" size_prop="14px" borderSize_prop="1px"></Loader>

                  <template v-else>
                    {{ transaction.hin }}
                  </template>
                </td> -->

                <td class="text-center">{{ transaction.ref }}</td>
                <td class="text-center">{{ transaction.product }}</td>
                <td class="text-center">JAK</td>
                <td class="text-center">{{ transaction.quantity }}</td>
                <td class="text-center">{{ ((transaction.totalGram).toFixed(2)) }}</td>
                <td class="text-right">{{ numberFormatExtend(((currency == 'usd') ? transaction.pricePerGram :
                  transaction.pricePerGramIdr).toFixed(2)) }}</td>
                <td class="text-right">{{ numberFormatExtend(((currency == 'usd') ? transaction.metalValueOfTrade :
                  transaction.metalValueOfTradeIdr).toFixed(2)) }}</td>
                <td class="text-right">{{ (currency == 'usd') ? numberFormatExtend(transaction.fee, 3) :
                  numberFormatExtend(transaction.feeIdr, 2) }}</td>
                <td class="text-right">{{ (currency == 'usd') ? numberFormatExtend(transaction.tax, 3) :
                  numberFormatExtend(transaction.taxIdr, 2) }}</td>
                <td class="text-right">{{ numberFormatExtend(((currency == 'usd') ? transaction.settlementAmount :
                  transaction.settlementAmountIdr).toFixed(2)) }}</td>
                <td class="text-center">{{ transaction.status }}</td>

                <td class="text-center">
                  <Loader v-if="invoiceLoading.indexOf(transaction.ref) >= 0" size_prop="14px" borderSize_prop="1px">
                  </Loader>

                  <span v-else class="cursor-hover-pointer"
                    :title="`${transaction.status.toLowerCase() != 'on process' ? 'Download Trade Confirmation' : 'On Proces...'}`"
                    @click="invoice(transaction.orderMatchId, transaction.direction, transaction.status, transaction.ref)">

                    <IconPDF></IconPDF>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>

          <tbody v-else-if="transactionsStatus == 'error'">
            <tr v-if="transactions.length < 1">
              <td colspan="16" class="text-center">No data available in table</td>
            </tr>
          </tbody>

          <tfoot>

          </tfoot>
        </table>
      </div>
    </div>

    <div class="flex flex--justify-content-end text-right mb-2">
      <div class="">
        <Pagination2 ref="paginationTransactions" :totalData_prop="totalTransactions" @changePage="setTransactions">
        </Pagination2>
      </div>
    </div>
  </div>
</template>

<script>
import axios, { abortController } from '@/api/axios.js'
import Decimal from 'decimal.js'

import ReportsNav from '@/components/reports/ReportsNav.vue'
import FilterContract from '@/components/FilterContract.vue'
import FilterDateAndType from '@/components/FilterDateAndType.vue'
import FilterOrderType from '@/components/FilterOrderType.vue'
import ButtonExportToCSV from '@/components/reports/ButtonExportToCSV.vue'
import Pagination2 from '@/components/Pagination2.vue'
import Loader from '@/components/Loader.vue'
import ExportAsButton from '../../ExportAsButton.vue'

import IconPDF from '@/components/icons/IconPDF.vue'

export default {
  name: 'Transactions',

  components: {
    ReportsNav,
    FilterContract,
    FilterDateAndType,
    FilterOrderType,
    ButtonExportToCSV,
    Pagination2,
    Loader,
    ExportAsButton,

    IconPDF,
  },

  data() {
    return {
      firstCallDone: false,

      transactions: [],
      transactionsStatus: 'idle',
      totalTransactions: 0,
      orderTypeTransactions: 'all',
      filterContractTransaction: [],
      selectedClientTransaction: 'all',
      filterDateAndTypeTransaction: {
        startDate: null,
        endDate: null,
        orderType: 'all'
      },
      invoiceLoading: [],

      // for export csv
      contractsParam_export: '',
      startDate_export: '',
      endDate_export: '',
      orderType_export: '',
      selectedClientTransaction_export: {},
    }
  },

  computed: {
    currency() {
      return this.$store.state.currency
    },

    hubs() {
      return this.$store.state.hubs
    },

    contracts() {
      return this.$store.state.contracts
    },

    accountLevel() {
      return this.$store.getters.getAccountLevel
    },

    streamingRate() {
      return this.$store.getters.getStreamingRate
    },
  },

  created() {
    const firstCall = setInterval(() => {
      if (this.contracts && this.accountLevel && this.hubs) {
        clearInterval(firstCall)

        this.firstCallDone = true
        this.setTransactions()
      }
    }, 1000)
  },

  methods: {
    setTransactions(skip = 0, limit = 10, orderType = this.orderTypeTransactions) {
      if (!this.firstCallDone) {
        return
      }
      //transactions
      let hubs = this.hubs
      let contracts = this.contracts

      let startDate = this.filterDateAndTypeTransaction.startDate
      let endDate = this.filterDateAndTypeTransaction.endDate

      if (startDate) {
        startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
      }

      if (endDate) {
        endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? '0' : ''}${endDate.getMonth() + 1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`
      }

      let selectedContracts = this.filterContractTransaction
      let contractsParam = ''

      let contractParamName = 'contract_id'
      if (this.accountLevel == 'ib' || this.accountLevel == 'fm') {
        contractParamName = 'contractId'
      }

      if (selectedContracts.length > 0) {
        selectedContracts.forEach((item) => {
          contractsParam = contractsParam + `${contractParamName}[]=${item}&`
        })
      }

      if (contractsParam == '') {
        contractsParam = `${contractParamName}[]=&`
      }

      this.orderTypeTransactions = orderType
      let selectedOrderType = orderType
      if (this.orderTypeTransactions == 'all') {
        selectedOrderType = ''
      }

      // for export csv
      this.contractsParam_export = contractsParam
      this.startDate_export = startDate
      this.endDate_export = endDate
      this.orderType_export = selectedOrderType
      this.selectedClientTransaction_export = {
        hin_abx: this.selectedClientTransaction.hin_abx,
        ac_abx: this.selectedClientTransaction.ac_abx
      }

      let urlApi = ''
      if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
        if (this.selectedClientTransaction == 'all') {
          urlApi = '/v2/rrs/transactions?offset=' + skip + '&limit=' + limit + '&' + contractsParam + 'dateStart=' + startDate + '&dateEnd=' + endDate + '&contract_id[]=100' + '&type=' + selectedOrderType
        } else {
          urlApi = '/v2/rrs/transactions?offset=' + skip + '&limit=' + limit + '&' + 'hin=' + this.selectedClientTransaction.hin_abx + '&accountId=' + this.selectedClientTransaction.ac_abx + '&' + contractsParam + 'dateStart=' + startDate + '&dateEnd=' + endDate + '&type=' + selectedOrderType
        }
      } else if (this.accountLevel == 'lp') {
        urlApi = '/v2/igx/market-maker/transaction?offset=' + skip + '&limit=' + limit + '&' + contractsParam + 'date_start=' + startDate + '&date_end=' + endDate + '&order_type=' + selectedOrderType
      } else {
        urlApi = '/v2/igx/transactions?offset=' + skip + '&limit=' + limit + '&' + contractsParam + 'date_start=' + startDate + '&date_end=' + endDate + '&order_type=' + selectedOrderType
      }

      this.transactionsStatus = 'loading'

      if (this.cancelTokenApiGetHinTransactions) {
        //cancel api get hin
        this.cancelTokenApiGetHinTransactions.controller.abort()
      }
      this.cancelTokenApiGetHinTransactions = abortController()

      if (this.cancelTokenApiGetTransactions) {
        //cancel api get transactions
        this.cancelTokenApiGetTransactions.controller.abort()
      }
      this.cancelTokenApiGetTransactions = abortController()

      axios.post(urlApi, {
        user_id: this.$store.state.userId
      }, {
        signal: this.cancelTokenApiGetTransactions.signal,
      }).then(response => {
        this.transactions = []

        this.totalTransactions = response.data.data.count

        let transactions = response.data.data.body
        if (this.accountLevel == 'ib' || this.accountLevel == 'fm') {
          transactions = response.data.data.body
        }

        let sortedTransactions = transactions.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
        // sortedTransactions = this.paginationOrder(sortedTransactions)

        if (transactions.length < 1) {
          this.transactionsStatus = 'idle'
        }
        
        function qaFunction(contractId, metalvalueOfTradeUsd, metalvalueOfTradeIdr, settlementAmountUsd, settlementAmountIdr) {
          this.contractId = contractId
          this.metalvalueOfTradeUsd = metalvalueOfTradeUsd
          this.metalvalueOfTradeIdr = metalvalueOfTradeIdr
          this.settlementAmountUsd = settlementAmountUsd
          this.settlementAmountIdr = settlementAmountIdr
        }
        const arrayQA = []

        for (let i = 0; i < sortedTransactions.length; i++) {
          const contractConfig = this.$store.state.contractConfigs[sortedTransactions[i].attributes.contractId]

          let contract = contracts.find(obj => obj.id == sortedTransactions[i].attributes.contractId)

          let tempArray = []
          let hub = '-'
          if (hubs) {
            hub = hubs.find(obj => obj.id == contract.attributes.hubId)
            hub = hub.attributes.iata
          }

          let dateTemp = new Date(sortedTransactions[i].attributes.createdAt)
          let date = {
            year: dateTemp.getFullYear(),
            month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
            date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
            hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
            minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
          }

          let settlementAmount = sortedTransactions[i].attributes.settlementAmount

          let metalValueOfTrade = sortedTransactions[i].attributes.metalValueOfTrade

          let fee = new Decimal(sortedTransactions[i].attributes.fee)

          let tax = new Decimal(sortedTransactions[i].attributes.tax)

          let weight = contractConfig.weightInGram

          const totalGram = Number(Decimal.mul(weight, sortedTransactions[i].attributes.quantity).div(contractConfig.weightCorrection))

          let pricePer = sortedTransactions[i].attributes.quantity == 0 ? 0 : Decimal.div(metalValueOfTrade, totalGram)

          let rate = sortedTransactions[i].attributes.rate || 0
          if (rate == 0) {
            rate = (sortedTransactions[i].attributes.direction == 'sell') ? this.streamingRate.stream_sell.cur_rate : this.streamingRate.stream_buy.cur_rate
          }
          tempArray = {
            id: sortedTransactions[i].id,
            orderId: sortedTransactions[i].attributes.orderId,
            orderMatchId: sortedTransactions[i].attributes.orderMatchId,
            contractId: sortedTransactions[i].attributes.contractId,
            direction: sortedTransactions[i].attributes.direction,
            date: sortedTransactions[i].attributes.createdAt ? `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}` : '-',
            hin: (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') ? 'loadingTemp' : this.$store.getters.getHin,
            ref: sortedTransactions[i].id || '-',
            product: contractConfig.name,
            hub: hub,
            orderType: sortedTransactions[i].attributes.orderType,
            quantity: Number(Decimal.div(sortedTransactions[i].attributes.quantity, contractConfig.weightCorrection)),
            totalGram: totalGram,
            pricePerGram: pricePer,
            pricePerGramIdr: Number(Decimal.mul(pricePer, rate)),
            metalValueOfTrade: metalValueOfTrade,
            metalValueOfTradeIdr: Number(Decimal.mul(metalValueOfTrade, rate)),
            fee: new Decimal(fee).toDecimalPlaces(3).toNumber(),
            feeIdr: new Decimal(fee).times(rate).toDecimalPlaces(2).toNumber(),
            tax: new Decimal(tax).toDecimalPlaces(3).toNumber(),
            taxIdr: new Decimal(tax).times(rate).toDecimalPlaces(2).toNumber(),
            settlementAmount: settlementAmount,
            settlementAmountIdr: Number(Decimal.mul(settlementAmount, rate)),
            status: (sortedTransactions[i].attributes.status == 'match') ? 'Matched' : 'On Process'
          }

          if (process.env.NODE_ENV == 'development') {
            arrayQA.push(new qaFunction(sortedTransactions[i].attributes.contractId, metalValueOfTrade, Decimal.mul(metalValueOfTrade, rate).toNumber(), settlementAmount, Decimal.mul(settlementAmount, rate).toNumber()))
          }

          // this.transactions[i] = tempArray

          this.$set(this.transactions, i, tempArray)

          if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
            axios.get('/v2/rrs/get/hin?accountId=' + sortedTransactions[i].attributes.accountId, {
              signal: this.cancelTokenApiGetHinTransactions.signal
            }).then(response2 => {
              this.$set(this.transactions, i, {
                ...this.transactions[i],
                'hin': response2.data.data.body
              })
            }).catch(error => {
            })
          }
        }

        // Buat Saskia QA. Dev only
        if (process.env.NODE_ENV == 'development') {
          console.table(arrayQA)
        }

        this.transactionsStatus = 'success'
      }).catch(error => {
      })
    },

    changeFilterClientTransaction(clientData) {
      this.selectedClientTransaction = clientData
      this.setTransactions()
    },

    changeFilterContractTransaction(selectedContracts) {
      this.filterContractTransaction = selectedContracts
      this.$refs.paginationTransactions.resetPagination()
      this.setTransactions()
    },

    changeFilterDateAndTypeTransaction(data) {
      this.filterDateAndTypeTransaction = data
      this.setTransactions()
    },

    invoice(orderMatchId, direction, transactionStatus, transactionRef) {
      // If Transaction Status == On Process, cannot download pdf
      if (transactionStatus == 'On Process') {
        return false
      }

      this.invoiceLoading.push(transactionRef)
      axios({
        method: 'POST',
        url: process.env.VUE_APP_BACKEND_URL + '/igx/reports/pdf/transaction_invoice',
        // responseType: 'arraybuffer',
        data: {
          order_match_id: orderMatchId,
          direction: direction,
        },
      }).then(response => {
        let datanya = response.data.data.body.data

        // let byte = this.base64ToArrayBuffer(datanya)
        const arrTes = new Uint8Array(datanya)
        let blob = new Blob([arrTes], { type: "application/pdf" })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        let fileName = 'trade_confirmation_invoice-' + orderMatchId
        link.download = fileName
        link.click()

        let indexLoading = this.invoiceLoading.indexOf(orderMatchId)
        this.invoiceLoading.splice(indexLoading, 1)

      }).catch(error => {
        let indexLoading = this.invoiceLoading.indexOf(orderMatchId)
        this.invoiceLoading.splice(indexLoading, 1)

        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
        } else {
          // Something happened in setting up the request and triggered an Error
        }
      })
    },
  }
}
</script>

<style></style>