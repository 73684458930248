<template>
	<div class="content">
		<div class="row">
			<div class="col">
				<h4 class="text-center">E WARRANT LIST</h4>
				<!-- <FilterHub></FilterHub> -->
				<!-- <button class="btn" id="btn-group-1" @click="changeActiveButton(1)" :disabled="tombolSatuState">Tombol satu</button>
				<button class="btn" id="btn-group-2" @click="changeActiveButton(2)" :disabled="tombolDuaState">Tombol dua</button>
				<div class="btn-group btn-group-toggle" data-toggle="buttons">
				  <label class="btn active">
				    <input type="radio" name="options" id="option1" autocomplete="off" checked> Active
				  </label>
				  <label class="btn">
				    <input type="radio" name="options" id="option2" autocomplete="off"> Radio
				  </label>
				  <label class="btn">
				    <input type="radio" name="options" id="option3" autocomplete="off"> Radio
				  </label>
				</div> -->
				<table id="datatable1" class="table table-striped">
					<thead>
						<tr>
							<th>HIN</th>
							<th>ACCOUNT</th>
							<th>HUB</th>
							<th>PRODUCT</th>
							<th>AVAILABLE FOR EVW</th>
							<th>EVW IN PROGRESS</th>
							<th>REQUESTER</th>
							<th>CREATED</th>
							<th>CERTIFIED</th>
							<th>CANCELLED</th>
							<th>SERIAL NUMBER</th>
							<th>HALLMARK</th>
							<th>WARRANT NUMBER</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>HIN</td>
							<td>ACCOUNT</td>
							<td>HUB</td>
							<td>PRODUCT</td>
							<td>AVAILABLE FOR EVW</td>
							<td>EVW IN PROGRESS</td>
							<td>REQUESTER</td>
							<td>CREATED</td>
							<td>CERTIFIED</td>
							<td>CANCELLED</td>
							<td>SERIAL NUMBER</td>
							<td>HALLMARK</td>
							<td>WARRANT NUMBER</td>
						</tr>
					</tbody>
					<tfoot>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	/*eslint-env jquery*/

	// import FilterHub from '@/components/FilterHub.vue'

	export default {
		name: 'E-warrant',
		components: {
			// FilterHub
		},
		data() {
			return {
				tombolSatuState: true,
				tombolDuaState: false
			}
		},
		mounted() {
		},
		methods: {
			changeActiveButton(buttonId) {
				if (buttonId == 1) {
					this.tombolSatuState = true
					this.tombolDuaState = false
				}else{
					this.tombolSatuState = false
					this.tombolDuaState = true
				}
			}
		}
	}
</script>