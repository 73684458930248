<template>
	<div class="filter-status">
		<button :class="buttonOrdersClass" tabindex="-1" @click="changeReport('orders')">ORDERS</button>
		<button :class="buttonTransactionsClass" tabindex="-1" @click="changeReport('transactions')">TRANSACTIONS</button>
		<button :class="buttonHoldingsClass" tabindex="-1" @click="changeReport('holdings')">HOLDINGS</button>
		<button v-if="accountLevel == 'ib' || accountLevel == 'fm'" :class="buttonClientsClass" tabindex="-1" @click="changeReport('clients')">CLIENTS</button>
		<button :class="buttonAccountBalanceClass" tabindex="-1" @click="changeReport('accountBalanceHistory')">ACCOUNT BALANCE
			HISTORY</button>
	</div>
</template>

<script>
export default {
	name: 'ReportsNav',
	props: {
		// reportToShow_prop: {
		// 	type: String,
		// 	default: 'orders'
		// }
	},
	data() {
		return {
			
		}
	},
	computed: {
		accountLevel() {
			return this.$store.getters.getAccountLevel
		},

		reportToShow() {
			return this.$store.state.reportToShow
		},

		activeOrders() {
			return this.reportToShow == 'orders' ? true : false
		},
		activeTransactions() {
			return this.reportToShow == 'transactions' ? true : false
		},
		activeHoldings() {
			return this.reportToShow == 'holdings' ? true : false
		},
		activeClients() {
			return this.reportToShow == 'clients' ? true : false
		},
		activeAccoundBalance() {
			return this.reportToShow == 'accountBalanceHistory' ? true : false
		},

		buttonOrdersClass() {
			return {
				'c-btn-active': this.activeOrders
			}
		},
		buttonTransactionsClass() {
			return {
				'c-btn-active': this.activeTransactions
			}
		},
		buttonHoldingsClass() {
			return {
				'c-btn-active': this.activeHoldings
			}
		},
		buttonClientsClass() {
			return {
				'c-btn-active': this.activeClients
			}
		},
		buttonAccountBalanceClass() {
			return {
				'c-btn-active': this.activeAccoundBalance
			}
		}
	},
	watch: {
		// reportToShow(newValue) {
		// 	if (newValue == 'orders') {
		// 		this.activeOrders = true
		// 		this.activeTransactions = false
		// 		this.activeHoldings = false
		// 		this.activeAccoundBalance = false
		// 	} else if (newValue == 'transactions') {
		// 		this.activeOrders = false
		// 		this.activeTransactions = true
		// 		this.activeHoldings = false
		// 		this.activeAccoundBalance = false
		// 	} else if (newValue == 'holdings') {
		// 		this.activeOrders = false
		// 		this.activeTransactions = false
		// 		this.activeHoldings = true
		// 		this.activeAccoundBalance = false
		// 	} else {
		// 		this.activeOrders = false
		// 		this.activeTransactions = false
		// 		this.activeHoldings = false
		// 		this.activeAccoundBalance = true
		// 	}
		// }
	},

	created() {
		if(this.accountLevel == 'client' && this.reportToShow == 'clients') {
			this.$store.commit('setReportToShow_mut', 'orders')
		}
	},

	methods: {
		changeReport(value) {
			this.$store.commit('setReportToShow_mut', value)
		}
	}
}
</script>

<style scoped>
.filter-status {
	background-color: #1e1e2c;
	display: inline-block;
	padding: 2px;
	border: 1px solid rgba(255, 255, 255, 0.25);
	border-radius: 4px;
	height: auto;
	vertical-align: middle;
}

button {
	background-color: #1e1e2c;
	color: white;
	border: 0px;
	/*border-radius: 5px;*/
	padding: 5px 15px;
	height: 2.375em;
	font-weight: 700;
}

button:hover {
	/* background-color: #3b3b56; */
	cursor: pointer;
	filter: brightness(125%);
}

button:active {
	background-color: #2e3d61;
}

.c-btn-active {
	background-color: var(--theme-color);
	border-radius: 2px;
}
</style>