<template>
	<div class="">
		<div v-if="!dateOnly_prop" class="col-auto flex--align-items-end p-0 d-inline-block text-left">
			<form class="form-inline">
				<div class="color-white-c mr-16-c">
					Report Type
				</div>

				<select class="custom-select mr-32-c" v-model="reportType">
					<option value="all" class="bg-grey-1">ALL</option>
					<option value="day" class="bg-grey-1">DAILY</option>
					<option value="week" class="bg-grey-1">WEEKLY</option>
					<option value="month" class="bg-grey-1">MONTHLY</option>
					<option value="year" class="bg-grey-1">ANNUAL</option>
				</select>
			</form>
		</div>

		<div v-if="reportType != 'all'" class="col-auto p-0 d-inline-block">
			<div class="color-white-c fs-12-c mb-8-c">
				{{ !dateOnly_prop ? 'Report Period' : 'Date Range' }}
			</div>

			<form class="form-inline">
				<template v-if="reportType == 'day'">
					<div class="form-group mr-2">
						<label for="filterStartDateClient" class="sr-only">Start Date</label>
						<input type="date" class="form-control py-0 px-2" id="filterStartDateClient" min="2020-01-01"
							:max="endDateDay" v-model="startDateDayInInput">
					</div>

					<div class="form-group">
						<label for="filterEndDateDayClient" class="sr-only">End Date</label>
						<input type="date" class="form-control py-0 px-2" id="filterEndDateDayClient" :min="startDateDayInInput"
							:max="dateToday" v-model="endDateDay">
					</div>
				</template>

				<template v-if="reportType == 'week'">
					<input type="week" name="week" v-model.lazy="week" />
				</template>

				<template v-if="reportType == 'month'">
					<input type="month" name="" v-model="month" onkeydown="return false">
				</template>

				<template v-if="reportType == 'year'">
					<input type="number" min="1900" max="2099" step="1" v-model.lazy="year" class="datepicker-year" />
				</template>

			</form>
		</div>
	</div>
</template>

<script>

export default {
	name: 'FIlterDateAndType',

	props: {
		filterId: {
			type: String,
			default: String(Math.random())
		},

		dateOnly_prop: {
			type: Boolean,
			default: false
		}
	},

	data() {
		const today = new Date(Date.now())
		let todayDay = today.getDay()
		let tempDay = new Date(Date.now())
		let tempWeek = new Date(Date.now())
		let tempMonth = new Date(Date.now())
		let tempYear = new Date(Date.now())
		return {
			reportType: 'all',
			reportMonth: 'january',
			reportYear: '2019',

			dateToday: `${tempDay.getFullYear()}-${tempDay.getMonth() + 1 < 10 ? '0' : ''}${tempDay.getMonth() + 1}-${tempDay.getDate() < 10 ? '0' : ''}${tempDay.getDate()}`,

			startDateDay: new Date(tempDay.setDate(tempDay.getDate() - 1)),
			// startDateDay: '',
			endDateDay: `${tempDay.getFullYear()}-${tempDay.getMonth() + 1 < 10 ? '0' : ''}${tempDay.getMonth() + 1}-${tempDay.getDate() + 1 < 10 ? '0' : ''}${tempDay.getDate() + 1}`,
			// endDate: '',

			week: '',
			startDateWeek: new Date(tempWeek.setDate(tempWeek.getDate() - todayDay + 1)),
			endDateWeek: new Date(tempWeek.setDate(tempWeek.getDate() - todayDay + 7)),
			weeklyDateList: [],

			month: '',
			startDateMonth: new Date(tempMonth.setDate(1)),
			endDateMonth: new Date(tempMonth.getFullYear(), tempMonth.getMonth() + 1, 0),
			monthlyDateList: [],

			year: '',
			startDateYear: new Date(new Date(tempYear.setDate(1)).setMonth(0)),
			endDateYear: new Date(new Date(tempYear.setDate(1)).setMonth(11)),
			yearlyDateList: [],

			showReportType: true,
		}
	},

	computed: {
		startDateDayInInput: {
			get: function () {
				let temp = this.startDateDay
				if (temp) {
					return `${temp.getFullYear()}-${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}-${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				if (newValue) {
					this.startDateDay = new Date(newValue)
				} else {
					this.startDateDay = ''
					this.endDateDay = ''
				}
			}
		},

		startDateWeekInInput: {
			get: function () {
				let temp = this.startDateWeek
				if (temp) {
					return `${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateWeek = temp
			}
		},

		startDateMonthInInput: {
			get: function () {
				let temp = this.startDateMonth
				if (temp) {
					return `01/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateMonth = temp
			}
		},

		startDateYearInInput: {
			get: function () {
				let temp = this.startDateYear
				if (temp) {
					return `01/01/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				if (newValue) {
					let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
					this.startDateYear = temp
				}
			}
		},

		watcherChangeFilter() {
			return `${this.reportType}${this.startDateDay}${this.startDateWeek}${this.startDateMonth}${this.startDateYear}${this.endDateDay}`
		},
	},

	watch: {
		week() {
			this.changeWeeklyStartDate()
		},
		month() {
			this.changeMonthlyStartDate()
		},
		year() {
			this.changeAnnualStartDate()
		},

		endDateDay(newValue) {
			if (newValue == '') {
				this.startDateDay = ''
			}
		},

		watcherChangeFilter() {
			this.changeFilterDateAndType()
		},

		reportType(newValue) {
			if (newValue == 'week') {
				if (this.weeklyDateList.length < 1) {

					let todayDate = new Date(Date.now())
					let todayDay = todayDate.getDay()
					let nearestMondayDate = new Date(todayDate.setDate(todayDate.getDate() - todayDay + 1))

					while (nearestMondayDate.getFullYear() >= 2020) {
						this.weeklyDateList.push(`${nearestMondayDate.getDate() < 10 ? '0' : ''}${nearestMondayDate.getDate()}/${nearestMondayDate.getMonth() + 1 < 10 ? '0' : ''}${nearestMondayDate.getMonth() + 1}/${nearestMondayDate.getFullYear()}`)
						nearestMondayDate = new Date(nearestMondayDate.setDate(nearestMondayDate.getDate() - 7))
					}

				}
			} else if (newValue == 'month') {
				if (this.monthlyDateList.length < 1) {

					let todayDate = new Date(Date.now())
					let todayMonth = todayDate.getMonth() // 0 = January
					let monthDate = new Date(todayDate.getFullYear() + '-' + (todayMonth + 1 < 10 ? '0' : '') + String(todayMonth + 1) + '-01')

					while (monthDate.getFullYear() >= 2020) {
						this.monthlyDateList.push(`01/${monthDate.getMonth() + 1 < 10 ? '0' : ''}${monthDate.getMonth() + 1}/${monthDate.getFullYear()}`)
						monthDate = new Date(monthDate.setMonth(monthDate.getMonth() - 1))
					}

				}
			} else {
				if (this.yearlyDateList.length < 1) {

					let todayDate = new Date(Date.now())
					let yearDate = new Date(todayDate.getFullYear() + '-01-01')

					while (yearDate.getFullYear() >= 2020) {
						this.yearlyDateList.push(`01/01/${yearDate.getFullYear()}`)
						yearDate = new Date(yearDate.setFullYear(yearDate.getFullYear() - 1))
					}

				}
			}
		},
	},

	created() {
		if (this.dateOnly_prop) {
			this.reportType = 'day'
			this.showReportType = false
			this.startDateDay = ''
			this.endDateDay = ''
		}

		this.setDefaultWeek()
		this.setDefaultMonth()
		this.setDefaultAnnual()
	},

	methods: {
		setDefaultWeek() {
			const today = new Date()
			const firstDateYear = new Date(today.getFullYear(), 0, 1)
			const day = firstDateYear.getDay()

			let nextMonday
			if (day > 1) {
				nextMonday = 8 - day
			} else {
				nextMonday = 1 - day
			}

			const date = new Date(firstDateYear.setDate(firstDateYear.getDate() + nextMonday))

			const week = Math.floor(((today - date) / 86400000) / 7) + 1

			this.week = `${today.getFullYear()}-W${week > 10 ? week : '0' + week}`
		},

		changeWeeklyStartDate() {
			const year = parseInt(this.week.slice(0, 4), 10)
			const week = parseInt(this.week.slice(6), 10)

			const day = (1 + (week - 1) * 7) // 1st of January + 7 days for each week

			let dayOffset = new Date(year, 0, 1).getDay() // we need to know at what day of the week the year start

			dayOffset--  // depending on what day you want the week to start increment or decrement this value. This should make the week start on a monday

			const startDate = new Date(year, 0, day - dayOffset)
			const endDate = new Date(year, 0, day - dayOffset + 6)

			this.startDateWeek = startDate
			this.endDateWeek = endDate
		},

		setDefaultMonth() {
			const today = new Date()
			this.month = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}`
		},

		changeMonthlyStartDate() {
			const startDate = new Date(this.month)

			const dateTemp = new Date(startDate.getFullYear(), startDate.getMonth() + 1)

			const endDate = new Date(dateTemp.setDate(dateTemp.getDate() - 1))

			this.startDateMonth = startDate
			this.endDateMonth = endDate
		},

		setDefaultAnnual() {
			const today = new Date()
			this.year = today.getFullYear()
		},

		changeAnnualStartDate(annualValue = '') {
			let year = annualValue
			if (!annualValue) {
				year = this.year
			}

			const startDate = new Date(year, 0, 1)

			const dateTemp = new Date(startDate.getFullYear() + 1, 0, 1)

			const endDate = new Date(dateTemp.getFullYear(), 0, dateTemp.getDate() - 1)

			this.startDateYear = startDate
			this.endDateYear = endDate
		},

		changeFilterDateAndType() {
			let startDate = this.startDateDay
			let endDate = this.endDateDay
			let tempStart
			let tempEnd


			if (this.reportType == 'all') {
				startDate = ''
				endDate = ''
			} else if (this.reportType == 'day') {
				startDate = this.startDateDay
			} else if (this.reportType == 'week') {
				startDate = this.startDateWeek
				endDate = this.endDateWeek
			} else if (this.reportType == 'month') {
				startDate = this.startDateMonth
				endDate = this.endDateMonth
			} else {
				startDate = this.startDateYear
				endDate = this.endDateYear
			}

			if (startDate) {
				tempStart = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
				startDate = new Date(tempStart)

				if (this.reportType == 'day' && endDate == '') {
					this.endDateDay = tempStart
					endDate = startDate
				}
			}
			if (endDate) {
				tempEnd = endDate
				endDate = new Date(endDate)

				if (this.reportType == 'day' && startDate == '') {
					this.startDateDayInInput = tempEnd
					startDate = endDate
				}
			}
			let temp = {
				type: this.reportType,
				startDate: startDate,
				endDate: endDate
			}
			this.$emit('changeFilterDateAndType', temp)
		}
	}
}
</script>

<style scoped>
div.form-group {
	border-radius: 4px;
}

.form-group input.form-control {
	border-radius: 4px !important;
	height: 3.2em;
}

.custom-select {
	width: fit-content;
	height: 33px;
	padding: 0 0.5rem;
	background-color: transparent;
	border-color: rgb(43, 53, 83);
	font-size: 13px;
}
</style>