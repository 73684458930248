<template>
	<div class="wrap-content">
		<div v-if="dataTradeCon">
			<div class="page">
				<!-- <div class="subpage">Page 1/2</div>    -->
				<div class="header-logo mb-5">
					<img :src="dataTradeCon.trade.exchange_logo" :alt="dataTradeCon.trade.exchange_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.clearing_logo" :alt="dataTradeCon.trade.clearing_alias" style="width: 230px;">
					<!-- <img class="ml-3" v-if="dataTradeCon.transaction.flag_merchant" :src="dataTradeCon.transaction.logo_merchant" style="width: 100px; float:right"> -->
					<img :src="dataTradeCon.trade.broker_logo" :alt="dataTradeCon.trade.broker_alias"
						style="width: 230px; float:right">
				</div>
				<div class="header">
					KONFIRMASI PERDAGANGAN : {{ dataTradeCon.direction == 'buy' ? 'BELI' : 'JUAL' }}
				</div>
				<div class="content">
					<table style="width: 30%;
									margin-bottom: 30px;">
						<tr>
							<p>ID Referensi Order <span>:</span> {{ dataTradeCon.transaction.order_id }}</p>
						</tr>
						<tr>
							<p>ID Transaksi <span>:</span> {{ dataTradeCon.transaction.transaction_id }}</p>
						</tr>
						<tr>
							<p>ID Pemilik <span>:</span> {{ dataTradeCon.transaction.hin }}</p>
						</tr>
						<tr>
							<p>Tanggal Perdagangan <span>:</span> {{ dataTradeCon.transaction.date_pay }}</p>
						</tr>
					</table>
					<table class="table table-bordered ">
						<thead class="thead-dark">
							<tr>
								<th>Pihak Pembelian</th>
								<th>Pihak Penjualan</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>Nama <span>:</span> {{ dataTradeCon.buy.fullname }}</p>
									<p>Bank <span>:</span> <span><img :src="dataTradeCon.trade.imgBank" style="width: 75px;"></span></p>
									<p>HIN <span>:</span> {{ dataTradeCon.buy.hin }}</p>
									<p>ID Referensi Order <span>:</span> {{ dataTradeCon.buy.order_id }}</p>
									<p>ID Pendaftaran Perusahaan<span>:</span> {{ dataTradeCon.buy.member_code }}</p>
								</td>
								<td>
									<p>Nama <span>:</span> {{ dataTradeCon.sell.fullname }}</p>
									<p>Bank <span>:</span> <span><img :src="dataTradeCon.trade.imgBank" style="width: 75px;"></span></p>
									<p>HIN <span>:</span> {{ dataTradeCon.sell.hin }}</p>
									<p>ID Referensi Order <span>:</span> {{ dataTradeCon.sell.order_id }}</p>
									<p>ID Pendaftaran Perusahaan <span>:</span> {{ dataTradeCon.sell.member_code }}</p>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="table table-bordered ">
						<thead>
							<tr class="thead-dark">
								<th colspan="7">Transaksi Untuk Kontrak: {{ dataTradeCon.trade.contract_name }}</th>
							</tr>
							<tr style="text-align: center;" class="thead-light">
								<th>Deskripsi</th>
								<th>QTY</th>
								<th>Mata Uang</th>
								<th>Jumlah</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div> {{ dataTradeCon.trade.contract_name }} {{ dataTradeCon.trade.contract_type }}</div>
								</td>
								<td>
									<div> {{ dataTradeCon.transaction.quantity }}</div>
								</td>
								<td>
									<div>Rupiah</div>
								</td>
								<td>
									<div>Rp {{ dataTradeCon.transaction.amount_idr }}</div>
								</td>
								<td>
									<div>Rp {{ dataTradeCon.transaction.amount_idr }}</div>
								</td>
							</tr>
							<tr>
								<td>Biaya Eksekusi (1%)</td>
								<td></td>
								<td>Rupiah</td>
								<td>Rp {{ dataTradeCon.transaction.fee_idr }}</td>
								<td>Rp {{ dataTradeCon.transaction.fee_idr }}</td>
							</tr>
							<tr>
								<td>Pajak (0.1%)</td>
								<td></td>
								<td>Rupiah</td>
								<td>Rp {{ dataTradeCon.transaction.tax_idr }}</td>
								<td>Rp {{ dataTradeCon.transaction.tax_idr }}</td>
							</tr>
							<tr>
								<td>Total</td>
								<td></td>
								<td></td>
								<td></td>
								<td>Rp {{ dataTradeCon.transaction.total_amount_idr }}</td>
							</tr>
						</tbody>
					</table>
					<table class="table table-bordered">
						<thead>
							<tr class="thead-dark">
								<th>Status Penyelesaian: Penyelesaian segera lewat Pembayaran</th>
								<th colspan="2">Status Vault Bullion: Didepositkan</th>
							</tr>
							<tr class="thead-light" style="text-align: center ;">
								<th>Lokasi</th>
								<th>Tanggal Nilai</th>
								<th>Tanggal Pengiriman*</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Jakarta</td>
								<td>
									<div> {{ dataTradeCon.transaction.date_pay }}</div>
									<!-- <div>14-12-2018 02:39:05 EST</div> -->
								</td>
								<td>
									{{ dataTradeCon.transaction.date_deposit }}
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<p>* Kontrak berlaku untuk Pengiriman Standar, dalam hal Tanggal Pengiriman yang telah
										ditentukan jatuh pada non-Hari Perdagangan dalam Trading Hub, maka akan diberlakukan
										pada Tanggal Pengiriman Hari Perdagangan berikutnya.</p>
									Kotrak yang berlaku untuk Pengiriman Tiga Hari,ketika non-Hari Perdagangan terjadi dalam
									Trading Hub antara Tanggal Nilai dan/atau Tanggal Pengiriman, maka Tanggal Pengiriman
									akan diperpanjang sama dengan jumlah Hari Perdagangan.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="footer">
					<div style="text-align: center;">Page 1/4 <span style="float: right;">DOC-ID: {{ documentId }}</span></div>
				</div>
			</div>
			<div class="page">
				<!-- <div class="header">
								Terms
						</div> -->
				<div class="header-logo mb-5">
					<img :src="dataTradeCon.trade.exchange_logo" :alt="dataTradeCon.trade.exchange_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.clearing_logo" :alt="dataTradeCon.trade.clearing_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.broker_logo" :alt="dataTradeCon.trade.broker_alias"
						style="width: 230px; float:right">
				</div>
				<div class="content">
					<div>
						<span style="font-size: 17px; color: #a5a5a5;">Ketentuan</span>
						<div class="row">
							<ul>
								<li>1.1 Konfirmasi Perdagangan ini dikeluarkan oleh {{ dataTradeCon.trade.exchange_alias }} dan tunduk
									pada
									ketentuan berikut :
									<ul class="pl-4">
										<li>1.1.1 Perjanjian Anggota yang mengatur dan Kerangka Jaminan Kualitas sebagaimana
											dikeluarkan dan
											diperbarui oleh {{ dataTradeCon.trade.exchange_name }} dari waktu ke waktu;</li>
										<li>1.1.2 Kebiasaan dan penggunaan Bursa; dan</li>
										<li>1.1.3 Koreksi kesalahan dan kelalaian.</li>
									</ul>
								</li>
								<li>1.2 Nasabah selalu memastikan bahwa semua detail order yang tercantum di sini adalah
									benar.</li>
								<li>1.3 Order nasabah mungkin telah dieksekusi sebagai beberapa transaksi di beberapa vault
									di Hub Perdagangan.
									Konfirmasi Perdagangan ini menggabungkan pemecahan transaksi Pasar dan penetapan harga
									agregat untuk
									Bullion yang menjadi subjek Konfirmasi Perdagangan ini.
								</li>
								<li>1.4 Dalam melaksanakan order ini, Nasabah dapat meminta {{ dataTradeCon.trade.exchange_name }} untuk
									mendebit dari
									Saldo Akun mereka dan / atau Kepemilikan mereka, dengan jumlah yang sama dengan total
									pertimbangan
									order tersebut dan biaya tambahan apa pun termasuk tetapi tidak terbatas pada
									pertimbangan perdagangan
									dan / atau Biaya Eksekusi. Untuk menghindari keraguan, Nasabah memberikan hak kepada
									{{ dataTradeCon.trade.exchange_name }} untuk memungut kredit di bawah semua pembayaran yang diterima
									untuk
									kepentingan
									Nasabah terhadap setiap hutang yang terhutang pada Akun Bullion Nasabah.</li>
								<li>1.5 Nasabah mengakui bahwa ketika telah membeli Bullion Untuk Didepositkan, maka Bullion
									mungkin tidak
									segera tersedia untuk dilakukan penarikan. Meskipun dalam Klausul
									1.5 ini, dikatakan bahwa Bullion Untuk Didepositkan harus tersedia untuk diperdagangkan
									dan dikirimkan
									sesuai dengan Perjanjian Anggota dan Kerangka Jaminan Kualitas.</li>
								<li>1.6 Ketika Nasabah meminta Konfirmasi Perdagangan untuk dikirim melalui pos; maka akan
									dikenakan biaya $
									2,00. Atas persetujuan Nasabah ongkos kirim didebit dari Saldo Akunnya.</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="footer">
					<div style="text-align: center;">Page 2/4 <span style="float: right;">DOC-ID: {{ documentId }}</span></div>
				</div>
			</div>
			<div class="page">
				<!-- <div class="subpage">Page 1/2</div>    -->
				<div class="header-logo mb-5">
					<img :src="dataTradeCon.trade.exchange_logo" :alt="dataTradeCon.trade.exchange_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.clearing_logo" :alt="dataTradeCon.trade.clearing_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.broker_logo" :alt="dataTradeCon.trade.broker_alias"
						style="width: 230px; float:right">
				</div>
				<div class="header">
					TRADE CONFIRMATION: {{ dataTradeCon.direction == 'buy' ? 'BUY' : 'SELL' }}
				</div>
				<div class="content">
					<table style="width: 30%;
									margin-bottom: 30px;">
						<tr>
							<p>Order Reference ID <span>:</span> {{ dataTradeCon.transaction.order_id }}</p>
						</tr>
						<tr>
							<p>Transaction ID <span>:</span> {{ dataTradeCon.transaction.transaction_id }}</p>
						</tr>
						<tr>
							<p>Holder ID <span>:</span> {{ dataTradeCon.transaction.hin }}</p>
						</tr>
						<tr>
							<p>Trade Date <span>:</span> {{ dataTradeCon.transaction.date_pay }}</p>
						</tr>
					</table>
					<table class="table table-bordered ">
						<thead class="thead-dark">
							<tr>
								<th>Purchasing Party</th>
								<th>Selling Party</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<p>Name <span>:</span> {{ dataTradeCon.buy.fullname }}</p>
									<p>Bank <span>:</span> <span><img :src="dataTradeCon.trade.imgBank" style="width: 75px;"></span></p>
									<p>HIN <span>:</span> {{ dataTradeCon.buy.hin }}</p>
									<p>Order Reference ID <span>:</span> {{ dataTradeCon.buy.order_id }}</p>
									<p>Company Registration ID <span>:</span> {{ dataTradeCon.buy.member_code }}</p>
								</td>
								<td>
									<p>Name <span>:</span> {{ dataTradeCon.sell.fullname }}</p>
									<p>Bank <span>:</span> <span><img :src="dataTradeCon.trade.imgBank" style="width: 75px;"></span></p>
									<p>HIN <span>:</span> {{ dataTradeCon.sell.hin }}</p>
									<p>Order Reference ID <span>:</span> {{ dataTradeCon.sell.order_id }}</p>
									<p>Company Registration ID <span>:</span> {{ dataTradeCon.sell.member_code }}</p>
								</td>
							</tr>
						</tbody>
					</table>
					<table class="table table-bordered ">
						<thead>
							<tr class="thead-dark">
								<th colspan="7">Transaction For Contract: {{ dataTradeCon.trade.contract_name }}</th>
							</tr>
							<tr style="text-align: center;" class="thead-light">
								<th>Description</th>
								<th>QTY</th>
								<th>Currency</th>
								<th>Amount</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div> {{ dataTradeCon.trade.contract_name }} {{ dataTradeCon.trade.contract_type }}</div>
								</td>
								<td>
									<div> {{ dataTradeCon.transaction.quantity }}</div>
								</td>
								<td>
									<div>USD</div>
								</td>
								<td>
									<div>$ {{ dataTradeCon.transaction.amount_usd }}</div>
								</td>
								<td>
									<div>$ {{ dataTradeCon.transaction.amount_usd }}</div>
								</td>
							</tr>
							<tr>
								<td>Execution Fee (1%)</td>
								<td></td>
								<td>USD</td>
								<td>$ {{ dataTradeCon.transaction.fee_usd }}</td>
								<td>$ {{ dataTradeCon.transaction.fee_usd }}</td>
							</tr>
							<tr>
								<td>Tax (0.1%)</td>
								<td></td>
								<td>USD</td>
								<td>$ {{ dataTradeCon.transaction.tax_usd }}</td>
								<td>$ {{ dataTradeCon.transaction.tax_usd }}</td>
							</tr>
							<tr>
								<td>Total</td>
								<td></td>
								<td></td>
								<td></td>
								<td>$ {{ dataTradeCon.transaction.total_amount_usd }}</td>
							</tr>
						</tbody>
					</table>
					<table class="table table-bordered">
						<thead>
							<tr class="thead-dark">
								<th>Settlement Status: Immediate Settlement By Payment</th>
								<th colspan="2">Bullion Vault Status: Deposited</th>
							</tr>
							<tr class="thead-light" style="text-align: center ;">
								<th>Locations</th>
								<th>Value Date</th>
								<th>Delivery Date*</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Jakarta</td>
								<td>
									<div> {{ dataTradeCon.transaction.date_pay }}</div>
								</td>
								<td>
									{{ dataTradeCon.transaction.date_deposit }}
								</td>
							</tr>
							<tr>
								<td colspan="3">
									<p>* Where a Contract is subject to Standard Delivery, in the event the specified
										Delivery Date falls on a non- Business Day within the Trading Hub, the Delivery Date
										shall be the next Business Day.</p>
									Where a Contract is subject to Three Day Delivery, in the event a non-Business Day
									occurs within the Trading Hub between the Value Date and / or on the Delivery Date, the
									Delivery Date shall be extended by an equal number of Business Days.
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="footer">
					<div style="text-align: center;">Page 3/4 <span style="float: right;">DOC-ID: {{ documentId }}</span></div>
				</div>
			</div>
			<div class="page">
				<!-- <div class="header">
								Terms
						</div> -->
				<div class="header-logo mb-5">
					<img :src="dataTradeCon.trade.exchange_logo" :alt="dataTradeCon.trade.exchange_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.clearing_logo" :alt="dataTradeCon.trade.clearing_alias" style="width: 230px;">
					<img :src="dataTradeCon.trade.broker_logo" :alt="dataTradeCon.trade.broker_alias"
						style="width: 230px; float:right">
				</div>
				<div class="content">
					<div>
						<span style="font-size: 17px; color: #a5a5a5;">Terms</span>
						<div class="row">
							<ul>
								<li>1.1 This Trade Confirmation is issued by {{ dataTradeCon.trade.exchange_alias }} and is issued subject
									to:
									<ul>
										<li>1.1.1 the governing Member Agreement and Quality Assurance Framework as issued
											and updated by {{ dataTradeCon.trade.exchange_name }} from time to time;</li>
										<li>1.1.2 the customs and usage of the Exchange; and</li>
										<li>1.1.3 the correction of errors and omissions.</li>
									</ul>
								</li>
								<li>1.2 Customer undertakes to ensure all order details contained herein are correct.</li>
								<li>1.3 Customer's order may have been executed as multiple transactions across multiple
									vaults in a Trading Hub. This Trade Confirmation consolidates the breakdown of Market
									transactions and aggregates pricing for Bullion the subject of this Trade Confirmation.
								</li>
								<li>1.4 In executing this order, Customer requests {{ dataTradeCon.trade.exchange_name }} to debit from
									their Account Balance and/ or their Holdings, an amount equal to the total consideration
									of said order and any ancillary costs including but not limited to trade consideration
									and/or Execution Fees. For the avoidance of doubt, Customer grants {{ dataTradeCon.trade.exchange_name
									}}
									the right to levy credits under all payments received for the benefit of
									Customer against any debits owed against Customer's Bullion Account.</li>
								<li>1.5 Customer acknowledges that where it has purchased To Be Deposited Bullion, said
									Bullion may not be immediately available for withdrawal. Notwithstanding this Clause
									1.5, said To Be Deposited Bullion shall be available to be traded and delivered in
									accordance with it's Member Agreement and Quality Assurance Framework.</li>
								<li>1.6 Where Customer requests a Trade Confirmation to be sent by post; a $2.00 fee will be
									charged. Customer requests said postage fee be debited from its Account Balance.
									<div id="waiter" class="vis-hidden">
										.
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="footer">
					<div style="text-align: center;">Page 4/4 <span style="float: right;">DOC-ID: {{ documentId }}</span></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'

export default {

	name: 'TradeConfirmation',

	data() {
		return {
			payload: {},
			dataTradeCon: {},


			documentId: '-',
		}
	},

	async created() {
		this.payload = this.$route.query

		this.generatePdf()
	},

	mounted() {
	},

	methods: {
		generatePdf() {
			axios({
				method: 'GET',
				url: '/v2/trade/confirmation_data?order_match_id=' + this.payload.order_match_id + '&direction=' + this.payload.direction + '&token=' + this.payload.token,
				headers: {
					common: {
						Authorization: ''
					}
				}
				// data: {
				// 	order_match_id : this.payload.order_match_id,
				// 	direction: this.payload.direction
				// }
			}).then(response => {
				const data = response.data.data.body
				this.dataTradeCon = structuredClone(data)

				if (data.direction.toLowerCase() == 'buy') {

					this.$set(this.dataTradeCon, 'transaction', data.buy)
					this.documentId = data.document.document_buy

				} else {
					this.$set(this.dataTradeCon, 'transaction', data.sell)
					this.documentId = data.document.document_buy
				}

				setTimeout(() => {
					document.getElementById('waiter').classList.remove('vis-hidden')
				}, 2000)
			}).catch(error => {
				if (error.response) {
				} else if (error.request) {
					// Something happened in setting up the request and triggered an Error
				}

			})
		}
	}
}
</script>

<style lang="css" scoped>
body {
	width: 100%;
	height: 100%;
	/*		margin: 0;*/
	padding: 0;
}

.wrap-content {
	font-size: 26px !important;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

* {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
}

ul,
li {
	list-style: none;
}

.page {
	/*width: 210mm;
			min-height: 324mm;*/
	width: 400mm;
	min-height: 565mm;
	padding: 10mm;
	/*			 margin: 10mm auto; */
	border: 1px #D3D3D3 solid;
	border-radius: 5px;
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
	position: relative;
}

.subpage {
	padding: 1cm;
	border: 5px red solid;
	height: 257mm;
	outline: 2cm #FFEAEA solid;
}

.sub-title p {
	margin-bottom: 0;
	width: 130px;
}


.header {
	font-size: 30px;
	font-weight: bold;
	border-bottom: solid #aaa;
	padding-bottom: 5px;
	margin-bottom: 20px;
}

.footer {
	position: absolute;
	bottom: 0px;
	width: 90%;
	height: 55px;
	border-top: solid 1px #D3D3D3;
}

.header-logo {
	position: relative;
	width: 90%;
	height: 75px;
}

p {
	color: black;
}

.table>tbody>tr>td {
	color: black !important;
}

.thead-dark th {
	color: white !important;
}

.thead-light {
	color: white !important;
}

.table-bordered td,
.table-bordered th {
	border: 1px solid #dee2e6;
}

.table .thead-dark th {
	border-color: #454d55;
	font-size: 20px;
}

.table .thead-light th {
	font-size: 20px;
}


ul li,
ol li {
	color: black;
}
</style>
