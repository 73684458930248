<template>
	<div class="content">
		<ul class="nav nav-pills nav-pills-primary justify-content-center" role="tablist">
			<li class="nav-item">
				<a class="nav-link active" data-toggle="tab" href="#nav1-link1" role="tablist" aria-expanded="true">
					TRADING
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-link2" role="tablist" aria-expanded="false">
					ACCOUNT
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-link3" role="tablist" aria-expanded="false">
					PAYMENT
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-link6" role="tablist" aria-expanded="false">
					PASSWORD
				</a>
			</li>
		</ul>
		<div class="tab-content tab-space">
			<!-- Trading -->
			<div class="tab-pane active" id="nav1-link1" aria-expanded="true">
				<div class="row justify-content-center">
					<div class="col-md-4">
						<h4>GENERAL</h4>
						<div class="form-group row">
							<label for="exampleFormControlSelect1" class="col-sm-4 col-form-label">MARKET</label>
							<div class="col-sm-8">
								<select class="custom-select c-select">
									<option value="1" disabled>GLOBAL</option>
									<option value="2" selected>INDONESIA</option>
									<!-- <option value="2">Foobar</option>
									<option value="3">Is great</option> -->
								</select>
							</div>
						</div>
						<hr>
						<h4>YOUR DETAILS</h4>
						<div class="form-group row">
							<label for="tradingFirstName" class="col-sm-4 col-form-label">FIRST NAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="tradingFirstName" v-model="trading.firstName"
									disabled>
							</div>
						</div>
						<div class="form-group row">
							<label for="tradingLastName" class="col-sm-4 col-form-label">LAST NAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="tradingLastName" v-model="trading.lastName"
									readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="tradingUsername" class="col-sm-4 col-form-label">USERNAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="tradingUsername" v-model="trading.username"
									readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="tradingEmail" class="col-sm-4 col-form-label">EMAIL</label>
							<div class="col-sm-8">
								<input type="email" class="form-control-plaintext" id="tradingEmail" v-model="trading.email" readonly>
							</div>
						</div>

						<div class="form-group row">
							<label for="referralCode" class="col-sm-4 col-form-label">REFERRAL CODE</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="referralCode"
									v-model="this.$store.getters.getReferralCode" readonly>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center text-center">
					<div class="col-md-6">
						<div class="alert alert-default" role="alert">
							<i class="tim-icons icon-alert-circle-exc"></i>&ensp;Please contact your brokerage firm to change your
							account details.
						</div>
					</div>
				</div>
				<br>
				<!-- <div class="row justify-content-center">
					<div class="col-auto">
						<button type="button" class="btn btn-success">UPDATE</button>
					</div>
				</div> -->
			</div>
			<!-- Account -->
			<div class="tab-pane" id="nav1-link2" aria-expanded="false">
				<div class="row justify-content-center">
					<div class="col-12 text-center">
						<div class="rounded c-balance p-3 mb-4" id="balance-usd">
							HIN: {{ hin }}
						</div>
					</div>
				</div>
				<div class="row justify-content-center mb-4">
					<div class="col-md-6 mb-4 mb-md-0 vertical-line">
						<h4>CONTACT DETAILS</h4>
						<div class="form-group row">
							<label for="accountBusinessName" class="col-sm-4 col-form-label">BUSINESS NAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="accountBusinessName" style="color: white"
									v-model="account.businessName" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="accountFirstName" class="col-sm-4 col-form-label">FIRST NAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="accountFirstName" v-model="account.firstName"
									readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="accountLastName" class="col-sm-4 col-form-label">LAST NAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="accountLastName" v-model="account.lastName"
									readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="accountEmail" class="col-sm-4 col-form-label">EMAIL</label>
							<div class="col-sm-8">
								<input type="email" class="form-control-plaintext" id="accountEmail" v-model="account.email" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="accountPhoneNumber" class="col-sm-4 col-form-label">PHONE NUMBER</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="accountPhoneNumber" v-model="account.phoneNumber"
									readonly>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<h4>POSTAL ADDRESS</h4>
						<div class="form-group row">
							<label for="street" class="col-sm-4 col-form-label">STREET</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="street" v-model="account.street" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="city" class="col-sm-4 col-form-label">CITY / SUBURB</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="city" v-model="account.city" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="province" class="col-sm-4 col-form-label">STATE / PROVINCE</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="province" v-model="account.province" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="postCode" class="col-sm-4 col-form-label">POSTCODE</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="postCode" v-model="account.postcode" readonly>
							</div>
						</div>
						<div class="form-group row">
							<label for="country" class="col-sm-4 col-form-label">COUNTRY</label>
							<div class="col-sm-8" v-if="account.country">
								<input type="text" class="form-control-plaintext" id="country" v-model="account.country" readonly>
								<!-- <select class="selectpicker" data-size="2" data-style="btn btn-primary">
									<option selected>{{account.country}}</option>
								</select> -->
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col-md-6 text-center">
						<div class="alert alert-default" role="alert">
							<i class="tim-icons icon-alert-circle-exc"></i>&ensp;Please contact your brokerage firm to change your
							account details.
						</div>
						<!-- <button type="button" class="btn btn-success">SEND REQUEST</button> -->
					</div>
				</div>
			</div>

			<!-- Payment -->
			<div class="tab-pane" id="nav1-link3" aria-expanded="false">
				<div class="row justify-content-center mb-3">
					<div class="col-12 text-center">
						<h4>BANK DETAILS</h4>
					</div>
				</div>
				<div v-if="paymentInfo" class="row mb-4">
					<div class="col-12 col-md-6 mb-4 mb-md-0 vertical-line">
						<!-- <h4 v-if="tradingType == 'kinesis'" class="text-center">COMMODITY ACCOUNT (RPED) KAU</h4> -->
						<h4 class="text-center">COMMODITY ACCOUNT (RPED)</h4>
						<!-- <template v-if="tradingType == 'kinesis'">
							<div class="form-group row">
								<label for="paymentCommodityBankName" class="col-sm-4 col-form-label">BANK NAME</label>
								<div class="col-sm-8">
									<input type="text" class="form-control-plaintext" id="paymentCommodityBankName" placeholder="-" v-model="paymentInfo.rdk_kinesis.bank_name" disabled>
								</div>
							</div>
							<div class="form-group row">
								<label for="paymentCommodityAccountName" class="col-sm-4 col-form-label">ACCOUNT NAME</label>
								<div class="col-sm-8">
									<input type="text" class="form-control-plaintext" id="paymentCommodityAccountName" placeholder="-" v-model="paymentInfo.rdk_kinesis.account_name" disabled>
								</div>
							</div>
							<div class="form-group row">
								<label for="paymentCommodityAccountNumber" class="col-sm-4 col-form-label">ACCOUNT NUMBER</label>
								<div class="col-sm-8">
									<input type="text" class="form-control-plaintext" id="paymentCommodityAccountNumber" placeholder="-" v-model="paymentInfo.rdk_kinesis.account_number" disabled>
								</div>
							</div>
							<div class="form-group row">
								<label for="paymentCommodityCurrency" class="col-sm-4 col-form-label">CURRENCY</label>
								<div class="col-sm-8">
									<input type="text" class="form-control-plaintext" id="paymentCommodityCurrency" placeholder="-" v-model="paymentInfo.rdk_kinesis.currency" disabled>
								</div>
							</div>
							<div class="form-group row">
								<label for="paymentCommoditySwiftCode" class="col-sm-4 col-form-label">SWIFT CODE</label>
								<div class="col-sm-8">
									<input type="text" class="form-control-plaintext" id="paymentCommoditySwiftCode" placeholder="For costumers outside of Indonesia" v-model="paymentInfo.rdk_kinesis.swift_code" disabled>
								</div>
							</div>
							<div class="form-group row">
								<label for="paymentCommodityBankAddress" class="col-sm-4 col-form-label">BANK ADDRESS</label>
								<div class="col-sm-8">
									<input type="text" class="form-control-plaintext" id="paymentCommodityBankAddress" disabled>
								</div>
							</div>
						</template> -->
						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>BANK NAME</div>
							<div class="color-white-c text-left">{{ paymentInfo.apd.bank_name }}</div>
						</div>
						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>ACCOUNT NAME</div>
							<div class="color-white-c text-left">{{ paymentInfo.apd.account_name }}</div>
						</div>
						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>ACCOUNT NUMBER</div>
							<div class="color-white-c text-left">{{ paymentInfo.apd.account_number }}</div>
						</div>
						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>CURRENCY</div>
							<div class="color-white-c text-left">{{ paymentInfo.apd.currency }}</div>
						</div>
						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>SWIFT CODE</div>
							<div class="color-white-c text-left">{{ paymentInfo.apd.swift_code }}</div>
						</div>
						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>BANK ADDRESS</div>
							<div class="color-white-c text-left">{{ paymentInfo.apd.bank_address }}</div>
						</div>

					</div>
					<div class="col-12 col-md-6">
						<h4 class="text-center">UTILITY ACCOUNT</h4>

						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>INSTITUTION NAME</div>
							<div class="color-white-c text-left">
								<div class="btn-group dropright pl-1">
									<button class="btn btn-secondary dropdown-toggle m-0" type="button" id="dropdownMenuWithdrawDestination"
										data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										<img v-if="institutionLogo != ''" :src="institutionLogo" style="height: auto; width: 30px;">
										<span class="d-inline mx-3 text-uppercase">{{ institution }}</span>
									</button>
									<div v-if="this.$store.state.bankType != 'BAGI'" class="dropdown-menu"
										aria-labelledby="dropdownMenuWithdrawDestination">
										<span class="dropdown-item-text text-white">BANK</span>
										<button :class="['dropdown-item', institution == 'cimb' ? 'active' : '']" type="button"
											@click="institutionAfterClose('cimb')">
											<img :src="`${publicPath}assets/img/logo-cimb-3.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">CIMB Niaga</span>
										</button>
										<button :class="['dropdown-item', institution == 'bca' ? 'active' : '']" type="button"
											@click="institutionAfterClose('bca')">
											<img :src="`${publicPath}assets/img/logo-bca-2.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">BCA</span>
										</button>
										<button :class="['dropdown-item', institution == 'mandiri' ? 'active' : '']" type="button"
											@click="institutionAfterClose('mandiri')">
											<img :src="`${publicPath}assets/img/logo-mandiri-3.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Mandiri</span>
										</button>
										<button :class="['dropdown-item', institution == 'bni' ? 'active' : '']" type="button"
											@click="institutionAfterClose('bni')">
											<img :src="`${publicPath}assets/img/logo-bni-2.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">BNI</span>
										</button>

										<div class="dropdown-divider"></div>

										<span class="dropdown-item-text">E-WALLET</span>
										<button :class="['dropdown-item', institution == 'giropos' ? 'active' : '']" type="button"
											@click="institutionAfterClose('giropos')">
											<img :src="`${publicPath}assets/img/pos-giro.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Giropos</span>
										</button>
										<button :class="['dropdown-item', institution == 'rekpon' ? 'active' : '']" type="button"
											@click="institutionAfterClose('rekpon')">
											<img :src="`${publicPath}assets/img/logo-cimb-3.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Rekpon</span>
										</button>
										<button :class="['dropdown-item', institution == 'ovo' ? 'active' : '']" type="button"
											@click="institutionAfterClose('ovo')">
											<img :src="`${publicPath}assets/img/logo-ovo-3.jpg`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Ovo</span>
										</button>
										<button :class="['dropdown-item', institution == 'gopay' ? 'active' : '']" type="button"
											@click="institutionAfterClose('gopay')">
											<img :src="`${publicPath}assets/img/logo-gopay-2.jpg`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Gopay</span>
										</button>
										<button :class="['dropdown-item', institution == 'linkaja' ? 'active' : '']" type="button"
											@click="institutionAfterClose('linkaja')">
											<img :src="`${publicPath}assets/img/logo-linkaja.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Linkaja</span>
										</button>
										<button :class="['dropdown-item', institution == 'dana' ? 'active' : '']" type="button"
											@click="institutionAfterClose('dana')">
											<img :src="`${publicPath}assets/img/logo-dana-2.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Dana</span>
										</button>
										<button :class="['dropdown-item', institution == 'isaku' ? 'active' : '']" type="button"
											@click="institutionAfterClose('isaku')">
											<img :src="`${publicPath}assets/img/logo-isaku.jpg`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Isaku</span>
										</button>
										<!-- <button class="dropdown-item" type="button">Another action</button>
										<button class="dropdown-item" type="button">Something else here</button> -->
									</div>
									<div v-else class="dropdown-menu" aria-labelledby="dropdownMenuWithdrawDestination">
										<span class="dropdown-item-text text-white pl-3">BANK</span>
										<button :class="['dropdown-item', institution == 'Artha Graha' ? 'active' : '']" type="button"
											@click="institutionAfterClose('Artha Graha')">
											<img :src="`${publicPath}assets/img/logo-bagi-removebg.png`" style="height: 30px; width: auto;">
											<span class="d-inline mx-3 text-uppercase">Artha Graha</span>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>ACCOUNT NAME</div>
							<div class="color-white-c text-left">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].account_name }}
							</div>
						</div>

						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>SWIFT CODE</div>
							<div class="color-white-c text-left">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].bank_code }}</div>
						</div>

						<div class="flex flex--justify-content-between align-items-center flex--same-width mb-28-c">
							<div>INSTITUTION ADDRESS</div>
							<div class="color-white-c text-left">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].bank_address }}
							</div>
						</div>

					</div>
				</div>
				<div class="row justify-content-center text-center">
					<div class="col-md-6">
						<div class="alert alert-default" role="alert">
							<i class="tim-icons icon-alert-circle-exc"></i>&ensp;Please contact your brokerage firm to change your
							account details.
						</div>
					</div>
				</div>
				<!-- <div class="row justify-content-center">
					<div class="col-12 col-md-5 text-center">
						<div class="form-group row">
							<label for="paymentInstitutionName" class="col-sm-4 col-form-label text-left">INSTITUTION NAME</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="paymentInstitutionName" >
							</div>
						</div>
						<div class="form-group row">
							<label for="paymentInstitutionAddress" class="col-sm-4 col-form-label text-left">INSTITUTION ADDRESS</label>
							<div class="col-sm-8">
								<input type="text" class="form-control-plaintext" id="paymentInstitutionAddress" >
							</div>
						</div>
					</div>
				</div> -->
			</div>
			<!-- Fees -->
			<div class="tab-pane" id="nav1-link4" aria-expanded="false">
				<div class="row justify-content-around">
					<div class="col-md-5">
						<h4>My Account Fees</h4>
						<ul class="nav nav-tabs justify-content-center" data-tabs="tabs">
							<li class="nav-item">
								<a class="nav-link active" href="#execution" data-toggle="tab">Execution Fees</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#storage" data-toggle="tab">Storage Fees</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#e-warrant" data-toggle="tab">E-Warrant Fees</a>
							</li>
						</ul>
						<div class="tab-content text-center">
							<div class="tab-pane active" id="execution">
								<div class="card text-left">
									<div class="card-body c-card-body">
										<p>FEE RATE (%)<span class="float-right" style="color: #1ebbe4;">1.00</span></p>
									</div>
								</div>
							</div>
							<div class="tab-pane" id="storage">
								<ul class="nav nav-pills justify-content-center nav-pills-icons" data-tabs="tabs">
									<li class="nav-item">
										<a class="nav-link active" href="#gold" data-toggle="tab">Gold</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#silver" data-toggle="tab">Silver</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#platinum" data-toggle="tab">Platinum</a>
									</li>
								</ul>
								<br>
								<div class="tab-content text-center">
									<div class="tab-pane active" id="gold">
										<div class="card text-left">
											<div class="card-body c-card-body">
												<p>FEE RATE (%)<span class="float-right" style="color: #1ebbe4;">1.00</span></p>
											</div>
										</div>
									</div>
									<div class="tab-pane" id="silver">
										<div class="card text-left">
											<div class="card-body c-card-body">
												<p>FEE RATE (%)<span class="float-right" style="color: #1ebbe4;">1.sd00</span></p>
											</div>
										</div>
									</div>
									<div class="tab-pane" id="platinum">
										<div class="card text-left">
											<div class="card-body c-card-body" style="padding: 15px 40px;">
												<p>FEE RATE (%)<span class="float-right" style="color: #1ebbe4;">1.00</span></p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane" id="e-warrant">
								<ul class="nav nav-pills justify-content-center nav-pills-icons" data-tabs="tabs">
									<li class="nav-item">
										<a class="nav-link active" href="#certification" data-toggle="tab">Certification</a>
									</li>
									<li class="nav-item">
										<a class="nav-link" href="#cancellation" data-toggle="tab">Cancellation</a>
									</li>
								</ul>
								<br>
								<div class="tab-content text-center">
									<div class="tab-pane active" id="certification">
										<h5>COST PER CONTRACT ($)</h5>
										<div class="card text-left" style="background-color: rgba(0,0,0,0);">
											<ul class="list-group list-group-flush">
												<li v-for="certification in certifications" :key="certification.id"
													class="list-group-item c-card-body" style="background-color: #27293d;">
													<p>{{ certification.country }}<span class="float-right" style="color: #1ebbe4;">{{
														certification.total }}</span></p>
												</li>
											</ul>
										</div>
									</div>
									<div class="tab-pane" id="cancellation">
										<h5>COST PER CONTRACT ($)</h5>
										<div class="card text-left" style="background-color: rgba(0,0,0,0);">
											<ul class="list-group list-group-flush">
												<li v-for="cancellation in cancellations" :key="cancellation.id"
													class="list-group-item c-card-body" style="background-color: #27293d;">
													<p>{{ cancellation.country }}<span class="float-right" style="color: #1ebbe4;">{{
														cancellation.total }}</span></p>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-around">
					<div class="col-md-5">
						<h4>WITHDRAWAL FEES</h4>
						<div class="card text-left">
							<div class="card-body" style="min-height: 100px;">
								<p>Fees as agreed with your member/broker.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Tax -->
			<div class="tab-pane" id="nav1-link5" aria-expanded="false">
				<div class="row justify-content-center">
					<div class="col-md-4">
						<h4>MY ACCOUNT TAX RATE</h4>
						<div class="card text-left">
							<div class="card-body">
								<p>Tax Rate <span class="float-right">10.00%</span></p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Security -->
			<div class="tab-pane" id="nav1-link6" aria-expanded="false">
				<div class="row justify-content-center mb-3">
					<div class="col-12 text-center">
						<h4>CHANGE PASSWORD</h4>
					</div>
				</div>
				<form @submit.prevent="changePasswordSubmit">
					<div class="row justify-content-center">
						<div class="col-11 text-center" style="max-width: 400px;">
							<div class="form-group row">
								<label for="oldPassword" class="col-12 col-form-label text-left">OLD PASSWORD</label>
								<div class="col-12">
									<input type="password" class="form-control" id="oldPassword" autocomplete="off" required
										v-model="oldPassword">
								</div>
							</div>
							<div class="form-group row">
								<label for="newPassword" class="col-12 col-form-label text-left">NEW PASSWORD</label>
								<div class="col-12">
									<input type="password" class="form-control" id="newPassword" autocomplete="off" required
										v-model="newPassword" @blur="outOfNewPassword = true">
									<code class="text-left" :style="{ display: displayNewPasswordError }">
										Password must be at least 8 characters and must contain at least 1 number.
									</code>
								</div>
							</div>
							<div class="form-group row">
								<label for="retypeNewPassword" class="col-12 col-form-label text-left">RETYPE NEW PASSWORD</label>
								<div class="col-12 text-left">
									<input type="password" class="form-control" id="retypeNewPassword" autocomplete="off"
										@click="retypePasswordValidation" @change="retypePasswordValidation" required
										v-model="retypeNewPassword">
									<code :style="{ display: displayRetypeNewPasswordError }">Password doesn't match!</code>
								</div>
							</div>
							<!-- <button type="button" class="btn btn-success">UPDATE ACCOUNT</button> -->
						</div>
					</div>
					<!-- <div class="row justify-content-center">
						<div class="col-md-5">
							<h4>MULTIFACTOR AUTHENTICATION</h4>
							<div class="card text-left">
								<div class="card-body">
									<div class="row">
										<div class="col-2 align-self-center text-center">
											<i class="tim-icons icon-alert-circle-exc" style="font-size: 40px"></i>
										</div>
										<div class="col-10">
											<p>
												 Multifactor authentication requires the use of a supported third party mobile Authenticator App (e.g. Google Authenticator, Duo and Microsoft Authenticator) 
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> -->
					<div class="row justify-content-center">
						<div class="col-md-5 text-center">
							<button type="submit" class="btn btn-warning">SUBMIT</button>
						</div>
					</div>
				</form>
				<div class="row justify-content-center text-center mt-5">
					<div class="col-md-6">
						<div class="alert alert-default" role="alert">
							<i class="tim-icons icon-alert-circle-exc"></i>&ensp;Your password in mobile app will also be replaced
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import Swal from 'sweetalert2'

export default {
	name: 'Settings',
	data() {
		return {
			publicPath: process.env.BASE_URL,

			hin: this.$store.getters.getHin,
			certifications: [
				{ country: 'DUBAI', total: '25.00' },
				{ country: 'HONG KONG', total: '25.00' },
				{ country: 'LONDON', total: '12.00' },
				{ country: 'NEW YORK', total: '25.00' },
				{ country: 'SINGAPORE', total: '12.00' },
				{ country: 'SYDNEY', total: '12.00' },
				{ country: 'ZURICH', total: '25.00' }
			],
			cancellations: [
				{ country: 'DUBAI', total: '15.00' },
				{ country: 'HONG KONG', total: '15.00' },
				{ country: 'LONDON', total: '6.00' },
				{ country: 'NEW YORK', total: '15.00' },
				{ country: 'SINGAPORE', total: '6.00' },
				{ country: 'SYDNEY', total: '6.00' },
				{ country: 'ZURICH', total: '15.00' }
			],
			trading: {
				email: '-',
				firstName: '-',
				lastName: '-',
				username: '-',
			},
			account: {
				businessName: '-',
				firstName: '-',
				lastName: '-',
				email: '-',
				phoneNumber: '-',
				street: '-',
				city: '-',
				province: '-',
				postcode: '-',
				country: '-'
			},

			// Change password
			oldPassword: '',
			newPassword: '',
			retypeNewPassword: '',
			displayNewPasswordError: 'none',
			displayRetypeNewPasswordError: 'none',
			outOfNewPassword: false,

			paymentInfo: null,
			utilityAccountIndex: 0,

			institution: 'Artha Graha',
			institutionLogo: `${process.env.BASE_URL}assets/img/logo-bagi-removebg.png`,
			institutionIsOpen: false,

			passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
		}
	},
	computed: {
		tradingType() {
			return this.$store.state.tradingType
		},
		utilityBankName: {
			get: function () {
				return this.utilityAccountIndex
			},
			set: function (val) {
				this.utilityAccountIndex == val
			}
		},
	},

	watch: {
		newPassword() {
			if (this.outOfNewPassword && !this.passwordRegex.test(this.newPassword)) {
				this.displayNewPasswordError = 'initial'
			} else {
				this.displayNewPasswordError = 'none'
			}
		}
	},

	created() {
		// axios({
		// 	method: 'get',
		// 	url: '/v2/abx/trading_information'
		// }).then(response => {
		// 	let trading = response.data.data
		// 	this.trading.email = trading.email
		// 	this.trading.firstName = trading.firstName
		// 	this.trading.lastName = trading.lastName
		// 	this.trading.username = trading.username
		// }).catch(error => {
		// 	if (error.response) {
		// 		/*
		// 		 * The request was made and the server responded with a
		// 		 * status code that falls out of the range of 2xx
		// 		 */
		// 	} else if (error.request) {
		// 		/*
		// 		 * The request was made but no response was received, `error.request`
		// 		 * is an instance of XMLHttpRequest in the browser and an instance
		// 		 * of http.ClientRequest in Node.js
		// 		 */
		// 	} else {
		// 		// Something happened in setting up the request and triggered an Error
		// 	}
		// })
		this.setTradingInfo()

		axios.post('/v2/igx/account_information', {
			user_id: this.$store.state.userId
		}).then(response => {
			let account = response.data.data.body
			this.account.businessName = '-'
			this.account.firstName = account.firstName || '-'
			this.account.lastName = account.lastName || '-'
			this.account.email = account.email || '-'
			this.account.phoneNumber = account.phoneNumber || '-'
			this.account.street = account.street || '-'
			this.account.city = account.city || '-'
			this.account.province = account.province || '-'
			this.account.postcode = account.postcode || '-'
			this.account.country = account.country || '-'
		}).catch(error => {
		})

		axios.post('/v2/igx/payment_information', {
			user_id: this.$store.state.userId
		}).then(response => {
			this.paymentInfo = response.data.data.body
		}).catch(error => {
		})

		// axios({
		// 	method: 'get',
		// 	url: '/v2/nasabah/get/referral_code'
		// }).then(response => {
		// 	this.referralCode = response.data.data.body.referral_code
		// }).catch(error => {
		// 	if (error.response) {
		// 		/*
		// 		 * The request was made and the server responded with a
		// 		 * status code that falls out of the range of 2xx
		// 		 */
		// 	} else if (error.request) {
		// 		/*
		// 		 * The request was made but no response was received, `error.request`
		// 		 * is an instance of XMLHttpRequest in the browser and an instance
		// 		 * of http.ClientRequest in Node.js
		// 		 */
		// 	} else {
		// 		// Something happened in setting up the request and triggered an Error
		// 	}
		// })
	},
	mounted() {
	},
	methods: {
		setTradingInfo() {
			const accountInfo = this.$store.state.account

			this.trading = {
				email: accountInfo.email || '-',
				firstName: accountInfo.firstName || '-',
				lastName: accountInfo.lastName || '-',
				username: accountInfo.name || '-',
			}
		},

		changePasswordSubmit() {

			if (!this.passwordRegex.test(this.newPassword)) {
				this.displayNewPasswordError = 'initial'

				Swal.fire({
					background: '#191f31',
					// title: '<span style="color: white;">Pre Order Trading Contract.</span>',
					html: '<span style="color: white;">Password must be at least 8 characters and must contain at least 1 number.</span>'
				})
			}
			else if (this.newPassword != this.retypeNewPassword) {
				this.displayRetypeNewPasswordError = 'initial'
			} else {
				Swal.fire({
					background: '#191f31',
					title: '<span style="color: white;">Are you sure want to change your password?</span>',
					imageWidth: 50,
					imageHeight: 50,
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes'
				}).then(async (result) => {
					if (result.value) {
						const oldPassword = await this.$store.dispatch('sha256', this.oldPassword)
						const newPassword = await this.$store.dispatch('sha256', this.newPassword)

						axios.post('/v2/igx/edit_password', {
							broker_id: this.$store.state.brokerId,
							password: oldPassword,
							new_password: newPassword
						}).then(response => {
							this.oldPassword = ''
							this.newPassword = ''
							this.retypeNewPassword = ''
							Swal.fire({
								background: '#191f31',
								type: 'success',
								title: '<span style="color: white;">Changed!</span>',
								html: '<span style="color: white;">Your password has been changed.</span>'
							})
						}).catch(error => {
							let err = error.response.data

							Swal.fire({
								background: '#191f31',
								type: 'error',
								title: '<span style="color: white;">Failed!</span>',
								html: `<span style="color: white;">${err.data.err_message}</span>`
							})
						})
					}
				})
			}
		},
		retypePasswordValidation() {
			// if (this.newPassword != this.retypeNewPassword) {
			// }else {
			// 	return 'initial'
			// }
			this.displayRetypeNewPasswordError = 'none'
		},

		showWithdrawDestination() {
			this.institutionIsOpen = true
		},
		institutionAfterClose(value) {
			if (value == 'giropos') {
				this.institutionLogo = `${this.publicPath}assets/img/pos-giro.png`
			} else if (value == 'gopay') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-gopay-2.jpg`
			} else if (value == 'ovo') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-ovo-3.jpg`
			} else if (value == 'rekpon' || value == 'cimb') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-cimb-3.png`
			} else if (value == 'linkaja') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-linkaja.png`
			} else if (value == 'dana') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-dana-2.png`
			} else if (value == 'isaku') { //isaku
				this.institutionLogo = `${this.publicPath}assets/img/logo-isaku.jpg`
			} else if (value == 'bca') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-bca-2.png`
			} else if (value == 'mandiri') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-mandiri-3.png`
			} else if (value == 'bni') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-bni-2.png`
			} else if (value == 'Artha Graha') {
				this.institutionLogo = `${this.publicPath}assets/img/logo-bagi-removebg.png`
				this.withdrawDestinationAccountNumber = this.paymentInfo.withdrawal_acc[0].account_number
			}

			if (value != '') {
				this.institution = value
			}

			this.institutionIsOpen = false
		},
	}
}
</script>

<style scoped>
.c-card-body {
	padding: 15px 40px;
}

.c-card-multiple {
	margin: 0px;
}

div.card li {
	padding: 5px 40px;
	padding-top: 10px;
	border: 0px;
	margin: 1px;
	border-radius: 7px;
}

.c-balance {
	background-color: #2e3d61;
	display: inline-block;
	color: white;
}

.vertical-line {
	/*border-left: 2px solid white;*/
	border-right: 1px solid white;
}

@media only screen and (max-width: 768px) {
	.vertical-line {
		/*border-left: 2px solid white;*/
		border-right: 0;
		border-bottom: 1px solid white;
	}
}

.dropdown-menu {
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #25273A;
	/*color: white;*/
	max-height: 290px;
}

.dropdown-menu:before {
	/*position: static;*/
	display: none;
}

.dropdown-menu .dropdown-item {
	color: white;
}

.dropdown-item-text {
	color: white;
	font-weight: bold;
}
</style>
