<template>
	<div class="content">
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">Economic Calendar</h3>
					</div>
					<div class="card-body text-center">
						<div class="row justify-content-center">
							<div class="col-lg-7 economic-calendar py-3" style="background-color: white;">
								<EconomicCalendarByInvesting></EconomicCalendarByInvesting>
							</div>
						</div>
					</div>
				</div>
			</div>	
		</div>
		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">Commodities</h3>
					</div>
					<div class="card-body">
						<CommoditiesByInvesting></CommoditiesByInvesting>
					</div>
				</div>
			</div>	
		</div>
		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">Live Currency Cross Rates</h3>
					</div>
					<div class="card-body">
						<LiveCurrencyCrossRatesByInvesting></LiveCurrencyCrossRatesByInvesting>
					</div>
				</div>
			</div>	
		</div>
		<div class="row">
			<div class="col">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">Indices</h3>
					</div>
					<div class="card-body">
						<IndicesByInvesting></IndicesByInvesting>
					</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import EconomicCalendarByInvesting from '@/components/investingdotcom/EconomicCalendarByInvesting.vue'
	import LiveCurrencyCrossRatesByInvesting from '@/components/investingdotcom/LiveCurrencyCrossRatesByInvesting.vue'
	import CommoditiesByInvesting from '@/components/investingdotcom/CommoditiesByInvesting.vue'
	import IndicesByInvesting from '@/components/investingdotcom/IndicesByInvesting.vue'

	export default {
		name: 'MarketInsight',
		components: {
			EconomicCalendarByInvesting,
			LiveCurrencyCrossRatesByInvesting,
			CommoditiesByInvesting,
			IndicesByInvesting
		}
	}
</script>