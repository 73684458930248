import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// import Home from './views/Home.vue'

import LoginView from '@/Login.vue'
// import TestPage from '@/views/TestPage.vue'
// import SidebarCobaView from '@/TestLogo.vue'

import DashView from '@/Dash.vue'
import MarketInsight from '@/views/MarketInsight.vue'
import TradingChartView from '@/views/TradingChartView.vue'
import TradeView from '@/views/Trade.vue'
import EWarrantView from '@/views/E-Warrant.vue'
import TransferView from '@/views/Transfer.vue'
import ReportsView from '@/views/Reports.vue'
import NewsView from '@/views/News.vue'
import HelpView from '@/views/Help.vue'
import SettingsView from '@/views/Settings.vue'
import RrsView from '@/views/ReconcileReportingSystem.vue'
import UploadVideoView from '@/views/UploadVideo.vue'

import Pdf from '@/views/Pdf.vue'

Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
	if (!store.getters.isAuthenticated) {
		next()
		return
	}
	next({name: 'Trade'})
	return
}

const ifAuthenticated = (to, from, next) => {
	if (!store.getters.isAuthenticated) {
		localStorage.setItem('nextUrl', JSON.stringify(to))
		store.dispatch('AUTH_LOGOUT').then(() => {
			next({name: 'Login'})
		})
		return
	}

	next()
	return
}

// const ifAdmin = () => {
// 	if (true) {
// 		next()
// 		return
// 	}
// 	next({name: 'Trade'})
// }

export default new Router({
	mode: 'history',
	routes: [
		// {
		//	 path: '/',
		//	 name: 'home',
		//	 component: Home
		// },
		{
			path: '/login',
			name: 'Login',
			component: LoginView,
			beforeEnter: ifNotAuthenticated,
		},
		{
			path: '/invoice',
			name: 'Invoice',
			component: Pdf,
			// beforeEnter: ifAuthenticated,
		},
		{
			path: '/',
			component: DashView,
			children: [
				// {

				// },
				// {
				// 	path: '/test',
				// 	name: 'Test Page',
				// 	component: TestPage
				// },
				{
					path: '/UploadVideo',
					name: 'Upload Video',
					component: UploadVideoView
				},
				{
					path: '/trade',
					alias: '/',
					name: 'Trade',
					component: TradeView
				},
				{
					path: '/e-warrant',
					name: 'E-Warrant',
					component: EWarrantView
				},
				{
					path: '/trading-chart',
					name: 'Trading Chart',
					component: TradingChartView
				},
				{
					path: '/transfer',
					name: 'Transfer',
					component: TransferView,
					meta: {
						pageName: 'Transfer'
					},
					beforeEnter: (to, from, next) => {
						if (store.getters.getAccountLevel == 'client' || store.getters.getAccountLevel == 'lp') {
							next()
							return
						}else {
							next(from)
							return
						}
					}
				},
				{
					path: '/reports',
					name: 'Reports',
					component: ReportsView
				},
				{
					path: '/news',
					name: 'News',
					component: NewsView
				},
				{
					path: '/help',
					name: 'Help',
					component: HelpView
				},
				{
					path: '/settings',
					name: 'Settings',
					component: SettingsView
				},
				{
					path: '/rrs',
					name: 'rrs',
					component: RrsView
				},
				{
					path: '/logout',
					name: 'Logout'
				},
				{
					path: '/marketinsight',
					name: 'Market Insight',
					component: MarketInsight
				}
			],
			beforeEnter: ifAuthenticated,
		}
		
		// {
		//	 path: '/about',
		//	 name: 'about',
		//	 // route level code-splitting
		//	 // this generates a separate chunk (about.[hash].js) for this route
		//	 // which is lazy-loaded when the route is visited.
		//	 component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
		// }
	]
})
