// src/mixins/clickMixin.js
// import store from '@/mixin/customMixin.js'

export default {
  methods: {
    numberFormat(value) {
      if (value == '-') {
        return '-'
      }
      let val = (value / 1)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
    },

    numberFormatExtend(value, nn = 2, xx = 3, ss = ',', cc = '.') {
      value = Number(value)
      Number.prototype.format = function(n, x, s, c) {
        let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
          num = this.toFixed(Math.max(0, ~~n));

        return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
      }

      if (isNaN(value)) {
        return '-'
      }
      return value.format(nn, xx, ss, cc)
    }
  },

  computed: {
    currencySymbol() {
      return this.$store.state.currency == 'idr' ? 'Rp' : '$'
    }
  }
};