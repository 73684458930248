<template>
	<svg :width="width" :height="height" viewBox="0 0 9 6" fill="none" >
		<path id="Polygon 1" d="M4.2 6L0.0430769 7.83039e-07L8.35692 5.62197e-08L4.2 6Z" :fill="color_prop" />
	</svg>
</template>

<script>
export default {

	name: 'IconArrowTriangle',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '11px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '8px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: getComputedStyle(document.documentElement).getPropertyValue('--theme-color'),
		},

		direction_prop: {
			type: String,
			default: 'left',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	},

	data() {
		return {

		}
	}
}
</script>

<style lang="css" scoped>
.up {
	transform: rotate(90deg);
}

.right {
	transform: rotate(180deg);
}

.down {
	transform: rotate(-90deg);
}
</style>