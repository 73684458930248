<template>
	<div :class="`sidebar`">
		<!--Tip 1: You can change the color of the sidebar using: data-color="blue | green | orange | red"-->
		<!-- <div class="sidebar-wrapper bg-blue-jfx" id="container"> -->
		<div :class="`sidebar-wrapper bg-theme`" id="container">
			<!-- <div class="sidebar-wrapper bg-green-nu" id="container"> -->
			<!-- <div class="sidebar-wrapper bg-blue-muhammadiyah" id="container"> -->
			<div class="logo px-0">

			</div>
			<ul class="nav" style="margin-bottom: 85px">
				<router-link tag="li" :to="{ name: 'Trade' }">
					<a class="mt-0">
						<i class="tim-icons icon-tv-2"></i>
						<p>Trade</p>
					</a>
				</router-link>

				<router-link tag="li" :to="{ name: 'Trading Chart' }">
					<a class="mt-0">
						<i class="fas fa-chart-line"></i>
						<p>Trading Chart</p>
					</a>
				</router-link>

				<router-link tag="li" :to="{ name: 'Reports' }">
					<a class="mt-0">
						<i class="tim-icons icon-single-copy-04"></i>
						<p>REPORTS</p>
					</a>
				</router-link>

				<router-link
					v-if="(this.$store.getters.getAccountLevel == 'client' || this.$store.getters.getAccountLevel == 'lp') && this.$store.getters.getBrokerName == 'metalgo'"
					tag="li" :to="{ name: 'Transfer' }">
					<a class="mt-0">
						<i class="tim-icons icon-refresh-02"></i>
						<p>TRANSFER</p>
					</a>
				</router-link>

				<router-link class="mb-16-c" tag="li" :to="{ name: 'rrs' }">
					<a class="mt-0">
						<i class="fas fa-book-open"></i>
						<p>RRS</p>
					</a>
				</router-link>

				<!-- <router-link tag="li" class="active" :to="{name: 'Login'}">
          <a class="mt-0">
            <i class="fas fa-sign-in-alt"></i>
            <p>Login</p>
          </a>
        </router-link> -->
				<!-- <router-link tag="li" :to="{name: 'Sidebar Coba'}">
          <a class="mt-0">
            <i class="fas fa-question"></i>
            <p>Test Logo</p>
          </a>
        </router-link> -->
				<router-link tag="li" :to="{ name: 'Market Insight' }">
					<a class="mt-0">
						<i class="tim-icons icon-chart-bar-32"></i>
						<p>Market Insight</p>
					</a>
				</router-link>

				<router-link tag="li" :to="{ name: 'News' }">
					<a class="mt-0">
						<i class="tim-icons icon-world"></i>
						<p>NEWS</p>
					</a>
				</router-link>

				<router-link tag="li" :to="{ name: 'Help' }">
					<a class="mt-0">
						<i class="tim-icons icon-chat-33"></i>
						<p>SUPPORT</p>
					</a>
				</router-link>

				<router-link class="mb-16-c" tag="li" :to="{ name: 'Settings' }">
					<a class="mt-0">
						<i class="tim-icons icon-settings-gear-63"></i>
						<p>SETTINGS</p>
					</a>
				</router-link>

				<li>
					<a class="mt-0" @click="comingSoon()">
						<i class="tim-icons icon-paper"></i>
						<p>E-WARRANT</p>
					</a>
				</li>

				<li @click="logout()">
					<a class="mt-0 mb-3">
						<i class="tim-icons icon-button-power"></i>
						<p>LOGOUT</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar'
import Swal from 'sweetalert2'
// import store from '@/store'

export default {
	name: 'Sidebar',
	data() {
		return {
			publicPath: process.env.BASE_URL,
			platformName: 'JFXGold X',
			imagePath: 'assets/img/logo-jfx-white.png',
			flagRegister: this.$store.state.flagRegister,
		}
	},

	computed: {
		appName() {
			return this.$store.getters.getAppName
		},
	},

	watch: {
		appName() {
			this.changeTheme()
		},
	},

	created() {
		this.changeTheme()
	},

	mounted() {
		const ps = new PerfectScrollbar('#container', {
			wheelPropagation: false,
		})
		ps.update()
	},
	methods: {
		changeTheme() {
			if (this.appName == 'PosPayGold') { //posgold
				this.platformName = 'POSPAY GOLD'
				this.imagePath = 'assets/img/pospaygold-white.png'
			} 
			else if (this.appName == 'NUNomics') { //nugold
				this.platformName = 'NUNomics'
				this.imagePath = 'assets/img/logo-nu.jpg'
			}
			else if (this.appName == 'BMTDigi') { //posgold
				this.platformName = 'BMTDigi'
				this.imagePath = 'assets/img/pospaygold-white.png'
			}
			else if (this.appName == 'AGIGold') { //AGIGold
				this.platformName = 'AGIGold'
				this.imagePath = 'assets/img/pospaygold-white.png'
			}
			else { //jfx
				this.platformName = 'JFXGold X'
				this.imagePath = 'assets/img/logo-jfx-white.png'
			}
		},

		logout() {
			Swal.fire({
				background: '#191f31',
				title: '<span style="color: white;">Are you sure want to logout?</span>',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes',
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('AUTH_LOGOUT').then(() => {
						this.$router.push({ name: 'Login' }, () => {
							window.location.reload()
						})
					})
				}
			})
		},

		comingSoon() {
			Swal.fire({
				background: '#191f31',
				// title: '<span style="color: white;">This feature will available soon.</span>',
				html: '<span style="color: white;">This feature will available soon.</span>',
			})
		},
	},
}
</script>

<style scoped>
.sidebar {
	margin-left: 0;
	margin-top: 0;

	height: 100vh;
}
.sidebar::before {
	border-bottom-color: var(--theme-color);
}

.logo {
	height: 4.2em;
}

.logo:after {
	/*margin-bottom: -7px;*/
	/*height: 0px;*/
	background-color: transparent;
}

.logo-normal {
	height: 51px;
	line-height: 66px !important;
	font-size: 21px !important;
}

</style>
