<template>
	<div class="content">
		<div class="title-c">
			Reports
		</div>

		<div v-show="tradingType == 'jgx'" class="tab-content tab-space">

			<!-- Order -->
			<div v-show="tabActive == 'orders'" :class="`${tabActive == 'orders' && 'active'}`" id="nav1-link1"
				:aria-expanded="`${tabActive == 'orders' ? 'true' : 'false'}`">

				<Orders></Orders>
			</div>

			<!-- Transactions -->
			<div v-show="tabActive == 'transactions'" :class="`${tabActive == 'transactions' && 'active'}`" id="nav1-link2"
				:aria-expanded="`${tabActive == 'transactions' ? 'true' : 'false'}`">
				<Transactions></Transactions>
			</div>

			<!-- Holdings -->
			<div v-show="tabActive == 'holdings'" :class="`${tabActive == 'holdings' && 'active'}`" id="nav1-link3"
				:aria-expanded="`${tabActive == 'holdings' ? 'true' : 'false'}`">
				<Holdings></Holdings>
			</div>

			<!-- CLIENTS -->
			<div v-show="tabActive == 'clients' && (accountLevel == 'ib' || accountLevel == 'fm')"
				:class="`${tabActive == 'clients' && 'active'}`" id="nav1-link-clients"
				:aria-expanded="`${tabActive == 'clients' ? 'true' : 'false'}`">
				<div class="flex flex--justify-content-between flex--align-items-end mb-14-c">
					<div>
						<ReportsNav />
					</div>
				</div>

				<div class="row">
					<!-- <div class="col-auto mb-2 text-left">
						<FilterClient class="d-inline-block"
							filterId="transaction"
							:clients_prop="listClients"
						>
						</FilterClient>
					</div> -->

					<!-- <div class="col-auto mb-2 text-left">
						<ExportAsButton class="d-inline-block">
						</ExportAsButton>
					</div> -->
				</div>

				<div class="row">
					<div class="col-12 table-responsive p-0">
						<table class="table table--report text-nowrap">
							<thead style="background-color: #0f0f17">
								<tr class="text-center">
									<th>NAME</th>
									<th>HIN</th>
									<th>BANK</th>
									<th>RPED</th>
									<th>BALANCE</th>
								</tr>
							</thead>
							<tbody v-if="apiStatusGetClients == 'loading'">
								<tr>
									<td class="text-center" colspan="4">
										<Loader size_prop="10em"></Loader>
									</td>
								</tr>
							</tbody>

							<tbody v-else>
								<tr v-for="(client, index) in clients" :key="client.hin">
									<td>{{ client.name }}</td>
									<td>{{ client.hin }}</td>
									<td class="text-center">{{ client.bank }}</td>
									<td class="text-center">{{ client.rped }}</td>

									<td class="text-right">Rp. {{ numberFormatExtend(clientsBalance[index]) }}</td>

									<!-- <td class="text-right">{{ numberFormatExtend(Decimal.mul(client.availableBalance, -->
									<!-- streamingRateBuy).toFixed(2)) }}</td> -->
								</tr>
							</tbody>
							<tfoot>

							</tfoot>
						</table>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<Pagination2 :totalData_prop="totalClients" @changePage="setClients">
						</Pagination2>
					</div>
				</div>
			</div>

			<!-- Statements -->
			<div v-show="tabActive == 'statements'" :class="`${tabActive == 'statements' && 'active'}`" id="nav1-link4"
				:aria-expanded="`${tabActive == 'statements' ? 'true' : 'false'}`">
				<div class="row justify-content-center">
					<div class="px-3 mb-3" style="width: 380px !important;">
						<!-- <h5 class="text-left">SELECT A DATE RANGE</h5> -->

						<div class="form-group">
							<label for="startDateStatements">Start Date</label>
							<input type="date" class="form-control" id="startDateStatements">
						</div>

						<div class="form-group">
							<label for="endDateStatements">End Date</label>
							<input type="date" class="form-control" id="endDateStatements">
						</div>


						<!-- <h4>REQUEST NEW CREDIT LIMIT</h4>
						<div class="input-group input-group-lg mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text" id="inputGroup-sizing-sm">AUD $</span>
							</div>
							<input type="number" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm">
							
								<button class="btn btn-sm" type="button">-</button>
								<button class="btn btn-sm" type="button">+</button>
					
						</div>
						<div class="form-group">
							<textarea class="form-control" placeholder="Optional description" rows="5" style="max-height: 200px;"></textarea>
						</div> -->
					</div>
					<div class="w-100"></div>
					<div class="px-3 text-center" style="width: 380px !important;">
						<div title="Coming Soon text-center">
							<button type="button" class="btn btn-success" disabled>RUN STATEMENT REPORT</button>
						</div>
					</div>
				</div>
			</div>

			<!-- Account balance history -->
			<div v-show="tabActive == 'accountBalanceHistory'" :class="`${tabActive == 'accountBalanceHistory' && 'active'}`"
				id="nav1-link5" :aria-expanded="`${tabActive == 'accountBalanceHistory' ? 'true' : 'false'}`">
				<div class="flex flex--justify-content-between flex--align-items-end mb-14-c">
					<div>
						<ReportsNav />
					</div>
				</div>

				<div class="row text-right mb-2">
					<div v-if="accountLevel == 'ib' || accountLevel == 'fm' || accountLevel == 'ccp'" class="col-auto text-left">
						<FilterClient class="d-inline-block" filterId="accountBalanceHistory"
							@changeFilterClient="changeFilterClientAccountBalanceHistory" :clients_prop="listClients">
						</FilterClient>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<div class="card">
							<div class="card-header">
								<!-- <h5 v-if="selectedClientAccountBalanceHistory != 'all'">CURRENT BALANCES ({{selectedClientAccountBalanceHistory.hin_abx}} - {{selectedClientAccountBalanceHistory.personal_data[0].full_name}})</h5>
								<h5 v-else> CURRENT BALANCES (ALL) </h5> -->
								<h5> CURRENT BALANCES (ALL) </h5>

								<div class="row">
									<div class="col-sm-12 col-md-4 text-left">
										<dl class="row text-left">
											<dt class="col-7">Available Trade Balance</dt>
											<dd class="col-5">: {{ currencySymbol }}
												{{ numberFormatExtend(Number(availableTradeBalance).toFixed(2)) }}</dd>

											<dt class="col-7">Account Balance</dt>
											<dd class="col-5">: {{ currencySymbol }} {{ numberFormatExtend((isNaN(accountBalance)) ? 0 :
												Number(accountBalance).toFixed(2)) }}</dd>

											<dt class="col-7">Reserved Balance</dt>
											<dd class="col-5">: {{ currencySymbol }} {{ numberFormatExtend(Number(reservedBalance).toFixed(2))
											}}
											</dd>
										</dl>
									</div>
									<div class="col-sm-12 col-md-4">
										<dl class="row text-left">
											<dt class="col-7">Credit Limit</dt>
											<dd class="col-5">: {{ currencySymbol }} {{
												numberFormatExtend(currentBalance.creditLimit.toFixed(2)) }}
											</dd>

											<dt class="col-7">Available Credit</dt>
											<dd class="col-5">: {{ currencySymbol }}
												{{ numberFormatExtend(currentBalance.availableCredit.toFixed(2)) }}</dd>
										</dl>
									</div>
									<div class="col-sm-12 col-md-4">
										<dl class="row text-left">
											<dt class="col-7">Settlement Owning</dt>
											<dd class="col-5">: {{ currencySymbol }}
												{{ numberFormatExtend(currentBalance.settlementOwning.toFixed(2)) }}</dd>
										</dl>
									</div>
								</div>
							</div>
							<div class="card-body">
								<div class="row">
									<div class="col-sm-12 col-md-4">
										<p class="lead"><small>Available Trade Balance = Account Balance - Reserved Balance + Credit
												Limit</small></p>
									</div>
									<div class="col-sm-12 col-md-4">
										<p class="lead"><small>Available Credit = Credit Limit - Settlement Owning - Credit Reserved for
												Trades</small></p>
									</div>
									<div class="col-sm-12 col-md-4">
										<p class="lead"><small>Reserved Balance = Total Limit Price + Execution Fee + Tax + {{
											$store.state.fxBuffer * 100 }}% Total Limit
												Price (FX Buffer) + Total Transactions On Process</small></p>
									</div>
								</div>

								<div class="row">
								</div>
							</div>
						</div>
					</div>
					<div class="col">
						<Pagination2 v-if="accountLevel == 'client' || accountLevel == 'lp'"
							:totalData_prop="totalAccountBalanceHistory" @changePage="setAccountBalanceHistory">
						</Pagination2>

						<Pagination2 v-else :totalData_prop="totalBankTransactions" @changePage="setListBankTransactions">
						</Pagination2>
					</div>
					<div class="col-12 table-responsive p-0">
						<table v-if="accountLevel == 'ib' || accountLevel == 'client' || accountLevel == 'lp'"
							class="table table--report text-nowrap">
							<thead style="background-color: #0f0f17">
								<tr class="text-center">
									<th>DATE</th>
									<th>EFFECTIVE ACCOUNT ID</th>
									<th>ACCOUNT</th>
									<th>TRANSACTION ID</th>
									<th>TRANSACTION</th>
									<th>AMOUNT</th>
									<!-- <th>ACCOUNT BALANCE</th> -->
								</tr>
							</thead>
							<tbody>
								<template v-if="accountBalanceHistoriesStatus != 'loading'">
									<tr v-for="accountBalanceHistory in accountBalanceHistories"
										v-bind:key="'accountBalanceHistory' + accountBalanceHistory.transactionId">
										<td class="text-center">{{ accountBalanceHistory.date }}</td>
										<td class="text-center">{{ accountBalanceHistory.effectiveAccountId }}</td>
										<td class="text-center">{{ accountBalanceHistory.account }}</td>
										<td class="text-center">{{ accountBalanceHistory.transactionId }}</td>
										<td class="text-center">{{ accountBalanceHistory.transaction }}</td>
										<td v-if="currency == 'usd'" class="text-right">{{ numberFormatExtend(accountBalanceHistory.amount) }}
										</td>
										<td v-else class="text-right">{{ numberFormatExtend(accountBalanceHistory.amountIdr) }}</td>
										<!-- <td class="text-right">{{numberFormatExtend(Decimal.mul(accountBalanceHistory.accountBalance, streamingRateSell).toFixed(2))}}</td> -->
									</tr>
									<tr v-if="accountBalanceHistories.length < 1">
										<td colspan="7" class="text-center">No data available in table</td>
									</tr>
								</template>
								<template v-else>
									<tr>
										<td class="text-center" colspan="7">
											<Loader size_prop="10em"></Loader>
										</td>
									</tr>
								</template>
							</tbody>
							<tfoot>

							</tfoot>
						</table>

						<table v-else class="table table--report text-nowrap">
							<thead style="background-color: #0f0f17">
								<tr class="text-center">
									<th>DATE</th>
									<th>TRANSACTION ID</th>
									<th>DEBIT</th>
									<th>CREDIT</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="bankTransactions.length > 0">
									<tr v-for="(item, index) in bankTransactions" :key="index + 'bankTransactions'">
										<td class="text-center">{{ item.date }}</td>
										<td class="text-center">{{ item.transactionId }}</td>
										<td class="text-right">{{ item.debit }}</td>
										<td class="text-right">{{ item.credit }}</td>
									</tr>
								</template>

								<tr v-else-if="apiStatusGetBankTransactions == 'loading'">
									<td class="text-center" colspan="4">
										<Loader size_prop="10em"></Loader>
									</td>
								</tr>

								<tr v-else-if="apiStatusGetBankTransactions == 'error'">
									<td class="text-center" colspan="4">
										Failed to retrieve data
									</td>
								</tr>

								<tr v-else>
									<td class="text-center" colspan="4">
										No data
									</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>
			</div>

			<!-- Cash In Cash Out -->
			<!-- <div v-if="accountLevel == 'ib'" class="tab-pane" id="nav1-link6" aria-expanded="false">
				<div class="row text-right mb-2">
					<div v-if="accountLevel == 'ib' || accountLevel == 'fm' || accountLevel == 'ccp'" class="col-auto mb-2 text-left">
						<FilterClient class="d-inline-block"
							filterId="accountBalanceHistory"
							@changeFilterClient="changeFilterClientAccountBalanceHistory"
							:clients_prop="listClients"
						>
						</FilterClient>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<Pagination
							:maxPage_prop =	"accountBalanceHistoriesShowed.length"
							@changePage = "accountBalanceHistoriesPageIndex = $event"
							:status_prop = "accountBalanceHistoriesStatus"
						>
						</Pagination>
					</div>
					<div class="col-12 table-responsive p-0">
						<table class="table table--report text-nowrap">
							<thead>
								<tr>
									<th scope="col">DATE</th>
									<th scope="col">TRANSACTION ID</th>
									<th scope="col">DEBIT</th>
									<th scope="col">CREDIT</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="lastTenTransactions.length > 0">
									<tr v-for="(item, index) in lastTenTransactions" :key="index">
										<td>{{ item.date }}</td>
										<td>{{ item.transactionId }}</td>
										<td>{{ item.debit }}</td>
										<td>{{ item.credit }}</td>
									</tr>
								</template>
								<template v-else>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>
								</template>
							</tbody>
						</table>

					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
/*eslint-env jquery*/
import axios, { abortController } from '@/api/axios.js'
import Decimal from 'decimal.js'

import Orders from '@/components/reports/orders/Orders.vue'
import Transactions from '@/components/reports/transactions/Transactions.vue'
import Holdings from '@/components/reports/holdings/Holdings.vue'

import ReportsNav from '@/components/reports/ReportsNav.vue'
import Pagination from '@/components/Pagination.vue'
import Pagination2 from '@/components/Pagination2.vue'
import Loader from '@/components/Loader.vue'
import FilterContract from '@/components/FilterContract.vue'
// import ExportAsButton from '@/components/ExportAsButton.vue'
import FilterDateAndType from '@/components/FilterDateAndType.vue'
import FilterClient from '@/components/FilterClient.vue'
import FilterOrderType from '@/components/FilterOrderType.vue'


export default {
	name: 'Reports',

	components: {
		Orders,
		Transactions,
		Holdings,

		ReportsNav,
		Pagination,
		Pagination2,
		Loader,
		FilterContract,
		// ExportAsButton,
		FilterDateAndType,
		FilterClient,
		FilterOrderType
	},

	data() {
		return {
			Decimal: Decimal,
			accountLevel: this.$store.getters.getAccountLevel,
			contracts: '',
			// products: '',
			hubs: '',

			endOfDayPrices: {},

			transactions: [],
			transactionsStatus: 'loading',

			filterType: 'jgx',

			listClients: [],

			filterContractTransaction: [],
			selectedClientTransaction: 'all',
			filterDateAndTypeTransaction: {
				type: 'day',
				startDate: '',
				endDate: ''
			},

			totalTransactions: 1,
			cancelTokenApiGetTransactions: '',
			cancelTokenApiGetHinTransactions: '',
			orderTypeTransactions: 'all',

			clients: [],
			totalClients: 1,
			apiStatusGetClients: 'idle',

			clientsBalance: [],

			selectedClientAccountBalanceHistory: 'all',

			accountBalanceHistories: [],
			accountBalanceHistoriesStatus: 'idle',
			currentBalance: {
				availableTradeBalance: 0,
				accountBalance: 0,
				reservedBalance: 0,
				creditLimit: 0,
				availableCredit: 0,
				settlementOwning: 0
			},
			totalAccountBalanceHistory: 1,
			cancelTokenApiGetAccountBalanceHistory: '',
			cancelTokenApiGetHinAccountBalanceHistory: '',

			bankTransactions: [],
			totalBankTransactions: 1,
			apiSkipGetBankTransactions: '0',
			apiStatusGetBankTransactions: 'loading',
			cancelTokenApiBankTransactions: '',

			invoiceLoading: [],

			lastTenTransactions: [],
		}
	},

	computed: {
		tabActive() {
			return this.$store.state.reportToShow
		},

		currency() {
			return this.$store.state.currency
		},
		hin() {
			return this.$store.getters.getHin
		},
		tradingType() {
			return this.$store.state.tradingType
		},

		hubsTemp() {
			return this.$store.state.hubs
		},

		contractsTemp() {
			return this.$store.state.contracts
		},

		products() {
			return this.$store.getters.PRODUCTS
		},

		gold() {
			return this.$store.state.gold
		},

		reservedGold() {
			return this.$store.getters.getReservedGold
		},

		dataOrderFilterStatusSubmitShowed: {
			get: function () {
				let sortedDataOrderFilterStatusSubmit = this.dataOrderFilterStatusSubmit.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
				return this.paginationOrder(sortedDataOrderFilterStatusSubmit)
			},
			set: function (newValue) {
				this.dataOrderFilterStatusSubmit = newValue
			}
		},

		dataOrderFilterStatusFillShowed: {
			get: function () {
				let sortedDataOrderFilterStatusFill = this.dataOrderFilterStatusFill.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
				return this.paginationOrder(sortedDataOrderFilterStatusFill)
			},
			set: function (newValue) {
				this.dataOrderFilterStatusFill = newValue
			}
		},

		dataOrderFilterStatusCancelShowed: {
			get: function () {
				let sortedDataOrderFilterStatusCancel = this.dataOrderFilterStatusCancel.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
				return this.paginationOrder(sortedDataOrderFilterStatusCancel)
			},
			set: function (newValue) {
				this.dataOrderFilterStatusCancel = newValue
			}
		},

		accountBalanceHistoriesShowed: {
			get: function () {
				let sortedAccountBalanceHistories = this.accountBalanceHistories.slice().sort((a, b) => new Date(b.date) - new Date(a.date))
				return this.paginationOrder(sortedAccountBalanceHistories)
			},
			set: function (newValue) {
				this.sortedAccountBalanceHistories = newValue
			}
		},

		currencySymbol() {
			if (this.currency == 'idr') {
				return 'Rp '
			} else {
				return '$'
			}
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		streamingRateBuy() {
			if (this.currency == 'idr') {
				return this.streamingRate.stream_buy.cur_rate
			} else {
				return 1
			}
		},

		streamingRateSell() {
			if (this.currency == 'idr') {
				return this.streamingRate.stream_sell.cur_rate
			} else {
				return 1
			}
		},

		availableTradeBalance() {
			if (!this.$store.state.balances) {
				return -1
			}

			if (this.currency == 'idr') {
				return this.$store.state.balances.available_trade_bal
			} else {
				return this.$store.state.balances.available_trade_bal_usd
			}
		},
		accountBalance() {
			if (!this.$store.state.balances) {
				return -1
			}

			if (this.currency == 'idr') {
				return this.$store.state.balances.rdk_balance
			} else {
				return this.$store.state.balances.account_bal
			}
		},
		reservedBalance() {
			if (this.currency == 'idr') {
				if (this.accountLevel == 'client') {
					return this.$store.state.reservedBalanceIdr
				} else {
					return Number(Decimal.mul(this.$store.state.reservedBalanceUsd, this.streamingRateBuy))
				}
			} else {
				return this.$store.state.reservedBalanceUsd
			}
		},

		// watcherHubsContractsProducts() {
		// 	return `${this.hubsTemp}${this.contractsTemp}${this.products}`
		// }
	},

	watch: {
		currency() {
			if (this.accountLevel != 'client' && this.accountLevel != 'lp') {
				this.setListBankTransactions(this.apiSkipGetBankTransactions)
			}
		},

		gold() {
			const firstCall = setInterval(() => {
				if (this.products && this.contractsTemp && this.accountLevel && this.hubsTemp) {
					clearInterval(firstCall)

					this.callSetHoldingFrom = 'watch'
					if (this.accountLevel == 'ib' || this.accountLevel == 'fm') {
						// this.setHoldings()
					} else {
						// this.setNewHoldings()
					}
				}
			}, 1000)
		}

		// watcherHubsContractsProducts() {
		// 	this.setTransactions()
		// }
	},

	created() {
		this.getGetEndOfDayPrices()

		const firstCall = setInterval(() => {
			if (this.products && this.contractsTemp && this.accountLevel && this.hubsTemp) {
				clearInterval(firstCall)

				if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'lp') {
					// this.setListBankTransactions()
					this.setAccountBalanceHistory()
				} else {
					this.setAccountBalanceHistory()
				}
			}
		}, 1000)

		if (this.accountLevel == 'ib' || this.accountLevel == 'fm') {
			this.setClients()

			axios.post('/v2/rrs/list_clients', {
				user_id: this.$store.state.userId
			})
		}

		if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
			let urlApi = ''
			if (this.accountLevel == 'ib') {
				urlApi = '/v2/rrs/ib/list/client'
			} else if (this.accountLevel == 'fm') {
				urlApi = '/v2/rrs/fm/list/branch'
			} else {
				urlApi = '/v2/rrs/ccp/list/branch'
			}

			axios.post(urlApi, {
				user_id: this.$store.state.userId
			}).then(response => {
				this.listClients = response.data.data.body
			}).catch(error => {
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		}
	},

	mounted() {
	},

	methods: {
		// setListClients() {
		// 	axios.post('/v2/rrs/list_clients?limit=&offset=', {
		// 		order_match_id: orderMatchId,
		// 		direction: direction,
		// 	}).then(
		// },

		paginationOrder(arr) {
			const itemPerPage = 10
			let arrTemp1 = [],
				arrTemp2 = []
			for (let i = 0; i < arr.length; i++) {
				arrTemp1.push(arr[i])
				if ((i + 1) % itemPerPage == 0 || (i == arr.length - 1 && (i + 1) % itemPerPage != 0)) {
					arrTemp2.push(arrTemp1)
					arrTemp1 = []
				}
			}
			return arrTemp2
		},

		invoice(orderMatchId, direction, transactionStatus) {
			// If Transaction Status == On Process, cannot download pdf
			if (transactionStatus == 'On Process') {
				return false
			}

			// this.$router.push('/invoice?order_match_id=' + orderMatchId + '&direction=' + direction)

			this.invoiceLoading.push(orderMatchId)
			axios.post('/v2/igx/reports/pdf/transaction_invoice', {
				order_match_id: orderMatchId,
				direction: direction,
			}).then(response => {
				let datanya = response.data.data.body.data

				// let byte = this.base64ToArrayBuffer(datanya)
				const arrTes = new Uint8Array(datanya)
				let blob = new Blob([arrTes], { type: "application/pdf" })
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				let fileName = 'trade_confirmation_invoice-' + orderMatchId
				link.download = fileName
				link.click()

				let indexLoading = this.invoiceLoading.indexOf(orderMatchId)
				this.invoiceLoading.splice(indexLoading, 1)

			}).catch(error => {
				let indexLoading = this.invoiceLoading.indexOf(orderMatchId)
				this.invoiceLoading.splice(indexLoading, 1)

				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		},
		base64ToArrayBuffer(base64) {
			let binaryString = window.atob(base64)
			let binaryLen = binaryString.length
			let bytes = new Uint8Array(binaryLen)
			for (let i = 0; i < binaryLen; i++) {
				let ascii = binaryString.charCodeAt(i)
				bytes[i] = ascii
			}
			return bytes
		},

		getGoldById(contractId) {
			return this.$store.getters.goldById(contractId)
		},

		setClientsFilter() {
			axios.post('/v2/rrs/list_clients', {
				user_id: this.$store.state.userId
			}).then(response => {
				this.listClients = response.data.data.body

			}).catch(error => {
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		},

		setClients(skip = 0, limit = 10) {
			this.apiStatusGetClients = 'loading'

			axios.post('/v2/rrs/list_trade_account?offset=' + skip + '&limit=' + limit, {
				user_id: this.$store.state.userId
			}).then(response => {
				const dataResponse = response.data.data.body
				this.totalClients = response.data.data.count


				if (this.totalClients == 0 && dataResponse.length > 0) {
					this.totalClients = this.listClients.length
				}

				this.clients = []
				this.clientsBalance = []

				dataResponse.forEach((value, index) => {
					axios.post('/v2/rrs/account_balance', {
						account_no: value.bank_account[0].account_no
					}).then(response2 => {
						const balance = response2.data.data.body.balance
						this.$set(this.clientsBalance, index, balance)
					})

					this.$set(this.clients, index, {
						'hin': value.trade_account.hin,
						'name': value.fullname,
						'bank': value.bank_account[0].name,
						'rped': value.bank_account[0].account_no,
						'balance': 'loading',

						// 'allowTrade': value.attributes.allowClientTrade,
						// 'availableBalance': value.attributes.availableTrade,
						// 'reservedBalance': value.attributes.totalReserveFunds,
					})
				})

				this.apiStatusGetClients = 'success'
			}).catch(error => {
				this.apiStatusGetClients = 'error'
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		},

		setAccountBalanceHistory(skip = 0, limit = 10) {
			let urlApi = ''
			let hin = ''
			if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
				if (this.selectedClientAccountBalanceHistory == 'all') {
					urlApi = '/v2/rrs/specific/balance_history?offset=' + skip + '&limit=' + limit
				} else {
					urlApi = '/v2/rrs/specific/balance_history?hin=' + this.selectedClientAccountBalanceHistory.hin_abx + '&accountId=' + this.selectedClientAccountBalanceHistory.ac_abx
				}
			} else if (this.accountLevel == 'lp') {
				urlApi = '/v2/igx/market-maker/balance_history?offset=' + skip + '&limit=' + limit
				hin = this.$store.getters.getHin
			} else {
				urlApi = '/v2/igx/balance_history?offset=' + skip + '&limit=' + limit
				hin = this.$store.getters.getHin
			}

			if (this.cancelTokenApiGetAccountBalanceHistory) {
				//cancel api get account balance history
				this.cancelTokenApiGetAccountBalanceHistory.controller.abort()
			}
			this.cancelTokenApiGetAccountBalanceHistory = abortController()

			this.accountBalanceHistoriesStatus = 'loading'

			axios.post(urlApi, {
				user_id: this.$store.state.userId
			}, {
				signal: this.cancelTokenApiGetAccountBalanceHistory.signal
			}).then(response => {
				this.accountBalanceHistories = []
				this.currentBalance = {
					availableTradeBalance: 0,
					accountBalance: 0,
					reservedBalance: 0,
					creditLimit: 0,
					availableCredit: 0,
					settlementOwning: 0
				}

				this.totalAccountBalanceHistory = response.data.data.count

				let balanceHistories = response.data.data.body

				if (balanceHistories.length < 1) {
					this.accountBalanceHistoriesStatus = 'success'
				}

				let indexAccountBalanceHistory = 0


				balanceHistories.forEach((balanceHistory) => {
					let dateTemp = new Date(balanceHistory.attributes.createdAt)
					let date = {
						year: dateTemp.getFullYear(),
						month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
						date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
						hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
						minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
					}

					//
					if (this.accountLevel != 'ib' && this.accountLevel != 'fm') {
						if (balanceHistory.attributes.transactionTypeId.toLowerCase() == 'cash deposit' || balanceHistory.attributes.transactionTypeId.toLowerCase() == 'cash withdrawal') {
							return
						}
					} else if ((balanceHistory.attributes.transactionTypeId.toLowerCase() == 'cash deposit' && balanceHistory.attributes.amount <= 0) || (balanceHistory.attributes.transactionTypeId.toLowerCase() == 'cash withdrawal' && balanceHistory.attributes.amount >= 0)) {
						return
					}

					const rate = balanceHistory.attributes.rate || this.streamingRate.stream_sell.cur_rate

					this.$set(this.accountBalanceHistories, indexAccountBalanceHistory, {
						date: `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
						effectiveAccountId: response.data.data.hin || hin || '-',
						account: this.$store.getters.getFirstName || '-',
						transactionId: balanceHistory.id,
						transaction: balanceHistory.attributes.transactionTypeId,
						amount: balanceHistory.attributes.amount,
						amountIdr: Decimal.mul(balanceHistory.attributes.amount, rate).toNumber(),
						accountBalance: balanceHistory.attributes.total
					})

					indexAccountBalanceHistory++

				})

				let creditLimit = 0
				let settlementOwning = 0
				let availableCredit = this.currentBalance.creditLimit - this.currentBalance.settlementOwning

				if (this.currency == 'idr') {
					this.currentBalance.creditLimit = creditLimit * this.streamingRate.stream_buy.cur_rate
					this.currentBalance.settlementOwning = settlementOwning * this.streamingRate.stream_buy.cur_rate
					this.currentBalance.availableCredit = availableCredit * this.streamingRate.stream_buy.cur_rate
				} else {
					this.currentBalance.creditLimit = creditLimit
					this.currentBalance.settlementOwning = settlementOwning
					this.currentBalance.availableCredit = availableCredit
				}

				this.accountBalanceHistoriesStatus = 'success'
			}).catch(error => {
				this.accountBalanceHistoriesStatus = 'idle'
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		},

		changeFilterClientAccountBalanceHistory(clientData) {
			this.selectedClientAccountBalanceHistory = clientData
			if (this.accountLevel == 'ib' || this.accountLevel == 'fm') {
				this.setListBankTransactions()
			} else {
				this.setAccountBalanceHistory()
			}
		},

		setListBankTransactions(skip = 0, limit = 10) {
			this.apiSkipGetBankTransactions = skip
			this.bankTransactions = []

			let param = ''
			if (this.accountLevel == 'ib') {
				if (this.selectedClientAccountBalanceHistory == 'all') {
					param = '/v2/bank-transaction/ib?skip=' + skip + '&limit=' + limit + '&start_date=&end_date=&type=&status=&ac_abx='
				} else {
					param = '/v2/bank-transaction/ib?skip=' + skip + '&limit=' + limit + '&start_date=&end_date=&type=&status=&ac_abx=' + this.selectedClientAccountBalanceHistory.ac_abx
				}
			} else {
				// get selected account level
				let temp = this.selectedClientAccountBalanceHistory.hin_abx
				let selectedAccountLevel = ''
				const template = { IB: 'ib', PB: 'fm', LP: 'lp', PC: 'client' }
				if (temp) {
					selectedAccountLevel = template[temp.substr(0, 2)]
				} else {
					selectedAccountLevel = '-'
				}

				if (this.selectedClientAccountBalanceHistory == 'all') {
					param = '/v2/bank-transaction/pb?skip=' + skip + '&limit=' + limit + '&start_date=&end_date=&type=&status=&ac_abx=&sponsor_id_abx='
				} else if (selectedAccountLevel == 'ib') {
					param = '/v2/bank-transaction/pb?skip=' + skip + '&limit=' + limit + '&start_date=&end_date=&type=&status=&ac_abx=&sponsor_id_abx=' + this.selectedClientAccountBalanceHistory.sponsor_id_abx
				} else {
					param = '/v2/bank-transaction/pb?skip=' + skip + '&limit=' + limit + '&start_date=&end_date=&type=&status=&ac_abx=' + this.selectedClientAccountBalanceHistory.ac_abx + '&sponsor_id_abx=' + this.selectedClientAccountBalanceHistory.sponsor_id_abx
				}
			}

			this.apiStatusGetBankTransactions = 'loading'
			if (this.cancelTokenApiBankTransactions) {
				this.cancelTokenApiBankTransactions.controller.abort
			}
			this.cancelTokenApiBankTransactions = abortController()

			axios.post(param, {
				user_id: this.$store.state.userId
			}, {
				signal: this.cancelTokenApiBankTransactions.signal,
			}).then(response => {

				this.totalBankTransactions = response.data.total

				let streamingRate = 1
				if (this.currency == 'usd') {
					streamingRate = this.streamingRate.stream_sell.cur_rate
				}

				let history = response.data.data
				for (let i = 0; i < 10; i++) {
					let dateTemp = new Date(history[i].created_at)
					let date = {
						year: dateTemp.getFullYear(),
						month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
						date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
						hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
						minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
					}

					let indexOfStart = history[i].reff_transaction.indexOf("-")
					let transactionId = history[i].reff_transaction.substring(indexOfStart + 1)

					let debit = history[i].type == 'debit' ? this.numberFormatExtend(Decimal.mul(history[i].amount, -1).dividedBy(streamingRate).toFixed(2)) : '-'
					let credit = history[i].type == 'credit' ? this.numberFormatExtend(Decimal.div(history[i].amount, streamingRate).toFixed(2)) : '-'

					this.bankTransactions.push({
						date: `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
						transactionId: transactionId,
						debit: debit,
						credit: credit
					})
				}

				this.apiStatusGetBankTransactions = 'success'
			}).catch(error => {
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}

				this.apiStatusGetBankTransactions = 'error'
			})
		},

		getHinByAccountId(accountId) {
			return new Promise((resolve) => {
				axios.get('/v2/rrs/get/hin?accountId=' + accountId
				).then(response => {
					resolve(response)
				}).catch(error => {
					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
				})
			})
		},

		getLastTenTransactions() {
			axios.get('/v2/transaction?skip=0&limit=10&type=bank'
			).then(response => {
				let streamingRate = 1
				if (this.currency == 'usd') {
					streamingRate = this.streamingRate.stream_sell.cur_rate
				}

				let history = response.data.data
				for (let i = 0; i < 10; i++) {
					let dateTemp = new Date(history[i].detail.created_at)
					let date = {
						year: dateTemp.getFullYear(),
						month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
						date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
						hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
						minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
					}

					let indexOfStart = history[i].detail.reff_transaction.indexOf("-")
					let transactionId = history[i].detail.reff_transaction.substring(indexOfStart + 1)

					let debit = history[i].detail.type == 'debit' ? Decimal.mul(history[i].detail.amount, -1).dividedBy(streamingRate).toFixed(2) : '-'
					let credit = history[i].detail.type == 'credit' ? Decimal.div(history[i].detail.amount, streamingRate).toFixed(2) : '-'

					this.lastTenTransactions.push({
						date: `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
						transactionId: transactionId,
						debit: debit,
						credit: credit
					})
				}

			}).catch(error => {
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		},

		getGetEndOfDayPrices() {
			let date = new Date()
			date.setDate(date.getDate() - (((2 + date.getDay()) % 7) || 0))
			date.setHours(7)
			date.setMinutes(0)
			date.setSeconds(0)
			date.setMilliseconds(0)

			axios.get('/v2/igx/end_day_prices?date=' + date.toISOString()
			).then(response => {
				this.endOfDayPrices = response.data.data.body
			}).catch(error => {
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		}

	}
}
</script>

<style scoped>
.c-row-table:hover {
	background-color: rgb(0, 0, 0, 0.3);
	cursor: pointer;
}

.title-c {
	font-size: 1.625rem;
	color: white;
}
</style>
