<template>
	<div>
		<div class="flex flex--justify-content-start flex--align-items-end flex--wrap gap-12 mb-12-c">
			<div class="flex--grow-1">
				<ReportsNav />
			</div>

			<div v-if="$parent.accountLevel == 'ib' || $parent.accountLevel == 'fm' || $parent.accountLevel == 'ccp'"
				class="col-auto text-left">
				<FilterClient class="d-inline-block" filterId="order" @changeFilterClient="changeFilterClientOrder"
					:clients_prop="listClients">
				</FilterClient>
			</div>

			<div class="flex flex--align-items-end flex--wrap gap-12">
				<div class="">
					<FilterStatus @filterStatus='choseFilterStatus' :filterStatus_prop='filterStatus'></FilterStatus>
				</div>

				<div class=" ">
					<FilterContract class="d-inline-block" filterId="order" @changeFilterContract="changeFilterContractOrder">
					</FilterContract>
				</div>
			</div>
		</div>

		<div class="row bg-grey-1">
			<div class="col-12 table-responsive p-0">
				<table class="table table--report text-nowrap">
					<thead>
						<tr class="text-center" v-if="filterStatus == 'submit' || filterStatus == 'cancel'">
							<th class="">DIRECTION</th>
							<th class="">ORDER TYPE</th>
							<th class="">DATE</th>
							<!-- <th class="">HIN</th> -->
							<th class="">ORDER ID</th>
							<th class="">PRODUCT</th>
							<!-- <th class="">HUB</th> -->
							<th class="">QTY</th>
							<th class="">CONTRACT VALUE</th>
							<th v-if="filterStatus == 'submit'" class="">ESTIMATED PRICE</th>

							<th v-if="filterStatus == 'submit'" class="">REMAIN/Tot. Gram</th>
							<th v-else class="">Total Gram</th>

							<th class="">STATUS</th>
							<!-- <th class="sorting_desc_disabled sorting_asc_disabled text-right">Actions</th> -->
						</tr>

						<tr class="text-center" v-else>
							<th class="">DIRECTION</th>
							<th class="">Order Type</th>
							<th class="">DATE</th>
							<th class="">ORDER ID</th>
							<th class="">TRANSACTION ID</th>
							<th class="">PRODUCT</th>
							<th class="">QTY.</th>
							<th class="">METAL VALUE <br> OF TRADE</th>
							<th class="">SETTLEMENT <br> AMOUNT</th>
							<th class="">TOTAL <br> GRAM</th>
							<th class="">STATUS</th>
						</tr>

					</thead>


					<tbody>
						<!-- OPEN -->
						<template v-if="filterStatus == 'submit'">
							<template v-if="this.apiStatusGetOrders == 'idle'">
								<tr v-for="(order, index) in orders" v-bind:key="order.id + 'open' + index" class="c-row-table"
									@click="showDetailOrder(order)">
									<td :class="`text-center`">
										<i :style="`color: ${(order.direction == 'buy') ? '#2ec4b6' : '#e71d36'}`"
											:class="`fas fa-lg fa-arrow-circle-${(order.direction == 'buy') ? 'down' : 'up'} mr-6-c`"
											:title="`${(order.direction == 'buy') ? 'BUY' : 'SELL'}`"></i>
										<span :class="`${(order.direction == 'buy') ? 'color-green-c' : 'color-red-c'}`">
											{{ (order.direction == 'buy') ? 'Buy' : 'Sell' }}
										</span>
									</td>
									<td class="text-center text-capitalize">{{ order.type }}</td>

									<td class="text-center">{{ order.date }}</td>
									<!-- <td class="text-center">{{order.hin}}</td> -->


									<!-- <td class="text-center">
										<Loader v-if="order.hin == 'loadingTemp'" size_prop="14px" borderSize_prop="1px"></Loader>

										<template v-else>
											{{ order.hin }}
										</template>
									</td> -->

									<td class="text-center">{{ order.id }}</td>
									<td class="text-center">{{ order.product }}</td>

									<!-- <td class="text-center">JAK</td> -->

									<td class="text-center">{{ order.quantity }}</td>

									<td class="text-right">
										{{ numberFormatExtend($parent.currency == 'usd' ? order.value : order.valueIdr) }}
									</td>
									<td class="text-right">
										{{ numberFormatExtend($parent.currency == 'usd' ? order.totalPriceUsd : order.totalPriceIdr) }}
									</td>
									<td class="text-center">{{ order.remaining }}</td>
									<td class="text-center text-capitalize">{{ order.status }}</td>
								</tr>
								<tr v-if="orders.length < 1">
									<td colspan="9" class="text-center">No data available in table</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="9" rowspan="3">
										<Loader size_prop="10em"></Loader>
									</td>
								</tr>
							</template>
						</template>

						<!-- FILLED -->
						<template v-else-if="filterStatus == 'fill'">
							<template v-if="apiStatusGetOrders == 'idle'">
								<tr v-for="(order, index) in orders" class="c-row-table" @click="showDetailOrder(order)"
									v-bind:key="'filled' + index + order.id">
									<td class="text-center">
										<i :style="`color: ${(order.direction == 'buy') ? '#2ec4b6' : '#e71d36'}`"
											:class="`fas fa-lg fa-arrow-circle-${(order.direction == 'buy') ? 'down' : 'up'} mr-6-c`"
											:title="`${(order.direction == 'buy') ? 'BUY' : 'SELL'}`"></i>
										<span :class="`${(order.direction == 'buy') ? 'color-green-c' : 'color-red-c'}`">
											{{ (order.direction == 'buy') ? 'Buy' : 'Sell' }}
										</span>
									</td>
									<td class="text-center text-capitalize">{{ order.orderType }}</td>
									<td class="text-center">{{ order.date }}</td>

									<!-- <td class="text-center">
										<Loader v-if="order.hin == 'loadingTemp'" size_prop="14px" borderSize_prop="1px"></Loader>

										<template v-else>
											{{ order.hin }}
										</template>
									</td> -->

									<td class="text-center">{{ order.orderId }}</td>
									<td class="text-center">{{ order.ref }}</td>
									<td class="text-center">{{ order.product }}</td>

									<td class="text-center">{{ order.quantity }}</td>
									<td class="text-right">{{ numberFormatExtend((($parent.currency == 'usd') ? order.metalValueOfTrade :
										order.metalValueOfTradeIdr).toFixed(2)) }}</td>

									<td class="text-right">{{ numberFormatExtend((($parent.currency == 'usd') ? order.settlementAmount :
										order.settlementAmountIdr).toFixed(2)) }}</td>

									<td class="text-center">{{ ((order.totalGram).toFixed(2)) }}</td>
									<!-- <td class="text-right">{{ numberFormatExtend((($parent.currency == 'usd') ? order.pricePerGram :
										order.pricePerGramIdr).toFixed(2)) }}</td>
									<td class="text-right">{{ numberFormatExtend((($parent.currency == 'usd') ? order.fee :
										order.feeIdr).toFixed(2)) }}</td>
									<td class="text-right">{{ numberFormatExtend((($parent.currency == 'usd') ? order.tax :
										order.taxIdr).toFixed(2)) }}</td> -->
									<td class="text-center">{{ order.status }}</td>

								</tr>

								<tr v-if="orders.length < 1">
									<td colspan="14" class="text-center">No data available in table</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="13">
										<Loader size_prop="10em"></Loader>
									</td>
								</tr>
							</template>
						</template>

						<!-- KILLED -->
						<template v-else>
							<template v-if="this.apiStatusGetOrders == 'idle'">
								<tr v-for="(order, index) in orders" v-bind:key="order.id + 'killed' + index" class="c-row-table"
									@click="showDetailOrder(order)">
									<td class="text-center">
										<i :style="`color: ${(order.direction == 'buy') ? '#2ec4b6' : '#e71d36'}`"
											:class="`fas fa-lg fa-arrow-circle-${(order.direction == 'buy') ? 'down' : 'up'} mr-6-c`"
											:title="`${(order.direction == 'buy') ? 'BUY' : 'SELL'}`"></i>
										<span :class="`${(order.direction == 'buy') ? 'color-green-c' : 'color-red-c'}`">
											{{ (order.direction == 'buy') ? 'Buy' : 'Sell' }}
										</span>
									</td>

									<td class="text-center">{{ order.type }}</td>

									<td class="text-center">{{ order.date }}</td>

									<td class="text-center">{{ order.id }}</td>

									<!-- <td class="text-center">
										<Loader v-if="order.hin == 'loadingTemp'" size_prop="14px" borderSize_prop="1px"></Loader>

										<template v-else>
											{{ order.hin }}
										</template>
									</td> -->

									<td class="text-center">{{ order.product }}</td>

									<td class="text-center">{{ order.quantity }}</td>

									<td class="text-right">{{ numberFormatExtend($parent.currency == 'usd' ? order.value : order.valueIdr)
									}}</td>

									<td class="text-center">{{ order.remaining }}</td>

									<td class="text-center text-capitalize">{{ order.status }}</td>
								</tr>
								<tr v-if="orders.length < 1">
									<td colspan="9" class="text-center">No data available in table</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td class="text-center" colspan="9" rowspan="3">
										<Loader size_prop="10em"></Loader>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
					<tfoot>
					</tfoot>
				</table>
			</div>
		</div>

		<div class="flex flex--justify-content-end text-right mb-2">
			<div class="">
				<Pagination2 ref="paginationOrders" :totalData_prop="totalOrders" @changePage="setOrdersBefore">
				</Pagination2>
			</div>
		</div>

		<detailOrder v-show="detailOrder.modalOpen" @close="closeModal" :data_prop="detailOrder"
			:account_prop="detailOrder.account" :ticker_prop="detailOrder.ticker" :name="detailOrder.name"
			:contractId_prop="detailOrder.contractId" :orderId_prop="detailOrder.orderId" :status_prop="detailOrder.status"
			:direction_prop="detailOrder.direction" :type_prop="detailOrder.type" :validity_prop="detailOrder.validity"
			:validUntil_prop="detailOrder.validUntil" :pricePerGram_prop="detailOrder.pricePerGram"
			:totalGram_prop="detailOrder.totalGram" :contractPrice_prop="detailOrder.contractPrice"
			:quantity_prop="detailOrder.quantity" :contractValue_prop="detailOrder.contractValue"
			:feeFilled_prop="detailOrder.feeFilled" :taxFilled_prop="detailOrder.taxFilled"
			:totalUsdFilled_prop="detailOrder.totalUsdFilled"></detailOrder>
	</div>
</template>

<script>
import axios, { abortController } from '@/api/axios.js'
import Decimal from 'decimal.js'

import detailOrder from '@/components/reports/orders/DetailOrder.vue'

import Loader from '@/components/Loader.vue'

import ReportsNav from '@/components/reports/ReportsNav.vue'
import Pagination2 from '@/components/Pagination2.vue'
import FilterContract from '@/components/FilterContract.vue'
import FilterClient from '@/components/FilterClient.vue'
import FilterStatus from '@/components/FilterStatus.vue'

export default {
	components: {
		detailOrder,

		Loader,

		ReportsNav,
		Pagination2,
		FilterContract,
		FilterClient,
		FilterStatus
	},

	data() {
		return {
			Decimal: Decimal,

			firsCallDone: false,

			filterContractOrder: [],

			filterStatus: 'submit',
			dataOrderFilterStatusSubmit: [],
			dataOrderFilterStatusFill: [],
			dataOrderFilterStatusCancel: [],

			dataOrderFilterStatusSubmitPageIndex: 0,
			dataOrderFilterStatusFillPageIndex: 0,
			dataOrderFilterStatusCancelPageIndex: 0,

			apiStatusGetOrders: 'idle',

			orders: [],
			totalOrders: 1,

			cancelTokenApiGetOrder: '',
			cancelTokenApiGetHinOrders: '',

			apiOrderSourceCancelToken: '',
			apiOrderCancelTokenReady: false,

			detailOrder: {
				account: '',
				modalOpen: false,
				orderId: '',
				status: '',
				direction: '',
				type: '',
				validUntil: '',
				pricePerGram: 0,
				totalGram: 0,
				contractPrice: 0,
				quantity: 0,
				contractValue: 0,
			},

			listClients: [],

			selectedClientOrder: 'all',
		}
	},

	created() {
		this.$store.state.filterContract.forEach((item) => {
			if (item.checked) {
				this.filterContractOrder.push(item.contractId)
			}
		})

		this.apiStatusGetOrders = 'loading'
		const firstCall = setInterval(() => {
			if (this.$parent.products && this.$parent.contractsTemp && this.$parent.accountLevel && this.$parent.hubsTemp) {
				clearInterval(firstCall)

				this.firsCallDone = true
				this.setOrdersBefore()
			}
		}, 1000)
	},

	methods: {
		cancelApiOrder() {
			if (this.apiOrderCancelTokenReady) {
				this.apiOrderCancelTokenReady = false
				this.apiOrderSourceCancelToken.cancel('CANCELLED')
			}
		},

		setOrdersBefore(skip = 0, limit = 10) {
			if (!this.firsCallDone) {
				return
			}

			if (this.filterStatus == 'submit' || this.filterStatus == 'cancel') {
				this.setOrders(skip, limit)
			} else { //fill
				this.setOrdersFill(skip, limit)
			}
		},

		setOrders(skip = 0, limit = 10) {
			let selectedContracts = this.filterContractOrder
			let contractsParam = ''

			if (selectedContracts.length > 0) {
				selectedContracts.forEach((item) => {
					contractsParam = contractsParam + `contractId[]=${item}&`
				})
			}

			if (contractsParam == '') {
				contractsParam = 'contractId[]=&'
			}

			let urlApi = ''
			if (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') {
				if (this.selectedClientOrder == 'all') {
					urlApi = '/v2/rrs/orders?offset=' + skip + '&limit=' + limit + '&' + 'order_type=limit&' + contractsParam + 'status=' + this.filterStatus
				} else {
					urlApi = '/v2/rrs/orders?offset=' + skip + '&limit=' + limit + '&' + 'hin=' + this.selectedClientOrder.hin_abx + '&accountId=' + this.selectedClientOrder.ac_abx + '&' + contractsParam + 'status=' + this.filterStatus
				}
			} else if (this.$parent.accountLevel == 'lp') {
				urlApi = '/v2/igx/market-maker/order?offset=' + skip + '&limit=' + limit + '&' + 'order_type=limit&' + contractsParam + 'status=' + this.filterStatus
			} else {
				urlApi = '/v2/igx/orders?offset=' + skip + '&limit=' + limit + '&' + 'order_type=limit&' + contractsParam + 'status=' + this.filterStatus
			}

			if (this.cancelTokenApiGetOrder) {
				//cancel api get Orders
				this.cancelTokenApiGetOrder.controller.abort()
			}
			this.cancelTokenApiGetOrder = abortController()

			this.apiStatusGetOrders = 'loading'

			axios.post(urlApi, {
				user_id: this.$store.state.userId
			}, {
				signal: this.cancelTokenApiGetOrder.signal,
			}).then(response => {
				this.orders = []
				this.totalOrders = response.data.data.count
				let orders = response.data.data.body || []

				if (this.filterStatus == 'submit' || this.filterStatus == 'cancel') {
					orders.forEach((order, index) => {
						let contract = this.$parent.contractsTemp.find(obj => obj.id == order.attributes.contractId)
						let contractConfig = this.$store.getters.getContractConfigs(order.attributes.contractId)
						let hub = this.$parent.hubsTemp.find(obj => obj.id == contract.attributes.hubId)
						let dateTemp = new Date(order.attributes.createdAt)
						let date = {
							year: dateTemp.getFullYear(),
							month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
							date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
							hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
							minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
						}
						let accountDetail = '-'

						let rate = order.attributes.rate
						if (rate == 0 || !rate) {
							if (order.attributes.paymentIdr) {
								rate = Decimal.div(order.attributes.paymentIdr, order.attributes.paymentUsd).toDecimalPlaces(2).toNumber()
							} else {
								rate = (order.attributes.direction == 'sell') ? this.$parent.streamingRate.stream_sell.cur_rate : this.$parent.streamingRate.stream_buy.cur_rate
							}
						}

						this.$set(this.orders, index, {
							id: order.id,
							account: (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') ? 'loadingTemp' : this.$store.getters.getFirstName,
							direction: order.attributes.direction,
							date: `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
							hin: (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') ? 'loadingTemp' : this.$store.getters.getHin,
							product: contractConfig.name,
							hub: hub.attributes.iata,
							type: order.attributes.orderType,
							value: Decimal.mul(order.attributes.limitPrice, order.attributes.quantity).toNumber(),
							valueIdr: Decimal.mul(order.attributes.limitPrice, order.attributes.quantity).times(rate).toNumber() || '-',
							quantity: Number(Decimal.div(order.attributes.quantity, contractConfig.weightCorrection)),
							totalPriceUsd: order.attributes.paymentUsd,
							totalPriceIdr: order.attributes.paymentIdr,
							remaining: order.attributes.remaining,
							status: order.attributes.status == 'cancel' ? 'Cancelled' : order.attributes.status,
						})

						if (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') {
							axios.get('/v2/rrs/get/hin?accountId=' + order.attributes.accountId
							).then(response => {
								accountDetail = response.data.data.body
								// this.$set(this.orders[index], 'account', accountDetail.attributes.firstName)
								this.$set(this.orders[index], 'hin', accountDetail)

							}).catch(error => {
								this.$set(this.orders[index], 'hin', accountDetail)
							})
						}
					})
				}

				this.apiStatusGetOrders = 'idle'
			}).catch(error => {

			})
		},

		setOrdersFill(skip = 0, limit = 10) {
			let selectedContracts = this.filterContractOrder
			let contractsParam = ''

			let contractParamName
			if (this.$parent.accountLevel == 'lp' || this.$parent.accountLevel == 'client') {
				contractParamName = 'contract_id'
			} else if (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm') {
				contractParamName = 'contractId'
			}

			if (selectedContracts.length > 0) {
				selectedContracts.forEach((item) => {
					contractsParam = contractsParam + `${contractParamName}[]=${item}&`
				})
			}

			if (contractsParam == '') {
				contractsParam = `${contractParamName}[]=&`
			}

			let urlApi = ''

			if (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') {
				if (this.selectedClientOrder == 'all') {
					urlApi = '/v2/rrs/transactions?offset=' + skip + '&' + contractsParam + 'limit=' + limit
				} else {
					urlApi = '/v2/rrs/transactions?offset=' + skip + '&limit=' + limit + '&' + 'hin=' + this.selectedClientOrder.hin_abx + '&accountId=' + this.selectedClientOrder.ac_abx + '&' + contractsParam
				}
			} else if (this.$parent.accountLevel == 'lp') {
				urlApi = '/v2/igx/market-maker/transaction?offset=' + skip + '&limit=' + limit + '&' + contractsParam + 'order_type=limit'
			} else {
				urlApi = '/v2/igx/transactions?offset=' + skip + '&limit=' + limit + '&' + contractsParam + 'order_type=limit'
			}

			if (this.cancelTokenApiGetHinOrders) {
				//cancel api get hin
				this.cancelTokenApiGetHinOrders.controller.abort()
			}
			this.cancelTokenApiGetHinOrders = abortController()

			if (this.cancelTokenApiGetOrder) {
				//cancel api get Orders
				this.cancelTokenApiGetOrder.controller.abort()
			}
			this.cancelTokenApiGetOrder = abortController()

			this.apiStatusGetOrders = 'loading'

			axios.post(urlApi, {
				user_id: this.$store.state.userId,
			}, {
				signal: this.cancelTokenApiGetOrder.signal,
			}).then(response => {
				this.orders = []
				this.totalOrders = response.data.data.count

				let orders = response.data.data.body
				if (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm') {
					orders = response.data.data.body
				}

				orders.forEach((order, index) => {
					const contract = this.$parent.contractsTemp.find(obj => obj.id == order.attributes.contractId)
					const contractConfig = this.$store.getters.getContractConfigs(order.attributes.contractId)
					const hubs = this.$parent.hubsTemp

					let hub = '-'
					if (hubs) {
						hub = hubs.find(obj => obj.id == contract.attributes.hubId)
						hub = hub.attributes.iata
					}

					let dateTemp = new Date(order.attributes.createdAt)
					const date = {
						year: dateTemp.getFullYear(),
						month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
						date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
						hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
						minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
					}

					let settlementAmount = order.attributes.settlementAmount

					let metalValueOfTrade = order.attributes.metalValueOfTrade

					let fee = new Decimal(order.attributes.fee)

					let tax = new Decimal(order.attributes.tax)

					let weight = contractConfig.weightInGram
					const totalGram = Number(Decimal.mul(weight, order.attributes.quantity).div(contractConfig.weightCorrection))

					let pricePer = Decimal.div(metalValueOfTrade, totalGram)

					let rate = order.attributes.rate || 0
					if (rate == 0) {
						rate = (order.attributes.direction == 'sell') ? this.$parent.streamingRate.stream_sell.cur_rate : this.$parent.streamingRate.stream_buy.cur_rate
					}

					const tempArray = {
						id: order.id,
						orderId: order.attributes.orderId,
						orderMatchId: order.attributes.orderMatchId,
						contractId: order.attributes.contractId,
						direction: order.attributes.direction,
						date: !order.attributes.createdAt ? '-' : `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
						hin: (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') ? 'loadingTemp' : this.hin,
						ref: order.id,
						product: contractConfig.name,
						hub: hub,
						orderType: order.attributes.orderType,
						quantity: Number(Decimal.div(order.attributes.quantity, contractConfig.weightCorrection)),
						totalGram: totalGram,
						pricePerGram: pricePer,
						pricePerGramIdr: Number(Decimal.mul(pricePer, rate)),
						metalValueOfTrade: metalValueOfTrade,
						metalValueOfTradeIdr: Number(Decimal.mul(metalValueOfTrade, rate)),
						fee: Number(fee),
						feeIdr: Number(fee.mul(rate)),
						tax: Number(tax),
						taxIdr: Number(tax.mul(rate)),
						settlementAmount: settlementAmount,
						settlementAmountIdr: Number(Decimal.mul(settlementAmount, rate)),
						status: (order.attributes.status == 'match') ? 'Matched' : 'On Process'
					}

					this.$set(this.orders, index, tempArray)

					if (this.$parent.accountLevel == 'ib' || this.$parent.accountLevel == 'fm' || this.$parent.accountLevel == 'ccp') {
						axios.get('/v2/rrs/get/hin?accountId=' + order.attributes.accountId, {
							signal: this.cancelTokenApiGetHinOrders.signal,
						}).then(response => {
							this.$set(this.orders[index], 'hin', response.data.data.hin)
						}).catch(error => {
						})
					}

				})


				this.apiStatusGetOrders = 'idle'

			}).catch(error => {
				this.apiStatusGetOrders = 'error'
			})
		},

		changeFilterClientOrder(clientData) {
			this.dataOrderFilterStatusSubmit = []
			this.cancelApiOrder()

			this.selectedClientOrder = clientData
			this.setOrdersBefore()
		},

		changeFilterContractOrder(items) {
			this.dataOrderFilterStatusSubmit = []
			this.cancelApiOrder()

			this.filterContractOrder = items

			this.setOrdersBefore()
		},

		choseFilterStatus(value) {
			// if (value == 'submit') {
			// 	this.filterStatus = 'submit'
			// 	this.orderFilterStatusSubmit()
			// }else if (value == 'fill') {
			// 	this.filterStatus = 'fill'
			// 	this.setOrdersFill()
			// }else {
			// 	this.filterStatus = 'cancel'
			// 	this.orderFilterStatusCancel()
			// }
			this.filterStatus = value
			this.setOrdersBefore()
		},

		showDetailOrder(orderParam) {
			this.$store.commit('CHANGE_GET_DETAIL_ORDER_STATUS', 'loading')
			this.$set(this.detailOrder, 'modalOpen', true)

			const orderId = this.filterStatus == 'fill' ? orderParam.orderId : orderParam.id

			axios.post('/v2/igx/order/detail?order_id=' + orderId, {
				user_id: this.$store.state.userId
			}).then(response => {
				let order = response.data.data.body
				let contract = this.$parent.contractsTemp.find(obj => obj.id == order.attributes.contractId)
				let product = this.$parent.products.find(obj => obj.id == contract.attributes.productId)
				let contractConfig = this.$store.getters.getContractConfigs(order.attributes.contractId)
				let oneOrder
				let weight = 0
				let name = ''
				let weightCorrection = contractConfig.weightCorrection

				name = contractConfig.name
				weight = contractConfig.weightInGram

				const totalGram = Number(Decimal.mul(weight, order.attributes.quantity).div(weightCorrection))
				this.$set(this.detailOrder, 'totalGram', totalGram)

				if (this.filterStatus == 'fill') {
					oneOrder = this.orders.find(obj => obj.orderId == orderId)
				} else {
					oneOrder = this.orders.find(obj => obj.id == orderId)
				}

				this.$set(this.detailOrder, 'paymentUsd', order.attributes.paymentUsd)
				this.$set(this.detailOrder, 'paymentIdr', order.attributes.paymentIdr)

				this.$set(this.detailOrder, 'fee', order.attributes.fee)
				this.$set(this.detailOrder, 'tax', order.attributes.tax)

				this.$set(this.detailOrder, 'ticker', product.attributes.ticker)
				this.$set(this.detailOrder, 'status', this.filterStatus)
				this.$set(this.detailOrder, 'quantity', Number(Decimal.div(order.attributes.quantity, contractConfig.weightCorrection)))
				this.$set(this.detailOrder, 'account', oneOrder.account)
				this.$set(this.detailOrder, 'name', name)
				this.$set(this.detailOrder, 'orderId', orderId)
				this.$set(this.detailOrder, 'contractId', order.attributes.contractId)
				this.$set(this.detailOrder, 'direction', order.attributes.direction)
				this.$set(this.detailOrder, 'type', order.attributes.orderType)
				this.$set(this.detailOrder, 'rate', order.attributes.rate || ((order.attributes.direction == 'sell') ? this.$parent.streamingRate.stream_sell.cur_rate : this.$parent.streamingRate.stream_buy.cur_rate))

				if (order.attributes.validity == 'GTC') {
					this.$set(this.detailOrder, 'validUntil', 'Until Cancel')
					this.$set(this.detailOrder, 'validity', 'GTC')
				} else {
					this.$set(this.detailOrder, 'validity', 'GTD')
					let expiryDateTemp = new Date(order.attributes.expiryDate)
					let expiryDate = {
						year: expiryDateTemp.getFullYear(),
						month: (expiryDateTemp.getMonth() + 1 < 10) ? `0${expiryDateTemp.getMonth() + 1}` : expiryDateTemp.getMonth() + 1,
						date: (expiryDateTemp.getDate() < 10) ? `0${expiryDateTemp.getDate()}` : expiryDateTemp.getDate(),
						hours: (expiryDateTemp.getHours() < 10) ? `0${expiryDateTemp.getHours()}` : expiryDateTemp.getHours(),
						minutes: (expiryDateTemp.getMinutes() < 10) ? `0${expiryDateTemp.getMinutes()}` : expiryDateTemp.getMinutes()
					}

					// this.detailOrder.validUntil = `${expiryDate.year}/${expiryDate.month}/${expiryDate.date} ${expiryDate.hours}:${expiryDate.minutes}`
					this.$set(this.detailOrder, 'validUntil', `${expiryDate.year}/${expiryDate.month}/${expiryDate.date} ${expiryDate.hours}:${expiryDate.minutes}`)
				}

				if (this.filterStatus == 'fill') {
					let pricePerGram = Number(Decimal.div(order.attributes.limitPrice, weightCorrection))
					let tempTotal = Decimal.mul(this.detailOrder.quantity, pricePerGram)

					const feeTemp = Decimal.mul(tempTotal, this.$store.state.fee).toDecimalPlaces(3).toNumber()
					this.$set(this.detailOrder, 'feeFilled', feeTemp)

					const taxTemp = Decimal.mul(feeTemp, this.$store.state.tax).toDecimalPlaces(3).toNumber()
					this.$set(this.detailOrder, 'taxFilled', taxTemp)

					this.$set(this.detailOrder, 'transactionId', orderParam.id)
					this.$set(this.detailOrder, 'settlementAmount', orderParam.settlementAmount)
					this.$set(this.detailOrder, 'settlementAmountIdr', orderParam.settlementAmountIdr)
					this.$set(this.detailOrder, 'metalValueOfTrade', orderParam.metalValueOfTrade)
					this.$set(this.detailOrder, 'metalValueOfTradeIdr', orderParam.metalValueOfTradeIdr)
					this.$set(this.detailOrder, 'fee', orderParam.fee)
					this.$set(this.detailOrder, 'feeIdr', orderParam.feeIdr)
					this.$set(this.detailOrder, 'tax', orderParam.tax)
					this.$set(this.detailOrder, 'taxIdr', orderParam.taxIdr)
					this.$set(this.detailOrder, 'pricePerGram', new Decimal(orderParam.pricePerGram).toNumber())
					this.$set(this.detailOrder, 'pricePerGramIdr', orderParam.pricePerGramIdr)

					if (order.attributes.buy == 'sell') {
						this.$set(this.detailOrder, 'totalUsdFilled', Number(tempTotal.sub(this.detailOrder.feeFilled).sub(this.detailOrder.taxFilled)))
					} else {
						this.$set(this.detailOrder, 'totalUsdFilled', Number(tempTotal.add(this.detailOrder.feeFilled).add(this.detailOrder.taxFilled)))
					}

				} else {
					this.$set(this.detailOrder, 'remain', Decimal.mul(contractConfig.weightInGram, order.attributes.remaining).div(contractConfig.weightCorrection).toNumber())
					this.$set(this.detailOrder, 'pricePerGram', Decimal.div(order.attributes.limitPrice, contractConfig.weightMultiplication).times(contractConfig.weightCorrection).toNumber())
				}

				this.$set(this.detailOrder, 'contractPrice', new Decimal(order.attributes.limitPrice).times(contractConfig.weightCorrection).toNumber())
				this.$set(this.detailOrder, 'contractValue', Decimal.mul(order.attributes.limitPrice, order.attributes.quantity).toNumber())
				this.$store.commit('CHANGE_GET_DETAIL_ORDER_STATUS', { status: 'idle' })
			}).catch(error => {
				if (error.response) {
					this.$store.commit('CHANGE_GET_DETAIL_ORDER_STATUS', { status: 'error', message: error.response.data.data.err_message })
				} else {
					this.$store.commit('CHANGE_GET_DETAIL_ORDER_STATUS', { status: 'error', message: 'something went wrong please try again in a few minutes' })
				}
			})
		},
		closeModal() {
			this.$set(this.detailOrder, 'modalOpen', false)
		},
	}
}
</script>

<style scoped>

.c-row-table:hover {
	background-color: rgb(0, 0, 0, 0.3);
	cursor: pointer;
}

.table thead {
	position: relative;
	border: 0;
}

.table thead::after {
	content: '';

	position: absolute;
	bottom: 0;
	/* left: -1.5em; */

	width: 100%;
	border-bottom: 1.5px solid var(--grey-2-color);
}

.table th {
	position: relative;

	padding: 2em 0em;

	border: 0;
}

.table th::before {
	content: '';

	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);

	height: 1.8em;
	border-left: 1px solid rgba(255, 255, 255, 0.25);
}
.table th:first-child::before {
	content: none;
}
.table th:first-child{
	padding-left: 3em;
}

.table th::after {
	content: '';

	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);

	height: 1.8em;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}
.table th:last-child::after {
	content: none;
}
.table th:last-child{
	padding-right: 3em;
}

.table tr {
	position: relative;
	border: 0;
}
.table tr::after {
	content: '';

	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);

	width: 96%;
	border-bottom: 1.5px solid rgba(255, 255, 255, 0.25);
}
.table tr:last-child::after {
	content: none;
}

.table td {
	border: 0;
}
.table tr td:first-child{
	padding-left: 3em;
}
.table tr td:last-child{
	padding-right: 3em;
}

</style>
