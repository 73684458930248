<template>
	<nav class="navbar navbar-expand-lg fixed-top">
		<!-- <div class="container-fluid"> -->
		<div class="flex flex--justify-content-center flex--align-items-center w-100">
			<div class="c-hum-bar">

				<div class="navbar-wrapper c-wrapper">
					<div class="navbar-minimize d-inline">
						<button class="minimize-sidebar btn btn-link btn-just-icon" rel="tooltip" data-original-title="Sidebar toggle"
							data-placement="right">
							<i class="tim-icons icon-align-center visible-on-sidebar-regular"></i>
							<i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini"></i>
						</button>
					</div>
					<div class="navbar-toggle d-inline">
						<button type="button" class="navbar-toggler">
							<span class="navbar-toggler-bar bar1"></span>
							<span class="navbar-toggler-bar bar2"></span>
							<span class="navbar-toggler-bar bar3"></span>
						</button>
					</div>
				</div>
			</div>
			
			<div class="flex--grow-1">
				<!-- <div class="row" :style="`width: ${navBalanceWidth}px`"> -->
				<div class="c-navbar-main d-flex justify-content-between">
					<div class="c-navbar-left d-flex align-items-center" style="gap: 20px;">
						<!-- Logo -->
						<div class="text-nowrap text-right c-col-hilang">
							<img :src="`${publicPath}assets/img/jfxgoldx.png`" height="18" class="mr-24-c">

							<a class="opacity-50 mr-8-c" href="https://www.jfx.co.id" target="_blank">
								<img :src="`${publicPath}assets/img/logo-jfx-white-text.png`" height="11">
							</a>

							<a class="opacity-50" href="https://www.ptkbi.com/" target="_blank">
								<img :src="`${publicPath}assets/img/logo-kbi-ver3-white.png`" height="18">
							</a>
						</div>

						<div v-if="phoneNumber == '628150000038'" class="align-items-center c-col-hilang pt-2"
							style="font-size: 18px; color: white; opacity: 50%;">
							JAKARTA FUTURES EXCHANGE
						</div>

						<div v-if="phoneNumber == '628150000039'" class="align-items-center c-col-hilang pt-2"
							style="font-size: 18px; color: white; opacity: 50%;">
							KLIRING BERJANGKA INDONESIA
						</div>
					</div>

					<div class="flex justify-content-end align-items-center gap-8">
					<!-- Running Price -->
						<div>
							<RunningPriceV2>
							</RunningPriceV2>
						</div>

						<!-- Balance -->
						<div v-if="accountLevel != 'ib' && accountLevel != 'fm' && this.$store.getters.getBrokerName != 'pospaygold'"
							class="float-right text-right	c-col-hilang pl-12-c">
							<Balance>
							</Balance>
						</div>

						<!-- Currency -->
						<div class="float-right c-col-hilang">
							<ChangeCurrency></ChangeCurrency>
						</div>

						<!-- HUB -->
						<div class="float-right text-center c-col-hilang pr-12-c">
							<ChangeHub></ChangeHub>
						</div>

						<!-- HIN -->
						<div class="float-right c-col-hilang">
							<TradingType></TradingType>
						</div>

						<div>
							<ProfilePicture></ProfilePicture>
						</div>
					</div>
				</div>
				<div class="row c-titik">
					<div class="col-12 text-right">
						<input type="checkbox" id="c-toggle-btn" />
						<label>
							<div class="c-nav-menu">
								<div class="row justify-content-center mb-5">
									<div class="col-12 text-center">
										&nbsp;
									</div>
								</div>
								<div class="row justify-content-center pt-2">
									<div class="col-auto text-center mb-2">
										<a class="mr-2" href="https://www.jfx.co.id" target="_blank">
											<img :src="`${publicPath}assets/img/logo-jfx-grey.png`" style="height: 40px;">
										</a>
										<a href="https://www.ptkbi.com/" target="_blank">
											<img :src="`${publicPath}assets/img/logo-kbi-grey.png`" style="height: 40px;">
										</a>
									</div>
									<div class="col-auto text-center mb-2">
										<TradingType></TradingType>
									</div>
									<div class="col-auto text-center mb-2">
										<Balance>
										</Balance>
									</div>

									<div class="col-auto text-center" style="white-space: nowrap;">
										<div class="row">
											<div class="col-auto mr-2 px-0">
												<ChangeCurrency></ChangeCurrency>
											</div>
											<div class="col-auto ml-2 mb-2 px-0">
												<ChangeHub></ChangeHub>
											</div>
										</div>
									</div>

								</div>
							</div>
							<i class="fas fa-ellipsis-v fa-lg" id="btn-titik-3" @click="toogleTitikTiga()"></i>
						</label>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
/*eslint-env jquery*/
import axios from '@/api/axios.js'
import resize from 'vue-resize-directive'
import { Decimal } from 'decimal.js'

import Balance from '@/components/Balance.vue'
// import RunningPrice from '@/components/RunningPrice.vue'
import RunningPriceV2 from '@/components/RunningPriceV2.vue'
import ChangeCurrency from '@/components/ChangeCurrency.vue'
import ChangeHub from '@/components/ChangeHub.vue'
import TradingType from '@/components/TradingType.vue'
import ProfilePicture from '@/components/navbar/ProfilePicture.vue'

export default {
	name: 'Navbar',
	components: {
		Balance,
		// RunningPrice,
		RunningPriceV2,
		ChangeCurrency,
		ChangeHub,
		TradingType,
		ProfilePicture,
	},
	directives: {
		resize,
	},
	data() {
		return {
			publicPath: process.env.BASE_URL,

			kelasKolom: 'col-auto',
			// lebarLayar: window.outerWidth,
			// navBalanceWidth: (window.outerWidth >= 1100) ? window.outerWidth-30 : window.outerWidth-30-50,
			hubs: {},
			productId: '1', //for running price (KBE 100 gr)

			eye_icon: '',
			eye_title: 'Hide',
			isHideBalance: false,

			balance_paddingTop: 5,

			setIntervalGetDepositCooldown: '',
		}
	},
	computed: {
		lebarLayar() {
			return window.outerWidth
		},

		navBalanceWidth() {
			if (window.outerWidth >= 1100) {
				return window.outerWidth - 30
			} else {
				return window.outerWidth - 30 - 50
			}
		},

		hin() {
			return this.$store.getters.getHin
		},

		tradingType() {
			return this.$store.state.tradingType
		},

		accountLevel() {
			return this.$store.getters.getAccountLevel
		},

		phoneNumber() {
			return this.$store.getters.getPhoneNumber
		},

		currency() {
			let currency = this.$store.state.currency
			return currency
		},
		getDepositIsCooldown() {
			return this.$store.state.getDepositIsCooldown
		}
	},
	created() {
		if (this.lebarLayar <= 1100) {
			this.kelasKolom = 'col-12'
			// this.navBalanceWidth = window.outerWidth-30-50
		} else {
			// this.navBalanceWidth = window.outerWidth-30
			this.kelasKolom = 'col-auto'
		}

		axios.get('/v2/igx/hub', {
			user_id: this.$store.state.userId
		}).then(response => {
			this.hubs = response.data.data.body

			if (response.data.hasOwnProperty('data')) {
				this.$store.commit('SET_HUBS', this.hubs)
			}
		}).catch(error => {
		})

		// Check getDeposit cooldown status
		// let temp = JSON.parse(localStorage.getItem('getDepositCooldownExpiredTime'))
		// this.setIntervalGetDepositCooldown = setInterval(() => {this.checkGetDepositCooldown()}, 1000)
	},
	mounted() {
		// Register an event listener when the Vue component is ready
		window.addEventListener('resize', this.onResize)
	},
	beforeDestroy() {
		// Unregister the event listener before destroying this Vue instance
		window.removeEventListener('resize', this.onResize)
	},
	methods: {
		tesTombol() {
			// this.$store.commit('CHANGE_GET_DEPOSIT_COOLDOWN_STATUS', true)
			// localStorage.setItem('getDepositCooldownExpiredTime', Date.now() + 180000)
			// this.setIntervalGetDepositCooldown = setInterval(() => {this.checkGetDepositCooldown()}, 1000)
		},
		numberFormatExtend(value) {
			let val = (value / 1)
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
		},
		onResize() {
			if (this.lebarLayar <= 1100) {
				this.kelasKolom = 'col-12'
				// this.navBalanceWidth = window.outerWidth-30-50
			} else {
				// this.navBalanceWidth = window.outerWidth-30
				this.kelasKolom = 'col-md-3'
			}
		},
		findProductById(productId) {
			let product = this.$store.getters.PRODUCTS
			if (product) {
				for (var i = 0; i < product.length; i++) {
					if (product[i]) {
						if (product[i].id == productId) {
							return product[i]
						}
					}
				}
			}
			return null
		},

		toogleTitikTiga() {
			if (document.getElementById("c-toggle-btn").checked == true) {
				document.getElementById("c-toggle-btn").checked = false
			} else {
				document.getElementById("c-toggle-btn").checked = true
			}
		},
		// checkGetDepositCooldown() {
		// 	let dateExpired = Number(localStorage.getItem('getDepositCooldownExpiredTime')) //in milisecond
		// 	let dateNow = Date.now()
		// 	if (dateExpired) {
		// 		if (dateNow > dateExpired) {
		// 			this.$store.commit('CHANGE_GET_DEPOSIT_COOLDOWN_STATUS', false)
		// 			localStorage.removeItem('getDepositCooldownExpiredTime')
		// 			clearInterval(this.setIntervalGetDepositCooldown)
		// 		}else {
		// 			this.$store.commit('CHANGE_GET_DEPOSIT_COOLDOWN_STATUS', true)
		// 		}
		// 	}else {
		// 		this.$store.commit('CHANGE_GET_DEPOSIT_COOLDOWN_STATUS', false)
		// 	}
		// },
	}
}
</script>

<style scoped>
select.c-select {
	background: #1a1e34;
}

.navbar {
	background-color: var(--black-color);
	align-items: center;
	top: 0px;
}

.c-hum-bar {
	margin-right: 14px;
	margin-left: 7px;
	width: 74px;
}

.c-titik {
	display: none;
}

.c-col-running {
	/*width: 100%;*/
	margin-left: 4px;
	margin-right: 4px;
}


.c-nav-menu {
	padding: 10px 40px;
	background-color: #1a1e34;
	color: black;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	transition-property: opacity, height;
	transition-duration: 0.3s;
}

.c-nav-menu>.content {}

/*.c-col-running {
		transition: 0.2s;
	}
	.c-col-running {
		transition: 0.2s;
	}*/

.c-wrapper {
	/*margin-right: 43px;*/
}

@media only screen and (max-width: 1320px) {

	/*when navbar shrink (only showing running price)*/
	.c-wrapper {
		/*margin-right: 0px;*/
	}

	.c-navbar-main {
		justify-content: center !important;
	}

	.c-navbar-left {}

	.c-col-hilang {
		display: none;
	}

	.c-col-running {
		width: 100%;
		margin-left: 0px;
		margin-right: 16px;
	}

	.c-titik {
		display: flex;
		position: absolute;
		top: 12px;
		right: 0px;
	}

	#c-toggle-btn:checked+label>.c-nav-menu {
		z-index: -3;
		visibility: visible;
		opacity: 1;
		min-height: 130px;
	}
}

@media only screen and (max-width: 1199.2px) {

	/*when sidebar disappear*/
	.c-hum-bar {
		padding-top: 6.5px;
		padding-left: 0px;
		padding-right: 0px;
		margin-right: 0px;
	}
}

/*	@media only screen and (max-width: 801px) {
		#c-toggle-btn:checked + label > .c-nav-menu {
			min-height: 170px;
		}
	}

	@media only screen and (max-width: 500px) {
		.c-col-balance {
			padding-left: 0;
		}
	}

	@media only screen and (max-width: 511px) {
		#c-toggle-btn:checked + label > .c-nav-menu {
			min-height: 210px;
		}
	}*/

#btn-titik-3 {
	/*color: white;*/
}

#btn-titik-3:hover {
	cursor: pointer;
}

#c-toggle-btn:checked+label>#btn-titik-3 {
	/*color: black;*/
}

#c-toggle-btn {
	position: relative;
	display: none;
	top: 2px;
	left: 12px;
	height: 15px;
	width: 15px;
}

.c-trading-type {
	display: inline-block;
	background-color: #2e3d61;
	color: white;
	padding: 10px 0px;
	height: 40px;
	border-radius: 0.25rem;
}

#eye:hover {
	cursor: pointer;
}

.fa-sync-alt:active {
	color: lightgrey;
}

.fa-sync-alt:hover {
	cursor: pointer;
}
</style>