<template>
	<div>
		<div class="tradebox-c" @click="showSomething()">
			<div class="tradebox-main">
				<div class="fs-10-c">{{ contractConfigs.codeName }}</div>
				<div class="fs-18-c mb-8-c">
					{{ contractConfigs.name }}
					<span class="color-muted-c fs-10-c">
						{{ contractConfigs.contractType == 'kau' ? '(KAU)' : '' }}
					</span>
				</div>
				<div class="flex flex--justify-content-start gap-16">
					<div>
						<div>Sell ({{ (currency == 'usd') ? '$' : 'Rp' }})</div>
						<div class="color-red-c">{{ sellShown }}</div>
					</div>
					<div>
						<div>Buy ({{ (currency == 'usd') ? '$' : 'Rp' }})</div>
						<div class="color-green-c">{{ buyShown }}</div>
					</div>
				</div>
			</div>

			<div class="tradebox-sub">
				<div>
					<div>Low</div>
					<div>{{ lowShown }}</div>
				</div>
				<div>
					<div>% Change</div>
					<div :class="`${change >= 0 ? 'color-green-c' : 'color-red-c'}`">
						{{ changeShown }}</div>
				</div>
				<div>
					<div>High</div>
					<div>{{ highShown }}</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { Decimal } from 'decimal.js'

export default {
	name: 'TradeBox',
	props: {
		width: {
			type: Number,
			default: 160
		},
		height: {
			type: Number,
			default: 0
		},
		add_class: {
			type: String,
			default: ''
		},

		contractId_prop: {
			type: Number,
			default: 1
		},
	},



	computed: {
		currency() {
			return this.$store.state.currency
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		gold() {
			return this.$store.getters.getGoldByContractId(this.contractId_prop)
		},

		ohlc() {
			return this.$store.getters.getOhlcByContractId(this.contractId_prop)
		},

		contractConfigs() {
			return this.$store.getters.getContractConfigs(this.contractId_prop)
		},

		sellShown() {
			if (!this.gold) {
				return '-'
			}

			let sellPrice = new Decimal(this.gold.sell)

			if (this.currency == "idr") {
				sellPrice = sellPrice.times(this.streamingRate.stream_sell.cur_rate)
			}

			return this.numberFormatExtend(sellPrice.toDecimalPlaces(this.contractConfigs.tbTtDecimalPlace).toNumber(), this.contractConfigs.tbTtDecimalPlace)
		},
		buyShown() {
			if (!this.gold) {
				return '-'
			}

			let buyPrice = new Decimal(this.gold.buy)

			if (this.currency == "idr") {
				buyPrice = buyPrice.times(this.streamingRate.stream_buy.cur_rate)
			}

			return this.numberFormatExtend(buyPrice.toDecimalPlaces(this.contractConfigs.tbTtDecimalPlace).toNumber(), this.contractConfigs.tbTtDecimalPlace)
		},

		style_tradebox() {
			return (`
					
				`)
		},
		style_percentage() {
			return 'color: ' + (this.caret_direction == 'up' ? 'green;' : 'red;')
		},

		caret() {
			if (!this.ohlc) {
				return '-'
			}

			return this.ohlc.caretDirection
		},

		lowShown() {
			if (!this.ohlc) {
				return '-'
			}

			let lowPrice = new Decimal(this.ohlc.low).toDecimalPlaces(2)

			if (this.currency == 'idr') {
				lowPrice = lowPrice.times(this.ohlc.rate)
			}

			return this.numberFormatExtend(lowPrice.toNumber())
		},
		highShown() {
			if (!this.ohlc) {
				return '-'
			}

			let highPrice = new Decimal(this.ohlc.high).toDecimalPlaces(2)

			if (this.currency == 'idr') {
				highPrice = highPrice.times(this.ohlc.rate)
			}

			return this.numberFormatExtend(highPrice.toNumber())
		},

		change() {
			if (!this.gold || !this.ohlc) {
				return '-'
			}

			const pricePerGram = new Decimal(this.gold.contractPriceBuy).dividedBy(this.contractConfigs.weightCorrection)
			const change = pricePerGram.minus(this.ohlc.open).dividedBy(this.ohlc.open).times(100).toDecimalPlaces(2).toNumber()

			return change
		},
		changeShown() {
			if (this.change == '-') {
				return '-'
			}

			const change = new Decimal(this.change)
			const sign = change.isNegative() ? '-' : '+'

			return sign + this.numberFormatExtend(change.abs().toDecimalPlaces(2).toNumber())
		},
	},

	methods: {
		showSomething() { //for QA purpose. only dev
			if (process.env.NODE_ENV === 'production') return

			console.log("ASDASDASDAS", process.env.NODE_ENV)

			const style = 'background-color: darkblue; color: white; font-style: italic; border: 5px solid hotpink; font-size: 2em;'
			console.log("%c😭😭😭😢😢😢", style,
				"\n\n",
				"Contract ID: ", this.contractId_prop, "\n",
				"Contract Name: ", this.contractConfigs.name, "\n",
				"Rate OHLC: ", this.ohlc.rate, "\n",
				"Rate Now: ", this.streamingRate.stream_buy.cur_rate, "\n",
				"Berat Kontrak: ", this.contractConfigs.weightMultiplication, "\n\n",
				"Contract Price Buy now: ", this.gold.contractPriceBuy, "\n",
				"Open BE: ", this.ohlc.original.open, "\n",
				"Close BE: ", this.ohlc.original.close, "\n",
				"Low BE: ", this.ohlc.original.low, "\n",
				"High BE: ", this.ohlc.original.high, "\n\n",

				"High: ", this.highShown, "\n",
				"Low: ", this.lowShown, "\n",
				"Change: ", this.changeShown, "\n",

				"High IDR: ", Decimal.mul(new Decimal(this.ohlc.high).toDecimalPlaces(2), this.ohlc.rate).toNumber(), "\n",
				"Low IDR: ", Decimal.mul(new Decimal(this.ohlc.low).toDecimalPlaces(2), this.ohlc.rate).toNumber(), "\n",
			)
		},
	}
}
</script>

<style scoped>
.tradebox-c {
	background-color: var(--black-color);
	padding: 0.5em;

	color: white;
	text-align: left;
	border-radius: 6px;
}

.tradebox-c:hover {
	cursor: pointer;
	filter: brightness(1.25);
}

.tradebox-main {
	background-color: #161A25;
	padding: 0.75em 1em 0.5em 1em;
	margin-bottom: 0.5em;
	border: 1px solid #232632;
}

.tradebox-sub {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 3em;
	padding: 0 0.5em;
}

.c-tradebox {
	display: inline-block;
	border: 0px;
	padding: 10px 15px 18px;
	background-color: #27293d;
	width: 100%;
	min-width: 100px;
	border-radius: 13px 13px 0 0;
	cursor: pointer;
	/* min-width: 251px; */
	overflow: auto;
	white-space: nowrap;
	position: relative;
}

.box-range {
	/* height: 50px; */
	background: #131722;
	margin-top: -5px;
	border-radius: 0 0 13px 13px;
}

.wrap-range {
	padding: 5px 0;
	display: flex;
	justify-content: space-around;
}

.c-tradebox:hover {
	background-color: #373a57;
}

.c-tradebox:active {
	background-color: #2e3048;
}

.c-name {
	color: yellow;
	font-size: 15px;
}

.c-percentage {
	font-size: 16px;
}

.c-sell {
	color: red;
	font-size: 18px;
}

.c-buy {
	color: green;
	font-size: 18px;
}

.c-bs {
	font-size: 10px;
	font-weight: bold;
	line-height: 3px;
}

.c-codename {
	position: absolute;
	background-color: #131722;
	width: 100%;
	top: 0;
	line-height: 30px;
}
</style>
