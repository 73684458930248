<template>
	<div class="select-box rounded" tabindex="-1" @blur="outOfFocus($event.target)" @mouseover="mousePosition = 'inside'"
		@mouseleave="mousePosition = 'outside'">
		<div :class="{ 'selected-currency': true, 'text-uppercase': true, 'rounded': true, 'active': isToogleActive }"
			@click="toggleOption()">
			{{ $store.state.currency }}

			<IconArrowTriangle class="ml-12-c" />
		</div>
		<div id="optionChangeCurrency1" class="option unselectable" :style="{ 'display': optionStyleDisplay }" tabindex="-1"
			@blur="outOfFocus($event.target)">
			<div class="form-group mb-3">
				<label for="selectCurrency" class="text-white font-weight-bold">Currency</label>
				<select id="selectCurrency" class="c-custom-select rounded" v-model="currency" @blur="outOfFocus($event.target)">
					<option value="usd">USD</option>
					<option value="idr">IDR</option>
				</select>
			</div>

			<table class="table">
				<thead>
					<tr>
						<th class="text-center" scope="col">Currency</th>
						<th class="text-center" scope="col">Buy</th>
						<th class="text-center" scope="col">Sell</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td class="text-center">USD</td>
						<td class="text-right">{{ this.$store.getters.getStreamingRate.stream_buy.cur_rate }}</td>
						<td class="text-right">{{ this.$store.getters.getStreamingRate.stream_sell.cur_rate }}</td>
					</tr>
				</tbody>
			</table>
		</div>

	</div>
</template>

<script>
import IconArrowTriangle from '@/components/icons/IconArrowTriangle.vue'

export default {
	name: 'ChangeCurrency',

	components: {
		IconArrowTriangle,
	},

	data() {
		return {
			isToogleActive: false,
			mousePosition: 'outside',
		}
	},
	computed: {
		name() {
			return this.$store.state.currency
		},
		currency: {
			get: function () {
				return this.$store.state.currency
			},
			set: function (val) {
				this.$store.dispatch('changeCurrency', val)
			}
		},

		optionStyleDisplay() {
			return this.isToogleActive ? 'block' : 'none'
		}
	},
	watch: {
	},
	created() {

	},

	mounted() {
	},

	methods: {
		toggleOption() {
			this.isToogleActive = !this.isToogleActive

			document.querySelector('#selectCurrency').focus()
		},

		outOfFocus() {
			if (this.mousePosition == 'outside') {
				this.isToogleActive = false
			}
		}
	}
}
</script>

<style scoped>
.select-box {
	position: relative;
	background-color: var(--grey-1-color);
	font-size: 1rem;
	padding: 0.5em 0.75em;
	height: 2.5em;
}

.selected-currency {
	position: relative;
	display: flex;
	align-items: center;
	/* font-size: 18px; */
	color: white;
	width: 100%;
	height: 100%;
	background-color: var(--grey-1-color);
	/* padding: 8px 16px; */
	z-index: 2;
}

.selected-currency:hover {
	cursor: pointer;
}

/* .selected-currency::after {
	position: relative;
	content: '<';
	margin-left: 14px;
	margin-bottom: 1px;
	font-size: 1.25em;

	transform: rotateZ(-90deg);
} */

.selected-currency.active::after {
	transform: translateX(0px) rotateZ(-270deg);
}

.option {
	position: absolute;
	display: none;
	background-color: var(--grey-1-color);
	top: 2.25em;
	right: 0;
	padding: 1.25em 1em 1em 1em;
	min-width: fit-content;
	border-radius: 4px;
	border-top-right-radius: 0;
	box-shadow: 0px 0px 10px -3px black;
}

.custom-select {
	background-color: var(--grey-1-color);
	border-color: rgba(255, 255, 255, 0.3);
}

.c-custom-select {
	display: block;
	width: 100%;
	background-color: var(--grey-1-color);
	border: solid rgba(255, 255, 255, 0.3) 1px;
	padding: 0.5rem 0.25rem;
	color: white;
}
</style>