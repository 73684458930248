<template>
	<div>
		<h4 class="text-center">RECONCILE REPORTING SYSTEM</h4>

		<div class="row">

			<div class="col-auto mb-2 text-left">
				<FilterDateAndType class="d-inline-block" @changeFilterDateAndType="changeFilterDateAndType">
				</FilterDateAndType>
			</div>

		</div>

		<div class="row">
			<div class="col-12">
				<TopSection></TopSection>
			</div>
		</div>

		<div class="row">
			<div class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">PRODUCT</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> ({{ $store.state.currency == 'usd' ? 'USD' : 'IDR' }})</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="rrsClientStatus != 'loading'">
							<tr v-for="(product, index) in products" class="c-row-table text-right" v-bind:key="product.id">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ product.name }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(product.buy.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(product.buy.totalPriceIdr) }}</td>
								
								<td>{{ product.buy.totalToz }}</td>
								<td>{{ product.buy.total_gram }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(product.sell.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(product.sell.totalPriceIdr) }}</td>
								
								<td>{{ product.sell.totalToz }}</td>
								<td>{{ product.sell.total_gram }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(product.total_trade) }}</td>
								<td v-else>{{ numberFormatExtend(product.totalTradeIdr) }}</td>
								
								<td>{{ product.totalToz }}</td>
								<td>{{ product.total_gram }}</td>
								<td class="text-center">{{ (product.rateRatio) ? product.rateRatio : '-' }}</td>
							</tr>
							<tr v-if="products.length < 1">
								<td colspan="12" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
import ExportAsButton from '@/components/ExportAsButton.vue'
import FilterDateAndType from '@/components/FilterDateAndType.vue'
import TopSection from '@/components/reconcile-reporting-system/TopSection.vue'

export default {
	name: 'Clients',

	props: {
		goldOrderProp: Array
	},

	components: {
		Loader,
		ExportAsButton,
		FilterDateAndType,
		TopSection
	},

	data() {
		return {
			grToTozMultiplier: 0.0321507,

			rrsClientStatus: 'loading',
			products: [],

			totalTurnOver: {
				usd: 0,
				toz: 0,
				gram: 0
			},

			selectedDateAndType: {
				type: 'all',
				startDate: '',
				endDate: ''
			},
		}
	},

	computed: {
		hin() {
			return this.$store.getters.getHin
		},
	},

	created() {
		this.setRrs()
	},

	methods: {
		setRrs() {
			let reportType = this.selectedDateAndType.type
			let startDate = this.selectedDateAndType.startDate
			let endDate = this.selectedDateAndType.endDate

			if (reportType == 'all') {
				reportType = ''
			}

			if (startDate) {
				startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
			}

			if (endDate) {
				endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? '0' : ''}${endDate.getMonth() + 1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`
			}

			if (this.hin == '') {
				this.rrsClientStatus = 'loading'
				this.products = []

				axios.get('/v2/rrs/client?type=' + this.reportType + '&startDate=' + startDate + '&endDate=' + endDate
				).then(response => {
					const processedData = this.$parent.processDataRRSClient(response.data.data)

					this.products = processedData.dataRRS
					this.totalTurnOver = processedData.totalTurnOver

					this.rrsClientStatus = 'success'
				}).catch(error => {
					this.rrsClientStatus = 'error'
				})
			} else {
				this.rrsClientStatus = 'loading'
				this.products = []

				axios.post('/v2/rrs/clients?hin=' + this.hin + '&type=' + reportType + '&date_start=' + startDate + '&date_end=' + endDate, {
					user_id: this.$store.state.userId
				}).then(response => {
					const processedData = this.$parent.processDataRRSClient(response.data.data)

					this.products = processedData.dataRRS
					this.totalTurnOver = processedData.totalTurnOver

					this.rrsClientStatus = 'success'
				}).catch(error => {
					this.rrsClientStatus = 'error'
				})
			}

		},

		changeFilterDateAndType(data) {
			this.selectedDateAndType = data
			this.setRrs()
		}

	}
}
</script>
