<template>
	<div class="content">
		<!-- <ul class="nav nav-pills nav-pills-primary justify-content-center" role="tablist">
			<li class="nav-item">
				<a class="nav-link active" data-toggle="tab" href="#nav1-ccp" role="tablist" aria-expanded="true">
					CCP
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-exchange-partner" role="tablist" aria-expanded="false">
					EXCHANGE PARTNER
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-full-member" role="tablist" aria-expanded="false">
					FULL MEMBER
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-ib" role="tablist" aria-expanded="false">
					IB
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-client" role="tablist" aria-expanded="false">
					CLIENT
				</a>
			</li>
		</ul>
		
		<div class="tab-content tab-space">
			<div class="tab-pane active" id="nav1-ccp" aria-expanded="true">
				<CentralCounterParty></CentralCounterParty>
			</div>

			<div class="tab-pane" id="nav1-exchange-partner" aria-expanded="false">
				<ExchangePartner></ExchangePartner>
			</div>

			<div class="tab-pane" id="nav1-full-member" aria-expanded="false">
				<FullMember></FullMember>
			</div>
			
			<div class="tab-pane" id="nav1-ib" aria-expanded="false">
				<IB></IB>
			</div>

			<div class="tab-pane" id="nav1-client" aria-expanded="false">
				<Clients></Clients>
			</div>
		</div> -->


		<CentralCounterParty v-if="this.$store.getters.getAccountLevel == 'ccp'" :goldOrderProp="goldOrder">
		</CentralCounterParty>

		<ExchangePartner v-if="this.$store.getters.getAccountLevel == 'lp'" :goldOrderProp="goldOrder"></ExchangePartner>

		<FullMember v-if="this.$store.getters.getAccountLevel == 'fm'" :goldOrderProp="goldOrder"></FullMember>

		<IB v-if="this.$store.getters.getAccountLevel == 'ib'" :goldOrderProp="goldOrder"></IB>

		<Clients v-if="this.$store.getters.getAccountLevel == 'client'" :goldOrderProp="goldOrder"></Clients>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import { Decimal } from 'decimal.js'

import CentralCounterParty from '@/components/reconcile-reporting-system/CentralCounterParty.vue'
import ExchangePartner from '@/components/reconcile-reporting-system/ExchangePartner.vue'
import FullMember from '@/components/reconcile-reporting-system/FullMember.vue'
import IB from '@/components/reconcile-reporting-system/IB.vue'
import Clients from '@/components/reconcile-reporting-system/Clients.vue'

export default {
	name: 'ReconcileReportingSystem',
	components: {
		CentralCounterParty,
		ExchangePartner,
		FullMember,
		IB,
		Clients,
	},

	data() {
		return {
			goldOrder: this.$store.state.goldOrderRrs,

			totalRevenue: {},
		}
	},

	computed: {
		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		streamingRateBuy() {
			return this.streamingRate.stream_buy.cur_rate
		},
		streamingRateSell() {
			return this.streamingRate.stream_sell.cur_rate
		},
	},

	methods: {
		getTotalRevenue() {
			return new Promise((resolve, reject) => {
				axios.post('/v2/rrs/total_revenue', {
					user_id: this.$store.state.userId
				}).then(response => {
					return resolve(response.data.data.body)
				}).catch(error => {
					return reject()
				})
			})
		},

		processDataRRSClient(dataRRS) {
			let temp = dataRRS
			
			// const trueContractIds = this.$store.state.trueContractIds

			// temp.contract_micro.contractId = trueContractIds.contractId_Micro001GramTo1Gram
			// temp.contract_100gr.contractId = trueContractIds.contractId_100g
			// temp.contract_1kg.contractId = trueContractIds.contractId_1kg
			// temp.contract_kau1gram.contractId = trueContractIds.contractId_KAU1Gram
			// temp.contract_kau001gram.contractId = trueContractIds.contractId_KAU1GramTo001Gram

			for (let key in temp) {
				temp[key].name = this.$store.getters.getContractConfigs(temp[key].contract_id).rrsName
			}

			let dataRRSTemp = []

			this.goldOrder.forEach(item => {
				dataRRSTemp.push(temp[Object.keys(temp).find(key => temp[key].contract_id == item)])
			})

			let tempUsd = new Decimal(0)
			let tempGram = new Decimal(0)

			dataRRSTemp.forEach((item, index) => {

				dataRRSTemp[index].buy.total_price = new Decimal(dataRRSTemp[index].buy.total_price).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].buy.totalPriceIdr = Decimal.mul(dataRRSTemp[index].buy.total_price, this.streamingRateBuy).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].buy.totalToz = Decimal.mul(item.buy.total_gram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber()
				dataRRSTemp[index].buy.total_gram = new Decimal(dataRRSTemp[index].buy.total_gram).toDecimalPlaces(2).toNumber()

				dataRRSTemp[index].sell.total_price = new Decimal(dataRRSTemp[index].sell.total_price).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].sell.totalPriceIdr = Decimal.mul(dataRRSTemp[index].sell.total_price, this.streamingRateSell).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].sell.totalToz = Decimal.mul(item.sell.total_gram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber()
				dataRRSTemp[index].sell.total_gram = new Decimal(dataRRSTemp[index].sell.total_gram).toDecimalPlaces(2).toNumber()

				dataRRSTemp[index].total_trade = new Decimal(dataRRSTemp[index].total_trade).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].totalTradeIdr = Decimal.mul(dataRRSTemp[index].total_trade, this.streamingRateBuy).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].totalToz = Decimal.mul(item.total_gram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber()
				dataRRSTemp[index].total_gram = new Decimal(dataRRSTemp[index].total_gram).toDecimalPlaces(2).toNumber()

				dataRRSTemp[index].rateRatio = Decimal.mul(Decimal.div(item.sell.total_price, Decimal.add(item.sell.total_price, item.buy.total_price)), 100).toDecimalPlaces(2).toNumber()
				if (isNaN(dataRRSTemp[index].rateRatio)) {
					dataRRSTemp[index].rateRatio = '-'
				}

				tempUsd = Decimal.add(tempUsd, item.total_trade)
				tempGram = Decimal.add(tempGram, item.total_gram)
			})

			const totalTurnOver = {
				usd: tempUsd.toDecimalPlaces(2).toNumber(),
				toz: Decimal.mul(tempGram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber(),
				gram: tempGram.toDecimalPlaces(2).toNumber(),
			}

			return { dataRRS: dataRRSTemp, totalTurnOver }
		},

		// for Full Member (FM)
		processDataRRSBursa(dataRRS, selectedAccountLevel = '') {
			let dataRRSTemp = dataRRS

			let tempUsd = new Decimal(0)
			let tempGram = new Decimal(0)
			dataRRSTemp.forEach((item, index) => {
				dataRRSTemp[index].gold.buy.total_price = new Decimal(dataRRSTemp[index].gold.buy.total_price).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].gold.buy.totalPriceIdr = Decimal.mul(dataRRSTemp[index].gold.buy.total_price, this.streamingRateBuy).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].gold.buy.totalToz = Decimal.mul(item.gold.buy.total_gram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber()
				dataRRSTemp[index].gold.buy.total_gram = new Decimal(dataRRSTemp[index].gold.buy.total_gram).toDecimalPlaces(2).toNumber()

				dataRRSTemp[index].gold.sell.total_price = new Decimal(dataRRSTemp[index].gold.sell.total_price).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].gold.sell.totalPriceIdr = Decimal.mul(dataRRSTemp[index].gold.sell.total_price, this.streamingRateSell).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].gold.sell.totalToz = Decimal.mul(item.gold.sell.total_gram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber()
				dataRRSTemp[index].gold.sell.total_gram = new Decimal(dataRRSTemp[index].gold.sell.total_gram).toDecimalPlaces(2).toNumber()

				dataRRSTemp[index].gold.total_trade = new Decimal(dataRRSTemp[index].gold.total_trade).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].gold.totalTradeIdr = Decimal.mul(dataRRSTemp[index].gold.total_trade, this.streamingRateBuy).toDecimalPlaces(2).toNumber()
				dataRRSTemp[index].gold.totalToz = Decimal.mul(item.gold.total_gram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber()
				dataRRSTemp[index].gold.total_gram = new Decimal(dataRRSTemp[index].gold.total_gram).toDecimalPlaces(2).toNumber()

				dataRRSTemp[index].gold.rateRatio = Decimal.mul(Decimal.div(item.gold.sell.total_price, Decimal.add(item.gold.sell.total_price, item.gold.buy.total_price)), 100).toDecimalPlaces(2).toNumber()
				if (isNaN(dataRRSTemp[index].gold.rateRatio)) {
					dataRRSTemp[index].gold.rateRatio = '-'
				}

				tempUsd = Decimal.add(tempUsd, item.gold.total_trade)
				tempGram = Decimal.add(tempGram, item.gold.total_gram)
			})

			if (selectedAccountLevel == 'ib') {
				dataRRSTemp = dataRRSTemp.sort((a, b) => (a.gold.total_trade < b.gold.total_trade) ? 1 : ((b.gold.total_trade < a.gold.total_trade) ? -1 : 0))
			}

			const totalTurnOver = {
				usd: tempUsd.toDecimalPlaces(2).toNumber(),
				toz: Decimal.mul(tempGram, this.$store.state.gramToTozMultiplier).toDecimalPlaces(10).toNumber(),
				gram: tempGram.toDecimalPlaces(2).toNumber(),
			}

			return { dataRRS: dataRRSTemp, totalTurnOver }
		}
	}
}
</script>