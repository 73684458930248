<template>
	<div class="content">
		<!-- Navbar -->
		<nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent fixed-top">
			<div class="container-fluid">
				<!-- <div class="navbar-wrapper">
					<div class="navbar-toggle d-inline">
						<button type="button" class="navbar-toggler">
							<span class="navbar-toggler-bar bar1"></span>
							<span class="navbar-toggler-bar bar2"></span>
							<span class="navbar-toggler-bar bar3"></span>
						</button>
					</div>
					<a class="navbar-brand" href="#">Login Page</a>
				</div>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-bar navbar-kebab"></span>
					<span class="navbar-toggler-bar navbar-kebab"></span>
					<span class="navbar-toggler-bar navbar-kebab"></span>
				</button>
				<div class="collapse navbar-collapse" id="navigation">
					<ul class="navbar-nav ml-auto">
						<li class="nav-item">
							
						</li>
						<li class="nav-item ">
							<a href="register.html" class="nav-link">
								<i class="tim-icons icon-laptop"></i> Register
							</a>
						</li>
						<li class="nav-item ">
							<a href="login.html" class="nav-link">
								<i class="tim-icons icon-single-02"></i> Login
							</a>
						</li>
						<li class="nav-item	active ">
							<a href="pricing.html" class="nav-link">
								<i class="tim-icons icon-coins"></i> Pricing
							</a>
						</li>
						<li class="nav-item ">
							<a href="lock.html" class="nav-link">
								<i class="tim-icons icon-lock-circle"></i> Lock
							</a>
						</li>
					</ul>
				</div> -->
			</div>
		</nav>
		<!-- <div class="modal modal-search fade" id="searchModal" tabindex="-1" role="dialog" aria-labelledby="searchModal" aria-hidden="true">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<input type="text" class="form-control" id="inlineFormInputGroup" placeholder="SEARCH">
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<i class="tim-icons icon-simple-remove"></i>
						</button>
					</div>
				</div>
			</div>
		</div> -->
		<!-- End Navbar -->
		<div class="wrapper wrapper-full-page ">
			<div class="full-page login-page ">
				<!--	 you can change the color of the filter page using: data-color="blue | purple | green | orange | red | rose " -->
				<div class="content pt-5">
					<div class="w-100 text-center mb-28-c">
						<img class="mb-16-c" style="width: 20em;" :src="`${publicPath}assets/img/jfxgoldx.png`" alt="">
						<div class=""><span class="text-title">Platform Perdagangan Fisik Emas Dalam Bursa Pertama di Indonesia</span>
						</div>
					</div>
					<div class="flex flex--justify-content-center">
						<div class="form-css">
							<!-- Login -->
							<form v-if="formType == 'login'" class="form" @submit.prevent="login">
								<div class="card">
									<div class="card-header">
										<h1 class="card-title">Log in</h1>
									</div>
									<div class="card-body">
										<label for="userId" class="color-white-c">Email / Username / Phone</label>
										<div class="input-group-c mb-16-c">
											<div class="input-group-prepend-c">
												<div class="">
													<IconUser />
												</div>
											</div>
											<input type="text" class="" name="userId" placeholder="Masukkan Email / Username / Phone"
												v-model="userId" required autofocus>
										</div>

										<label for="password" class="color-white-c">Password</label>
										<div class="input-group-c mb-16-c">
											<div class="input-group-prepend-c">
												<div class="input-group-text-c">
													<IconPadlock />
												</div>
											</div>

											<input v-if="!showPassword" type="password" class="" name="password" placeholder="Masukkan Password"
												v-model="password" autocomplete="off" required>
											<input v-else type="text" class="" name="password" placeholder="Password" v-model="password"
												autocomplete="off" required>

											<div class="input-group-append-c cursor-hover-pointer" @click="tooglePassword"
												:title="showPassword ? 'Hide Password' : 'Show Password'">
												<span class="input-group-text-c">
													<IconEyeClose v-if="showPassword" :sizeMultiplier_prop="1.3"> </IconEyeClose>
													<IconEyeOpen v-else :sizeMultiplier_prop="1.3"> </IconEyeOpen>
												</span>
											</div>
										</div>

										<label for="perantaraPerdagangan" class="color-white-c">Perantara Perdagangan</label>
										<div class="input-group-c mb-2">
											<select class="" id="perantaraPerdagangan" v-model="perantaraPerdagangan">
												<option value="MetalGo">ABI KB - MetalGO</option>
												<option value="PosPayGold">ABI KB - PospayGold</option>
												<option v-if="environment == 'development'" value="NUNomics">ABI KB - NUNomics</option>
												<option v-if="environment == 'development'" value="BMTDigi">ABI KB - BMTDigi</option>
											</select>
										</div>

									</div>
									<div class="card-footer">
										<button type="submit" class="btn c-btn-theme btn-lg btn-block mb-24-c"
											:disabled="this.$store.state.status == 'loading' ? true : false">Login</button>
										<small class="form-text text-center" @click="changeFormType('forgotPassword')"><span
												class="color-gold-c c-text-theme-hoverable  cursor-hover-pointer">Forgot your
												password?</span></small>
										<div class="pull-left">
											<h6>
												<!-- <a href="#" class="link footer-link">Create Account</a> -->
											</h6>
										</div>
										<div class="text-center">
											<h6 v-if="response"
												:class="`${response.toLowerCase() == 'loading' ? 'color-theme-c' : 'color-red-c'} mt-2`">{{
													response }}
												<!-- <a href="#" class="link footer-link">Need Help?</a> -->
											</h6>
										</div>
									</div>
								</div>
							</form>

							<!-- Forgot Password -->
							<form v-else-if="formType == 'forgotPassword'" class="form" @submit.prevent="forgotPassword">
								<div class="card">
									<div class="card-header">
										<h1 class="card-title">Forgot your password?</h1>
									</div>

									<div class="card-body pb-0 mb-28-c">
										<div class="color-white-c mb-2">We will send a link to your Email</div>
										<label for="emailForgotPass" class="color-white-c">Email</label>
										<div class="input-group-c mb-16-c">
											<div class="input-group-prepend-c">
												<div class="">
													<i class="tim-icons icon-email-85"></i>
												</div>
											</div>
											<input type="text" id="emailForgotPass" class="" name="userId" placeholder="Enter your email"
												v-model="emailForgotPass" autofocus>
										</div>

										<label for="perantaraPerdagangan" class="color-white-c">Perantara Perdagangan</label>
										<div class="input-group-c">
											<select id="perantaraPerdagangan" v-model="perantaraPerdagangan">
												<option value="MetalGo">ABI KB - MetalGO</option>
												<option value="PosPayGold">ABI KB - PospayGold</option>
												<option v-if="environment == 'development'" value="NUNomics">ABI KB - NUNomics</option>
												<option v-if="environment == 'development'" value="BMTDigi">ABI KB - BMTDigi</option>
											</select>
										</div>
									</div>

									<div class="card-footer">
										<button type="submit" class="btn c-btn-theme btn-lg btn-block mb-24-c"
											:disabled="forgotPasswordApiStatus == 'loading' ? true : false">Submit</button>
										<small class="form-text text-center" @click="changeFormType('login')"><span
												class="color-gold-c c-text-theme-hoverable  cursor-hover-pointer">Back to
												<b>Login</b></span></small>

										<div class="pull-left">
											<h6>
												<!-- <a href="#" class="link footer-link">Create Account</a> -->
											</h6>
										</div>
										<div class="text-center">
											<h6 v-if="responseForgotPassword" class="text-red">
												{{ responseForgotPassword }}
												<!-- <a href="#" class="link footer-link">Need Help?</a> -->
											</h6>
										</div>

									</div>
								</div>
							</form>
						</div>
					</div>

					<div class="flex flex--justify-content-center text-center">
						<div class="">
							<div class="lable-icon">Organized by</div>
							<div class="logo-css">
								<img class="mb-3" :src="`${publicPath}assets/img/perdagangan-gray-1.png`" alt="">
								<img class="mb-3" :src="`${publicPath}assets/img/logo-jfx-full-white.png`" alt="">
								<img class="mb-3" :src="`${publicPath}assets/img/logo-kbi-ver3-white.png`" alt="">
								<img class="mb-3" :src="`${publicPath}assets/img/logo-kmi-putih.png`" alt="">
								<img class="mb-3" :src="`${publicPath}assets/img/POSIND_Logo_3. Putih.png`" alt="">
							</div>
						</div>
					</div>
				</div>
				<Footer></Footer>
				<!-- <footer class="footer">
					<div class="container-fluid">
						<ul class="nav">
							<li class="nav-item">
								<a href="#" class="nav-link">
									Creative Tim
								</a>
							</li>
							<li class="nav-item">
								<a href="#" class="nav-link">
									About Us
								</a>
							</li>
							<li class="nav-item">
								<a href="#" class="nav-link">
									Blog
								</a>
							</li>
						</ul>
						<div class="copyright">
							©
							 made with <i class="tim-icons icon-heart-2"></i> by
							<a href="#" target="_blank">Creative Tim</a> for a better web.
						</div>
					</div>
				</footer> -->
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import Swal from 'sweetalert2'

import Footer from '@/components/layout/Footer.vue'

import IconEyeClose from './components/icons/IconEyeClose.vue'
import IconEyeOpen from './components/icons/IconEyeOpen.vue'
import IconUser from './components/icons/IconUser.vue'
import IconPadlock from './components/icons/IconPadlock.vue'

export default {
	name: 'Login',
	components: {
		Footer,

		IconEyeClose,
		IconEyeOpen,
		IconUser,
		IconPadlock,
	},
	data() {
		return {
			publicPath: process.env.BASE_URL,
			environment: process.env.NODE_ENV,

			userId: '',
			password: '',
			perantaraPerdagangan: 'MetalGo',

			formType: 'login',
			emailForgotPass: '',
			forgotPasswordApiStatus: 'idle',
			responseForgotPassword: '',

			script: null,

			showPassword: false,
		}
	},
	computed: {
		response() {
			let response = this.$store.state.status
			if (response) {
				return response
			} else {
				return null
			}
		}
	},

	created() {
		this.script = document.createElement('script')

		this.script.src = "https://www.google.com/recaptcha/enterprise.js?render=" + this.$store.state.captchaSiteKey
		this.script.async = true

		document.body.appendChild(this.script)

		// show Google reCaptcha logo
		const reCaptchaBadge = window.document.getElementsByClassName("grecaptcha-badge")
		if (reCaptchaBadge.length > 0) {
			reCaptchaBadge[0].style.visibility = 'initial'
		}
	},

	destroyed() {
		document.body.removeChild(this.script)
	},

	methods: {
		tooglePassword() {
			this.showPassword = !this.showPassword
		},

		login() {
			const { userId, password, perantaraPerdagangan } = this
			this.$store.dispatch('getGeolocation')
				.then((geolocation) => {
					if (!geolocation.hasOwnProperty('latitude') || !geolocation.hasOwnProperty('longitude') || !geolocation.hasOwnProperty('city') || !geolocation.hasOwnProperty('region') || !geolocation.hasOwnProperty('country') || !geolocation.hasOwnProperty('userIP')) {
						this.$store.commit('AUTH_ERROR', "Unable to Login. Please check your connection.")
						throw ('')
					}

					this.$store.dispatch('checkFormatAuth', { userId, password })
						.then((user) => {
							this.$store.dispatch('checkUser', user)
								.then((responseCheckUser) => {
									this.$store.dispatch('AUTH_REQUEST', [responseCheckUser.is_status, { ...user, app_name: perantaraPerdagangan }])
										.then(() => {
											this.$store.dispatch('setAccountInformation')
												.then(() => {
													// from Auth Request
													localStorage.setItem('token', this.$store.state.token)
													localStorage.setItem('authInfo', JSON.stringify(this.$store.state.authInfo))
													localStorage.setItem('userId', this.$store.state.userId)
													localStorage.setItem('brokerId', this.$store.state.brokerId)
													localStorage.setItem('bankType', JSON.stringify(this.$store.state.bankType))

													localStorage.setItem('loginExpiredTime', this.$store.state.loginExpiredTime) // for login expired in frontend only
													localStorage.setItem('refreshTokenTime', Date.now() + this.$store.state.refreshTokenTimeState) // for refresh token

													localStorage.setItem('account', JSON.stringify(this.$store.state.account))

													this.$store.commit('AUTH_CHANGE_STATUS', 'success')

													const temp = JSON.parse(localStorage.getItem('nextUrl'))

													if (temp) {
														localStorage.removeItem('nextUrl')
														this.$router.push(temp)
															.then(() => {
															}).catch(() => {
															})
													} else {
														this.$router.push('/')
															.then(() => {

															}).catch(() => {

															})
													}
												})
												.catch(() => { })
										})
										.catch(() => { })
								})
								.catch(() => { })
						})
						.catch(() => { })
				})
				.catch(() => { })
		},

		changeFormType(formType) {
			this.formType = formType
		},

		forgotPassword() {
			this.forgotPasswordApiStatus = 'loading'
			this.responseForgotPassword = ''
			Swal.showLoading()

			axios({
				method: 'POST',
				url: '/v2/user/forgot_password',
				data: {
					email: this.emailForgotPass,
					app_name: this.perantaraPerdagangan
				}
			})
				.then(response => {
					this.forgotPasswordApiStatus = 'success'

					Swal.hideLoading()
					Swal.fire({
						background: '#191f31',
						type: 'success',
						title: '<span style="color: white;">Success</span>',
						html: '<span style="color: white;">' + response.data.message + '</span>',
					}).then((result) => {
						if (result.value) {
							this.responseForgotPassword = ''
							this.formType = 'login'
						}
					})

				})
				.catch(error => {
					this.forgotPasswordApiStatus = 'error'
					Swal.hideLoading()

					let title = 'Error'

					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */

						this.responseForgotPassword = error.response.data.message

						if (error.response.data.data) {
							this.responseForgotPassword = error.response.data.data.err_message
							title = error.response.data.data.err_message
						}
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */

						this.responseForgotPassword =' Server appears to be offline'
					} else {
						// Something happened in setting up the request and triggered an Error
					}

					Swal.fire({
						background: '#191f31',
						type: 'error',
						title: '<span style="color: white;">' + title + '</span>',
					})
				})
		}
	}
}
</script>

<style scoped>
.input-group-prepend,
.input-group-append,
.input-group-append>* {
	background-color: white !important;
	border-color: #d1d3d7 !important;
}

.input-group-text {
	background-color: white;
}

.input-group-append>* {
	color: #9E9E9E;
	font-size: 1.1rem;
}

.lable-icon {
	font-size: 13px;
	color: #9E9E9E;
	margin-bottom: 20px;
}

.full-page>.content {
	padding-top: 60px;
	padding-bottom: 50px;
}

.text-title {
	display: inline-block;
	max-width: 40ch;
	text-align: center;
	font-size: 0.75rem;
	color: white;
	font-weight: 400;
}

.card>* {
	padding-right: 1.75em;
	padding-left: 1.75em;
}

.logo-css {
	display: flex;
	align-items: center;
	justify-content: center;

	gap: 1.5625em;
}

.logo-css img {
	opacity: 0.5;
}

.logo-css img:nth-child(2) {
	width: 6.5em;
}

.logo-css img:nth-child(3) {
	width: 5.5em;
}

.logo-css img:nth-child(4) {
	width: 6em;
}

.logo-css img:nth-child(5) {
	width: 3.75em;
}

.logo-css img:nth-child(1) {
	width: 10.75em;
}

.form-css {
	min-width: min(380px, 100%);
}

select {
	-webkit-appearance: caret;

	/* outline: none;
	border: none;
	padding: 0 1em;
	width: calc(100% - 1em);
	color: black;
	background-color: white;
	font-size: 0.75rem; */
}
</style>
