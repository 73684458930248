<template>
	<Modal ref="modalRef" @close="onModalClose()" :cancelable_prop="cancelable">
		<!-- loading -->
		<div v-if="type == 'loading'" class="text-center">
			<Loader size_prop="12em" class="mb-24-c" />

			<!-- <button type="button" class="w-100 btn c-btn-theme" @click="closeModalCancel()">CANCEL</button> -->
		</div>

		<!-- confirmation -->
		<div v-else-if="type == 'confirmation'" class="text-center">

			<h2>{{ text }}</h2>
			<div class="flex flex--same-width gap-8">
				<button type="button" class="btn c-btn-black color-theme-c" @click="closeModal('no')">{{ buttonNoText
				}}</button>
				<button type="button" class="btn c-btn-theme" @click="closeModal('yes')">{{ buttonYesText }}</button>
			</div>
		</div>

		<!-- success -->
		<div v-else-if="type == 'success'" class="text-center">
			<IconSuccess class="mb-24-c" />

			<h2>{{ text }}</h2>
			<button type="button" class="w-100 btn c-btn-theme" @click="closeModal()">{{ buttonYesText }}</button>
		</div>

		<!-- error -->
		<div v-else-if="type == 'error'" class="text-center">
			<!-- <IconSuccess class="mb-24-c" /> -->

			<h2>{{ text }}</h2>
			<button type="button" class="w-100 btn c-btn-theme" @click="closeModal()">{{ buttonYesText }}</button>
		</div>
	</Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'

import Loader from '@/components/Loader.vue'
import IconSuccess from '@/components/icons/IconSuccess.vue'

export default {

	name: 'Alert',

	props: {
	},

	components: {
		Modal,
		Loader,
		IconSuccess,
	},

	data() {
		return {
			type: 'success', // idle || loading || success || error || warning || confirmation,
			text: '---',

			buttonNoText: 'No',
			buttonYesText: 'Yes',

			payload: {
				status: 'idle', // idle || success || error || warning || cancel
				message: '-',
			},

			next: '', // what to do next

			closingType: ''
		}
	},

	computed: {
		cancelable() {
			return this.type == 'loading' || this.type == 'confirmation' ? false : true
		},
	},

	methods: {
		changeType(payload) {
			this.type = payload
		},
		changeText(payload) {
			this.text = payload
		},
		changeButtonNoText(payload) {
			this.buttonNoText = payload
		},
		changeButtonYesText(payload) {
			this.buttonYesText = payload
		},
		changeNext(payload) {
			this.next = payload
		},

		openModal() {
			this.$refs.modalRef.openModal()
		},
		closeModal(closingType = null) {
			this.closingType = closingType

			if (this.type == 'confirmation') {
				this.$refs.modalRef.forceCloseModal()
				return
			}

			this.$refs.modalRef.closeModal()
		},

		onModalClose() {
			if (this.type == 'confirmation') {
				if (this.closingType == 'no') {
					this.closeModalNo()
				}

				else if (this.closingType == 'yes') {
					this.closeModalYes()
				}
			}

			else if (this.type == 'success') {
				this.closeModalSuccess()
			}

			else if (this.type == 'error') {
				this.closeModalError()
			}

			this.$emit('closeAlert', {payload: this.payload, next: this.next})
		},

		closeModalCancel() {
			this.payload = {
				status: 'cancel',
				message: 'Cancelled',
			}
		},

		closeModalSuccess() {
			this.payload = {
				status: 'success',
				message: 'Order success',
			}
		},

		closeModalError() {
			this.payload = {
				status: 'error',
				message: 'Order failed',
			}
		},

		closeModalNo() {
			this.payload = {
				status: 'no',
				message: 'No',
			}
		},

		closeModalYes() {
			this.payload = {
				status: 'yes',
				message: 'Yes',
			}
		},

	}
}
</script>

<style lang="css" scoped></style>