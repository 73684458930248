<template>
	<svg :width="width" :height="height" viewBox="0 0 20 20" fill="none" >
		<g id="ic:round-refresh">
			<path id="Vector"
				d="M14.7086 5.29167C14.0101 4.58689 13.1622 4.04801 12.2276 3.71482C11.2929 3.38164 10.2954 3.26266 9.30855 3.36667C6.25022 3.675 3.73355 6.15833 3.39189 9.21667C2.93355 13.2583 6.05855 16.6667 10.0002 16.6667C11.2586 16.6667 12.4912 16.3096 13.5548 15.6369C14.6184 14.9643 15.4692 14.0037 16.0086 12.8667C16.2752 12.3083 15.8752 11.6667 15.2586 11.6667C14.9502 11.6667 14.6586 11.8333 14.5252 12.1083C14.0414 13.1492 13.2139 13.9916 12.1818 14.494C11.1498 14.9964 9.97622 15.128 8.85855 14.8667C7.00855 14.4583 5.51689 12.95 5.12522 11.1C4.96058 10.3685 4.96249 9.60944 5.1308 8.87883C5.29911 8.14822 5.62952 7.46479 6.0976 6.8791C6.56567 6.29341 7.15942 5.82044 7.83495 5.49518C8.51047 5.16992 9.25047 5.00069 10.0002 5C11.3836 5 12.6169 5.575 13.5169 6.48333L12.2586 7.74167C11.7336 8.26667 12.1002 9.16667 12.8419 9.16667H15.8336C16.2919 9.16667 16.6669 8.79167 16.6669 8.33333V5.34167C16.6669 4.6 15.7669 4.225 15.2419 4.75L14.7086 5.29167Z"
				fill="white" />
		</g>
	</svg>
</template>

<script>
export default {

	name: 'IconRefresh',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '22px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '22px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: 'white',
		},

		direction_prop: {
			type: String,
			default: 'left',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	},

	data() {
		return {

		}
	}
}
</script>

<style lang="css" scoped>
.up {
	transform: rotate(90deg);
}

.right {
	transform: rotate(180deg);
}

.down {
	transform: rotate(-90deg);
}
</style>