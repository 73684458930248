<template>
	<div id="dropdownChangeHub1" class="select-box rounded" tabindex="-1" @blur="outOfFocus($event.target)"
		@mouseover="mousePosition = 'inside'" @mouseleave="mousePosition = 'outside'">
		<div :class="{ 'selected-currency': true, 'text-uppercase': true, 'rounded': true, 'active': isToogleActive }"
			@click="toggleOption()">
			{{ $store.state.hub }}

			<IconArrowTriangle class="ml-12-c" />
		</div>

		<div id="optionChangeHub1" class="option optionChangeHub text-white text-left"
			:style="{ 'display': optionStyleDisplay, 'min-width': optionWidth }">
			<div class="subOption" @click="changeHub('jakarta')">Jakarta</div>
			<div class="subOption disable">Singapore</div>
			<div class="subOption disable">Hong Kong</div>
			<div class="subOption disable">Dubai</div>
			<div class="subOption disable">Sidney</div>
			<div class="subOption disable">London</div>
			<div class="subOption disable">New York</div>
			<div class="subOption disable">Zurich</div>

			<!-- 
			@click="changeHub('singapore')"
			@click="changeHub('hongKong')"
			@click="changeHub('dubai')"
			@click="changeHub('sidney')"
			@click="changeHub('london')"
			@click="changeHub('newYork')"
			@click="changeHub('zurich')"
			-->
		</div>
	</div>

	<!-- <select class="custom-select" >
		<option value="jakarta" selected>Jakarta</option>
		<option value="singapore" title="On Progress" disabled>Singapore</option>
		<option value="hongkong" title="On Progress" disabled>Hong Kong</option>
		<option value="dubai" title="On Progress" disabled>Dubai</option>
		<option value="sidney" title="On Progress" disabled>Sidney</option>
		<option value="london" title="On Progress" disabled>London</option>
		<option value="newyork" title="On Progress" disabled>New York</option>
		<option value="zurich" title="On Progress" disabled>Zurich</option>
	</select> -->
</template>

<script>
import IconArrowTriangle from '@/components/icons/IconArrowTriangle.vue'

export default {
	name: 'ChangeHub',

	props: {
		componentId_prop: {
			type: String,
			default: 'id'
		}
	},

	components: {
		IconArrowTriangle,
	},

	data() {
		return {
			isToogleActive: false,
			mousePosition: 'outside',
		}
	},

	computed: {
		hub: {
			get: function () {
				return this.$store.state.hub
			},
			set: function (val) {
				this.$store.dispatch('changeHub', val)
			}
		},

		optionStyleDisplay() {
			return this.isToogleActive ? 'block' : 'none'
		},

		optionWidth() {
			let width = document.querySelector('#dropdownChangeHub1')
			return width ? width.offsetWidth + 'px' : '100%'
		}
	},

	methods: {
		toggleOption() {
			this.isToogleActive = !this.isToogleActive
		},

		outOfFocus() {
			if (this.mousePosition == 'outside') {
				this.isToogleActive = false
			}
		},

		changeHub(newHub) {
			this.$store.dispatch('changeHub', newHub)
			this.isToogleActive = false
		}
	}
}
</script>

<style scoped>
.select-box {
	position: relative;
	background-color: var(--grey-1-color);
	font-size: 1rem;
	padding: 0.5em 0.75em;
	height: 2.5em;
}

.selected-currency {
	position: relative;
	display: flex;
	align-items: center;
	color: white;
	width: 100%;
	height: 100%;
	background-color: var(--grey-1-color);
	z-index: 2;
}

.selected-currency:hover {
	cursor: pointer;
}

/* .selected-currency::after {
	position: relative;
	content: '<';
	margin-left: 14px;
	margin-bottom: 1px;
	font-size: 1.25em;

	transform: rotateZ(-90deg);
} */

.selected-currency.active::after {
	transform: translateX(0px) rotateZ(-270deg);
}

.option {
	position: absolute;
	display: none;
	background-color: var(--grey-1-color);
	top: 2.25em;
	right: 0;
	padding: 1em 0px 1em 0px;
	min-width: 1.25em;
	width: max-content;
	border-radius: 4px;
	border-top-right-radius: 0;
	box-shadow: 0px 0px 10px -3px black;
}

.subOption {
	color: white;
	font-size: 1rem;
	padding: 1em 1.25em 1em 12px;
	user-select: none;
}

.subOption:hover {
	cursor: pointer;
	filter: brightness(75%);
}

.disable {
	filter: brightness(50%);
}

.subOption.disable:hover {
	cursor: no-drop;
	background-color: var(--grey-1-color);
}

.custom-select {
	background-color: var(--grey-1-color);
	border-color: rgba(255, 255, 255, 0.3);
}

.c-custom-select {
	display: block;
	width: 100%;
	background-color: var(--grey-1-color);
	border: solid rgba(255, 255, 255, 0.3) 1px;
	padding: 0.5rem 0.25rem;
	color: white;
}</style>