<template>
	<div>
		<iframe src="https://www.widgets.investing.com/live-indices?theme=darkTheme&pairs=166,27,172,175,53094,178,179,959210,959206,44336" width="100%" height="500" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe>
	</div>
</template>

<script>
	export default {
		name: "IndicesByInvesting"
	}
</script>