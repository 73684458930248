<template>
	<div>
		<div class="flex flex--justify-content-between flex--align-items-end mb-14-c">
			<div>
				<ReportsNav />
			</div>
		</div>

		<div v-if="accountLevel == 'lp'" class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">

						<div class="row">
							<div class="col-sm-12 col-md-4 text-left">
								<dl class="row text-left mb-0">
									<dt class="col-auto">Total Gold</dt>
									<dd class="col">: {{ numberFormatExtend((totalGoldLp).toFixed(2)) }} g</dd>
								</dl>
							</div>

							<div class="col-sm-12 col-md-4">
								<dl class="row text-left mb-0">
									<dt class="col-auto">Available Trade Gold</dt>
									<!-- <dd class="col">: {{numberFormatExtend((totalGoldLp).minus(5).toFixed(2))}} g</dd> -->
									<dd class="col">: {{ numberFormatExtend((totalGoldLp).minus(5000).toFixed(2)) }} g</dd>
								</dl>
							</div>

							<div class="col-sm-12 col-md-4">
								<dl class="row text-left mb-0">
									<dt class="col-auto">Status</dt>
									<dd class="col">:
										<div v-if="Number((totalGoldLp)) >= 10000"
											class="alert alert-primary py-0 px-2 d-inline-block ml-3 font-weight-bold" role="alert"
											style="background-color: #2EC4B6;">
											Active
										</div>

										<div v-if="Number((totalGoldLp)) >= 5000 && Number((totalGoldLp)) < 10000"
											class="alert alert-primary py-0 px-2 d-inline-block ml-3 font-weight-bold" role="alert"
											style="background-color: orange;">
											Consider
										</div>

										<div v-if="Number((totalGoldLp)) < 5000"
											class="alert alert-primary py-0 px-2 d-inline-block ml-3 font-weight-bold" role="alert"
											style="background-color: #E71D36;">
											Suspended
										</div>
									</dd>
								</dl>

							</div>

						</div>
					</div>
					<div class="card-body pt-0">
						<div class="row">
							<div class="col-sm-12 col-md-4">
								<p class="lead"><small>Available Trade Gold = Total Gold - Reserve Gold (5 kg)</small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 
				<div class="row text-right mb-2">
					<div v-if="accountLevel == 'ib' || accountLevel == 'fm' || accountLevel == 'ccp'" class="col-auto mb-2 text-left">
						<FilterClient class="d-inline-block"
							filterId="holdings"
							@changeFilterClient="changeFilterClientHolding"
							:clients_prop="listClients"
						>
						</FilterClient>
					</div>
				</div> -->

		<div v-if="accountLevel == 'client'" class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h4> Reserved to Delivery</h4>

						<div class="row">
							<div class="col-sm-12 col-md-5 text-left">
								<dl v-for="item in reservedGold" :key="'reservedGold' + item.attributes.contractId"
									class="row mb-1 text-left">
									<dt class="col-4 text-nowrap">{{ item.name }}</dt>
									<dd class="col-8">: {{ item.totalGram }} gram</dd>

									<!-- 	<dt class="col-7 text-nowrap">1 g pool</dt>
											<dd class="col-5">: 0 gram (0)</dd>

											<dt class="col-7 text-nowrap">100 g non pool</dt>
											<dd class="col-5">: 0 gram (0)</dd>

											<dt class="col-7 text-nowrap">1 kg non pool</dt>
											<dd class="col-5">: 0 gram (0)</dd> -->
								</dl>
							</div>
						</div>
					</div>

					<div class="card-body">
						<div class="row">
							<div class="col-12">
								<p class="lead"><small>* The physical gold balance is processing to be delivered to your
										domicile.</small></p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Account Level FM, IB, LP  -->
		<div v-if="accountLevel == 'fm' || accountLevel == 'ib' || accountLevel == 'lp'" class="row">
			<div class="col-12 table-responsive p-0">
				<table class="table table--report text-nowrap">
					<thead style="background-color: #0f0f17">
						<tr class="text-center">
							<th>HIN</th>
							<th>ACCOUNT</th>
							<th>PRODUCT</th>
							<th>HUB</th>
							<th>QTY</th>
							<th>TOTAL <br> GRAM</th>
							<th>AVERAGE <br> METAL COST</th>
							<th>FEE & <br> TAX</th>
							<th>AVERAGE TOTAL <br> COST INC. FEES & TAX</th>
							<th>METAL VALUE <br> AT DATE</th>
							<th v-if="accountLevel != 'lp'">ESTIMATED <br> P & L</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="holdingsStatus == 'error'">
							<tr>
								<td class="text-center" colspan="11">
									<h4>FAILED TO RETRIEVE DATA</h4>
									<br />
									<button class="btn c-btn-theme" @click="setHoldings()">Try Again</button>
								</td>
							</tr>
						</template>
						<template v-else-if="holdingsStatus != 'loading'">
							<tr v-for="holding in holdings" v-bind:key="holding.id">
								<td class="text-center">
									<Loader v-if="holding.hin == 'loadingTemp'" size_prop="14px" borderSize_prop="1px"></Loader>

									<template v-else>
										{{ holding.hin }}
									</template>
								</td>
								<td class="text-center">
									<Loader v-if="holding.account == 'loadingTemp'" size_prop="14px" borderSize_prop="1px"></Loader>

									<template v-else>
										{{ accountLevel == 'lp' ? 'Liquidity Provider' : holding.account }}
									</template>
								</td>
								<td class="text-center">{{ holding.product }}</td>
								<td class="text-center">JAK</td>
								<td class="text-center">{{ Decimal.div(holding.quantity,
									$store.getters.getContractConfigs(holding.contractId).weightCorrection).toFixed(2) }}</td>
								<td class="text-center">{{ numberFormatExtend((holding.totalGram)) }}</td>

								<td class="text-right">{{ isNaN(holding.averageMetalCostUsd) ? '-' : (currency == 'usd') ?
									numberFormatExtend(holding.averageMetalCostUsd.toFixed(2)) :
									numberFormatExtend(holding.averageMetalCostIdr.toFixed(2)) }}</td>

								<td class="text-right">{{ isNaN(holding.totalFeeAndTaxUsd) ? '-' : (currency == 'usd') ?
									numberFormatExtend(holding.totalFeeAndTaxUsd.toFixed(3), 3) :
									numberFormatExtend(holding.totalFeeAndTaxIdr.toFixed(2)) }}</td>

								<td class="text-right">{{ isNaN(holding.averageTotalCostIncFeeAndTaxUsd) ? '-' : (currency == 'usd') ?
									numberFormatExtend(holding.averageTotalCostIncFeeAndTaxUsd.toFixed(2)) :
									numberFormatExtend(holding.averageTotalCostIncFeeAndTaxIdr.toFixed(2)) }}</td>

								<td class="text-right">{{ metalValueAtDateShown(holding) }}</td>

								<td v-if="clientChosed == 'lp'" class="text-center">
									<div v-if="Number((holding.totalGram)) >= 10000"
										class="alert alert-primary py-0 px-2 m-0 d-inline-block font-weight-bold" role="alert"
										style="background-color: #2EC4B6;">
										Active
									</div>

									<div v-if="Number((holding.totalGram)) >= 5000 && Number((holding.totalGram)) < 10000"
										class="alert alert-primary py-0 px-2 m-0 d-inline-block font-weight-bold" role="alert"
										style="background-color: orange;">
										Consider
									</div>

									<div v-if="Number((holding.totalGram)) < 5000"
										class="alert alert-primary py-0 px-2 m-0 d-inline-block font-weight-bold" role="alert"
										style="background-color: #E71D36;">
										Suspended
									</div>
								</td>

								<td v-else-if="accountLevel != 'lp'" class="text-right">{{ estimatePNLShown(holding) }} {{
									estimatePNLPercentageShown(holding) }}</td>
							</tr>
							<tr v-if="holdings.length < 1">
								<td colspan="11" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="11">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>

		<!-- Account Level Client -->
		<div v-if="accountLevel == 'client'" class="row">
			<div class="col-12 table-responsive p-0">
				<table class="table table--report text-nowrap">
					<thead style="background-color: #0f0f17">
						<tr class="text-center">
							<th>HIN</th>
							<th>ACCOUNT</th>
							<th>PRODUCT</th>
							<th>HUB</th>
							<th>QTY</th>
							<th>TOTAL <br> GRAM</th>
							<th>AVERAGE <br> METAL COST</th>
							<th>FEE & <br> TAX</th>
							<th>AVERAGE TOTAL <br> COST INC. FEES & TAX</th>
							<th>METAL VALUE <br> AT DATE</th>
							<th>ESTIMATED <br> P & L</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="holdingsStatus == 'error'">
							<tr>
								<td class="text-center" colspan="11">
									<h4>FAILED TO RETRIEVE DATA</h4>
									<br />
									<button class="btn c-btn-theme" @click="setNewHoldings()">Try Again</button>
								</td>
							</tr>
						</template>
						<template v-if="holdingsStatus != 'loading'">
							<tr v-for="holding in newHoldings" v-bind:key="'holding' + holding.contract_id">
								<td class="text-center">
									{{ holding.hin }}
								</td>

								<td class="text-center">
									{{ holding.account }}
								</td>

								<td class="text-center">
									{{ $store.getters.getContractConfigs(holding.contract_id).name }}
								</td>

								<td class="text-center">JAK</td>

								<td class="text-center">
									{{ Decimal.div(holding.quantity,
										$store.getters.getContractConfigs(holding.contract_id).weightCorrection).toFixed(2) }}
								</td>

								<td class="text-center">
									{{ numberFormatExtend(Decimal.div(holding.quantity,
										$store.getters.getContractConfigs(holding.contract_id).weightCorrection).times($store.getters.getContractConfigs(holding.contract_id).weightInGram).toFixed(2))
									}}
								</td>

								<td class="text-right">{{ holding.avg_metal_cost != null ? (currency == 'usd') ?
									numberFormatExtend((holding.avg_metal_cost).toFixed(2)) :
									numberFormatExtend(holding.avg_metal_cost_idr.toFixed(2)) : '-' }}</td>

								<td class="text-right">{{ (currency == 'usd') ?
									numberFormatExtend(holding.fee_tax.toFixed(3), 3) :
									numberFormatExtend(holding.fee_tax_idr.toFixed(2)) }}
								</td>

								<td class="text-right">{{ holding.avg_metal_cost != null ? (currency == 'usd') ?
									numberFormatExtend((holding.avg_total_cost).toFixed(2)) :
									numberFormatExtend(holding.avg_total_cost_idr.toFixed(2)) : '-' }}</td>

								<td class="text-right">{{ metalValueAtDateShown(holding) }}</td>

								<td v-if="clientChosed == 'lp'" class="text-center">
									<div v-if="Number((holding.totalGram)) >= 10000"
										class="alert alert-primary py-0 px-2 m-0 d-inline-block font-weight-bold" role="alert"
										style="background-color: #2EC4B6;">
										Active
									</div>

									<div v-if="Number((holding.totalGram)) >= 5000 && Number((holding.totalGram)) < 10000"
										class="alert alert-primary py-0 px-2 m-0 d-inline-block font-weight-bold" role="alert"
										style="background-color: orange;">
										Consider
									</div>

									<div v-if="Number((holding.totalGram)) < 5000"
										class="alert alert-primary py-0 px-2 m-0 d-inline-block font-weight-bold" role="alert"
										style="background-color: #E71D36;">
										Suspended
									</div>
								</td>

								<td v-else class="text-right">{{ estimatePNLShown(holding) }} {{ estimatePNLPercentageShown(holding)
								}}</td>
							</tr>
							<tr v-if="newHoldings.length < 1">
								<td colspan="11" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="11">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>

		<div class="row" v-if="accountLevel == 'fm' || accountLevel == 'ib' || this.accountLevel == 'lp'">
			<div class="col">
				<Pagination2 :totalData_prop="totalHoldings" @changePage="setHoldings">
				</Pagination2>
			</div>
		</div>
	</div>
</template>

<script>
import axios, { abortController } from '@/api/axios.js'
import { Decimal } from 'decimal.js'

import ReportsNav from '@/components/reports/ReportsNav.vue'

import Pagination2 from '@/components/Pagination2.vue'
import Loader from '@/components/Loader.vue'

export default {
	name: 'Holdings',

	components: {
		ReportsNav,
		Pagination2,
		Loader,
	},

	data() {
		return {
			Decimal: Decimal,

			callSetHoldingFrom: 'created', //created || watch
			holdings: [],
			newHoldings: [],
			totalHoldings: 1,
			holdingsPageIndex: 0,
			holdingsStatus: 'loading',

			selectedClientHolding: 'all',

			clientChosed: 'all',

			totalGoldLp: new Decimal(0),

		}
	},

	computed: {
		currency() {
			return this.$store.state.currency
		},

		accountLevel() {
			return this.$store.getters.getAccountLevel
		},

		products() {
			return this.$store.getters.PRODUCTS
		},

		contractsTemp() {
			return this.$store.state.contracts
		},

		hubsTemp() {
			return this.$store.state.hubs
		},

		reservedGold() {
			return this.$store.getters.getReservedGold
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		streamingRateBuy() {
			if (this.currency == 'idr') {
				return this.streamingRate.stream_buy.cur_rate
			} else {
				return 1
			}
		},

		streamingRateSell() {
			if (this.currency == 'idr') {
				return this.streamingRate.stream_sell.cur_rate
			} else {
				return 1
			}
		},

		listClients() {
			return this.$parent.listClients
		}
	},

	created() {
		this.$store.dispatch('setHoldings')

		const firstCall = setInterval(() => {
			if (this.products && this.contractsTemp && this.accountLevel && this.hubsTemp) {
				clearInterval(firstCall)

				// this.orderFilterStatusSubmit()
				// this.setTransactions()

				if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'lp') {
					// this.setListBankTransactions()
					this.setHoldings()
				} else {
					this.setNewHoldings()
				}
			}
		}, 1000)
	},

	methods: {
		async setNewHoldings(skip = 0, limit = 10) {
			let contractsParam = ''
			for (let key in this.$store.state.contractIds) {
				contractsParam = contractsParam + `contract_id[]=${this.$store.state.contractIds[key]}&`
			}

			if (this.callSetHoldingFrom != 'watch') {
				this.holdingsStatus = 'loading'
			}

			try {
				const response = await this.$store.dispatch('setHoldings', {
					skip: skip,
					limit: limit,
					selectedClientHolding: this.selectedClientHolding,
				})

				// sort holdings based on sequence on config
				const holdingsAfterSequence = (holdingsParam) => {
					let holdingsAfter = []
					this.$store.state.holdingsSequence.forEach(element => {
						const temp = holdingsParam.find(obj => obj.contract_id == element)
						if (temp) {
							holdingsAfter.push(temp)
						}
					})

					return holdingsAfter
				}

				this.newHoldings = []
				let tempData = holdingsAfterSequence(response.data.data.body)
				for (let i = 0; i < tempData.length; i++) {
					if (tempData[i].contract_id != 0 && tempData[i].quantity != 0) {

						// the response's structure is different between client and fm/ib accocunt. Client: 'contract_id'; FM/IB: 'contractId' ¯\(°_o)/¯ 
						if (this.accountLevel == 'fm' || this.accountLevel == 'ib') {
							this.$set(tempData, 'contract_id', tempData.attributes.contractId)
						}

					}

					// Buat Saskia QA. Dev only
					if (process.env.NODE_ENV == 'development') {

						const contractConfig = this.$store.getters.getContractConfigs(tempData[i].contract_id)
						const gold = this.$store.state.golds[tempData[i].contract_id]

						const metalValueAtDateUsd = this.$store.getters.getMetalValueAtDateUsdByContractId(tempData[i].contract_id)
						const metalValueAtDateIdr = this.$store.getters.getMetalValueAtDateIdrByContractId(tempData[i].contract_id)

						const pnlUsd = this.$store.getters.getEstimatePNLUsdByContractId(tempData[i].contract_id)
						const pnlIdr = this.$store.getters.getEstimatePNLIdrByContractId(tempData[i].contract_id)

						// console.image("https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.teenvogue.com%2Fstory%2Fbts-jungkook-covid-19-grammys-2022&psig=AOvVaw37Z04fj8onphzNe5teznGO&ust=1709974039914000&source=images&cd=vfe&opi=89978449&ved=0CBMQjRxqFwoTCNDO1-yj5IQDFQAAAAAdAAAAABAI");

						const style = 'background-color: darkblue; color: white; font-style: italic; border: 5px solid hotpink; font-size: 2em;'
						console.log("%c🤔🤔🤔", style,
							"\n\n",
							"Kontrak: ", contractConfig.name,
							"\n\n",
							"Metal Value at Date USD: ", this.numberFormatExtend(metalValueAtDateUsd.toNumber()),
							"\n",
							"Metal Value at Date IDR: ", this.numberFormatExtend(metalValueAtDateIdr.toNumber()),
							"\n\n",
							"PnL USD: ", this.numberFormatExtend(pnlUsd.toNumber()),
							"\n",
							"PnL IDR: ", this.numberFormatExtend(pnlIdr.toNumber()),
							"\n\n",
							"Harga JUAL USD: ", gold.sell,
							"\n",
							"Harga JUAL IDR: ", Decimal.mul(gold.sell, this.streamingRate.stream_sell.cur_rate).toNumber(),
							"\n\n",
							"Harga BELI USD: ", gold.buy,
							"\n",
							"Harga BELI IDR: ", Decimal.mul(gold.buy, this.streamingRate.stream_sell.cur_rate).toNumber(),
						)
					}
				}
				this.newHoldings = tempData

				this.holdingsStatus = 'idle'

			} catch (error) {
				this.holdingsStatus = 'error'

				console.error('Gagal set new holdings', error.config)
			}
		},

		async setHoldings(skip = 0, limit = 10) {
			this.holdingsStatus = 'loading'

			let hubs = this.hubsTemp
			let contracts = this.contractsTemp

			let contractsParam = ''
			if (this.accountLevel == 'lp') {
				for (let key in this.$store.state.contractIds) {
					contractsParam = contractsParam + `contract_id[]=${this.$store.state.contractIds[key]}&`
				}
			} else {
				for (let key in this.$store.state.contractIds) {
					contractsParam = contractsParam + `contractId[]=${this.$store.state.contractIds[key]}&`
				}
			}

			this.clientChosed = 'all'

			let urlApiHoldings = ''
			let urlApiTransactions = ''
			if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
				if (this.selectedClientHolding == 'all') {
					urlApiTransactions = '/v2/rrs/transactions?' + contractsParam + 'date_start=&date_end='

					urlApiHoldings = '/v2/rrs/holdings?hin=' + this.$store.getters.getHin + '&offset=' + skip + '&limit=' + limit
				} else {
					if (this.accountLevel == 'fm') {
						urlApiTransactions = '/v2/rrs/transactions?hin=' + this.selectedClientHolding.attributes.hin + '&accountId=' + this.selectedClientHolding.id + '&' + contractsParam + 'date_start=&date_end='

						urlApiHoldings = '/v2/rrs/holdings?hin=' + this.selectedClientHolding.attributes.hin + '&accountId=' + this.selectedClientHolding.id
					} else {
						urlApiTransactions = '/v2/rrs/transactions?hin=' + this.selectedClientHolding.trade_account.hin + '&accountId=' + this.selectedClientHolding.trade_account.account_id + '&' + contractsParam + 'date_start=&date_end='

						urlApiHoldings = '/v2/rrs/holdings?hin=' + this.selectedClientHolding.trade_account.hin + '&accountId=' + this.selectedClientHolding.trade_account.account_id
					}

					if (this.selectedClientHolding.trade_account.hin.substring(0, 2).toLowerCase() == 'lp') {
						this.clientChosed = 'lp'
					}
				}
			} else if (this.accountLevel == 'lp') {
				urlApiTransactions = '/v2/igx/market-maker/transaction?' + contractsParam + 'date_start=&date_end='

				urlApiHoldings = '/v2/igx/market-maker/holding'
			}
			else {
				urlApiTransactions = '/v2/igx/transactions?' + contractsParam + 'date_start=&date_end='

				urlApiHoldings = '/v2/igx/holding'
			}


			// this.holdingsStatus = 'loading'
			// const response = await axios.all([
			// 	axios.post(urlApiTransactions, {
			// 		user_id: this.$store.state.userId
			// 	}),
			// 	axios.post(urlApiHoldings, {
			// 		user_id: this.$store.state.userId
			// 	}),
			// ])

			let resTransactions, resHoldings
			try {
				resTransactions = await axios.post(urlApiTransactions, {
					user_id: this.$store.state.userId
				})

				resHoldings = await axios.post(urlApiHoldings, {
					user_id: this.$store.state.userId
				})
			} catch (error) {
				this.holdingsStatus = 'error'
				return
			}

			this.holdingsPageIndex = 0
			this.holdings = []
			let transactions = resTransactions.data.data.body.data
			let holdings = resHoldings.data.data.body

			if (this.accountLevel == 'fm' || this.accountLevel == 'ib' || this.accountLevel == 'lp') {
				transactions = resTransactions.data.data.body
				holdings = resHoldings.data.data.body
				this.totalHoldings = resHoldings.data.data.count
			}


			if (holdings.length < 1) {
				this.holdingsStatus = 'idle'
			}

			let sortedHoldings = holdings.sort((a, b) => (a.attributes.accountId > b.attributes.accountId) ? 1 : ((b.attributes.accountId > a.attributes.accountId) ? -1 : 0))

			sortedHoldings.forEach(async (holding) => {
				let contract = contracts.find(obj => obj.id == holding.attributes.contractId)
				const contractConfig = this.$store.getters.getContractConfigs(holding.attributes.contractId)

				let hub = hubs.find(obj => obj.id == contract.attributes.hubId)
				let transactionsNew = transactions.filter(obj => (obj.attributes.contractId == holding.attributes.contractId && obj.attributes.accountId == holding.attributes.accountId && obj.attributes.status == 'match'))
				if (this.accountLevel == 'lp') {
					transactionsNew = transactions.filter(obj => (obj.attributes.contractId == holding.attributes.contractId && obj.attributes.accountId == holding.attributes.accountId))
				}
				let gold = this.$store.getters.goldById(holding.attributes.contractId)

				let totalQuantityBuy = new Decimal(0)
				let totalQuantitySell = new Decimal(0)

				let totalTransactionsBuy = new Decimal(0)
				let totalTransactionsSell = new Decimal(0)

				let totalPricePerGramBuyUsd = new Decimal(0)
				let totalPricePerGramBuyIdr = new Decimal(0)
				let totalPricePerGramSellUsd = new Decimal(0)
				let totalPricePerGramSellIdr = new Decimal(0)

				let totalFeeBuyUsd = new Decimal(0)
				let totalFeeBuyIdr = new Decimal(0)
				let totalFeeSellUsd = new Decimal(0)
				let totalFeeSellIdr = new Decimal(0)

				let totalTaxBuyUsd = new Decimal(0)
				let totalTaxBuyIdr = new Decimal(0)
				let totalTaxSellUsd = new Decimal(0)
				let totalTaxSellIdr = new Decimal(0)


				transactionsNew.forEach((transactionNew) => {
					if (!transactionNew.attributes.quantity) {
						return
					}
					if (transactionNew.attributes.direction == 'buy') {
						// let totalGram = Number(Decimal.mul(this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightInGram, transactionNew.attributes.quantity).div(this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection))
						// let pricePer = Decimal.div(transactionNew.attributes.metalValueOfTrade, totalGram).div(this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection)

						let pricePer = (Decimal.div(transactionNew.attributes.metalValueOfTrade, Decimal.div(transactionNew.attributes.quantity, this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection)))

						// totalPricePerGramBuyUsd = Decimal.mul(transactionNew.attributes.matchPrice, this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection).toDecimalPlaces(2).plus(totalPricePerGramBuyUsd)
						// totalPricePerGramBuyIdr = Decimal.mul(transactionNew.attributes.matchPrice, this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection).toDecimalPlaces(2).times(transactionNew.attributes.rate).toDecimalPlaces(2).plus(totalPricePerGramBuyIdr)

						totalPricePerGramBuyUsd = totalPricePerGramBuyUsd.plus(pricePer.toDecimalPlaces(2))
						totalPricePerGramBuyIdr = totalPricePerGramBuyIdr.plus(pricePer.times(transactionNew.attributes.rate).toDecimalPlaces(2))

						totalFeeBuyUsd = totalFeeBuyUsd.plus(transactionNew.attributes.fee)
						totalFeeBuyIdr = totalFeeBuyIdr.plus(Decimal.mul(transactionNew.attributes.fee, transactionNew.attributes.rate))

						totalTaxBuyUsd = totalTaxBuyUsd.plus(transactionNew.attributes.tax)
						totalTaxBuyIdr = totalTaxBuyIdr.plus(Decimal.mul(transactionNew.attributes.tax, transactionNew.attributes.rate))

						totalQuantityBuy = totalQuantityBuy.plus(Decimal.div(transactionNew.attributes.quantity, contractConfig.weightCorrection)).toDecimalPlaces(2)

						totalTransactionsBuy = totalTransactionsBuy.plus(1)
					}
					else {

						// let totalGram = Number(Decimal.mul(this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightInGram, transactionNew.attributes.quantity).div(this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection))
						// let pricePer = Decimal.div(transactionNew.attributes.metalValueOfTrade, totalGram).div(this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection)

						let pricePer = (Decimal.div(transactionNew.attributes.metalValueOfTrade, Decimal.div(transactionNew.attributes.quantity, this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection)))

						// totalPricePerGramSellUsd = Decimal.mul(transactionNew.attributes.matchPrice, this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection).toDecimalPlaces(2).plus(totalPricePerGramSellUsd)
						// totalPricePerGramSellIdr = Decimal.mul(transactionNew.attributes.matchPrice, this.$store.getters.getContractConfigs(transactionNew.attributes.contractId).weightCorrection).toDecimalPlaces(2).times(transactionNew.attributes.rate).toDecimalPlaces(2).plus(totalPricePerGramSellIdr)

						totalPricePerGramSellUsd = totalPricePerGramSellUsd.plus(pricePer.toDecimalPlaces(2))
						totalPricePerGramSellIdr = totalPricePerGramSellIdr.plus(pricePer.times(transactionNew.attributes.rate).toDecimalPlaces(2))


						totalFeeSellUsd = totalFeeSellUsd.plus(transactionNew.attributes.fee)
						totalFeeSellIdr = totalFeeSellIdr.plus(Decimal.mul(transactionNew.attributes.fee, transactionNew.attributes.rate))

						totalTaxSellUsd = totalTaxSellUsd.plus(transactionNew.attributes.tax)
						totalTaxSellIdr = totalTaxSellIdr.plus(Decimal.mul(transactionNew.attributes.tax, transactionNew.attributes.rate))

						totalQuantitySell = totalQuantitySell.plus(Decimal.div(transactionNew.attributes.quantity, contractConfig.weightCorrection)).toDecimalPlaces(2)

						totalTransactionsSell = totalTransactionsSell.plus(1)
					}

				})

				let averagePricePerGramBuyUsd = totalPricePerGramBuyUsd.div(totalTransactionsBuy).toDecimalPlaces(3)
				let averagePricePerGramBuyIdr = totalPricePerGramBuyIdr.div(totalTransactionsBuy).toDecimalPlaces(3)
				let averagePricePerGramSellUsd = totalPricePerGramSellUsd.div(totalTransactionsSell).toDecimalPlaces(3)
				let averagePricePerGramSellIdr = totalPricePerGramSellIdr.div(totalTransactionsSell).toDecimalPlaces(3)

				if (totalTransactionsSell == 0) {
					averagePricePerGramSellUsd = 0
					averagePricePerGramSellIdr = 0
				}

				if (totalTransactionsBuy == 0) {
					averagePricePerGramBuyUsd = 0
					averagePricePerGramBuyIdr = 0
				}

				const averageMetalCostBuyUsd = totalQuantityBuy.times(averagePricePerGramBuyUsd).toDecimalPlaces(2)
				const averageMetalCostBuyIdr = totalQuantityBuy.times(averagePricePerGramBuyIdr).toDecimalPlaces(2)
				const averageMetalCostSellUsd = totalQuantitySell.times(averagePricePerGramSellUsd).toDecimalPlaces(2)
				const averageMetalCostSellIdr = totalQuantitySell.times(averagePricePerGramSellIdr).toDecimalPlaces(2)

				const averageMetalCostUsd = averageMetalCostBuyUsd.minus(averageMetalCostSellUsd)
				const averageMetalCostIdr = averageMetalCostBuyIdr.minus(averageMetalCostSellIdr)

				const totalFeeAndTaxBuyUsd = totalFeeBuyUsd.plus(totalTaxBuyUsd)
				const totalFeeAndTaxBuyIdr = totalFeeBuyIdr.plus(totalTaxBuyIdr)
				const totalFeeAndTaxSellUsd = totalFeeSellUsd.plus(totalTaxSellUsd)
				const totalFeeAndTaxSellIdr = totalFeeSellIdr.plus(totalTaxSellIdr)

				const totalFeeAndTaxUsd = totalFeeAndTaxBuyUsd.plus(totalFeeAndTaxSellUsd)
				const totalFeeAndTaxIdr = totalFeeAndTaxBuyIdr.plus(totalFeeAndTaxSellIdr)

				const averageTotalCostIncFeeAndTaxBuyUsd = averageMetalCostBuyUsd.plus(totalFeeAndTaxBuyUsd).toDecimalPlaces(2)
				const averageTotalCostIncFeeAndTaxBuyIdr = averageMetalCostBuyIdr.plus(totalFeeAndTaxBuyIdr).toDecimalPlaces(2)
				const averageTotalCostIncFeeAndTaxSellUsd = averageMetalCostSellUsd.minus(totalFeeAndTaxSellUsd).toDecimalPlaces(2)
				const averageTotalCostIncFeeAndTaxSellIdr = averageMetalCostSellIdr.minus(totalFeeAndTaxSellIdr).toDecimalPlaces(2)

				const averageTotalCostIncFeeAndTaxUsd = averageTotalCostIncFeeAndTaxBuyUsd.minus(averageTotalCostIncFeeAndTaxSellUsd)
				const averageTotalCostIncFeeAndTaxIdr = averageTotalCostIncFeeAndTaxBuyIdr.minus(averageTotalCostIncFeeAndTaxSellIdr)

				const totalQuantity = totalQuantityBuy.minus(totalQuantitySell)

				console.log(
					`
								Contract Id: ${holding.attributes.contractId}

								---

								BUY Total Transactions New: ${transactionsNew}

								BUY Total Transactions Buy: ${totalTransactionsBuy}

								BUY Total Price Per Gram: ${totalPricePerGramBuyUsd}
								BUY Total Price Per Gram Average: ${averagePricePerGramBuyUsd}

								BUY Total Quantity: ${totalQuantityBuy}
								BUY Total Fee: ${totalFeeBuyUsd}
								BUY Total Tax: ${totalTaxBuyUsd}

								BUY Average Metal Cost: ${averageMetalCostBuyUsd}
								BUY Fee & Tax: ${totalFeeAndTaxBuyUsd}
								BUY Average Total Cost Inc. Fee & Tax: ${averageTotalCostIncFeeAndTaxBuyUsd}

								---

								SELL Total Price Per Gram: ${totalPricePerGramSellUsd}
								SELL Total Price Per Gram Average: ${averagePricePerGramSellUsd}

								SELL Total Quantity: ${totalQuantitySell}
								SELL Total Fee: ${totalFeeSellUsd}
								SELL Total Tax: ${totalTaxSellUsd}

								SELL Average Metal Cost: ${averageMetalCostSellUsd}
								SELL Fee & Tax: ${totalFeeAndTaxSellUsd}
								SELL Average Total Cost Inc. Fee & Tax: ${averageTotalCostIncFeeAndTaxSellUsd}

								---

								Average Metal Cost: ${averageMetalCostUsd}
								Fee & Tax: ${totalFeeAndTaxUsd}
								Average Total Cost Inc. Fee & Tax: ${averageTotalCostIncFeeAndTaxUsd}


							`
				)

				let weight = contractConfig.weightInGram

				this.totalGoldLp = this.totalGoldLp.plus(Decimal.mul(Decimal.div(weight, contractConfig.weightCorrection), holding.attributes.value))

				this.holdings.push({
					id: holding.id,
					contractId: holding.attributes.contractId,
					hin: 'loadingTemp',
					account: 'loadingTemp',
					product: contractConfig.name,
					hub: hub.attributes.iata,
					quantity: new Decimal(holding.attributes.value),
					quantityShown: Decimal.div(holding.attributes.value, contractConfig.weightCorrection),
					totalGram: Decimal.mul(weight, totalQuantity).toNumber(),
					averageMetalCostUsd: averageMetalCostUsd,
					averageMetalCostIdr: averageMetalCostIdr,
					totalFeeAndTaxUsd: totalFeeAndTaxUsd,
					totalFeeAndTaxIdr: totalFeeAndTaxIdr,
					averageTotalCostIncFeeAndTaxUsd: averageTotalCostIncFeeAndTaxUsd,
					averageTotalCostIncFeeAndTaxIdr: averageTotalCostIncFeeAndTaxIdr,
					totalQuantity: totalQuantity
				})

				const response2 = await new Promise((resolve) => {
					axios.get('/v2/rrs/get/hin?accountId=' + holding.attributes.accountId
					).then((responseHin) => {
						return resolve(responseHin.data.data.body)
					}).catch(() => {
						return resolve('-')
					})
				})

				const clientHin = response2
				let client
				let accountName = '-'
				if (this.accountLevel == 'fm') {
					// client = this.listClients.find(obj => obj.attributes.hin == clientHin)
					client = this.listClients.find(obj => obj.trade_account.hin == clientHin)
				} else {
					client = this.listClients.find(obj => obj.trade_account.hin == clientHin)
				}

				if (client && this.accountLevel == 'fm') {
					// accountName = client.attributes.name || '-'
					accountName = client.fullname || '-'
				} else if (client && this.accountLevel == 'ib') {
					accountName = client.fullname || '-'
				}

				const targetIndex = this.holdings.findIndex(obj => obj.id == holding.id)


				this.$set(this.holdings[targetIndex], 'hin', clientHin)
				this.$set(this.holdings[targetIndex], 'account', accountName)
			})

			this.holdingsStatus = 'idle'
		},

		countMetalValueAtDate(holding) {
			let contractId
			if (this.accountLevel == 'fm' || this.accountLevel == 'ib' || this.accountLevel == 'lp') {
				contractId = holding.contractId
			} else {
				contractId = holding.contract_id
			}

			if (this.currency == 'usd') {
				const temp = this.$store.getters.getMetalValueAtDateUsdByContractId(contractId)
				return temp
			} else {
				const temp = this.$store.getters.getMetalValueAtDateIdrByContractId(contractId)
				return temp
			}
		},
		metalValueAtDateShown(holding) {
			const metalValueAtDate = this.countMetalValueAtDate(holding)
			if (metalValueAtDate.lessThan(0)) {
				return '-'
			}

			return this.numberFormatExtend(metalValueAtDate.toFixed(2))
		},

		countEstimatePNL(holding) {
			let contractId
			if (this.accountLevel == 'fm' || this.accountLevel == 'ib' || this.accountLevel == 'lp') {
				contractId = holding.contractId
			} else {
				contractId = holding.contract_id
			}

			if (this.currency == 'usd') {
				return this.$store.getters.getEstimatePNLUsdByContractId(contractId)
			} else {
				return this.$store.getters.getEstimatePNLIdrByContractId(contractId)
			}
		},
		estimatePNLShown(holding) {
			const estimatePNL = this.countEstimatePNL(holding)
			if (estimatePNL == '-') {
				return '-'
			}

			return this.numberFormatExtend(estimatePNL.toFixed(2))
		},

		countEstimatePNLPercentage(holding) {
			const estimatePNL = this.countEstimatePNL(holding)
			if (estimatePNL == '-') {
				return '-'
			}

			let tempAMC
			if (this.accountLevel == 'fm' || this.accountLevel == 'ib') {
				tempAMC = this.currency == 'usd' ? holding.averageTotalCostIncFeeAndTaxUsd : holding.averageTotalCostIncFeeAndTaxIdr
			} else {
				tempAMC = this.currency == 'usd' ? holding.avg_total_cost : holding.avg_total_cost_idr
			}

			if (tempAMC == 0) {
				return new Decimal(0)
			}

			return Decimal.div(estimatePNL, (tempAMC)).times(100).toFixed(2)
		},
		estimatePNLPercentageShown(holding) {
			let tempAMC
			if (this.accountLevel == 'fm' || this.accountLevel == 'ib') {
				tempAMC = this.currency == 'usd' ? holding.averageTotalCostIncFeeAndTaxUsd : holding.averageTotalCostIncFeeAndTaxIdr
			} else {
				tempAMC = this.currency == 'usd' ? holding.avg_total_cost : holding.avg_total_cost_idr
			}

			const metalValueAtDate = this.countMetalValueAtDate(holding)
			if (metalValueAtDate.lessThan(0) || isNaN(tempAMC)) {
				return '-'
			}

			const estimatePNLPercentage = this.countEstimatePNLPercentage(holding)
			return '(' + estimatePNLPercentage + ')%'
		},

		changeFilterClientHolding(clientData) {
			this.selectedClientHolding = clientData
			if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'lp') {
				this.setHoldings()
			} else {
				this.setNewHoldings()
			}
		},
	},
}
</script>

<style scoped></style>