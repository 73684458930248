<template>
	<div>
		<button class="btn c-btn-theme dropdown-toggle px-3 py-2 m-0" @click="exportToCSV_method()"
			:disabled="transactionsStatus == 'loading'">
			<span>
				<i class="fas fa-download mr-8-c"></i> EXPORT
			</span>
			<Loader v-if="transactionsStatus == 'loading'" class="loader-export" size_prop='2.2em' borderSize_prop='0.4em'>
			</Loader>
		</button>
	</div>
</template>

<script>
import axios, { abortController } from '@/api/axios.js'
import Decimal from 'decimal.js'

import exportToCSV_mixin from '@/mixin/exportToCSV.js'

import Loader from '@/components/Loader.vue'

export default {
	name: 'ButtonExportToCSV',

	mixins: [exportToCSV_mixin],

	props: {
		orderType_prop: {
			type: String,
			default: ''
		},

		contractsParam_prop: {
			type: String,
			default: ''
		},

		startDate_prop: {
			type: String,
			default: ''
		},

		endDate_prop: {
			type: String,
			default: ''
		},

		selectedClientTransaction_prop: {
			type: Object,
			default: (() => {
				return {
					hin_abx: '',
					ac_abx: ''
				}
			})
		},
	},

	components: {
		Loader
	},

	data() {
		return {
			transactions: [],
			filterDateAndTypeTransaction: {},
			filterContractTransaction: [],
			selectedClientTransaction: 'all',
			orderTypeTransactions: 'all',
			cancelTokenApiGetHinTransactions: null,
			cancelTokenApiGetTransactions: null,
			transactionsStatus: 'idle',

		}
	},

	computed: {
		currency() {
			return this.$store.state.currency
		},

		hubs() {
			return this.$store.state.hubs
		},

		contracts() {
			return this.$store.state.contracts
		},

		accountLevel() {
			return this.$store.getters.getAccountLevel
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},
	},

	methods: {
		exportToCSV_method() {
			this.transactionsStatus = 'loading'
			this.setTransactions().then(() => {
				this.transactionsStatus = 'idle'
				this.exportToCSV(this.transactions, 'Transactions')
			})
			// this.exportCSV(this.data, this.headers, this.fileName)
		},

		setTransactions(skip = '', limit = '') {
			return new Promise((resolve) => {
				let urlApi = ''
				if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
					if (this.selectedClientTransaction == 'all') {
						urlApi = '/v2/rrs/transactions?offset=' + skip + '&limit=' + limit + '&' + this.contractsParam_prop + 'date_start=' + this.startDate_prop + '&date_end=' + this.endDate_prop + '&contract_id[]=100'
					} else {
						urlApi = '/v2/rrs/transactions?offset=' + skip + '&limit=' + limit + '&' + 'hin=' + this.selectedClientTransaction_prop.hin_abx + '&accountId=' + this.selectedClientTransaction_prop.ac_abx + '&' + this.contractsParam_prop + 'date_start=' + this.startDate_prop + '&date_end=' + this.endDate_prop
					}
				} else if (this.accountLevel == 'lp') {
					urlApi = '/v2/igx/market-maker/transaction?offset=' + skip + '&limit=' + limit + '&' + this.contractsParam_prop + 'date_start=' + this.startDate_prop + '&date_end=' + this.endDate_prop + '&order_type=' + this.orderType_prop
				} else {
					urlApi = '/v2/igx/transactions?offset=' + skip + '&limit=' + limit + '&' + this.contractsParam_prop + 'date_start=' + this.startDate_prop + '&date_end=' + this.endDate_prop + '&order_type=' + this.orderType_prop
				}

				this.transactionsStatus = 'loading'

				if (this.cancelTokenApiGetHinTransactions) {
					//cancel api get hin
					this.cancelTokenApiGetHinTransactions.controller.abort()
				}
				this.cancelTokenApiGetHinTransactions = abortController()

				if (this.cancelTokenApiGetTransactions) {
					//cancel api get transactions
					this.cancelTokenApiGetTransactions.controller.abort()
				}
				this.cancelTokenApiGetTransactions = abortController()

				let hubs = this.hubs
				let contracts = this.contracts

				axios.post(urlApi, {
					user_id: this.$store.state.userId
				}, {
					signal: this.cancelTokenApiGetTransactions.signal
				}).then(response => {
					this.transactions = []

					this.totalTransactions = response.data.data.count

					let transactions = response.data.data.body
					if (this.accountLevel == 'ib' || this.accountLevel == 'fm') {
						transactions = response.data.data.body
					}

					let sortedTransactions = transactions.sort((a, b) => new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt))
					// sortedTransactions = this.paginationOrder(sortedTransactions)

					if (transactions.length < 1) {
						this.transactionsStatus = 'idle'
					}

					let arrayPromise = []
					for (let i = 0; i < sortedTransactions.length; i++) {
						const contractConfig = this.$store.state.contractConfigs[sortedTransactions[i].attributes.contractId]

						let contract = contracts.find(obj => obj.id == sortedTransactions[i].attributes.contractId)

						let tempArray = []
						let hub = '-'
						if (hubs) {
							hub = hubs.find(obj => obj.id == contract.attributes.hubId)
							hub = hub.attributes.iata
						}

						let dateTemp = new Date(sortedTransactions[i].attributes.createdAt)
						let date = {
							year: dateTemp.getFullYear(),
							month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
							date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
							hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
							minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
						}

						let settlementAmount = sortedTransactions[i].attributes.settlementAmount

						let metalValueOfTrade = sortedTransactions[i].attributes.metalValueOfTrade

						let fee = new Decimal(sortedTransactions[i].attributes.fee)

						let tax = new Decimal(sortedTransactions[i].attributes.tax)

						let weight = contractConfig.weightInGram

						const totalGram = Number(Decimal.mul(weight, sortedTransactions[i].attributes.quantity).div(contractConfig.weightCorrection))

						let pricePer = Decimal.div(metalValueOfTrade, totalGram)

						let rate = sortedTransactions[i].attributes.rate || 0
						if (rate == 0) {
							rate = (sortedTransactions[i].attributes.direction == 'sell') ? this.streamingRate.stream_sell.cur_rate : this.streamingRate.stream_buy.cur_rate
						}
						tempArray = {
							'Direction': sortedTransactions[i].attributes.direction == 'buy' ? 'BUY' : 'SELL',
							'Date': sortedTransactions[i].attributes.createdAt ? `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}` : '-',
							'Hin': (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') ? 'loadingTemp' : this.$store.getters.getHin,
							'Ref': sortedTransactions[i].id || '-',
							'Product': contractConfig.name,
							'Hub': 'JAK',
							'Order Type': sortedTransactions[i].attributes.orderType,
							'Quantity': Number(Decimal.div(sortedTransactions[i].attributes.quantity, contractConfig.weightCorrection)),
							'Total Gram': totalGram,
							'Price per Gram': (this.currency == 'usd' ? pricePer : Number(Decimal.mul(pricePer, rate))).toFixed(2),
							'Metal Value of Trade': (this.currency == 'usd' ? metalValueOfTrade : Number(Decimal.mul(metalValueOfTrade, rate))).toFixed(2),
							'Fee': (this.currency == 'usd' ? Number(fee) : Number(Decimal.mul(fee, rate))).toFixed(2),
							'Tax': (this.currency == 'usd' ? Number(tax) : Number(Decimal.mul(tax, rate))).toFixed(2),
							'Settlement Amount': (this.currency == 'usd' ? settlementAmount : Number(Decimal.mul(settlementAmount, rate))).toFixed(2),
							'Status': (sortedTransactions[i].attributes.status == 'match') ? 'Matched' : 'On Process'
						}

						this.$set(this.transactions, i, tempArray)

						if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
							arrayPromise.push(new Promise((resolve) => {
								axios.get('/v2/rrs/get/hin?accountId=' + sortedTransactions[i].attributes.accountId, {
									signal: this.cancelTokenApiGetHinTransactions.signal
								}).then(response2 => {
									// this.transactions[i][j].hin = response2.data.data.hin

									this.$set(this.transactions, i, {
										...this.transactions[i],
										'Hin': response2.data.data.body
									})

									return resolve()
								}).catch(error => {
									if (error.response) {
										/*
										* The request was made and the server responded with a
										* status code that falls out of the range of 2xx
										*/
									} else if (error.request) {
										/*
										 * The request was made but no response was received, `error.request`
										 * is an instance of XMLHttpRequest in the browser and an instance
										 * of http.ClientRequest in Node.js
										 */
									} else {
										// Something happened in setting up the request and triggered an Error
									}
								})
							}))
						}
					}

					if (this.accountLevel == 'ib' || this.accountLevel == 'fm' || this.accountLevel == 'ccp') {
						Promise.all(arrayPromise).then(() => {
							return resolve()
						})
					}else {
						return resolve()
					}
				}).catch(error => {
					this.transactionsStatus = 'idle'
					if (error.response) {
						/*
						* The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
				})
			})
		},
	}
}
</script>

<style scoped>
button {
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader-export {
	position: absolute;
}
</style>