import Vue from 'vue'
import axios from '@/api/axios.js'
import { Decimal } from 'decimal.js'
import { set } from 'core-js/core/dict'
// import { io } from 'socket.io-client'

export default {
	sha256(context, message) {
		return new Promise(async (resolve) => {
			// encode as UTF-8
			const msgBuffer = new TextEncoder().encode(message)

			// hash the message
			const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)

			// convert ArrayBuffer to Array
			const hashArray = Array.from(new Uint8Array(hashBuffer))

			// convert bytes to hex string									
			const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')


			return resolve(hashHex)
		})
	},

	setContracts({ dispatch, commit }) {
		commit('changeSetContractsApiStatus_mut', 'loading')

		return new Promise((resolve) => {
			axios.get('/v2/igx/contract'
			).then((response) => {
				const responseData = response.data.data.body
				commit('setContracts_mut', responseData)

				commit('changeSetContractsApiStatus_mut', 'success')
				return resolve()
			}).catch(() => {
				setTimeout(() => {
					commit('changeSetContractsApiStatus_mut', 'error')
					return dispatch('setContracts')
				}, 5000)
			})

		})
	},

	setProducts({ dispatch, commit }) {
		commit('changeSetProductsApiStatus_mut', 'loading')

		return new Promise((resolve) => {
			axios.get('/v2/igx/product'
			).then((response) => {
				const responseData = response.data.data.body
				commit('setProducts_mut', responseData)

				commit('changeSetProductsApiStatus_mut', 'success')
				return resolve()
			}).catch(() => {
				setTimeout(() => {
					commit('changeSetProductsApiStatus_mut', 'error')
					return dispatch('setProducts')
				}, 5000)
			})

		})
	},

	// setProfile({ commit }) {
	// 	return new Promise((resolve) => {
	// 		axios.get('/v2/profile')
	// 		.then((response) => {
	// 			const responseData = response.data.data
	// 			localStorage.setItem('profile', JSON.stringify(responseData))
	// 			commit('setProfile_mut', responseData)

	// 			return resolve()
	// 		})
	// 	})
	// },

	setAccountInformation({ dispatch, commit, state }) {
		commit('changeSetAccountInformationApiStatus_mut', 'loading')

		return new Promise((resolve) => {
			axios.post('/v2/igx/account_information', {
				user_id: state.userId
			}).then((response) => {
				const responseData = response.data.data.body
				commit('setAccountInformation_mut', responseData)

				commit('changeSetAccountInformationApiStatus_mut', 'success')
				return resolve()
			}).catch(() => {
				setTimeout(() => {
					commit('changeSetAccountInformationApiStatus_mut', 'error')
					return dispatch('setAccountInformation')
				}, 5000)
			})

		})
	},

	setDepths({ commit }) {
		commit('changeSetDepthsApiStatus_mut', 'loading')

		return new Promise((resolve, reject) => {
			axios.get('/v2.1/igx/depth')
				.then((response) => {
					const responseData = response.data.data.body
					commit('setDepths_mut', responseData)

					commit('changeSetDepthsApiStatus_mut', 'success')

					return resolve()
				}).catch(() => {
					commit('changeSetDepthsApiStatus_mut', 'error')

					return reject()
				})
		})
	},

	setStreamingRate({ commit, state }) {
		commit('changeSetStreamingRateApiStatus_mut', 'loading')

		return new Promise((resolve) => {
			state.getStreamingRate_apiStatus = 'loading'
			axios.get('/v2.1/rate/get'
			).then((response) => {
				const responseData = response.data.data
				commit('setStreamingRate_mut', responseData)

				commit('changeSetStreamingRateApiStatus_mut', 'success')
				return resolve()
			}).catch(error => {
				commit('changeSetStreamingRateApiStatus_mut', 'error')
			})
		})
	},

	setBalances({ dispatch, commit, state }) {
		commit('changeSetBalanceApiStatus_mut', 'loading')

		return new Promise((resolve) => {
			axios.post('/v2/igx/balance', {
				user_id: state.userId
			}).then((response) => {
				const responseData = response.data.data.body
				commit('setBalances_mut', responseData)
				commit('changeSetBalanceApiStatus_mut', 'success')
				return resolve()
			}).catch(() => {
				setTimeout(() => {
					commit('changeSetBalanceApiStatus_mut', 'error')
					return dispatch('setBalance')
				}, 5000)
			})
		})
	},

	setGoldBalances({ dispatch, commit, state }) {
		commit('changeSetGoldBalancesApiStatus_mut', 'loading')

		return new Promise((resolve) => {
			axios.post('/v2/igx/gold_balance', {
				user_id: state.userId
			}).then((response) => {
				const responseData = response.data.data.body
				commit('setGoldBalances_mut', responseData)

				commit('changeSetGoldBalancesApiStatus_mut', 'success')
				return resolve()
			}).catch(() => {
				setTimeout(() => {
					commit('changeSetGoldBalancesApiStatus_mut', 'error')
					return dispatch('setGoldBalances')
				}, 5000)
			})
		})
	},

	setGolds({ commit, state, getters }) {
		return new Promise((resolve) => {
			let depths = structuredClone(state.depths)

			if (!depths) {
				commit('setGold_mut', {})
				return
			}

			//switch buy and sell value in depths
			let temp = []
			for (let prop in depths) {
				temp = depths[prop].buy
				depths[prop].buy = depths[prop].sell
				depths[prop].sell = temp
			}

			let golds = {}

			for (const contractId in depths) {
				let caretDirection = 'down'
				let percentage = 0.0
				let sellPrice = new Decimal(-1)
				let buyPrice = new Decimal(-1)

				const depth = depths[contractId]
				const contractConfig = getters.getContractConfigs(contractId)

				//Multiply with weightCorrection if there is any contract that need to be shown differently from the original weight :)
				for (let i = depth.buy.length - 1; i >= 0; i--) {
					depths[contractId].buy[i].price = Number(Decimal.mul(depths[contractId].buy[i].price, contractConfig.weightCorrection))
					depths[contractId].buy[i].quantity = Number(Decimal.div(depths[contractId].buy[i].quantity, contractConfig.weightCorrection).toFixed(contractConfig.depthQuantityDecimalPlace, Decimal.ROUND_FLOOR))
				}
				for (let i = depth.sell.length - 1; i >= 0; i--) {
					depths[contractId].sell[i].price = Number(Decimal.mul(depths[contractId].sell[i].price, contractConfig.weightCorrection))
					depths[contractId].sell[i].quantity = Number(Decimal.div(depths[contractId].sell[i].quantity, contractConfig.weightCorrection).toFixed(contractConfig.depthQuantityDecimalPlace, Decimal.ROUND_FLOOR))
				}

				for (let i = depth.buy.length - 1; i >= 0; i--) {
					let buyProp = depth.buy[i]
					if (i == depth.buy.length - 1) { //iniltialize buyPrice
						buyPrice = new Decimal(buyProp.price)
					}
					if (buyPrice.greaterThanOrEqualTo(buyProp.price)) {
						buyPrice = new Decimal(buyProp.price)
					}
				}
				for (let i = depth.sell.length - 1; i >= 0; i--) {
					let sellProp = depth.sell[i]
					if (i == depth.sell.length - 1) { //iniltialize sellPrice
						sellPrice = new Decimal(sellProp.price)
					}
					if (sellPrice.lessThanOrEqualTo(sellProp.price)) {
						sellPrice = new Decimal(sellProp.price)
					}
				}
				// if buy price is not found in depth, then it the same as sell price. Vice versa.
				if (buyPrice.lessThan(0)) {
					buyPrice = sellPrice
				}
				if (sellPrice.lessThan(0)) {
					sellPrice = buyPrice
				}

				let contractPriceBuy = buyPrice
				let contractPriceSell = sellPrice

				buyPrice = buyPrice.dividedBy(contractConfig.weightMultiplication)
				sellPrice = sellPrice.dividedBy(contractConfig.weightMultiplication)

				golds[contractId] = {
					contractId: contractId,
					contractPriceBuy: Number(contractPriceBuy),
					contractPriceSell: Number(contractPriceSell),
					sell: Number(sellPrice),
					buy: Number(buyPrice),
				}

			}
			commit('setGold_mut', golds)

			return resolve()
		})
	},

	setOhlc({ commit, state, getters }) {
		return new Promise(async (resolve) => {
			const golds = state.golds

			let ohlc = {}
			for (const index in golds) {
				const contractConfig = getters.getContractConfigs(golds[index].contractId)

				const responseOhlc = await axios.get('/v2.1/igx/ohlc/' + golds[index].contractId)

				const data = responseOhlc.data.data.body.ohlc[0]
				let openPrice = (new Decimal(data.open[0]).greaterThanOrEqualTo(data.open[1])) ? data.open[0] : data.open[1]
				let highPrice = (new Decimal(data.high[0]).greaterThanOrEqualTo(data.high[1])) ? data.high[0] : data.high[1]
				let lowPrice = (new Decimal(data.low[0]).greaterThanOrEqualTo(data.low[1])) ? data.low[0] : data.low[1]
				let closePrice = (new Decimal(data.close[0]).greaterThanOrEqualTo(data.close[1])) ? data.close[0] : data.close[1]
				
				//for QA purpose
				const originalOhlc = {
					open: openPrice,
					high: highPrice,
					low: lowPrice,
					close: closePrice
				}

				let change = Decimal.sub(closePrice, openPrice).dividedBy(openPrice).times(100).toDecimalPlaces(2).toNumber()
				highPrice = Decimal.div(highPrice, Decimal.div(contractConfig.weightInGram, contractConfig.weightCorrection)).toDecimalPlaces(2).toNumber()
				lowPrice = Decimal.div(lowPrice, Decimal.div(contractConfig.weightInGram, contractConfig.weightCorrection)).toDecimalPlaces(2).toNumber()

				ohlc[golds[index].contractId] = {
					rate: responseOhlc.data.data.body.streaming_rate,
					open: openPrice,
					close: closePrice,
					low: lowPrice,
					high: highPrice,
					change: change,
					caretDirection: 'down',
					percentage: 0.0,

					original: originalOhlc
				}

				commit('setOhlc_mut', ohlc)
			}

			return resolve()
		})
	},

	destroyToken(context) {
		if (context.getters.loggedIn) {
			context.commit('DESTROY_TOKEN')
		}
	},
	destroyUser(context) {
		context.commit('DESTROY_USER')
	},

	async refreshToken(context) {
		context.state.apiStatusRefreshToken = 'loading'
		try {
			const response = await axios({
				method: 'POST',
				url: '/v2/user/refresh_token',
				data: {
					user_id: context.state.userId
				}
			})

			context.state.apiStatusRefreshToken = 'idle'

			const newToken = response.data.data.token
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken

			localStorage.setItem('token', newToken)
			context.commit('SET_TOKEN', newToken)

			localStorage.setItem('refreshTokenTime', Date.now() + context.state.refreshTokenTimeState)
		} catch {
			context.state.apiStatusRefreshToken = 'error'
		}
	},

	getGeolocation({ commit }) {
		commit('AUTH_REQUEST')
		return new Promise((resolve, reject) => {
			const geoApi = (process.env.NODE_ENV == 'development') ? 'https://asia-southeast1-jfx-kbi-dev.cloudfunctions.net/get-user-access-location' : 'https://asia-southeast2-jfx-prod.cloudfunctions.net/get-user-access-location'
			fetch(geoApi)
				.then(result => result.json())
				.then((output) => {
					const geolocation = {
						...output,
						latitude: output.cityLatLong.split(',')[0],
						longitude: output.cityLatLong.split(',')[1]
					}

					localStorage.setItem('geolocation', JSON.stringify(geolocation))
					commit('SET_GEOLOCATION', geolocation)

					resolve(geolocation)
				})
				.catch(() => {
					commit('AUTH_ERROR', 'Server appears to be offline')
					reject()
				})
		})
	},

	checkFormatAuth(context, user) {
		return new Promise((resolve, reject) => {
			const emailRegex = /^\w+([!#$%&‘*+–/=?^`.{|}~]?\w+)*@\w+([.-]?\w+)*(\.\w{1,4})+$/
			const phoneRegex = /^[-+]?[0-9]+$/

			let phoneNumber = '',
				email = '',
				username = '',
				isError = false,
				data = {}

			if (emailRegex.test(user.userId)) {
				email = user.userId
				data = {
					email: email
				}
			} else if (phoneRegex.test(user.userId)) {
				phoneNumber = user.userId
				data = {
					phone_number: phoneNumber
				}
			}
			else {
				username = user.userId
				data = {
					username: username
				}
			}

			if (isError) {
				reject("")
			}

			data.password = user.password

			resolve(data)
		})
	},

	checkUser({ commit }, user) {
		return new Promise((resolve, reject) => {
			/*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
			const { password, ...data } = user

			axios({
				method: 'POST',
				url: '/v2/user/check_user',
				data: data
			})
				.then(response => {


					resolve(response.data.data.body)
				})
				.catch(error => {

					if (error.data) {
						if (error.data.status_code == 2018 || error.data.status_code == 2023 || error.data.status_code == 2022) {
							commit('AUTH_ERROR', 'ENSURE YOUR EMAIL AND PASSWORD ARE CORRECT')
						} else {
							commit('AUTH_ERROR', error.data.err_message)
						}
					}
					else if (error.response) {
						if (error.response.data.data) {
							commit('AUTH_ERROR', error.response.data.data.err_message)
						} else {
							/*
							 * The request was made and the server responded with a
							 * status code that falls out of the range of 2xx
							 */
							commit('AUTH_ERROR', error.response.data.message)

							if (error.response.data.status == 404 && error.response.data.message == 'Account not found!' || error.response.data.status == 422) {
								commit('AUTH_ERROR', 'ENSURE YOUR EMAIL AND PASSWORD ARE CORRECT')
							}
						}

					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
						commit('AUTH_ERROR', 'Server appears to be offline')
					} else {
						// Something happened in setting up the request and triggered an Error
					}

					reject(error)
				})
		})
	},

	AUTH_REQUEST({ commit, state, dispatch }, payload) {
		return new Promise((resolve, reject) => {
			dispatch('sha256', payload[1].password)
				.then((hashedPassword) => {
					if (payload[0]) {
						Vue.set(payload[1], 'password', hashedPassword)
					}

					window.grecaptcha.enterprise.ready(() => {
						window.grecaptcha.enterprise.execute(state.captchaSiteKey, {
							action: 'LOGINTRADING'
						})
							.then((token) => {
								axios({
									method: 'POST',
									url: '/v2/user/auth',
									data: {
										...payload[1],
										clientCaptchaKey: token,
									},
									headers: {
										'Latitude': state.geolocation.latitude,
										'Longitude': state.geolocation.longitude,
										'City': state.geolocation.city,
										'Region': state.geolocation.region,
										'Country': state.geolocation.country,
										'IP-Address': state.geolocation.userIP,
									}
								})
									.then(response => {
										// const errorCode = response.data.data.status_code
										if (response.data.iserror == true) {
											throw ({ data: response.data.data })
										}

										const token = response.data.data.token
										const res = response.data.data

										// // localStorage.setItem('token', token)
										// state.token = token

										// // localStorage.setItem('bankType', JSON.stringify(res.data.bank_type))
										// state.bankType = res.bank_type

										// // for login expired in frontend only
										// // localStorage.setItem('loginExpiredTime', Date.now() + state.loginExpiredTimeState)
										// state.loginExpiredTime = Date.now() + state.loginExpiredTimeState

										// // for refresh token
										// // localStorage.setItem('refreshTokenTime', Date.now() + state.refreshTokenTimeState)
										// state.refreshTokenTime = Date.now() + state.refreshTokenTimeState

										// state.authInfo = res
										// state.userId = res.user_id
										// state.brokerId = res.broker_id

										axios.defaults.headers.common['Authorization'] = 'Bearer ' + token

										commit('AUTH_SUCCESS', res)

										resolve(response)
									})
									.catch(error => {
										dispatch('itemsToRemove')

										if (error.data) {
											if (error.data.status_code == 2018 || error.data.status_code == 2023 || error.data.status_code == 2022) {
												commit('AUTH_ERROR', 'ENSURE YOUR EMAIL AND PASSWORD ARE CORRECT')
											} else {
												commit('AUTH_ERROR', error.data.err_message)
											}
										}
										else if (error.response) {
											if (error.response.data.data) {
												commit('AUTH_ERROR', error.response.data.data.err_message)
											} else {
												/*
												 * The request was made and the server responded with a
												 * status code that falls out of the range of 2xx
												 */
												commit('AUTH_ERROR', error.response.data.message)

												if (error.response.data.status == 404 && error.response.data.message == 'Account not found!' || error.response.data.status == 422) {
													commit('AUTH_ERROR', 'ENSURE YOUR EMAIL AND PASSWORD ARE CORRECT')
												}
											}

										} else if (error.request) {
											/*
											 * The request was made but no response was received, `error.request`
											 * is an instance of XMLHttpRequest in the browser and an instance
											 * of http.ClientRequest in Node.js
											 */
											commit('AUTH_ERROR', 'Server appears to be offline')
										} else {
											// Something happened in setting up the request and triggered an Error
										}
										reject(error)
									})
							})
					})


				})
		})
	},

	setAccountInformation(context) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: '/v2/igx/account_information',
				data: {
					user_id: context.state.userId
				}
			}).then(response => {
				let account = response.data.data

				context.commit('SETACCOUNT', account)
				// context.commit('AUTH_CHANGE_STATUS', 'success')

				// localStorage.setItem('account',	JSON.stringify(account))

				resolve(response)
			}).catch(error => {
				context.dispatch('itemsToRemove')

				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
					context.commit('AUTH_ERROR', 'Something went Wrong')
					if (error.response.status == 403) {
						context.commit('AUTH_ERROR', error.response.data.message)
					}
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
					context.commit('AUTH_ERROR', 'Server appears to be offline')
				} else {
					// Something happened in setting up the request and triggered an Error
				}
				reject(error)
			})
		})
	},

	AUTH_LOGOUT(context) {
		return new Promise((resolve) => {
			const itemsToRemove = context.state.itemsToRemove

			context.commit('AUTH_LOGOUT', itemsToRemove)
			context.dispatch('itemsToRemove')

			// remove the axios default header
			delete axios.defaults.headers.common['Authorization']

			if (window.LiveChatWidget) {
				window.LiveChatWidget.call("hide")
			}

			resolve()
		})
	},

	itemsToRemove({ state }) {
		const itemsToRemove = state.itemsToRemove
		itemsToRemove.forEach(item => localStorage.removeItem(item))
	},

	setBalance_old(context) {
		context.commit('CHANGE_SET_BALANCE_STATUS', 'loading')
		if (context.getters.getAccountLevel == 'ib' || context.getters.getAccountLevel == 'fm' || context.getters.getAccountLevel == 'ccp') {
			axios({
				method: 'get',
				url: '/v2/rrs/total_balance_history'
			}).then(response => {
				let deposit = response.data.data

				if (context.getters.getAccountLevel == 'ib' || context.getters.getAccountLevel == 'fm') {
					context.commit('SET_BALANCE', {
						balanceIdr: Number(Number(deposit.tot_bal_idr).toFixed(2)),
						balanceUsd: Number(Number(deposit.tot_bal_usd).toFixed(2)),
						availableTradeBalanceIdr: 0,
						availableTradeBalanceUsd: 0,
						accountBalanceIdr: Number(Number(deposit.tot_bal_idr).toFixed(2)),
						accountBalance: Number(Number(deposit.tot_bal_usd).toFixed(2)),
						reservedBalance: 0
					})
				} else {
					context.commit('SET_BALANCE', {
						balanceIdr: Number(Decimal.mul(deposit.tot_bal_usd, context.getters.getStreamingRate.stream_buy.cur_rate).toFixed(2)),
						balanceUsd: Number(Number(deposit.tot_bal_usd).toFixed(2)),
						availableTradeBalanceIdr: Number(Decimal.mul(deposit.tot_avail_bal, context.getters.getStreamingRate.stream_buy.cur_rate).toFixed(2)),
						availableTradeBalanceUsd: Number(Number(deposit.tot_avail_bal).toFixed(2)),
						accountBalance: Number(Number(deposit.tot_bal_usd).toFixed(2)),
						reservedBalance: Number(Number(deposit.tot_reserved_bal).toFixed(2)),
						reservedBalanceIdr: Number(Number(deposit.tot_reserved_bal).toFixed(2))
					})
				}
				context.commit('CHANGE_SET_BALANCE_STATUS', 'idle')
			}).catch(error => {
				context.commit('CHANGE_SET_BALANCE_STATUS', 'idle')
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		} else {
			axios({
				method: 'POST',
				url: '/v2/igx/balance',
				data: {
					user_id: context.state.userId
				}
			}).then(response => {
				let userBalance = response.data.data.body

				let rdk_balance = userBalance.rdk_balance
				let usd_balance = userBalance.usd_balance
				let available_trade_bal = Number(userBalance.available_trade_bal)
				let available_trade_bal_usd = userBalance.available_trade_bal_usd
				let account_bal_idr = Number(userBalance.rdk_balance) || 0
				let account_bal = userBalance.account_bal || 0
				let reserved_bal = userBalance.reserved_bal_usd || 0
				let reserved_bal_idr = userBalance.reserved_bal_idr || 0

				context.commit('CHANGE_SET_BALANCE_STATUS', 'idle')
				context.commit('SET_BALANCE', {
					balanceIdr: Number(Decimal(rdk_balance).toFixed(2)),
					balanceUsd: Number(Decimal(usd_balance).toFixed(2)),
					availableTradeBalanceIdr: Number(available_trade_bal.toFixed(2)),
					availableTradeBalanceUsd: Number(available_trade_bal_usd.toFixed(2)),
					accountBalanceIdr: Number(account_bal_idr.toFixed(2)),
					accountBalance: Number(account_bal.toFixed(2)),
					reservedBalance: Number(reserved_bal.toFixed(2)),
					reservedBalanceIdr: Number(reserved_bal_idr.toFixed(2))
				})

			}).catch(error => {
				context.commit('CHANGE_SET_BALANCE_STATUS', 'idle')
				if (error.response) {
					/*
					 * The request was made and the server responded with a
					 * status code that falls out of the range of 2xx
					 */
				} else if (error.request) {
					/*
					 * The request was made but no response was received, `error.request`
					 * is an instance of XMLHttpRequest in the browser and an instance
					 * of http.ClientRequest in Node.js
					 */
				} else {
					// Something happened in setting up the request and triggered an Error
				}
			})
		}
	},

	setToken(context, token) {
		context.commit('SET_TOKEN', token)
	},
	setUser(context, payload) {
		context.commit('SET_USER', payload)
	},

	setOrderInTradeTicket(context, contractId) {
		let suffix, method, dataBody
		if (contractId == '6') {
			suffix = '/abx/kinesis/get/orders'
			method = 'post'
			dataBody = {
				cookieSession: context.state.kinesisCookieSession
			}
		} else {
			suffix = '/abx/orders'
			method = 'get'
			dataBody = null
		}
		axios({
			method: method,
			url: suffix,
			data: dataBody
		}).then(response => {
			let orders = response.data.data
			let finalOrders = []
			orders.forEach((order) => {
				if (contractId == '6') {
					if (order.orderType == 'limit') {
						let date = order.createdAt
						finalOrders.push({
							price: order.limitPrice,
							direction: order.direction,
							date: date.substring(8, 10) + date.substring(5, 7) + date.substring(2, 4)
						})
					}
				} else {
					if (order.attributes.contractId == contractId) {
						let date = order.attributes.createdAt
						finalOrders.push({
							price: 0,
							direction: order.attributes.direction,
							date: date.substring(8, 10) + date.substring(5, 7) + date.substring(2, 4)
						})
					}
				}
			})
			context.commit('SETORDERINTRADETICKET', finalOrders)
		}).catch(error => {
			if (error.response) {
				/*
				 * The request was made and the server responded with a
				 * status code that falls out of the range of 2xx
				 */
			} else if (error.request) {
				/*
				 * The request was made but no response was received, `error.request`
				 * is an instance of XMLHttpRequest in the browser and an instance
				 * of http.ClientRequest in Node.js
				 */
			} else {
				// Something happened in setting up the request and triggered an Error
			}
		})
	},

	setStreamingRate_old(context) {
		context.commit('SET_STREAMING_RATE_STATUS', 'loading')

		axios({
			method: 'get',
			url: '/v2/rate/get',
			data: {
				user_id: context.state.userId
			}
		}).then(response => {
			let stream = response.data.data
			context.commit('SET_STREAMING_RATE', stream)
		}).catch(error => {
			if (error.response) {
				/*
				 * The request was made and the server responded with a
				 * status code that falls out of the range of 2xx
				 */
			} else if (error.request) {
				/*
				 * The request was made but no response was received, `error.request`
				 * is an instance of XMLHttpRequest in the browser and an instance
				 * of http.ClientRequest in Node.js
				 */
			} else {
				// Something happened in setting up the request and triggered an Error
			}
		})
	},

	changeCurrency(context, currency) {
		context.commit('CHANGE_CURRENCY', currency)
		localStorage.setItem('currency', JSON.stringify(currency))
	},

	changeHub(context, hub) {
		context.commit('CHANGE_HUB', hub)
		localStorage.setItem('hub', JSON.stringify(hub))
	},

	changeTradingType(context, payload) {
		localStorage.setItem('tradingType', JSON.stringify(payload))
		context.commit('CHANGE_TRADING_TYPE', payload)
	},

	setReservedGold(context) {
		axios({
			method: 'get',
			url: '/v2/poslog/get/list_reserved_delivery',
		}).then(response => {
			context.commit('SET_RESERVED_GOLD', response.data.data)
		}).catch(error => {
			if (error.response) {
				/*
				 * The request was made and the server responded with a
				 * status code that falls out of the range of 2xx
				 */
			} else if (error.request) {
				/*
				 * The request was made but no response was received, `error.request`
				 * is an instance of XMLHttpRequest in the browser and an instance
				 * of http.ClientRequest in Node.js
				 */
			} else {
				// Something happened in setting up the request and triggered an Error
			}

		})
	},

	checkCloseMarket(context) {
		return new Promise((resolve) => {
			const date = new Date()
			const day = date.getDay() // sunday = 0, monday = 1, etc
			const hours = date.getHours()
			const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
			const time = Number(`${hours}${minutes}`)

			const month = date.getMonth() + 1 // +1 because Jan = 0
			const dayDate = date.getDate()

			// console.log('Day day', day, hours, minutes, time)

			// if winter (21 Des - 20 Mar)
			if ((month == 12 && dayDate >= 21) || month == 1 || month == 2 || (month == 3 && dayDate <= 20)) {

				//(market close from saturday 4:00 until monday 6:59) and (market close from 4:00 until 6:59 everyday)
				if ((day == 0) || (day == 6 && time >= 400 && time <= 2359) || (day == 1 && time >= 0 && time <= 659) || (time >= 400 && time <= 659)) {
					context.commit('CHANGE_MARKET_STATUS', 'close')
				} else {
					context.commit('CHANGE_MARKET_STATUS', 'open')
				}
			}
			else { // if summer (21 Mar - 20 Dec)

				//(market close from saturday 5:00 until monday 6:59) and (market close from 5:00 until 6:59 everyday)
				if ((day == 0) || (day == 6 && time >= 500 && time <= 2359) || (day == 1 && time >= 0 && time <= 659) || (time >= 500 && time <= 659)) {
					context.commit('CHANGE_MARKET_STATUS', 'close')
				} else {
					context.commit('CHANGE_MARKET_STATUS', 'open')
				}
			}

			resolve()
		})
	},

	setEndOfDayPrices({ commit, getters }) {
		commit('changeSetEndOfDayPricesApiStatus_mut', 'loading')
		return new Promise((resolve, reject) => {
			let date = new Date()
			// date.setDate(date.getDate() - (((2 + date.getDay()) % 7) || 0))
			// date.setHours(7)
			// date.setMinutes(0)
			// date.setSeconds(0)
			// date.setMilliseconds(0)

			axios.get('/v2/igx/end_day_prices?date=' + date.toISOString()
			).then(response => {
				const responseData = response.data.data.body

				let golds = {}

				responseData.forEach((data) => {
					const contractId = data.attributes.contractid
					const contractConfig = getters.getContractConfigs(contractId)

					const contractPrice = Decimal.mul(data.attributes.price, contractConfig.weightCorrection)
					const price = Decimal.div(contractPrice, contractConfig.weightMultiplication)

					golds[contractId] = {
						contractId: contractId,
						contractPriceBuy: contractPrice.toNumber(),
						contractPriceSell: contractPrice.toNumber(),
						sell: price.toNumber(),
						buy: price.toNumber(),
					}
				})

				commit('setGold_mut', golds)
				commit('changeSetEndOfDayPricesApiStatus_mut', 'success')

				return resolve()
			}).catch(() => {
				commit('changeSetEndOfDayPricesApiStatus_mut', 'error')
				return reject()
			})
		})
	},

	// HOLDING vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
	setHoldings({ commit, state, getters }, payload = { skip: 0, limit: 0, selectedClientHolding: 'all' }) {
		// param
		const skip = payload.skip
		const limit = payload.limit
		const selectedClientHolding = payload.selectedClientHolding

		let contractsParam = ''
		for (let key in state.contractIds) {
			contractsParam = contractsParam + `contract_id[]=${state.contractIds[key]}&`
		}

		let urlApiHoldings = ''
		if (getters.getAccountLevel == 'ib' || getters.getAccountLevel == 'fm' || getters.getAccountLevel == 'ccp') {
			if (selectedClientHolding == 'all') {
				urlApiHoldings = '/v2/rrs/holdings'
			} else {

				if (getters.getAccountLevel == 'fm') {
					urlApiHoldings = '/v2/rrs/holdings?offset=' + skip + '&limit=' + limit + '&hin=' + selectedClientHolding.attributes.hin + '&accountId=' + selectedClientHolding.id
				} else {
					urlApiHoldings = '/v2/rrs/holdings?offset=' + skip + '&limit=' + limit + '&hin=' + selectedClientHolding.trade_account.hin + '&accountId=' + selectedClientHolding.trade_account.account_id
				}

			}
		} else { //for CLIENT and LP and CP account
			urlApiHoldings = '/v2/igx/holding'
		}

		commit('changeSetHoldingsApiStatus_mut', 'loading')
		return new Promise((resolve, reject) => {
			axios.post(urlApiHoldings, {
				user_id: state.userId
			}).then(response => {
				commit('setHoldings_mut', response.data.data.body)
				commit('changeSetHoldingsApiStatus_mut', 'success')

				return resolve(response)
			}).catch((error) => {
				commit('changeSetHoldingsApiStatus_mut', 'error')

				return reject(error)
			})
		})
	},
	//HOLDING  ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

	//status
	changeMarketInquiryStatus(context, status) {
		context.commit('CHANGE_MARKET_INQUIRY_STATUS', status)
	},

	updateVideo({ commit }, data) {
		commit('updateDataVideo', data)
	},
}
