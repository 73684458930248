<template>
	<svg :width="width" :height="height" viewBox="0 0 8 8" fill="none" >
		<path id="Vector"
			d="M4.4 2.8H3.6V2H4.4M4.4 6H3.6V3.6H4.4M4 0C3.47471 0 2.95457 0.103463 2.46927 0.304482C1.98396 0.505501 1.54301 0.800139 1.17157 1.17157C0.421427 1.92172 0 2.93913 0 4C0 5.06087 0.421427 6.07828 1.17157 6.82843C1.54301 7.19986 1.98396 7.4945 2.46927 7.69552C2.95457 7.89654 3.47471 8 4 8C5.06087 8 6.07828 7.57857 6.82843 6.82843C7.57857 6.07828 8 5.06087 8 4C8 3.47471 7.89654 2.95457 7.69552 2.46927C7.4945 1.98396 7.19986 1.54301 6.82843 1.17157C6.45699 0.800139 6.01604 0.505501 5.53073 0.304482C5.04543 0.103463 4.52529 0 4 0Z"
			:fill="color_prop" />
	</svg>
</template>

<script>
export default {

	name: 'IconInfo2',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '10px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '10px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: '#279BFB',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	}
}
</script>

<style lang="css" scoped></style>