<template>
	<div class="content">
		<div class="row">
			<div class="col">
				<form @submit.prevent="uploadVideo">
					<input type="text" name="title" v-model="title" placeholder="title"><br>
					<input type="text" name="description" v-model="description" placeholder="description"><br>
					<input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/><br>
					<button type="submit" class="btn btn-warning">SUBMIT</button>
				</form>
				<br>
				<Loader v-if="statusUpload == 'loading'"></Loader>
			</div>
		</div>
	</div>
</template>
<script >
	import axios from '@/api/axios.js'
	import Loader from '@/components/Loader.vue'

	export default {
		name: 'UploadVideo',
		components: {
			Loader
		},
		data(){
			return {
				statusUpload: 'idle',
				title: '',
				description: '',
				file: ''
			}
		},
		methods: {
			handleFileUpload() {
				this.file = this.$refs.file.files[0]
			},
			uploadVideo() {
				let formData = new FormData()
				formData.append('title', this.title)
				formData.append('description', this.description)
				formData.append('video_file', this.file)
				this.statusUpload = 'loading'
				axios.post(process.env.VUE_APP_BACKEND_URL+'/admin/upload/video',
					formData,
					{
						headers: {
								'Content-Type': 'multipart/form-data'
						}
					}
				).then(() => {
					this.statusUpload = 'idle'
				}).catch(error => {
					this.statusUpload = 'idle'
					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
				})
			}
		}
	}
</script>