<template>
	<div id="openModal" :class="modalClass">
		<div class="row c-modal-head">
			<div class="col-12 text-center">
				<span class="c-title">DETAIL - {{detail.ref_number}}</span>
			</div>
		</div>
		<div class="row c-modal-body">
			<div class="col-12">
				<form>
					<div class="form-group row">
						<label for="hin" class="col form-control-sm my-0 text-right">HIN</label>
						<div class="col-7 text-right">
							<input id="hin" type="text" class="form-control-sm form-control-plaintext" :value="hin" readonly>
						</div>
					</div>
					<div class="form-group row">
						<label for="receiptNumber" class="col form-control-sm my-0 text-right">RECEIPT NUMBER</label>
						<div class="col-7 text-right">
							<input id="receiptNumber" type="text" class="form-control-sm form-control-plaintext py-0" :value="detail.ref_number" readonly>
						</div>
					</div>
					<div class="form-group row">
						<label for="dateOfShipment" class="col form-control-sm my-0 text-right">DATE OF SHIPMENT</label>
						<div class="col-7 text-right">
							<input id="dateOfShipment" type="text" class="form-control-sm form-control-plaintext py-0" :value="`${dateOfShipment}`" readonly>
						</div>
					</div>
					<div class="form-group row">
						<label for="destination" class="col form-control-sm my-0 text-right">DESTINATION</label>
						<div class="col-7 text-right">
							<input id="destination" type="text" class="form-control-sm form-control-plaintext py-0" value="-" readonly>
						</div>
					</div>

					<div class="form-group row">
						<label for="quantity" class="col form-control-sm my-0 text-right">QUANTITY</label>
						<div class="col-7 text-right">
							<input id="quantity" type="text" class="form-control-sm form-control-plaintext py-0" :value="`${detail.quantity}   ${(detail.quantity != 1) ? 'bars' : 'bar'}`" readonly>
						</div>
					</div>
					<div class="form-group row">
						<label for="weight" class="col form-control-sm my-0 text-right">WEIGHT</label>
						<div class="col-7 text-right">
							<input id="weight" type="text" class="form-control-sm form-control-plaintext py-0" :value="`${detail.weight}   gram`" readonly>
						</div>
					</div>
					<div class="form-group row">
						<label for="valueGoodsHistory" class="col form-control-sm my-0 text-right">VALUE GOODS</label>
						<div class="col-7 text-right">
							<input id="valueGoodsHistory" type="text" class="form-control-sm form-control-plaintext py-0" :value="`Rp. ${numberFormatExtend(detail.valuegoods)}`" readonly>
						</div>
					</div>
					<div class="form-group row">
						<label for="totalCharges" class="col form-control-sm my-0 text-right">TOTAL CHARGES</label>
						<div class="col-7 text-right">
							<input id="totalCharges" type="text" class="form-control-sm form-control-plaintext py-0" :value="`Rp. ${numberFormatExtend(detail.total_charge)}`" readonly>
						</div>
					</div>
				</form>
				<hr>
			</div>
			<div class="col-12 px-0">
				<h5 class="text-center">HISTORY</h5>
				<div class="w-100 text-center" v-if="apiStatusTraceAndTrack == 'loading'">
					<Loader
						size_prop = "10em"
					>
					</Loader>
				</div>
				<template v-else>
					<table class="" style="width: 100%; border-spacing: 2px; border-collapse: separate;">
						<tbody>
							<tr v-for="(element, index) in traceAndTrack" :key="element.description" :class="classHistory(index + 1, traceAndTrack.length)">
								<td class="first-column">{{element.eventDate.substr(0, 10)}}<br><small>{{element.eventDate.substr(11, 8)}}</small><br><small style="color: transparent;">-</small></td>
								<td class="second-column"><span class="dot"></span></td>
								<td class="third-column">
									<div class="mb-2" style="font-weight: bold;">
										{{element.eventName}}
										<br>
										{{element.office}}
									</div>
									<span class="c-description" v-html="traceAndTrackDescription(index)"></span><br><br>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</div>
		</div>
		<div class="row c-modal-foot">
			<div class="col-6">
				<button id="buttonCloseDetailOrder" type="button" class="btn btn-sm btn-secondary" @click="closeModal()">Close</button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from '@/api/axios.js'
	import Loader from '@/components/Loader.vue'

	export default {
		name: 'TrackBullionHistory',
		props: {
			data_prop: Object
		},
		components: {
			Loader
		},
		data() {
			return {
				traceAndTrack: [],
				apiStatusTraceAndTrack: 'idle'
			}
		},
		computed: {
			hin() {
				return this.$store.getters.getHin
			},
			modalClass() {
				return {
					modalDialog: true,
					'modal-open': true
				}
			},

			detail() {
				const temp = this.data_prop
				return temp
			},
			dateOfShipment() {
				let dateTemp = new Date(this.detail.withdrawal_date)
				let date = {
					year: dateTemp.getFullYear(),
					month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
					date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
					hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
					minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
				}
				return  `${date.date}/${date.month}/${date.year} ${date.hours}:${date.minutes}`
			}
		},
		watch: {
			detail(newValue) {
				if (newValue) {
					this.apiStatusTraceAndTrack = 'loading'

					axios({
						method: 'POST',
						url:process.env.VUE_APP_BACKEND_URL+'/poslog/trace_and_track',
						data: {
							barcode: newValue.ref_number
						}
					}).then(response => {
						this.traceAndTrack = []
						this.traceAndTrack = response.data.data.reverse()

						this.apiStatusTraceAndTrack = 'success'
					}).catch(error => {
						this.apiStatusTraceAndTrack = 'error'
						if (error.response) {
							/*
							 * The request was made and the server responded with a
							 * status code that falls out of the range of 2xx
							 */
						} else if (error.request) {
							/*
							 * The request was made but no response was received, `error.request`
							 * is an instance of XMLHttpRequest in the browser and an instance
							 * of http.ClientRequest in Node.js
							 */
						} else {
							// Something happened in setting up the request and triggered an Error
						}
					})
				}
			}
		},
		methods: {
			numberFormatExtend(value) {
				let val = (value / 1)
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
			},

			closeModal() {
				this.$emit('close')
			},

			classHistory(index, length) {
				// const tanggal = this.traceAndTrack[index].eventDate
				if (length == 1) {
					return 'one-row'
				}else if (length == 2){
					if (index == 1) {
						return 'top-row'
					}else {
						return 'bottom-row'
					}
				}else {
					if (index == 1) {
						return 'top-row'
					}else if (index == length) {
						return 'bottom-row'
					}else {
						return 'middle-row'
					}
				}
			},
			traceAndTrackDescription(index) {
				const temp = this.traceAndTrack[index].description.replace(/;/g, '<br>')
				return String(temp)
			}
		}
	}
</script>

<style scoped>
	.modalDialog {
		position: fixed;
		font-family: Arial, Helvetica, sans-serif;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding-left: 20px;
		padding-right: 20px;
		overflow-y: scroll;
		background: rgba(0,0,0,0.8);
		z-index: 1050;
		opacity:0;
		-webkit-transition: opacity 400ms ease-in;
		-moz-transition: opacity 400ms ease-in;
		transition: opacity 400ms ease-in;
		pointer-events: none;
	}.modalDialog:target {
		opacity:1;
		pointer-events: auto;
	}
	.modal-open {
		opacity: 1;
		pointer-events: auto;	
	}

	.modalDialog > div {
		position: relative;
		max-width: 550px;
		margin-right: auto;
		margin-left: auto;
		padding: 10px 20px;
		background: #191f31;
		background: -moz-linear-gradient(#fff, #999);
		background: -webkit-linear-gradient(#fff, #999);
		background: -o-linear-gradient(#fff, #999);
	}
	.c-modal-head {
		border-radius: 7px 7px 0px 0px;
		margin-top: 30px;
		border-bottom: 1px solid white;
	}
	.c-modal-body {

	}
	.c-modal-foot {
		border-radius: 0px 0px 7px 7px;
		margin-bottom: 30px;
		border-top: 1px solid white;
	}
	.c-title {
		font-size: 1.5em;
	}
	.close {
		background: #606061;
		color: #FFFFFF;
		line-height: 25px;
		position: absolute;
		right: -12px;
		text-align: center;
		top: -10px;
		width: 24px;
		text-decoration: none;
		font-weight: bold;
		-webkit-border-radius: 12px;
		-moz-border-radius: 12px;
		border-radius: 12px;
		-moz-box-shadow: 1px 1px 3px #000;
		-webkit-box-shadow: 1px 1px 3px #000;
		box-shadow: 1px 1px 3px #000;
	}
	.close:hover { background: #00d9ff; }

	label.form-control-sm {
		line-height: 1.55em;
	}


	.top-row {
		margin-bottom: 30px;
	}

	.first-column {
		vertical-align: top;
		width: 30%;
		background: transparent;
		/*background-color: blue;*/
	}

	.second-column {
		position: relative;
		vertical-align: top;
		width: 1%;
		background: transparent;
		/*background-color: green;*/
		border-left: 3px dotted white;
	}
	.one-row .second-column {
		border: 0;
	}
	.second-column:before {
		position: relative;
		display: inline;
		padding: 0;
		margin: 0;
		content: ".";
		top: -4px;
		left: -4px;
		width: 4px;
		height: 25%;
		background-color: #191f31;
		color: #191f31;
	}
	.top-row > .second-column:before {
		background-color: #191f31;
		color: #191f31;
	}
	.middle-row > .second-column:before {
		background-color: transparent;
		color: transparent;
	}
	.bottom-row > .second-column:before {
		position: absolute;
		display: block;
		padding: 0;
		margin: 0;
		content: ".";
		top: 10px;
		left: -4px;
		width: 6px;
		height: 100%;
		background-color: #191f31;
		color: #191f31;
	}

	.dot {
		position: relative;
		display: inline-block;
		top: -18px;
		left: -8.9px;
		border-radius: 50%;
		background-color: grey;
		width: 13px;
		height: 13px;
	}
	.top-row .dot {
		background-color: green;		
	}
	.bottom-row .dot {
		top: 0px;
	}
	.one-row .dot {
		background-color: green;
	}

	.third-column {
		vertical-align: top;
		width: 69%
	}

	.c-description {
		font-size: 0.87em;
		font-weight: lighter;
		line-height: 10px;
	}
</style>