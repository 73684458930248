<template>
	<div>
		<img :src="`${publicPath}assets/img/foto-default.png`" height="40" class="mr-24-c">
	</div>
</template>

<script>
export default {
	name: 'ReportsNav',
	data() {
		return {
			publicPath: process.env.BASE_URL,
		}
	}
}
</script>

<style scoped>
</style>