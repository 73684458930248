<template>
	<div>
		<div class="color-white-c fs-12-c mb-8-c">
			Order Type
		</div>

		<div id="dropdownChangeHub1" class="select-box rounded" tabindex="-1" @blur="outOfFocus($event.target)"
			@mouseover="mousePosition = 'inside'" @mouseleave="mousePosition = 'outside'">
			<div :class="{ 'selected-currency': true, 'text-capitalize': true, 'rounded': true, 'active': isToogleActive }"
				@click="toggleOption()">
				<span>{{ selectedOrderType }}</span>
			</div>

			<div id="optionChangeHub1" class="option optionChangeHub text-white text-left"
				:style="{ 'display': optionStyleDisplay, 'min-width': optionWidth }">
				<div class="subOption" @click="changeOrderType('all')">All</div>
				<div class="subOption" @click="changeOrderType('market')">Market</div>
				<div class="subOption" @click="changeOrderType('limit')">Limit</div>
			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: 'FilterOrderType',

	props: {
		componentId_prop: {
			type: String,
			default: 'id'
		}
	},

	data() {
		return {
			isToogleActive: false,
			mousePosition: 'outside',

			selectedOrderType: 'all',
		}
	},

	computed: {
		hub: {
			get: function () {
				return this.$store.state.hub
			},
			set: function (val) {
				this.$store.dispatch('changeHub', val)
			}
		},

		optionStyleDisplay() {
			return this.isToogleActive ? 'block' : 'none'
		},

		optionWidth() {
			let width = document.querySelector('#dropdownChangeHub1')
			return width ? width.offsetWidth + 'px' : '100%'
		}
	},

	watch: {
		selectedOrderType(newValue) {
			this.$emit('changeFilterOrderType', undefined, undefined, newValue)
		}
	},

	methods: {
		toggleOption() {
			this.isToogleActive = !this.isToogleActive
		},

		outOfFocus() {
			if (this.mousePosition == 'outside') {
				this.isToogleActive = false
			}
		},

		changeOrderType(newOrderType) {
			this.selectedOrderType = newOrderType
			this.isToogleActive = false
		}
	}
}
</script>

<style scoped>
.select-box {
	position: relative;
	background-color: transparent;
	height: 2.7em;
	/*padding: 8px 16px;*/
}

.selected-currency {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 13px;
	color: white;
	width: 100%;
	height: 100%;
	min-width: 8em;
	background-color: transparent;
	padding: 8px 12px 8px 14px;
	border: solid 1px rgba(255, 255, 255, 0.25);

	z-index: 2;
}

.selected-currency:hover {
	cursor: pointer;
}

.selected-currency::after {
	position: relative;
	content: url("/assets/svg/arrow.svg");
	margin-left: 10px;
	margin-bottom: 1px;
	font-size: 15px;

}

.selected-currency.active::after {
	transform: translateX(0px) rotateZ(180deg);
}

.option {
	position: absolute;
	display: none;
	/*background-color: rgb(26, 30, 52);*/
	background: #1a1e34;
	top: 36px;
	right: 0px;
	padding: 8px 0px 8px 0px;
	/*min-width: 20px;*/
	width: max-content;
	border-radius: 4px;
	border: solid 1px rgba(255, 255, 255, 0.25);
	color: white;

	z-index: 1;
}

.subOption {
	color: white;
	font-size: 13px;
	padding: 8px 20px 8px 12px;
	user-select: none;
}

.subOption:hover {
	cursor: pointer;
	background-color: #263353;
}

.disable {
	filter: brightness(50%);
}

.subOption.disable:hover {
	cursor: no-drop;
	background-color: #2E3D61;
}

.custom-select {
	background-color: #2E3D61;
	border-color: rgba(255, 255, 255, 0.3);
}

.c-custom-select {
	display: block;
	width: 100%;
	background-color: #2E3D61;
	border: solid rgba(255, 255, 255, 0.3) 1px;
	padding: 0.5rem 0.25rem;
	color: white;
}
</style>