<!-- <template>
	<span class="dropdown mr-1">
		<a class="c-btn" id="dropdownMenuLink">
			<template v-if="tradingType == 'jgx'">
				<LogoAbx class="mr-2" style="height: 30px; width: 30px;"></LogoAbx>
				HIN: {{hin}}
			</template>
			<template v-else>
				<img :src="`${publicPath}assets/img/logo-kau.png`" class="mr-2" style="height: 30px; border-radius: 0;">
				HIN: {{hinKinesis}}
			</template>
		</a>
	</span>
</template> -->

<template>
	<div class="select-box rounded">
		<LogoAbx class="logo-abx"></LogoAbx>
		<span class="text-nowrap">HIN: {{hin}}</span>
	</div>
</template>

<script>
	import LogoAbx from '@/components/svg/logo-abx.vue'

	export default {
		name: 'TradingType',
		components: {
			LogoAbx,
		},
		data() {
			return {
        publicPath: process.env.BASE_URL,
			}
		},
		computed: {
			hin() {
				return this.$store.getters.getHin
			},
			hinKinesis() {
				return this.$store.getters.getHinKinesis
			},
			tradingType() {
				return this.$store.state.tradingType
			},
		},
		methods: {
			changeTradingType(tradingType) {
        this.$store.dispatch('changeTradingType', tradingType)
			}
		}
	}
</script>

<style scoped>
	.select-box {
		position: relative;
		display: flex;
		background-color: var(--grey-1-color);
		width: fit-content;
		align-items: center;
		font-size: 1rem;
		color: white;
		padding: 0.5em 0.75em;
		height: 2.5em;
	}

	.logo-abx{
		height: 1.3em;
		width: 1.3em;
		margin-right: 0.5em;
	}
</style>