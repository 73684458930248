<template>
	<div>
		<iframe src="https://www.widgets.investing.com/live-commodities?theme=darkTheme" width="100%" height="420" frameborder="0" allowtransparency="true" marginwidth="0" marginheight="0"></iframe><div class="poweredBy" style="font-family: Arial, Helvetica, sans-serif;">Powered by <a href="https://www.investing.com?utm_source=WMT&amp;utm_medium=referral&amp;utm_campaign=LIVE_COMMODITIES&amp;utm_content=Footer%20Link" target="_blank" rel="nofollow">Investing.com</a></div>
	</div>
</template>

<script>
	export default {
		name: "CommoditiesByInvesting"
	}
</script>