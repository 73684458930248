<template>
	<div class="content">
		<div class="tradingview-widget-container" ref="economicCalendar">
	    <div class="tradingview-widget-container__widget"></div>
	    <div class="tradingview-widget-copyright">
	        <a href="https://www.tradingview.com/markets/currencies/economic-calendar/" rel="noopener" target="_blank">
	            <span class="blue-text">Economic Calendar</span>
	        </a> by TradingView
	    </div>
	  </div>
	</div>
</template>

<script>
	export default {
		name: 'EconomicCalender',
		props: {
			height: {
				type: String,
				default: "600"
			},
			width: {
				type: String,
				default: "510"
			}
		},
		mounted() {
			let c_height = this.height
			let c_width = this.width

			let tagReference = document.createElement('script')
			tagReference.setAttribute('type', 'text/javascript')
			tagReference.setAttribute('src', 'https://s3.tradingview.com/external-embedding/embed-widget-events.js')
			tagReference.setAttribute('async', true)
			tagReference.innerHTML = `
				{
				  "colorTheme": "dark",
          "isTransparent": false,
          "width": "${c_width}",
          "height": "${c_height}",
          "locale": "en",
          "importanceFilter": "-1,0,1"
				}
			`
			this.$refs.economicCalendar.appendChild(tagReference)
		}
	} 
</script>