<template>
	<footer class="footer">
    <div class="container-fluid">
      <nav class="float-left">
        <!-- <ul>
          <li>
            <a href="https://www.creative-tim.com">
              Creative Tim
            </a>
          </li>
        </ul> -->
      </nav>
      <div class="copyright float-right">
      <span> Powered by Metaldesk X (MDX) </span>
        <!-- MDX
        &copy;
        2019 -->
        <!-- made with <i class="tim-icons icon-heart-2"></i> by
        <a href="https://www.creative-tim.com" target="_blank">Creative Tim</a> for a better web. -->
      </div>
       <!-- your footer here -->
    </div>
  </footer>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
      return{
        year: new Date().getFullYear() 
      }
    }
	}
</script>
