<template>
	<div class="loader" :style="styleLoader"></div>
</template>

<script>
	export default {
		name: 'Loader',
		props: {
			size_prop: {
				type: String,
				default: '4em'
			},
			borderSize_prop: {
				type: String,
				default: '1.1em'
			},
		},
		computed: {
			styleLoader() {
				return {
					width: this.size_prop,
					height: this.size_prop,
					'border-top': this.borderSize_prop + ' solid rgba(255, 255, 255, 0.2)',
				  'border-right': this.borderSize_prop + ' solid rgba(255, 255, 255, 0.2)',
				  'border-bottom': this.borderSize_prop + ' solid rgba(255, 255, 255, 0.2)',
				  'border-left': this.borderSize_prop + ' solid #ffffff'
				}
			}
		}
	}
</script>

<style scoped>
	.loader,
	.loader:after {
	  border-radius: 50%;
	  width: 4em;
	  height: 4em;
	}
	.loader {
	  /*margin: 60px auto;*/
	  display: inline-block;
	  font-size: 10px;
	  position: relative;
	  text-indent: -9999em;
	  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
	  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
	  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
	  border-left: 1.1em solid #ffffff;
	  -webkit-transform: translateZ(0);
	  -ms-transform: translateZ(0);
	  transform: translateZ(0);
	  -webkit-animation: load8 1.1s infinite linear;
	  animation: load8 1.1s infinite linear;
	}
	@-webkit-keyframes load8 {
	  0% {
	    -webkit-transform: rotate(0deg);
	    transform: rotate(0deg);
	  }
	  100% {
	    -webkit-transform: rotate(360deg);
	    transform: rotate(360deg);
	  }
	}
	@keyframes load8 {
	  0% {
	    -webkit-transform: rotate(0deg);
	    transform: rotate(0deg);
	  }
	  100% {
	    -webkit-transform: rotate(360deg);
	    transform: rotate(360deg);
	  }
	}
</style>