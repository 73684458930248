<template>
	<div class="tooltip-c">
		<IconInfo v-if="type_prop == '1'" class="va-text-top ml-6-c" />
		<IconInfo2 v-if="type_prop == '2'" class="va-text-top ml-6-c" />
		
		<span :class="`tooltiptext-c ${textPosition_prop == 'bottom' ? 'bottom-c' : ''}`">{{ text_prop }}</span>
	</div>
</template>

<script>
import IconInfo from '@/components/icons/IconInfo.vue'
import IconInfo2 from './icons/IconInfo2.vue'

export default {
	name: 'Tooltip',

	props: {
		type_prop: {
			type: String,
			default: '1',
		},

		textPosition_prop: {
			type: String,
			default: 'top',
		},

		text_prop: {
			type: String,
			default: '?',
		}
	},

	components: {
		IconInfo,
		IconInfo2,
	},

	data() {
		return {
		}
	}
}	
</script>

<style lang="css" scoped>
.tooltip-c {
	position: relative;
	display: inline-block;
}

.tooltip-c .tooltiptext-c {
	visibility: hidden;
	width: max-content;
	min-width: 120px;
	max-width: 30ch;
	background-color: var(--grey-3-color);
	color: #fff;
	text-align: center;
	border-radius: 4px;
	padding: 0.75rem;
	position: absolute;
	bottom: 125%;
	left: -90%;
	margin-left: -60px;
	font-weight: 600;
	opacity: 0;

	/* shadow */
	box-shadow: 0 0 10px -3px black;

	text-wrap: wrap;

	transition: opacity 0.3s;
}

.tooltip-c:hover .tooltiptext-c {
	visibility: visible;
	opacity: 1;
}

.tooltip-c .tooltiptext-c.bottom-c {
  top: 100%;
  left: 50%;
	bottom: initial;
}
</style>
