<template>
	<div class="dropdown">
		<div class="color-white-c fs-12-c mb-8-c">
			Product Filter
		</div>

		<button class="btn btn-secondary c-dropdown-toggle py-2 m-0" style="min-width: 186px;" type="button"
			id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<template v-if="checkedContracts.length < 1">
				Filter Contracts
			</template>
			<template v-else v-for="(item, index) in checkedContractsShown">
				{{ item }}
				<pre class="d-inline" :key="index"
					v-if="checkedContracts.length > 0 && index != checkedContracts.length - 1">,</pre>
			</template>
		</button>

		<div class="dropdown-menu">
			<form class="p-0 text-unselectable">
				<template v-for="item in filterContract">

					<!-- <div class="flex cursor-hover-pointer" :for="'filterContractId' + filterId + item.contractId"
						:key="'filterContractKey' + item.contractId">
						<div>
							<input class="" type="checkbox" :value="item.contractId"
								:id="'filterContractId' + filterId + item.contractId" v-model="checkedContracts">
						</div>
						<div class="form-check-label">
							{{ item.name }}
						</div>
					</div> -->

					<label class="dropdown-item-checkbox" :for="'filterContractId' + filterId + item.contractId"
						:key="'filterContractKey' + item.contractId">
						<input class="form-check-input" type="checkbox" :value="item.contractId"
							:id="'filterContractId' + filterId + item.contractId" v-model="checkedContracts">

						<div class="form-check-label">
							{{ item.name }}
						</div>
					</label>
				</template>

				<!-- <label class="dropdown-item-checkbox" :for="'transactionFilterContractMicro' + filterId" style="background-color: #3B3B56">
					<input class="form-check-input" type="checkbox" value="0.01 gr" :id="'transactionFilterContractMicro' + filterId" v-model="checkedContracts">
					<span class="form-check-label">
						Micro 0.01 gr pool
					</span>
				</label>

				<label class="dropdown-item-checkbox" :for="'transactionFilterContract1gr' + filterId" style="background-color: #1A1E34">
					<input class="form-check-input" type="checkbox" value="1 gr" :id="'transactionFilterContract1gr' + filterId" v-model="checkedContracts">
					<span class="form-check-label">
						KAU 1 gr pool
					</span>
				</label>

				<label class="dropdown-item-checkbox" :for="'transactionFilterContract100gr' + filterId" style="background-color: #3B3B56">
					<input class="form-check-input" type="checkbox" value="100 gr" :id="'transactionFilterContract100gr' + filterId" v-model="checkedContracts">
					<span class="form-check-label">
						100 gr non pool
					</span>
				</label>

				<label class="dropdown-item-checkbox" :for="'transactionFilterContract1kg' + filterId" style="background-color: #1A1E34">
					<input class="form-check-input" type="checkbox" value="1 kg" :id="'transactionFilterContract1kg' + filterId" v-model="checkedContracts">
					<span class="form-check-label">
						1 kg non pool
					</span>
				</label>

				<label class="dropdown-item-checkbox" :for="'transactionFilterContract10kg' + filterId" style="background-color: #3B3B56">
					<input class="form-check-input" type="checkbox" value="10 kg" :id="'transactionFilterContract10kg' + filterId" v-model="checkedContracts">
					<span class="form-check-label">
						10 kg non pool
					</span>
				</label> -->

			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FilterContract',

	props: {
		filterId: {
			type: String,
			default: String(Math.random())
		}
	},

	data() {
		return {
			filterContract: this.$store.state.filterContract,
			checkedContracts: []
		}
	},

	computed: {
		checkedContractsShown() {
			let temp = []
			this.filterContract.forEach(item => {
				if (this.checkedContracts.findIndex(element => element == item.contractId) != -1) {
					temp.push(item.name)
				}
			})
			return temp
		}
	},

	watch: {
		checkedContracts(newValue) {
			this.$emit('changeFilterContract', newValue)
		}
	},

	created() {
		this.filterContract.forEach((item) => {
			if (item.checked) {
				this.checkedContracts.push(item.contractId)
			}
		})
	}

}
</script>

<style scoped>
.dropdown-menu {
	background: #1a1e34;
	border: solid 1px rgba(255, 255, 255, 0.25);
	color: white;
}

.dropdown-menu::before {
	color: transparent;
}

.dropdown-item-checkbox {
	background-color: #3B3B56;

	margin: 0;
	display: block;
	padding: 0.3em 0 0.3em 2em;

	/* margin-bottom: 1em; */
}

.dropdown-item-checkbox:hover {
	cursor: pointer;
	filter: brightness(130%);
}

.c-dropdown-toggle {
	background-image: none !important;
	background-color: transparent !important;
	border: 1px solid rgba(255, 255, 255, 0.25);
	font-weight: initial;
	/* font-size: 1rem; */
	/* padding: 2px; */
}

.c-dropdown-toggle::after {
	content: url("/assets/svg/arrow.svg");

	margin-left: 0.5em !important;
}


.c-dropdown-toggle:has(+ .show)::after {
	transform: translateX(0px) rotateZ(180deg);
}

.form-check-input {
	margin-top: -0.4em;
}

.dropdown-toggle:hover {
	transform: none;
	-webkit-transform: none;
}

.btn.dropdown-toggle:after {
	margin-left: 8px !important;
}

.btn.dropdown-toggle {
	height: 2.375em;
	padding: 5px 15px !important;
}
</style>