<template>
	<svg width="13" height="16" viewBox="0 0 13 16" fill="none" >
		<g id="Group 7">
			<path id="Vector"
				d="M6.09524 12.1905C6.49938 12.1905 6.88696 12.0299 7.17273 11.7442C7.4585 11.4584 7.61905 11.0708 7.61905 10.6667C7.61905 10.2625 7.4585 9.87494 7.17273 9.58917C6.88696 9.3034 6.49938 9.14286 6.09524 9.14286C5.6911 9.14286 5.30351 9.3034 5.01774 9.58917C4.73197 9.87494 4.57143 10.2625 4.57143 10.6667C4.57143 11.0708 4.73197 11.4584 5.01774 11.7442C5.30351 12.0299 5.6911 12.1905 6.09524 12.1905ZM10.6667 5.33333C11.0708 5.33333 11.4584 5.49388 11.7442 5.77965C12.0299 6.06542 12.1905 6.453 12.1905 6.85714V14.4762C12.1905 14.8803 12.0299 15.2679 11.7442 15.5537C11.4584 15.8395 11.0708 16 10.6667 16H1.52381C1.11967 16 0.732083 15.8395 0.446313 15.5537C0.160544 15.2679 0 14.8803 0 14.4762V6.85714C0 6.453 0.160544 6.06542 0.446313 5.77965C0.732083 5.49388 1.11967 5.33333 1.52381 5.33333H2.28571V3.80952C2.28571 2.79918 2.68707 1.83021 3.4015 1.11578C4.11592 0.401359 5.08489 0 6.09524 0C6.59551 0 7.09089 0.0985363 7.55308 0.289983C8.01527 0.481429 8.43523 0.762037 8.78898 1.11578C9.14272 1.46953 9.42333 1.88949 9.61478 2.35168C9.80623 2.81387 9.90476 3.30925 9.90476 3.80952V5.33333H10.6667ZM6.09524 1.52381C5.48903 1.52381 4.90765 1.76463 4.47899 2.19328C4.05034 2.62193 3.80952 3.20331 3.80952 3.80952V5.33333H8.38095V3.80952C8.38095 3.20331 8.14014 2.62193 7.71148 2.19328C7.28283 1.76463 6.70145 1.52381 6.09524 1.52381Z"
				fill="#27293D" />
		</g>
	</svg>
</template>

<script>
export default {

	name: 'IconPadlock',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '15px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '16px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: 'black',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	}
}
</script>

<style lang="css" scoped></style>