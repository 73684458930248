<template>
	<div id="openModal" :class="[modalClass, 'mx-3']">
		<div class="c-modal-head text-center">
			<div class="row">
				<div class="col-12">
					<p class="c-title">Disclaimer</p>
				</div>
			</div>
		</div>
		<div class="c-modal-body text-center mb-3">
			<div class="row">
				<div class="col-auto text-center pr-0" style="width: 32px;">
					<p class="mb-2">1.</p>
				</div>

				<div class="col">
					<p class="text-left">
						Ketentuan besar uang Ganti Rugi sebagai berikut: <br>
						Ganti rugi yang dilindungi Perusahaan Asuransi dengan Nilai Jaminan Ganti Rugi Terhadap kiriman diberikan ganti rugi sebagai berikut: <br>
					</p>
					<table class="table table-sm text-left">
						<thead>
							<tr>
								<th>No.</th>
								<th>Kondisi</th>
								<th>Ganti rugi standar perusahaan (Pos.</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1.</td>
								<td>Hilang</td>
								<td>Hilang 	Maksimal 100% x Nilai pertanggungan barang yang hilang </td>
							</tr>
							<tr>
								<td>2.</td>
								<td>Rusak</td>
								<td>Rusak	Maksimal 100% x Nilai pertanggungan barang yang rusak </td>
							</tr>
							<tr>
								<td>3.</td>
								<td>Keterlambatan</td>
								<td>Keterlambatan 25% x biaya pengiriman </td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;">
					<p class="mb-2">2.</p>
				</div>
				<div class="col text-left">
					<p class="mb-2">
						<b>POS</b> tidak berkewajiban memberikan Ganti Rugi terhadap hal-hal sebagai berikut:
					</p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">a.</p></div>
						<div class="col pl-2"><p class="mb-2">Tuntutan Ganti Rugi diajukan melampaui batas waktu yang ditetapkan;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">b.</p></div>
						<div class="col pl-2"><p class="mb-2">Atas kerugian yang tidak langsung atau keuntungan yang tidak jadi diperoleh karena Hilang dan/atau Rusak;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">c.</p></div>
						<div class="col pl-2"><p class="mb-2">Jika peristiwa Rusak dan/atau Hilang Kiriman diakibatkan karena <i>Force majeure</i>.</p></div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">3.</p></div>
				<div class="col text-left">
					<p class="mb-2"><b>POS</b> dapat menolak tuntutan Ganti Rugi apabila terjadi Hilang dan/atau Rusak Kiriman yang disebabkan oleh: </p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">a.</p></div>
						<div class="col pl-2"><p class="mb-2">Kondisi pembungkus dan pengemasan yang tidak memenuhi standar keamanan dan kelayakan <b>POS</b>;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">b.</p></div>
						<div class="col pl-2"><p class="mb-2">Risiko teknis yang disebabkan karena kegagalan pabrikasi sepanjang bungkus atau kemasan Kiriman tidak dibuka dan tidak ada indikasi kesalahan dalam penanganan distribusi Kiriman oleh <b>POS</b>, contohnya: Produk tidak berfungsi atau berubah fungsi;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">c.</p></div>
						<div class="col pl-2"><p class="mb-2">Penahanan, penyitaan dan/atau pemusnahan Kiriman yang dilakukan oleh instansi Pemerintah seperti Bea Cukai, Karantina, Kepolisian dan Kejaksaan sebagai akibat hukum dari keadaan dan/atau jenis isi Kiriman;</p></div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">4.</p></div>
				<div class="col text-left">
					<p class="mb-2">Ketentuan pengajuan tuntutan Ganti Rugi:</p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">a.</p></div>
						<div class="col pl-2"><p class="mb-2">Rusak, paling lambat 2 (dua) Hari Kerja sejak tanggal Kiriman diterima oleh penerima.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">b.</p></div>
						<div class="col pl-2"><p class="mb-2">Hilang, paling lambat 7 (tujuh) Hari Kerja sejak tanggal Kiriman ditetapkan Hilang oleh POS.</p></div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">5.</p></div>
				<div class="col text-left">
					<p class="mb-2">
						Persyaratan pengajuan tuntutan Ganti Rugi adalah sebagai berikut:<br>
						Tuntutan Ganti Rugi dapat diajukan pengirim atau kuasanya paling lambat 21 (dua puluh satu) hari kerja sejak tanggal pengajuan, dengan melampirkan:
					</p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">a.</p></div>
						<div class="col pl-2"><p class="mb-2">Resi;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">b.</p></div>
						<div class="col pl-2"><p class="mb-2">Faktur/bukti pembelian barang/surat keterangan dari penerima untuk kerusakan atau kehilangan;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">c.</p></div>
						<div class="col pl-2"><p class="mb-2">Barang (isi Kiriman) yang rusak, untuk kerusakan;</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">d.</p></div>
						<div class="col pl-2"><p class="mb-2">Surat kuasa, bila yang mengunjukkan adalah si penerima, untuk seluruh kriteria kerugian.</p></div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">6.</p></div>
				<div class="col text-left">
					<p class="mb-2">
						Masa kedaluwarsa Kiriman yang dapat diterima sebagai dasar pengajuan pengaduan dan atau klaim tuntutan Ganti Rugi, ditetapkan maksimal 90 (sembilan puluh) hari sejak Kiriman diproses <i>collecting</i>.
					</p>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">7.</p></div>
				<div class="col text-left">
					<p class="mb-2">
						Penetapan hilang rusak sebagian merupakan kewenangan kepala kantorpos yang tertuang dalam surat keterangan pada formulir pertimbangan kepala kantorpos, termasuk menentukan besar kerusakan kiriman.
					</p>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">8.</p></div>
				<div class="col text-left">
					<p class="mb-2">
						Pada pengiriman <i>Valuable Goods</i> logam mulia/emas nilai pertanggungan yang ditetapkan sebesar nilai pertanggungan barang yang dipertanggungkan dengan maksimal nilai pertanggungan sebesar <b>Rp 1.000.000.000,- / per item /per tujuan</b>. Nilai emas yang akan diganti sebesar nilai emas yang dipertanggungkan di loket Kantorpos.
					</p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2"></p></div>
						<div class="col pl-2"><p class="mb-2"></p></div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">9.</p></div>
				<div class="col text-left">
					<p class="mb-2">
						Ketentuan Pengambilan emas di Kantorpos Tujuan :
					</p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">a.</p></div>
						<div class="col pl-2"><p class="mb-2">Peserta Emas Digital mendapatkan notfikasi melalui sms ke nomor telepon atau email yang terdaftar untuk melakukan pengambilan kiriman emas di Tempat Penyerahan Terdaftar (Kantor Pos tujuan).</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">b.</p></div>
						<div class="col pl-2">
							<p class="mb-0">Notifikasi berisi informasi sebagai berikut :</p>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Nomor seri emas, HIN (<i>Holding Identification Number</i>),</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Berat emas,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Nama Peserta Emas Digital,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Tanggal barang siap diambil,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Lokasi pengambilan,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-2">Masa toleransi,</p></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">c.</p></div>
						<div class="col pl-2"><p class="mb-2">HIN (<i>Holding Identification Number</i>) bersifat rahasia dan tidak boleh diinformasikan ke pihak lain. Penyalahgunaan HIN (Holding Identifcation Number) oleh pihak lain yang disebabkan oleh kelalaian Peserta Emas Digital, tidak menjadi tanggung jawab pihak yang lalai.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">d.</p></div>
						<div class="col pl-2"><p class="mb-2">Pengambilan kiriman emas di Tempat Penyerahan Terdaftar (Kantor Pos tujuan) dilayani pada hari kerja.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">e.</p></div>
						<div class="col pl-2"><p class="mb-2">Pengambilan kiriman emas di kantorpos oleh Peserta Emas Digital diharuskan membawa kartu identitas yang berlaku serta wajib menginput HIN (<i>Holding Identification Number</i>)</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">f.</p></div>
						<div class="col pl-2"><p class="mb-2">Jika proses input HIN (<i>Holding Identification Number</i>) gagal, maka Peserta Emas Digital harus menunjukkan kartu identitas untuk verifikasi data.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">g.</p></div>
						<div class="col pl-2">
							<p class="mb-0">Dalam hal pengambilan kiriman emas dilakukan oleh orang lain di luar Peserta Emas Digital yang terdaftar, maka harus disertai dengan dokumen sebagai berikut :</p>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Surat kuasa bermeterai (Sesuai format)</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Fotokopi kartu identitas (KTP, SIM, Paspor) Peserta Emas Digital selaku pemberi kuasa.</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-2">Kartu identitas (KTP, SIM, Paspor) penerima kuasa</p></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">h.</p></div>
						<div class="col pl-2"><p class="mb-2">Petugas kantorpos melakukan verifikasi dan memastikan kesesuaian data Peserta Emas Digital.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">i.</p></div>
						<div class="col pl-2"><p class="mb-2">Penyerahan kiriman emas dilakukan di loket yang telah disediakan oleh kantorpos tujuan.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">j.</p></div>
						<div class="col pl-2"><p class="mb-2">Penyerahan kiriman kepada penerima dengan perlakuan Pos Restant sebagaimana diatur dalam ketentuan Pos.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">k.</p></div>
						<div class="col pl-2"><p class="mb-2">Apabila Peserta Emas Digital tidak mengambil kiriman emas pada waktu yang telah ditentukan, maka kiriman disimpan di kantorpos selama masa toleransi 6 (enam) hari. Jika melewati masa toleransi maka dikenakan biaya penyimpanan yang menjadi beban Peserta Emas Digital dan ditagihkan ketika Peserta Emas Digital mengambil kiriman Emas.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">l.</p></div>
						<div class="col pl-2"><p class="mb-2">Jangka waktu dan besar biaya penyimpanan ditentukan oleh <b>POS</b>.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">m.</p></div>
						<div class="col pl-2"><p class="mb-2">Jika dalam waktu 6 (enam) hari kalender setelah pemberitahuan ketiga disampaikan kiriman Emas tidak diambil oleh Peserta Emas Digital, maka <b>POS</b> menghubungi kembali Peserta Emas Digital untuk mengambil kiriman Emasnya.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">n.</p></div>
						<div class="col pl-2"><p class="mb-2">Apabila Peserta Emas Digital meninggal dunia sebelum kiriman Emas diserahterimakan, maka kiriman Emas tersebut menjadi hak ahli waris Peserta Emas Digital.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">o.</p></div>
						<div class="col pl-2"><p class="mb-2">Ahli waris yang diakui adalah ahli waris yang sah menurut hukum.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">p.</p></div>
						<div class="col pl-2">
							<p class="mb-0">Pengambilan kiriman Emas oleh ahli waris sebagaimana yang dimaksud butir b Pasal ini, wajib dilengkapi sebagai berikut :</p>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Surat keterangan kematian Peserta Emas Digital yang bersangkutan dari RT, RW, dan Kelurahan setempat,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Surat keterangan kematian Peserta Emas Digital yang bersangkutan dari rumah sakit,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Kartu keluarga,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Identitas diri Peserta Emas Digital (KTP, SIM, Paspor),</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Identitas diri ahli waris,</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Print out HIN (<i>Holding Identification Number</i>),</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Surat pernyataan ahli waris (sesuai format dibawah ini),</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-0">Surat pernyataan kebenaran dan keabsahan dokumen sesuai lampiran-3.</p></div>
							</div>
							<div class="row">
								<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-0">&bull;</p></div>
								<div class="col pl-2"><p class="mb-2">Identitas yang dimaksud wajib ditunjukan dokumen aslinya. </p></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">q.</p></div>
						<div class="col pl-2"><p class="mb-2">Petugas kantorpos wajib melakukan verifikasi terhadap data Ahli waris, status kiriman Emas dan status pembayaran.</p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">r.</p></div>
						<div class="col pl-2"><p class="mb-2">Ahli waris wajib melunasi biaya-biaya yang timbul sebelum mengambil kiriman Emas, seperti biaya penyimpanan dan biaya-biaya lain yang ditentukan oleh pihak yang berwenang. </p></div>
					</div>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2">s.</p></div>
						<div class="col pl-2"><p class="mb-2">Setelah kiriman emas diserahterimakan kepada ahli waris, maka <b>POS</b> dibebaskan dari segala tanggung jawab. </p></div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2"></p></div>
				<div class="col text-left">
					<p class="mb-2"></p>
					<div class="row">
						<div class="col-auto text-center pr-0" style="width: 32px;"><p class="mb-2"></p></div>
						<div class="col pl-2"><p class="mb-2"></p></div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<button class="btn c-btn-success float-right" @click="closeModal('agree')">Agree</button>
					<button class="btn c-btn-danger float-right mr-2" @click="closeModal('cancel')">Cancel</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
		},
		data() {
			return {
			}
		},
		computed: {
			modalClass() {
				return {
					modalDialog: true,
					'modal-open': true
				}
			},
		},
		methods: {
			closeModal(value) {
				this.$emit('close', value)
			},
		}
	}
</script>

<style scoped>
	.modalDialog {
		position: fixed;
		font-family: Arial, Helvetica, sans-serif;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow-y: scroll;
		background: rgba(0,0,0,0.8);
		z-index: 1050;
		opacity:0;
		-webkit-transition: opacity 400ms ease-in;
		-moz-transition: opacity 400ms ease-in;
		transition: opacity 400ms ease-in;
		pointer-events: none;
	}.modalDialog:target {
		opacity:1;
		pointer-events: auto;
	}
	.modal-open {
		opacity: 1;
		pointer-events: auto;	
	}

	.modalDialog > div {
		position: relative;
		max-width: 700px;
		margin-right: auto;
		margin-left: auto;
		padding: 10px 20px;
		background: #191f31;
		background: -moz-linear-gradient(#fff, #999);
		background: -webkit-linear-gradient(#fff, #999);
		background: -o-linear-gradient(#fff, #999);
	}
	.c-modal-head {
		border-radius: 7px 7px 0px 0px;
		margin-top: 30px;
		border-bottom: 1px solid white;
	}
	.c-modal-body {
		border-radius: 7px 7px 0px 0px;
		margin-top: 30px;
	}
	.c-modal-head + .c-modal-body {
		border-radius: 0px 0px 7px 7px;
		margin-top: 0;
	}
	.c-modal-foot {
		border-radius: 0px 0px 7px 7px;
		margin-bottom: 30px;
		border-top: 1px solid white;
	}
	.c-title {
		font-size: 1.5em;
	}
	.close {
		background: #606061;
		color: #FFFFFF;
		line-height: 25px;
		position: absolute;
		right: -12px;
		text-align: center;
		top: -10px;
		width: 24px;
		text-decoration: none;
		font-weight: bold;
		-webkit-border-radius: 12px;
		-moz-border-radius: 12px;
		border-radius: 12px;
		-moz-box-shadow: 1px 1px 3px #000;
		-webkit-box-shadow: 1px 1px 3px #000;
		box-shadow: 1px 1px 3px #000;
	}
	.close:hover { background: #00d9ff; }
</style>
