<template>
	<div>
		<h4 class="text-center">RECONCILE REPORTING SYSTEM</h4>

		<div class="row">

			<div class="col-auto mb-2 text-left">
				<FilterDateAndType class="d-inline-block"
					@changeFilterDateAndType="changeFilterDateAndType"
				>
				</FilterDateAndType>
			</div>

		</div>

		<div class="row">
			<div class="col-12">
				<TopSection></TopSection>
			</div>
		</div>

		<div class="row">
			<div class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">PRODUCT</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> (USD)</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="rrsClientStatus != 'loading'">
							<tr v-for="(product, index) in products" class="c-row-table text-right" v-bind:key="product.id">
								<td class="text-center">{{index + 1}}</td>
								<td class="text-left">{{product.name}}</td>
								<td>{{(product.buy.total_price).toFixed(2)}}</td>
								<td>{{(product.buy.totalToz).toFixed(10)}}</td>
								<td>{{product.buy.total_gram}}</td>
								<td>{{(product.sell.total_price).toFixed(2)}}</td>
								<td>{{(product.sell.totalToz).toFixed(10)}}</td>
								<td>{{product.sell.total_gram}}</td>
								<td>{{(product.total_trade).toFixed(2)}}</td>
								<td>{{(product.totalToz).toFixed(10)}}</td>
								<td>{{product.total_gram}}</td>
								<td class="text-center">{{(product.rateRatio) ? product.rateRatio : '-'}}</td>
							</tr>
							<tr v-if="products.length < 1">
								<td colspan="12" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader
										size_prop="10em"
									></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from '@/api/axios.js'
	import {Decimal} from 'decimal.js'

	import Loader from '@/components/Loader.vue'
	import ExportAsButton from '@/components/ExportAsButton.vue'
	import FilterDateAndType from '@/components/FilterDateAndType.vue'
	import TopSection from '@/components/reconcile-reporting-system/TopSection.vue'

	export default {
		name: 'ExchangePartner',

		props: {
			goldOrderProp: Array
		},

		components: {
			Loader,
			ExportAsButton,
			FilterDateAndType,
			TopSection
		},

		data() {
			return {
				grToTozMultiplier: 0.0321507,

				rrsClientStatus: 'loading',
				products: [],
				
				totalTurnOver: {
					usd: 0,
					anotherCurrency: 0,
					toz: 0,
					gram: 0
				},

				selectedDateAndType: {
					type: 'all',
					startDate: '', 
					endDate: ''
				},
			}
		},

		computed: {
			hin() {
				return this.$store.getters.getHin
			},

			streamingRate() {
				return this.$store.getters.getStreamingRate
			},

			productsGlobal() {
        let products = this.$store.getters.PRODUCTS
        return products
      },
		},

		created() {
			const firstCall = setInterval(() => {
        if (this.productsGlobal) {
          clearInterval(firstCall)
					this.setRrs()
        }
      }, 1000)
		},

		methods: {
			setRrs() {
				let reportType = this.selectedDateAndType.type
				let startDate = this.selectedDateAndType.startDate
				let endDate = this.selectedDateAndType.endDate

				if (reportType == 'all') {
					reportType = ''
				}

				if (startDate) {
					startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
				}

				if (endDate) {
					endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? '0' : ''}${endDate.getMonth() + 1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`
				}

				if (this.hin == '') {
					this.rrsClientStatus = 'loading'
					this.products = []
					axios({
						method: 'get',
						url:process.env.VUE_APP_BACKEND_URL+'/rrs/client?type='+ this.reportType +'&startDate='+ startDate +'&endDate='+ endDate
					}).then(response => {
						let temp = response.data.data

						const trueContractIds = this.$store.state.trueContractIds

						temp.contract_micro.contractId = trueContractIds.contractId_Micro001GramTo1Gram
						temp.contract_100gr.contractId = trueContractIds.contractId_100g
						temp.contract_1kg.contractId = trueContractIds.contractId_1kg
						temp.contract_kau1gram.contractId = trueContractIds.contractId_KAU1Gram
						temp.contract_kau001gram.contractId = trueContractIds.contractId_KAU1GramTo001Gram

						for (let key in temp) {
							temp[key].name = this.$store.state.contractConfigs[temp[key].contractId].rrsName
						}

						this.goldOrderProp.forEach(item => {
							const property = Object.keys(temp).find(key => temp[key].contractId == item)
							this.products.push(temp[property])
						})

						let tempUsd = 0
						let tempGram = 0
						this.products.forEach((product, index) => {

							this.products[index].buy.total_price = Number((this.products[index].buy.total_price).toFixed(2))
							this.products[index].buy.totalToz = Number(Decimal.mul(product.buy.total_gram, this.grToTozMultiplier).toFixed(2))
							this.products[index].buy.total_gram = Number((this.products[index].buy.total_gram).toFixed(2))

							this.products[index].sell.total_price = Number((this.products[index].sell.total_price).toFixed(2))
							this.products[index].sell.totalToz = Number(Decimal.mul(product.sell.total_gram, this.grToTozMultiplier).toFixed(2))
							this.products[index].sell.total_gram = Number((this.products[index].sell.total_gram).toFixed(2))
							
							this.products[index].total_trade = Number((this.products[index].total_trade).toFixed(2))
							this.products[index].totalToz = Number(Decimal.mul(product.total_gram, this.grToTozMultiplier).toFixed(2))
							this.products[index].total_gram = Number((this.products[index].total_gram).toFixed(2))

							this.products[index].rateRatio = Number(Decimal.mul(Decimal.div(product.sell.total_price, Decimal.add(product.sell.total_price, product.buy.total_price)), 100).toFixed(2))
							if (isNaN(this.products[index].rateRatio)) {
								this.products[index].rateRatio = '-'
							}

							// this.products[index].rateRatio = (product.sell.total_price / (product.sell.total_price + product.buy.total_price)) * 100

							tempUsd = Decimal.add(tempUsd, product.total_trade)
							tempGram = Decimal.add(tempGram, product.total_gram)
						})

						this.totalTurnOver = {
							usd: Number(tempUsd),
							anotherCurrency: Number(Decimal.mul(tempUsd, this.streamingRate.stream_buy.cur_rate)),
							toz: Number(Decimal.mul(tempGram, this.grToTozMultiplier)),
							gram: Number(tempGram),
						}

						this.rrsClientStatus = 'success'
					}).catch(error => {
						if (error.response) {
							/*
							 * The request was made and the server responded with a
							 * status code that falls out of the range of 2xx
							 */
						} else if (error.request) {
							/*
							 * The request was made but no response was received, `error.request`
							 * is an instance of XMLHttpRequest in the browser and an instance
							 * of http.ClientRequest in Node.js
							 */
						} else {
							// Something happened in setting up the request and triggered an Error
						}

						this.rrsClientStatus = 'error'
					})		
				}else {
					this.rrsClientStatus = 'loading'
					this.products = []
					axios({
						method: 'get',
						url:process.env.VUE_APP_BACKEND_URL+'/rrs/ib/client?hin=' + this.hin + '&type=' + reportType + ' &startDate=' + startDate + '&endDate=' + endDate
						
					}).then(response => {
						let temp = response.data.data

						const trueContractIds = this.$store.state.trueContractIds

						temp.contract_micro.contractId = trueContractIds.contractId_Micro001GramTo1Gram
						temp.contract_100gr.contractId = trueContractIds.contractId_100g
						temp.contract_1kg.contractId = trueContractIds.contractId_1kg
						temp.contract_kau1gram.contractId = trueContractIds.contractId_KAU1Gram
						temp.contract_kau001gram.contractId = trueContractIds.contractId_KAU1GramTo001Gram

						for (let key in temp) {
							temp[key].name = this.$store.state.contractConfigs[temp[key].contractId].rrsName
						}
						
						this.goldOrderProp.forEach(item => {
							const property = Object.keys(temp).find(key => temp[key].contractId == item)
							this.products.push(temp[property])
						})

						let tempUsd = 0
						let tempGram = 0
						this.products.forEach((product, index) => {
							this.products[index].totalToz = Number(Decimal.mul(this.products[index].total_gram, this.grToTozMultiplier))
							this.products[index].sell.totalToz = Number(Decimal.mul(this.products[index].sell.total_gram, this.grToTozMultiplier))
							this.products[index].buy.totalToz = Number(Decimal.mul(this.products[index].buy.total_gram, this.grToTozMultiplier))

							this.products[index].rateRatio = Number(Decimal.div(product.sell.total_price, Decimal.add(product.sell.total_price, product.buy.total_price)).times(100).toFixed(2))

							tempUsd = Decimal.add(tempUsd, product.total_trade)
							tempGram = Decimal.add(tempGram, product.total_gram)
						})

						this.totalTurnOver = {
							usd: Number(tempUsd),
							anotherCurrency: Number(Decimal.mul(tempUsd, this.streamingRate.stream_buy.cur_rate)),
							toz: Number(Decimal.mul(tempGram, this.grToTozMultiplier)),
							gram: Number(tempGram),
						}

						this.rrsClientStatus = 'success'
					}).catch(error => {
						if (error.response) {
							/*
							 * The request was made and the server responded with a
							 * status code that falls out of the range of 2xx
							 */
						} else if (error.request) {
							/*
							 * The request was made but no response was received, `error.request`
							 * is an instance of XMLHttpRequest in the browser and an instance
							 * of http.ClientRequest in Node.js
							 */
						} else {
							// Something happened in setting up the request and triggered an Error
						}

						this.rrsClientStatus = 'error'
					})	
				}

			},

			changeFilterDateAndType(data) {
				this.selectedDateAndType = data
				this.setRrs()
			}

		}
	}
</script>
