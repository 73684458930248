export default {
  callingAPI: false,
  searching: '',
  serverURI: '',
  serverURL: 'http://0.0.0.0:80',
  backendURL: 'http://34.87.103.204:80/v1',
  captchaSiteKey: (process.env.NODE_ENV == 'development') ? '6LdJgMUkAAAAANwheJ3H2ZbQjesyanANH-i42yNm' : '6Le3EfckAAAAAJbr49YZeQy2dfpEm9NxS1NAvvyK',
  geolocation: JSON.parse(localStorage.getItem('geolocation')) || null,
  authInfo: JSON.parse(localStorage.getItem('authInfo')) || null,
  account: JSON.parse(localStorage.getItem('account')) || null,
  kinesisAuth: JSON.parse(localStorage.getItem('kinesisAuth')) || null,
  kinesisCookieSession: JSON.parse(localStorage.getItem('kinesisCookieSession')) || null,
  flagRegister: localStorage.getItem('flagRegister') || '1',
  tradingType: JSON.parse(localStorage.getItem('tradingType')) || 'jgx',
  balances: null,
  balanceIdr: 0,
  balanceUsd: 0,
  kinesisBalanceIdr: 0,
  kinesisBalanceUsd: 0,
  availableTradeBalanceIdr: 0,
  availableTradeBalanceUsd: 0,
  accountBalanceIdr: 0,
  accountBalance: 0,
  reservedBalanceIdr: 0,
  reservedBalanceUsd: 0,
  reservedBalance: 0,
  userId: localStorage.getItem('userId') || null,
  brokerId: localStorage.getItem('brokerId') || null,
  token: localStorage.getItem('token') || null,
  bankType: JSON.parse(localStorage.getItem('bankType')) || '-',
  status: null,
  contracts: null,
  depths: null,
  kinesisDepth: null,
  gold: null,
  golds: null,
  ohlc: null,
  products: [],
  orderInTradeTicket: null,
  timerBarStatus: false,
  streamingRate: JSON.parse(window.localStorage.getItem('streamingRate')) || null,
  currency: JSON.parse(localStorage.getItem('currency')) || 'idr', //usd || idr
  srcVideo: '',
  getDepositIsCooldown: false,
  endOfDayPrices: null,
  holdings: [],
  reservedGold: [],
  goldBalance: [],
  hubs: null,
  hub: JSON.parse(localStorage.getItem('hub')) || 'jakarta',
  hideBalance: localStorage.getItem('hideBalance') || false,
  // loginExpiredTime:
  // refreshTokenTime:

  isRefreshTokenFirstTime: true,
  reportToShow: window.localStorage.getItem('tabActiveReports') || 'orders', // orders || transactions || holdings || accountBalanceHistory

  // Api Status ==========================================================
  setContractsApiStatus: 'idle',
  setProductsApiStatus: 'idle',
  setAccountInformationApiStatus: 'idle',
  setDepthsApiStatus: 'idle',
  setStreamingRateApiStatus: 'idle',
  setBalancesApiStatus: 'idle',
  setGoldBalanceApiStatus: 'idle',

  setHoldingsApiStatus: 'idle',
  setEndOfDayPricesApiStatus: 'idle',

  marketInquiryStatus: 'idle',
  depositWithdrawalHistoryStatus: 'idle',
  reqWithdrawalStatus: 'idle',
  setBalanceStatus: 'idle',
  marketStatus: 'open',
  getDetailOrderStatus: 'idle',
  setContractsStatus: 'idle',
  getStreamingRate_apiStatus: 'idle',
  setStreamingRateStatus: 'idle',
  apiStatusRefreshToken: 'idle',

  getDetailOrderStatusMessage: '',

  // Swal status
  swalSessionExpiredOn: 'off', // off || on

  // userInfo: {
  //   messages: [
  //     {
  //       id: 1,
  //       title: 'Support Team',
  //       body: 'Why not consider this a test message?',
  //       createdAt: '17 min ago'
  //     }
  //   ],
  //   notifications: [
  //     {
  //       id: 1,
  //       title: 'Birthday Reminder',
  //       body: 'Today is Brians birthday.',
  //       createdAt: 'just now',
  //       readAt: null
  //     },
  //     {
  //       id: 2,
  //       title: 'Bank Holiday in London',
  //       body: 'Our office in London has a bank holiday today. Do not expect them to answer the phone.',
  //       createdAt: '4 hours ago',
  //       readAt: null
  //     },
  //     {
  //       id: 3,
  //       title: 'Birthday Reminder',
  //       body: 'Today is Christians birthday.',
  //       createdAt: '27 days ago',
  //       readAt: '2018-08-12 00:00:00'
  //     },
  //     {
  //       id: 4,
  //       title: 'Birthday Reminder',
  //       body: 'Today is Tanjas birthday.',
  //       createdAt: '29 days ago',
  //       readAt: '2018-08-12 00:00:00'
  //     },
  //     {
  //       id: 5,
  //       title: 'Sales Bonus received',
  //       body: 'You received your monthly sales bonus of 3%. This month you made $2,700 extra!',
  //       createdAt: '7 hours ago',
  //       readAt: null
  //     }
  //   ],
  //   tasks: []
  // }


  // Config ==============================================================

  // items to remove in state and local storage when logout
  itemsToRemove: [
    'geolocation', 'bankType', 'token', 'loginExpiredTime',
    'depths', 'gold', 'account', 'products', 'refreshTokenTime',
    'userId', 'brokerId', 'authInfo'],

  loginExpiredTimeState: 900000, //milisecond
  refreshTokenTimeState: 90000, //milisecond

  nextGetStreamingRate: window.localStorage.getItem('nextGetStreamingRate') || null,

  gramToTozMultiplier: 0.0321507, //1 gram = 0.0321507 troy ounce (from google)

  // this is the contract id corresponding to the true weight and to "fake" weight
  trueContractIds: {
    contractId_100g: process.env.NODE_ENV == 'development' ? 1 : 1,
    contractId_1kg: process.env.NODE_ENV == 'development' ? 3 : 2,
    contractId_Micro001GramTo1Gram: process.env.NODE_ENV == 'development' ? 5 : 3,
    contractId_KAU1GramTo001Gram: process.env.NODE_ENV == 'development' ? 7 : 4,
    // const contractId_KAU001GramTo1Gram: process.env.NODE_ENV == 'development' ? 8 : 5,
    contractId_KAU1Gram: process.env.NODE_ENV == 'development' ? 9 : 5,
  },

  // this is the contract id that will be used in the app
  contractIds: {
    contract_100g: process.env.NODE_ENV == 'development' ? 1 : 1,

    contract_1kg: process.env.NODE_ENV == 'development' ? 3 : 2,

    contract_1g: process.env.NODE_ENV == 'development' ? 9 : 5,

    contract_001g: process.env.NODE_ENV == 'development' ? 7 : 4,
  },

  get contractConfigs() {
    return {
      [this.trueContractIds.contractId_100g]: { // 100 gram
        contractStatus: 'active', // 'active' || 'disabled'

        weightInGram: 100,

        /*
        weightMultiplication -> this variable was made because contract 0.01 price 
        is count per 0.01 gram, unlike other contract that count per 1 gram.
        So when you want to get the contract price from "price per gram" 
        you need to multiply it with this variable, NOT weightInGram.
        */
        weightMultiplication: 100,

        //dividing weightInGram by weightCorrection will get the original weight
        weightCorrection: 1,

        //Number of digit behind decimal point for PRICE in DEPTH
        depthTTDecimalPlace: 2,

        // Number of digit behind decimal point for PRICE in TRADEBOX and TRADETICKET
        tbTtDecimalPlace: 2,

        //Number of digit behind decimal point for QUANTITY in DEPTH
        depthQuantityDecimalPlace: 5,

        //hasQuantityDecimal. If true, it will have quantity decimal in form.
        hasQuantityDecimal: false,

        contractType: 'igx',

        codeName: 'DG100',
        tradeTicketName: '100 g',
        name: '100 g non pool',
        tradeBoxName: '100 g non pool',
        rrsName: 'DG100 100 G NON POOL',

        // for Review Order button on Trade Ticket for this contract with this condition
        isReviewOrderButtonDisabledBuyMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledBuyLimit: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellLimit: process.env.NODE_ENV == 'development' ? false : false,

        ohlcId: this.trueContractIds.contractId_100g,
      },

      [this.trueContractIds.contractId_1kg]: { // 1kg
        contractStatus: 'disabled',

        weightInGram: 1000,
        weightMultiplication: 1000,
        weightCorrection: 1,
        depthTTDecimalPlace: 2,
        tbTtDecimalPlace: 2,
        depthQuantityDecimalPlace: 5,
        hasQuantityDecimal: false,

        contractType: 'igx',

        codeName: 'DG1K',
        tradeTicketName: '1 kg',
        name: '1 kg non pool',
        tradeBoxName: '1 kg non pool',
        rrsName: 'DG1K 1 KG NON POOL',

        isReviewOrderButtonDisabledBuyMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledBuyLimit: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellLimit: process.env.NODE_ENV == 'development' ? false : false,

        ohlcId: -1,
      },

      [this.trueContractIds.contractId_Micro001GramTo1Gram]: { // Micro 0.01 gram -> 1 gram
        contractStatus: 'active',

        weightInGram: 1,
        weightMultiplication: 1,
        weightCorrection: 100,
        depthTTDecimalPlace: 2,
        tbTtDecimalPlace: 2,
        depthQuantityDecimalPlace: 5,
        hasQuantityDecimal: false,

        contractType: 'igx',

        codeName: 'DG1',
        tradeTicketName: '1 g pool',
        name: '1 g pool',
        tradeBoxName: '1 g pool',
        rrsName: 'DG1 1 G POOL',

        isReviewOrderButtonDisabledBuyMarket: process.env.NODE_ENV == 'development' ? true : true,
        isReviewOrderButtonDisabledBuyLimit: process.env.NODE_ENV == 'development' ? true : true,
        isReviewOrderButtonDisabledSellMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellLimit: process.env.NODE_ENV == 'development' ? false : false,

        ohlcId: this.trueContractIds.contractId_Micro001GramTo1Gram,
      },

      [this.trueContractIds.contractId_KAU1GramTo001Gram]: { // KAU 1 gram -> 0.01 gram
        contractStatus: 'active',

        weightInGram: 0.01,
        weightMultiplication: 1,
        weightCorrection: 0.01,
        depthTTDecimalPlace: 4,
        tbTtDecimalPlace: 4,
        depthQuantityDecimalPlace: 5,
        hasQuantityDecimal: false,

        contractType: 'kau',

        codeName: 'DG001',
        tradeTicketName: '0.01 g pool',
        name: '0.01 g pool',
        tradeBoxName: '0.01 g pool',
        rrsName: 'DG001 0.01 G POOL',

        isReviewOrderButtonDisabledBuyMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledBuyLimit: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellLimit: process.env.NODE_ENV == 'development' ? false : false,

        ohlcId: this.trueContractIds.contractId_KAU1GramTo001Gram,
      },

      // [this.trueContractIds.contractId_KAU001GramTo1Gram]: { // KAU 0.01 gram -> 1 gram
      // 	contractStatus: 'active',

      // 	weightInGram: 1,
      // 	weightMultiplication: 1,
      // 	weightCorrection: 100,
      // 	depthTTDecimalPlace: 2,
      // 	tbTtDecimalPlace: 2,
      // 	depthQuantityDecimalPlace: 5,
      // 	hasQuantityDecimal: false,

      // contractType: 'kau',

      // 	codeName: 'DG1',
      // 	tradeTicketName: '1 g pool',
      // 	name: '1 g pool',
      // 	tradeBoxName: '1 g pool',
      // 	rrsName: 'DG1 1 G POOL',

      // isReviewOrderButtonDisabledBuyMarket: process.env.NODE_ENV == 'development' ? false : false,
      // isReviewOrderButtonDisabledBuyLimit: process.env.NODE_ENV == 'development' ? false : false,
      // isReviewOrderButtonDisabledSellMarket: process.env.NODE_ENV == 'development' ? false : false,
      // isReviewOrderButtonDisabledSellLimit: process.env.NODE_ENV == 'development' ? false : false,

      // 	ohlcId: this.trueContractIds.contractId_KAU001GramTo1Gram,
      // },

      [this.trueContractIds.contractId_KAU1Gram]: { // KAU 1 gram
        contractStatus: 'active',

        weightInGram: 1,
        weightMultiplication: 1,
        weightCorrection: 1,
        depthTTDecimalPlace: 2,
        tbTtDecimalPlace: 2,
        depthQuantityDecimalPlace: 5,
        hasQuantityDecimal: false,

        contractType: 'kau',

        codeName: 'DG1',
        tradeTicketName: '1 g pool',
        name: '1 g pool',
        tradeBoxName: '1 g pool',
        rrsName: 'DG1 1 G POOL',

        isReviewOrderButtonDisabledBuyMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledBuyLimit: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellMarket: process.env.NODE_ENV == 'development' ? false : false,
        isReviewOrderButtonDisabledSellLimit: process.env.NODE_ENV == 'development' ? false : false,

        ohlcId: this.trueContractIds.contractId_KAU1Gram,
      },
    }
  },

  // fee and tax
  fee: 0.01, // 1% of value
  feeMin: 0.036,
  tax: 0.11, // 11% of fee
  taxMin: 0.004,

  feeTaxMultiplier: 0.0111, // 1.11% of value
  feeTaxMin: 0.04, // $0.04
  totalIdrMin: 50000, // if total idr less than this, then (fee + tax = $0.04) Or (fee = $0.036 and tax = $0.004)

  // fx buffer
  fxBuffer: 0.05,
  fxBufferMin: 0.01,

  // percent away from current price. For order limit.
  minMaxLimitPriceKAU: 0.1, // 10 percent from price per gram
  minMaxLimitPriceIGX: 0.15, // 15 percent from price per gram

  percentRevenueFm: 0.25, // 
  percentRevenueIb: 0.35, // 

  get filterContract() {
    return [
      {
        contractId: this.contractIds.contract_001g,
        name: '0.01 g',
        checked: true,
      },
      {
        contractId: this.contractIds.contract_1g,
        name: '1 g',
        checked: true,
      },
      {
        contractId: this.contractIds.contract_100g,
        name: '100 g',
        checked: true,
      },
      {
        contractId: this.contractIds.contract_1kg,
        name: '1 kg',
        checked: true,
      },
    ]
  },

  // Default order of gold shown in RRS 
  get goldOrderRrs() {
    return [this.contractIds.contract_001g, this.contractIds.contract_1g, this.contractIds.contract_100g, this.contractIds.contract_1kg]
  },

  get reservedDeliveryGoldOrder() {
    return [
      {
        contractId: this.contractIds.contract_001g,
        name: '0.01 g pool'
      },
      {
        contractId: this.contractIds.contract_1g,
        name: '1 g pool'
      },
      {
        contractId: this.contractIds.contract_100g,
        name: '100 g non pool'
      },
      {
        contractId: this.contractIds.contract_1kg,
        name: '1 kg non pool'
      },
    ]
  },

  // default sequence of gold in Transfer Bullion Withdrawal
  get bullionWithdrawalSequence() {
    return [
      this.contractIds.contract_001g,
      this.contractIds.contract_1g,
      this.contractIds.contract_100g,
      this.contractIds.contract_1kg
    ]
  },

  get holdingsSequence() {
    return [
      this.contractIds.contract_001g,
      this.contractIds.contract_1g,
      this.contractIds.contract_100g,
      this.contractIds.contract_1kg
    ]
  },

  // for development
  pnlEtc: {},
}
