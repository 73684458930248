<template>
	<nav v-if="maxPage_prop > 0" :aria-label="paginationName_prop">
		<ul class="pagination">
			<li class="page-item">
				<a class="page-link" aria-label="Previous" @click="previous()">
					<span aria-hidden="true">&#10094;</span>
				</a>
			</li>
			<li v-for="n in maxPage_prop" :key="n" :class="{'page-item': true, active: n == selected}">
				<a class="page-link" @click="klik(n)">{{n}}<span v-if="n == selected" class="sr-only">(current)</span></a>
			</li>
			<li class="page-item">
				<a class="page-link" aria-label="Next" @click="next()">
					<span aria-hidden="true">&#10095;</span>
				</a>
			</li>
			<li v-if="status_prop != 'idle'">
				<Loader
					size_prop="2.5em"
					borderSize_prop="5px"
				></Loader>
			</li>
		</ul>
	</nav>
</template>

<script>
	import Loader from '@/components/Loader.vue'

	export default {
		name: 'Pagination',
		props: {
			paginationName_prop: {
				type: String,
				default: 'pagination'
			},
			maxPage_prop: {
				type: Number,
				default: 9
			},
			status_prop: {
				type: String,
				default: 'idle'
			}
		},

		components: {
			Loader
		},

		data() {
			return {
				selected: 1
			}
		},
		watch: {
			selected(newValue) {
				this.$emit('changePage', newValue-1)
			}
		},
		methods: {
			klik(selectedValue) {
				this.selected = selectedValue
			},
			previous() {
				let temp = this.selected
				temp--
				if (temp < 1) {
					this.selected = 1
				}else {
					this.selected = temp
				} 
			},
			next() {
				let temp = this.selected
				temp++
				if (temp > this.maxPage_prop) {
					this.selected = this.maxPage_prop
				}else {
					this.selected = temp
				} 
			}
		}
	}
</script>