<template>
	<div>
		<h4 class="text-center">RECONCILE REPORTING SYSTEM</h4>

		<div class="row">

			<div class="col-auto mb-2 text-left">
				<FilterClient @changeFilterClient="changeFilterClient" filterId="ccp" :clients_prop="clients">
				</FilterClient>
			</div>

			<div v-show="tableView != 'client'" class="col-auto mb-2 text-left">
				<FilterContract class="d-inline-block" filterId="ccp" @changeFilterContract="changeFilterContract">
				</FilterContract>
			</div>

			<div class="col-auto mb-2 text-left">
				<FilterDateAndType class="d-inline-block" @changeFilterDateAndType="changeFilterDateAndType">
				</FilterDateAndType>
			</div>

			<div class="col-auto mb-2">
				<ExportAsButton class="d-inline-block">
				</ExportAsButton>
			</div>

		</div>

		<div class="row">
			<div class="col-12">
				<TopSection></TopSection>
			</div>
		</div>

		<div class="row">
			<div v-if="tableView == 'all'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">ACCOUNT NAME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> (USD) </th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="(rrsStatus != 'loading')">
							<tr v-for="(item, index) in rrsData" class="c-row-table cursor-pointer text-right" :key="item.fm._id"
								@click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ item.fm.personal_data[0].full_name }}</td>
								<td>{{ item.fm.hin_abx }}</td>
								<td>{{ item.gold.buy.total_price }}</td>
								<td>{{ item.gold.buy.totalToz }}</td>
								<td>{{ item.gold.buy.total_gram }}</td>
								<td>{{ item.gold.sell.total_price }}</td>
								<td>{{ item.gold.sell.totalToz }}</td>
								<td>{{ item.gold.sell.total_gram }}</td>
								<td>{{ item.gold.total_trade }}</td>
								<td>{{ item.gold.totalToz }}</td>
								<td>{{ item.gold.total_gram }}</td>
								<td class="text-center">{{ item.gold.rateRatio }}</td>
							</tr>
							<tr v-if="rrsData.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>

			<div v-if="tableView == 'ib' || tableView == 'fm'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">ACCOUNT NAME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="(rrsStatus != 'loading')">
							<tr v-for="(item, index) in rrsDataIb" class="c-row-table cursor-pointer text-right"
								:key="tableView == 'ib' ? item.identity._id : item.ib._id" @click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ tableView == 'ib' ? item.identity.personal_data[0].full_name :
									item.ib.personal_data[0].full_name }}</td>
								<td>{{ tableView == 'ib' ? item.identity.hin_abx : item.ib.hin_abx }}</td>
								<td>{{ item.gold.buy.total_price }}</td>
								<td>{{ item.gold.buy.totalToz }}</td>
								<td>{{ item.gold.buy.total_gram }}</td>
								<td>{{ item.gold.sell.total_price }}</td>
								<td>{{ item.gold.sell.totalToz }}</td>
								<td>{{ item.gold.sell.total_gram }}</td>
								<td>{{ item.gold.total_trade }}</td>
								<td>{{ item.gold.totalToz }}</td>
								<td>{{ item.gold.total_gram }}</td>
								<td class="text-center">{{ item.gold.rateRatio }}</td>
							</tr>
							<tr v-if="rrsDataIb.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>

			<div v-if="tableView == 'client' || tableView == 'lp'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">PRODUCT</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">$</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="(rrsStatus != 'loading')">
							<tr v-for="(item, index) in clientData" class="c-row-table cursor-pointer text-right" :key="item.name"
								@click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ item.name }}</td>
								<td>{{ selectedClient.hin_abx }}</td>
								<td>{{ item.buy.total_price }}</td>
								<td>{{ item.buy.totalToz }}</td>
								<td>{{ item.buy.total_gram }}</td>
								<td>{{ item.sell.total_price }}</td>
								<td>{{ item.sell.totalToz }}</td>
								<td>{{ item.sell.total_gram }}</td>
								<td>{{ item.total_trade }}</td>
								<td>{{ item.totalToz }}</td>
								<td>{{ item.total_gram }}</td>
								<td class="text-center">{{ item.rateRatio }}</td>
							</tr>
							<tr v-if="clientData.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
import FilterClient from '@/components/FilterClient.vue'
import FilterContract from '@/components/FilterContract.vue'
import FilterDateAndType from '@/components/FilterDateAndType.vue'
import ExportAsButton from '@/components/ExportAsButton.vue'
import TopSection from '@/components/reconcile-reporting-system/TopSection.vue'

export default {
	name: 'CentralCounterParty',

	props: {
		goldOrderProp: Array
	},

	components: {
		Loader,
		FilterClient,
		FilterContract,
		FilterDateAndType,
		ExportAsButton,
		TopSection
	},

	data() {
		const today = new Date(Date.now())
		let todayDay = today.getDay()
		let tempDay = new Date(Date.now())
		let tempWeek = new Date(Date.now())
		let tempMonth = new Date(Date.now())
		let tempYear = new Date(Date.now())
		return {
			clientsStatus: 'loading',
			clients: [],
			clientsFilterIb: '',

			grToTozMultiplier: 0.0321507,

			rrsClientStatus: 'loading',
			products: [],

			rrsStatus: 'loading',
			rrsData: [],
			rrsDataIb: [],

			totalTurnOver: {
				usd: 0,
				anotherCurrency: 0,
				toz: 0,
				gram: 0
			},

			reportType: 'day',
			reportMonth: 'january',
			reportYear: '2019',

			startDateDay: new Date(tempDay.setDate(tempDay.getDate() - 1)),
			endDate: `${tempDay.getFullYear()}-${tempDay.getMonth() + 1 < 10 ? '0' : ''}${tempDay.getMonth() + 1}-${tempDay.getDate() + 1 < 10 ? '0' : ''}${tempDay.getDate() + 1}`,

			startDateWeek: new Date(tempWeek.setDate(tempWeek.getDate() - todayDay + 1)),
			weeklyDateList: [],

			startDateMonth: new Date(tempMonth.setDate(1)),
			monthlyDateList: [],

			startDateYear: new Date(new Date(tempYear.setDate(1)).setMonth(0)),
			yearlyDateList: [],

			tableView: 'all',

			selectedClient: 'all',

			selectedContracts: [],

			selectedDateAndType: {
				type: 'all',
				startDate: '',
				endDate: ''
			},

			clientData: [],

			userAccess: '-'
		}
	},

	computed: {
		// watcherChangeFilter() {
		// 	return `${this.startDateDay}${this.startDateWeek}${this.startDateMonth}${this.startDateYear}${this.endDate}`
		// },

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		productsGlobal() {
			let products = this.$store.getters.PRODUCTS
			return products
		},

		startDateDayInInput: {
			get: function () {
				let temp = this.startDateDay
				return `${temp.getFullYear()}-${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}-${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}`
			},
			set: function (newValue) {
				this.startDateDay = new Date(newValue)
			}
		},

		startDateWeekInInput: {
			get: function () {
				let temp = this.startDateWeek
				if (temp) {
					return `${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateWeek = temp
			}
		},

		startDateMonthInInput: {
			get: function () {
				let temp = this.startDateMonth
				if (temp) {
					return `01/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateMonth = temp
			}
		},

		startDateYearInInput: {
			get: function () {
				let temp = this.startDateYear
				if (temp) {
					return `01/01/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				if (newValue) {
					let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
					this.startDateYear = temp
				}
			}
		},
	},

	watch: {
		// watcherChangeFilter() {
		// 	this.changeFilter()
		// },
	},

	created() {
		const firstCall = setInterval(() => {
			if (this.productsGlobal) {
				clearInterval(firstCall)
				axios({
					method: 'get',
					url: process.env.VUE_APP_BACKEND_URL + '/rrs/ccp/list/branch'
				}).then(response => {
					this.clients = response.data.data
				}).catch(error => {
					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
				})

				this.setRrs()
			}
		}, 1000)
	},

	methods: {
		setRrs() {
			let selectedContracts = this.selectedContracts
			let contractsParam = ''

			if (selectedContracts.length > 0) {
				selectedContracts.forEach((item) => {
					contractsParam = contractsParam + `contractId[]=${item}&`
				})
			}

			if (contractsParam == '') {
				contractsParam = 'contractId[]=&'
			}

			let reportType = this.selectedDateAndType.type
			let startDate = this.selectedDateAndType.startDate
			let endDate = this.selectedDateAndType.endDate

			if (reportType == 'all') {
				reportType = ''
			}

			if (startDate) {
				startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
			}

			if (endDate) {
				endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? '0' : ''}${endDate.getMonth() + 1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`
			}

			if (this.tableView == 'all') {
				this.rrsStatus = 'loading'
				axios({
					method: 'get',
					url: process.env.VUE_APP_BACKEND_URL + '/rrs/ccp?' + contractsParam + 'type=' + reportType + '&startDate=' + startDate + '&endDate=' + endDate
				}).then(response => {
					this.rrsData = []
					this.totalTurnOver = {
						usd: 0,
						anotherCurrency: 0,
						toz: 0,
						gram: 0
					}

					if (response.data.data[0].fm.hin_abx) {
						this.rrsData = response.data.data

						let tempUsd = 0
						let tempGram = 0
						this.rrsData.forEach((element, index) => {
							this.rrsData[index].gold.buy.total_price = Number((this.rrsData[index].gold.buy.total_price).toFixed(2))
							this.rrsData[index].gold.buy.totalToz = Number((Number(Decimal.mul(element.gold.buy.total_gram, this.grToTozMultiplier))).toFixed(2))
							this.rrsData[index].gold.buy.total_gram = Number((this.rrsData[index].gold.buy.total_gram).toFixed(2))

							this.rrsData[index].gold.sell.total_price = Number((this.rrsData[index].gold.sell.total_price).toFixed(2))
							this.rrsData[index].gold.sell.totalToz = Number((Number(Decimal.mul(element.gold.sell.total_gram, this.grToTozMultiplier))).toFixed(2))
							this.rrsData[index].gold.sell.total_gram = Number((this.rrsData[index].gold.sell.total_gram).toFixed(2))

							this.rrsData[index].gold.total_trade = Number((this.rrsData[index].gold.total_trade).toFixed(2))
							this.rrsData[index].gold.totalToz = Number((Number(Decimal.mul(element.gold.total_gram, this.grToTozMultiplier))).toFixed(2))
							this.rrsData[index].gold.total_gram = Number((this.rrsData[index].gold.total_gram).toFixed(2))

							this.rrsData[index].gold.rateRatio = Number(((element.gold.sell.total_price / (element.gold.sell.total_price + element.gold.buy.total_price)) * 100).toFixed(2))
							if (isNaN(this.rrsData[index].gold.rateRatio)) {

								this.rrsData[index].gold.rateRatio = '-'

							}

							tempUsd += element.gold.total_trade
							tempGram += element.gold.total_gram
						})

						this.totalTurnOver = {
							usd: Number((tempUsd).toFixed(2)),
							anotherCurrency: Number((tempUsd * this.streamingRate.stream_buy.cur_rate).toFixed(2)),
							toz: Number((Number(Decimal.mul(tempGram, this.grToTozMultiplier))).toFixed(2)),
							gram: Number(tempGram.toFixed(2)),
						}
					}

					this.rrsStatus = 'success'
				}).catch(error => {
					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */

						if (error.response.data.message == "There are no clients under FM!") {
							this.rrsDataIb = []
							this.totalTurnOver = {
								usd: 0,
								anotherCurrency: 0,
								toz: 0,
								gram: 0
							}
						}

					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
					this.rrsStatus = 'error'
				})
			} else {
				this.rrsStatus = 'loading'
				axios({
					method: 'get',
					url: process.env.VUE_APP_BACKEND_URL + '/rrs/ccp/get/branch?hin=' + this.selectedClient.hin_abx + '&' + contractsParam + 'type=' + reportType + '&startDate=' + startDate + '&endDate=' + endDate
				}).then(response => {
					let temp = response.data.data
					this.clientData = []
					if (this.tableView == 'client' || this.tableView == 'lp') {
						this.totalTurnOver = {
							usd: 0,
							anotherCurrency: 0,
							toz: 0,
							gram: 0
						}

						const trueContractIds = this.$store.state.trueContractIds

						temp.contract_micro.contractId = trueContractIds.contractId_Micro001GramTo1Gram
						temp.contract_100gr.contractId = trueContractIds.contractId_100g
						temp.contract_1kg.contractId = trueContractIds.contractId_1kg
						temp.contract_kau1gram.contractId = trueContractIds.contractId_KAU1Gram
						temp.contract_kau001gram.contractId = trueContractIds.contractId_KAU1GramTo001Gram

						for (let key in temp) {
							temp[key].name = this.$store.state.contractConfigs[temp[key].contractId].rrsName
						}
						
						this.goldOrderProp.forEach(item => {
							const property = Object.keys(temp).find(key => temp[key].contractId == item)
							this.clientData.push(temp[property])
						})

						let tempUsd = 0
						let tempGram = 0
						this.clientData.forEach((element, index) => {

							this.clientData[index].buy.total_price = Number((this.clientData[index].buy.total_price).toFixed(2))
							this.clientData[index].buy.totalToz = Number((Number(Decimal.mul(element.buy.total_gram, this.grToTozMultiplier))).toFixed(2))
							this.clientData[index].buy.total_gram = Number((this.clientData[index].buy.total_gram).toFixed(2))

							this.clientData[index].sell.total_price = Number((this.clientData[index].sell.total_price).toFixed(2))
							this.clientData[index].sell.totalToz = Number((Number(Decimal.mul(element.sell.total_gram, this.grToTozMultiplier))).toFixed(2))
							this.clientData[index].sell.total_gram = Number((this.clientData[index].sell.total_gram).toFixed(2))

							this.clientData[index].total_trade = Number((this.clientData[index].total_trade).toFixed(2))
							this.clientData[index].totalToz = Number((Number(Decimal.mul(element.total_gram, this.grToTozMultiplier))).toFixed(2))
							this.clientData[index].total_gram = Number((this.clientData[index].total_gram).toFixed(2))

							this.clientData[index].rateRatio = Number(((element.sell.total_price / (element.sell.total_price + element.buy.total_price)) * 100).toFixed(2))
							if (isNaN(this.clientData[index].rateRatio)) {
								this.clientData[index].rateRatio = '-'
							}

							tempUsd += element.total_trade
							tempGram += element.total_gram
						})

						this.totalTurnOver = {
							usd: Number((tempUsd).toFixed(2)),
							anotherCurrency: Number((tempUsd * this.streamingRate.stream_buy.cur_rate).toFixed(2)),
							toz: Number((Number(Decimal.mul(tempGram, this.grToTozMultiplier))).toFixed(2)),
							gram: Number((tempGram).toFixed(2)),
						}
					} else {
						this.rrsDataIb = []
						this.totalTurnOver = {
							usd: 0,
							anotherCurrency: 0,
							toz: 0,
							gram: 0
						}

						let hinAbx
						if (response.data.data[0].hasOwnProperty('identity')) { // Login as FM
							hinAbx = response.data.data[0].identity.hin_abx
						} else { // login as CCP
							hinAbx = response.data.data[0].ib.hin_abx
						}

						if (hinAbx) {
							this.rrsDataIb = response.data.data

							let tempUsd = 0
							let tempGram = 0
							this.rrsDataIb.forEach((element, index) => {
								this.rrsDataIb[index].gold.buy.total_price = Number((this.rrsDataIb[index].gold.buy.total_price).toFixed(2))
								this.rrsDataIb[index].gold.buy.totalToz = Number((Number(Decimal.mul(element.gold.buy.total_gram, this.grToTozMultiplier))).toFixed(2))
								this.rrsDataIb[index].gold.buy.total_gram = Number((this.rrsDataIb[index].gold.buy.total_gram).toFixed(2))

								this.rrsDataIb[index].gold.sell.total_price = Number((this.rrsDataIb[index].gold.sell.total_price).toFixed(2))
								this.rrsDataIb[index].gold.sell.totalToz = Number((Number(Decimal.mul(element.gold.sell.total_gram, this.grToTozMultiplier))).toFixed(2))
								this.rrsDataIb[index].gold.sell.total_gram = Number((this.rrsDataIb[index].gold.sell.total_gram).toFixed(2))

								this.rrsDataIb[index].gold.total_trade = Number((this.rrsDataIb[index].gold.total_trade).toFixed(2))
								this.rrsDataIb[index].gold.totalToz = Number((Number(Decimal.mul(element.gold.total_gram, this.grToTozMultiplier))).toFixed(2))
								this.rrsDataIb[index].gold.total_gram = Number((this.rrsDataIb[index].gold.total_gram).toFixed(2))

								this.rrsDataIb[index].gold.rateRatio = Number(((element.gold.sell.total_price / (element.gold.sell.total_price + element.gold.buy.total_price)) * 100).toFixed(2))
								if (isNaN(this.rrsDataIb[index].gold.rateRatio)) {
									this.rrsDataIb[index].gold.rateRatio = '-'
								}

								tempUsd += element.gold.total_trade
								tempGram += element.gold.total_gram
							})

							this.totalTurnOver = {
								usd: Number((tempUsd).toFixed(2)),
								anotherCurrency: Number((tempUsd * this.streamingRate.stream_buy.cur_rate).toFixed(2)),
								toz: Number((Number(Decimal.mul(tempGram, this.grToTozMultiplier))).toFixed(2)),
								gram: Number(tempGram.toFixed(2)),
							}
						}
					}

					this.rrsStatus = 'success'
				}).catch(error => {
					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */

						if (error.response.data.message == "There are no clients under IB!") {
							this.rrsDataIb = []
							this.totalTurnOver = {
								usd: 0,
								anotherCurrency: 0,
								toz: 0,
								gram: 0
							}
						}

					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
					this.rrsStatus = 'error'
				})

			}
		},

		changeFilterClient(clientData) {
			if (clientData != 'all') {
				const template = { IB: 'ib', PB: 'fm', LP: 'lp', PC: 'client' }
				this.tableView = template[clientData.hin_abx.substr(0, 2)]
			} else {
				this.tableView = 'all'
			}

			this.userAccess = clientData.hin_abx

			this.selectedClient = clientData
			this.setRrs()
		},

		changeFilterContract(selectedContracts) {
			this.selectedContracts = selectedContracts
			this.setRrs()
		},

		changeFilterDateAndType(data) {
			this.selectedDateAndType = data
			this.setRrs()
		},

		clearStartDate(value) {
			if (value == 'week') {
				this.startDateWeek = ''
			} else if (value == 'month') {
				this.startDateMonth = ''
			} else if (value == 'year') {
				this.startDateYear = ''
			}
		}
	}
}
</script>
