<template>
	<div>
		<!-- <nav class="navbar navbar-expand-lg justify-content-end bg-light">
				<button class="btn c-btn-theme my-2 my-sm-0" type="submit">DOWNLOAD</button>
		</nav> -->

			<TradeConfirmation></TradeConfirmation>
	</div>
</template>

<script>
	import TradeConfirmation from "@/components/pdf/TradeConfirmation.vue"

	export default {
		name: 'Pdf',

		components: {
			TradeConfirmation,
		},

		data () {
			return {

			}
		}
	}
</script>

<style lang="css" scoped>
</style>