<template>
	<div class="content">
		<!-- TradingView Widget BEGIN -->
		<div class="tradingview-widget-container" ref="tradingChart">
			<div id="tradingview_9fa6b"></div>
			<div class="tradingview-widget-copyright">
				<a :href="`https://www.tradingview.com/symbols/${ defaultSymbolAnchor_prop }/`" rel="noopener" target="_blank">
					<span class="blue-text">{{ showTitle_prop ? title_prop : '' }}</span>
				</a>{{ showTitle_prop ? subTitle_prop : '' }}
			</div>
		</div>
		<!-- TradingView Widget END -->
	</div>
</template>

<script>
	export default {
		name: 'TradingChart',
		props: {
			chart_width: {
				type: String,
				default: "1100"
			},
			chart_height: {
				type: String,
				default: "580"
			},
			showTitle_prop: {
				type: Boolean,
				default: true
			},
			title_prop: {
				type: String,
				default: 'XAUUSD Chart'
			},
			subTitle_prop: {
				type: String,
				default: ' by TradingView'
			},
			defaultSymbolAnchor_prop: {
				type: String,
				default: "FX_IDC-XAUUSD"
			},
			defaultSymbol_prop: {
				type: String,
				default: "FX_IDC:XAUUSD"
			},
		},
		data() {
			return {
				tradingChartID: ''
			}
		},

		computed: {
		},

		mounted() {
			const c_width = this.chart_width
			const c_height = this.chart_height
			const c_symbol = this.defaultSymbol_prop

			let tagReference2 = document.createElement('script')
			tagReference2.setAttribute('type', 'text/javascript')
			tagReference2.setAttribute('src', 'https://s3.tradingview.com/tv.js')
			this.$refs.tradingChart.appendChild(tagReference2)

			let tagReference3 = document.createElement('script')
			tagReference3.setAttribute('type', 'text/javascript')
			setTimeout(function() {
				tagReference3.innerHTML = `
					new TradingView.widget(
						{
							"width": "${c_width}",
							"height": "${c_height}",
							"symbol": "${c_symbol}",
							"interval": "D",
							"timezone": "Asia/Jakarta",
							"theme": "Dark",
							"style": "1",
							"locale": "en",
							"toolbar_bg": "#f1f3f6",
							"enable_publishing": false,
							"allow_symbol_change": true,
							"container_id": "tradingview_9fa6b"
						}
					)
				`
			},1000)
			this.$refs.tradingChart.appendChild(tagReference3)
		}
	}
</script>

<!-- <script type="text/javascript" src="https://s3.tradingview.com/external-embedding/embed-widget-events.js" async>
{
	"colorTheme": "light",
	"isTransparent": false,
	"width": "510",
	"height": "600",
	"locale": "en",
	"importanceFilter": "-1,0,1"
}
</script> -->