<template>
	<div class="content">
		<div class="row">
			<div class="col-sm-12 col-md-12 col-lg-4">
				<div class="card card-tasks" style="height: 550px">
					<div class="card-header">
						<h3 class="title d-inline" style="color: gold">Gold</h3>
					</div>
					<div class="card-body">
						<div class="table-full-width table-responsive" style="height: 480px; max-height: 1000px;">
							<table class="table" id="scrollbar1">
								<tbody>
									<tr v-for="item in goldNews" :key="item.title">
										<td>
											<p class="title news-title"><a :href="item.url" target="_blank">{{item.title}}</a></p>
											<!-- <p class="text-muted">{{item.description}}</p> -->
											<p class="text-muted">{{item.pubDate}}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 col-lg-4">
				<div class="card card-tasks" style="height: 550px">
					<div class="card-header">
						<h3 class="title d-inline" style="color: #a190cd">Global Market</h3>
					</div>
					<div class="card-body">
						<div class="table-full-width table-responsive" style="height: 480px; max-height: 1000px;" id="scrollbar2">
							<table class="table">
								<tbody>
									<tr v-for="item in globalNews" :key="item.title">
										<td>
											<p class="title news-title"><a :href="item.url" target="_blank">{{item.title}}</a></p>
											<!-- <p class="text-muted">{{item.description}}</p> -->
											<p class="text-muted">{{item.pubDate}}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-6 col-lg-4">
				<div class="card card-tasks" style="height: 550px">
					<div class="card-header">
						<h3 class="title d-inline" style="color: silver">Domestic Market</h3>
					</div>
					<div class="card-body">
						<div class="table-full-width table-responsive" style="height: 480px; max-height: 1000px;" id="scrollbar3">
							<table class="table">
								<tbody>
									<tr v-for="item in domesticNews" :key="item.title">
										<td>
											<p class="title news-title"><a :href="item.url" target="_blank">{{item.title}}</a></p>
											<!-- <p class="text-muted">{{item.description}}</p> -->
											<p class="text-muted">{{item.pubDate}}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-sm-12 col-md-12 col-lg-4" v-for="(item1, index) in news" :key="index">
				<div class="card card-tasks" style="height: 550px">
					<div class="card-header">
						<h3 class="title d-inline" style="color: white">{{item1.name}}</h3>
					</div>
					<div class="card-body">
						<div class="table-full-width table-responsive" style="height: 480px; max-height: 1000px;" id="scrollbar1">
							<table class="table">
								<tbody>
									<tr v-for="item2 in item1.news" :key="item2.link">
										<td>
											<p class="title"><a @click="goTo(item2.url)">{{item2.title}}</a></p>
											<p class="text-muted">{{item2.description}}</p>
											<p class="text-muted">{{item2.pubDate}}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	// import PerfectScrollbar from 'perfect-scrollbar'
	// import Feed from 'rss-to-json'

	export default {
		name: 'News',
		data() {
			return {
				goldNews: [],
				globalNews: [],
				domesticNews: [],
			}
		},
		created() {
			// Feed.load(`${process.env.VUE_APP_PROXY}/https://www.investing.com/rss/news_11.rss`, (err, rss) => {
			// 	this.goldNews = rss.items
			// })
			// Feed.load(`${process.env.VUE_APP_PROXY}/https://www.investing.com/rss/news_285.rss`, (err, rss) => {
			// 	this.globalNews = rss.items
			// })
			// Feed.load(`${process.env.VUE_APP_PROXY}/https://id.investing.com/rss/news_11.rss`, (err, rss) => {
			// 	this.domesticNews = rss.items
			// })
			fetch(`${process.env.VUE_APP_PROXY}/https://www.investing.com/rss/news_11.rss`)
			  .then(response => response.text())
			  .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
			  .then(data => {
			    const items = data.querySelectorAll("item")
			    items.forEach(el => {
			      this.goldNews.push ({
			        title: el.querySelector("title").innerHTML,
			        url: el.querySelector("link").innerHTML,
			        description: el.querySelector("title").innerHTML,
			        pubDate: el.querySelector("pubDate").innerHTML
			      })
			    })
			  })

			fetch(`${process.env.VUE_APP_PROXY}/https://www.investing.com/rss/news_285.rss`)
			  .then(response => response.text())
			  .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
			  .then(data => {
			    const items = data.querySelectorAll("item")
			    items.forEach(el => {
			      this.globalNews.push ({
			        title: el.querySelector("title").innerHTML,
			        url: el.querySelector("link").innerHTML,
			        description: el.querySelector("title").innerHTML,
			        pubDate: el.querySelector("pubDate").innerHTML
			      })
			    })
			  })

			fetch(`${process.env.VUE_APP_BACKEND_URL}/get/rss/news/domestic_news.rss`)
			  .then(response => response.text())
			  .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
			  .then(data => {
			    const items = data.querySelectorAll("item")
			    items.forEach(el => {
			      this.domesticNews.push ({
			        title: el.querySelector("title").innerHTML,
			        url: el.querySelector("link").innerHTML,
			        description: el.querySelector("title").innerHTML,
			        pubDate: el.querySelector("pubDate").innerHTML
			      })
			    })
			  })
			// let rssUrls = [
			// 		{
			// 			name: 'Commodities & Futures News',
			// 			url: '/v2https://www.investing.com/rss/news_11.rss'
			// 		},
			// 		{
			// 			name: 'Economic Indicators News',
			// 			url: '/v2https://www.investing.com/rss/news_95.rss'
			// 		},
			// 		{
			// 			name: 'Forex News',
			// 			url: '/v2https://www.investing.com/rss/news_1.rss'
			// 		},
			// 		{
			// 			name: 'Stock Market News',
			// 			url: '/v2https://www.investing.com/rss/news_25.rss'
			// 		},
			// 		{
			// 			name: 'Economy News',
			// 			url: '/v2https://www.investing.com/rss/news_14.rss'
			// 		},
			// 		{
			// 			name: 'World News',
			// 			url: '/v2https://www.investing.com/rss/news_287.rss'
			// 		},
			// 	]
			// // Feed.load('https://cors-anywhere.herokuapp.com/https://www.investing.com/rss/news_11.rss', function(err, rss){
			// // });
			// let rssOrder = ['Commodities & Futures', 'Economic Indicators', 'Forex', 'Stock Market', 'Economy', 'World']
			// for (var j = 0; j < rssOrder.length; j++) {
			// 	for (var i = 0; i < rssUrls.length; i++) {
			// 		if (rssUrls[i].name.includes(rssOrder[j])) {
			// 			Feed.load(`https://cors-anywhere.herokuapp.com/${rssUrls[i].url}`, (err, rss) => {
			// 				this.news.push({
			// 					name: rss.title,
			// 					news: rss.items
			// 				})
			// 			})
			// 			rssUrls.splice(i, 1)
	 //					break
			// 		}
			// 	}
			// }
		},
		mounted() {
			// const ps1 = new PerfectScrollbar('#scrollbar1', {
			// 	wheelPropagation: false
			// })
			// const ps2 = new PerfectScrollbar('#scrollbar2', {
			// 	wheelPropagation: false
			// })
			// const ps3 = new PerfectScrollbar('#scrollbar3', {
			// 	wheelPropagation: false
			// })
			// ps1.update()
			// ps2.update()
			// ps3.update()
		},
		methods: {
			goTo(url) {
				// window.open(url, '_blank')
				window.location.assign(url)
			}
		}
	}
</script>

<style scoped>
	.news-title a {
		color: rgba(255, 255, 255, 0.8);
	}
	.news-title:hover {
		/*cursor: pointer;*/
		text-decoration: underline;
	}
</style>
