<template>
	<div>
		<div id="openModal" :class="modalClass">
			<div class="margin-top-60">
				<div class="flex flex--justify-content-between flex--align-items-center gap-10 color-white-c">
					<div class="fs-24-c flex-1">Review Order</div>
					<div class="flex-1 text-right">
						<div>
							{{ $parent.codeName }}
						</div>
						<div class="fw-700">
							{{ name }}
							<span class="fs-10-c color-muted-c">
								{{ $parent.ticker_prop.includes('KAU_USD') || $parent.ticker_prop.includes('MGAU') ? 'KAU' : ' ' }}
							</span>
						</div>
					</div>
					<div>
						<img class="" :src="`${$parent.publicPath}assets/icon/gold.png`" alt="" width="20" />
					</div>
				</div>

				<hr class="bg-white-c">

				<!-- Market -->
				<div v-if="type_prop == 'market'" class="text-left mb-26-c">
					<div class="mb-6-c">
						<span class="text-left">Direction</span>
						<div
							:class="`text-right float-right ${direction_prop == 'buy' ? 'color-green-c ' : 'color-red-c '} number-font text-uppercase font-weight-bold`">
							{{ direction_prop }}</div>
					</div>
					<div class="mb-16-c">
						<span class="text-left">Order Type</span>
						<div class="text-capitalize text-right float-right text-white number-font">{{ type_prop }}</div>
					</div>


					<div class="mb-6-c">
						<span class="text-left">Price Per Gram</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + pricePerGramShown }}
						</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Contract Price</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + contractPriceShown }}
						</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Quantity</span>
						<div class="text-right float-right text-white number-font">{{ totalQuantity }}</div>
					</div>
					<div class="mb-16-c">
						<span class="text-left">Total Gram</span>
						<div class="text-right float-right text-white number-font">{{ Number(totalOzt_prop).toFixed(2) }}</div>
					</div>


					<div class="mb-6-c">
						<span class="text-left">Contract Value</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + valueShown }}</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Fee / {{ $store.state.fee * 100 }}%</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + feeShown }}</div>
					</div>
					<div class="">
						<span class="text-left">Tax / {{ $store.state.tax * 100 }}%</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + taxShown }}</div>
					</div>
					<div v-if="direction_prop == 'sell'" class="mt-6-c">
						<span class="text-left">Storage Fee</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol }} 0</div>
					</div>


					<div class="fw-600 mt-16-c">
						<span class="text-left">Total</span>
						<div class="text-right float-right number-font">$ {{ totalUSDShown }}</div>
						<div class="text-right number-font">Rp {{ totalIDRShown }}</div>
					</div>

				</div>

				<!-- Limit -->
				<div v-if="type_prop == 'limit'" class="text-left mb-26-c">
					<div class="mb-6-c">
						<span class="text-left">Direction</span>
						<div
							:class="`text-right float-right ${direction_prop == 'buy' ? 'color-green-c ' : 'color-red-c '} number-font text-uppercase font-weight-bold`">
							{{ direction_prop }}</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Order Type</span>
						<div class="text-capitalize text-right float-right text-white number-font">{{ type_prop }}</div>
					</div>
					<div class="mb-16-c">
						<span class="text-left">Valid Until</span>
						<div class="text-right float-right text-white number-font text-capitalize">{{ validUntil_prop }}</div>
					</div>


					<div class="mb-6-c">
						<span class="text-left">Price Per Gram</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + pricePerGramLimitShown }}
						</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Contract Price</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + contractPriceLimitShown
						}}
						</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Quantity</span>
						<div class="text-right float-right text-white number-font">{{ totalQuantity }}</div>
					</div>
					<div class="mb-16-c">
						<span class="text-left">Total Gram</span>
						<div class="text-right float-right text-white number-font">{{ Number(totalOzt_prop) }}</div>
					</div>


					<div class="mb-6-c">
						<span class="text-left">Contract Value</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + valueShown }}</div>
					</div>
					<div class="mb-6-c">
						<span class="text-left">Fee / {{ $store.state.fee * 100 }}%</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + feeShown }}</div>
					</div>
					<div class="">
						<span class="text-left">Tax / {{ $store.state.tax * 100 }}%</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol + ' ' + taxShown }}</div>
					</div>
					<div v-if="direction_prop == 'sell'" class="mt-6-c">
						<span class="text-left">Estimate Storage Fee</span>
						<div class="text-right float-right text-white number-font">{{ currencySymbol }} 0</div>
					</div>
					<div v-if="direction_prop == 'buy'" class="mt-6-c">
						<span class="text-left">FX Buffer</span>
						<div class="text-right float-right text-white number-font">Rp {{ fxBufferShown }}</div>
					</div>


					<div class="fw-600 mt-16-c">
						<span class="text-left">Estimate Price</span>
						<div class="text-right float-right number-font">$ {{ totalUsdLimitShown }}</div>
						<div class="text-right number-font">Rp {{ $parent.totalIdrLimitShown }}</div>
					</div>

				</div>

				<div class="">
					<div v-if="this.$store.state.marketInquiryStatus == 'loading'" class="col-12 text-center">
						<Loader size_prop='3em'>
						</Loader>
					</div>
					<template v-if="this.$store.state.marketInquiryStatus != 'loading'">
						<!-- <button type="button" class="col btn c-btn-danger mr-3 px-0" @click="closeReviewOrder()">{{closeButtonText}}</button> -->
						<!-- <button type="button" class="col btn c-btn-warning px-0" @click="submitOrder()" :disabled="submitButtonDisabled">SUBMIT ORDER</button> -->
						<button type="button" class="w-100 btn c-btn-black color-theme-c mb-8-c"
							@click="closeReviewOrder()">CHANGE</button>
						<button type="button" class="w-100 btn c-btn-theme" @click="submitOrder()">SUBMIT ORDER</button>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from '@/components/Loader.vue'
import { Decimal } from 'decimal.js'

export default {
	name: 'ReviewOrder',
	props: {
		account_prop: {
			type: String,
			default: 'Default'
		},
		hin_prop: {
			type: String,
			default: 'HIN Default'
		},
		name: {
			type: String,
			default: 'Default'
		},
		direction_prop: {
			type: String,
			default: 'buy'
		},

		gold_id_prop: {
			type: String,
			default: '0'
		},

		type_prop: {
			type: String,
			default: 'market'
		},
		validUntil_prop: {
			type: String,
			default: 'CANCELLED'
		},
		pricePerOzt_prop: {
			type: Number,
			default: 0
		},
		totalOzt_prop: {
			type: Number,
			default: 0
		},
		contractPrice_prop: {
			type: Number,
			default: 0
		},
		contractValue_prop: {
			type: Number,
			default: 0
		},
		modalOpen_prop: {
			type: Boolean,
			default: false
		},
		fee_prop: {
			type: Number,
			default: 0.01
		},
		tax_prop: {
			type: Number,
			default: 0.001
		},
		fxBuffer_prop: {
			type: Number,
			default: 0.05
		},
		streamRate_prop: {
			type: Number,
			default: 14000
		},
		timerStart_prop: {
			type: Boolean,
			default: false
		},
		marketInquirySuccess_prop: {
			type: Boolean,
			default: false
		},

		pricePerGramLimit_prop: {
			type: Number,
			default: 0
		},
		weight_prop: {
			type: Number,
			default: 0
		},
		totalGram_prop: {
			type: Number,
			default: 0
		},
		totalQuantity_prop: {
			type: Number,
			default: 0
		},
	},
	components: {
		Loader
	},
	data() {
		return {
			formControl: false,
			formControlPlaintext: true,
			originalQuantity: 0,
			originalPricePerOzt: 0,
			originalContractPrice: 0,
			quantityEdit: 1,
			pricePerOztEdit: 1,
			contractPriceEdit: 0,
			displayFormLimit: 'none',
			displayFormMarket: 'flex',
			varia: true,
			submitButtonDisabled: true,
			setIntervalData: '',
		}
	},
	computed: {
		account() {
			return ''
		},
		hin() {
			return this.$store.getters.getHin
		},

		currency() {
			return this.$store.state.currency
		},
		currencySymbol() {
			return (this.currency == 'idr') ? 'Rp' : '$'
		},

		modalOpen() {
			return this.modalOpen_prop
		},
		modalClass() {
			return {
				modalDialog: true,
				'modal-open': true
			}
		},
		inputClass() {
			return {
				'form-control': this.formControl,
				'form-control-plaintext': this.formControlPlaintext,
				'form-control-sm': true,
				'px-2': true,
			}
		},

		sellPrice() {
			let gold = this.$store.getters.goldById(this.gold_id_prop)
			if (gold) {
				if (gold.sell < 0 || gold.id == 0) {
					if (gold.buy < 0 || gold.id == 0) {
						return 0
					} else {
						return gold.buy
					}
				} else {
					return gold.sell
				}
			} else {
				return 0
			}
		},
		buyPrice() {
			let gold = this.$store.getters.goldById(this.gold_id_prop)
			if (gold) {
				if (gold.buy < 0 || gold.id == 0) {
					if (gold.sell < 0 || gold.id == 0) {
						return 0
					} else {
						return gold.sell
					}
				} else {
					return gold.buy
				}
			} else {
				return 0
			}
		},

		contractPriceSell() {
			let gold = this.$store.getters.goldById(this.gold_id_prop)
			if (gold) {
				if (gold.contractPriceSell < 0 || gold.id == 0) {
					if (gold.contractPriceBuy < 0 || gold.id == 0) {
						return 0
					} else {
						return gold.contractPriceBuy
					}
				} else {
					return gold.contractPriceSell
				}
			} else {
				return 0
			}
		},
		contractPriceBuy() {
			let gold = this.$store.getters.goldById(this.gold_id_prop)
			if (gold) {
				if (gold.contractPriceBuy < 0 || gold.id == 0) {
					if (gold.contractPriceSell < 0 || gold.id == 0) {
						return 0
					} else {
						return gold.contractPriceSell
					}
				} else {
					return gold.contractPriceBuy
				}
			} else {
				return 0
			}
		},

		contractPriceShown() {
			let price = this.contractPriceBuy
			let streamingRate = this.streamingRate.stream_buy.cur_rate
			if (this.direction == 'sell') {
				price = this.contractPriceSell
				streamingRate = this.streamingRate.stream_sell.cur_rate
			}

			if (this.currency == 'idr') {
				return this.numberFormatExtend(Decimal.mul(price, streamingRate).toFixed(2))
			}
			return this.numberFormatExtend(price.toFixed(2))
		},

		contractPriceLimitShown() {
			let price = this.contractPrice
			let streamingRate = this.streamingRate.stream_buy.cur_rate
			if (this.direction == 'sell') {
				streamingRate = this.streamingRate.stream_sell.cur_rate
			}

			if (this.currency == 'idr') {
				return this.numberFormatExtend(Decimal.mul(price, streamingRate).toFixed(2))
			}
			return this.numberFormatExtend(price.toFixed(2))
		},

		direction() {
			return this.direction_prop
		},
		orderType() {
			return this.type_prop
		},
		validUntil() {
			return this.validUntil_prop
		},

		pricePerOzt() {
			return this.pricePerOzt_prop
		},

		pricePerGramShown() {
			let price = this.buyPrice
			let streamingRate = this.streamingRate.stream_buy.cur_rate
			if (this.direction == 'sell') {
				price = this.sellPrice
				streamingRate = this.streamingRate.stream_sell.cur_rate
			}

			if (price == '-') {
				return '-'
			} else {
				if (this.currency == 'usd') {
					return this.numberFormatExtend(new Decimal(price).toFixed(2))
				} else {
					return this.numberFormatExtend(Decimal.mul(price, streamingRate).toFixed(2))
				}
			}
		},

		pricePerGramLimit() {
			return this.pricePerGramLimit_prop
		},

		pricePerGramLimitShown() {
			let price = this.pricePerGramLimit
			let streamingRate = this.streamingRate.stream_buy.cur_rate
			if (this.direction == 'sell') {
				streamingRate = this.streamingRate.stream_sell.cur_rate
			}

			if (price == '-') {
				return '-'
			} else {
				if (this.currency == 'usd') {
					return this.numberFormatExtend(new Decimal(price).toFixed(2))
				} else {
					return this.numberFormatExtend(Decimal.mul(price, streamingRate).toFixed(2))
				}
			}
		},

		totalOzt() {
			return this.totalOzt_prop
		},
		contractPrice() {
			return this.contractPrice_prop
		},
		contractValue() {
			return this.contractValue_prop
		},
		displayEdit() {
			return (this.orderType == 'limit') ? 'initial' : 'none'
		},
		displayKill() {
			return 'initial'
		},
		timerStart() {
			return this.$store.state.timerBarStatus
		},
		// submitButtonDisabled(){
		// 	let status = true
		// 	if (this.$store.state.marketInquiryStatus == 'success') {
		// 		status = false
		// 	}
		// 	return status
		// },
		closeButtonText() {
			if (this.submitButtonDisabled) {
				return 'ORDER AGAIN'
			} else if (this.$store.state.marketInquiryStatus == 'loading') {
				return 'CHANGE'
			} else {
				return 'CHANGE'
			}
		},

		pricePerOztShown() {
			let temp = this.pricePerOzt_prop
			if (this.currency == 'idr') {
				temp = Number(Decimal.mul(temp, this.streamRate_prop))
			}
			return this.numberFormatExtend(temp.toFixed(2))
		},
		contractValueShown() {
			let temp = this.contractValue_prop
			if (this.currency == 'idr') {
				temp = Number(Decimal.mul(temp, this.streamRate_prop))
			}
			return this.numberFormatExtend(temp)
		},
		// feeShown() {
		// 	let temp = this.fee_prop
		// 	if (this.currency == 'idr') {
		// 		temp = Number(Decimal.mul(temp, this.streamRate_prop))
		// 	}

		// 	return (this.numberFormatExtend(temp))
		// },

		// taxShown() {
		// 	let temp = this.tax_prop
		// 	if (this.currency == 'idr') {
		// 		temp = Number(Decimal.mul(temp, this.streamRate_prop))
		// 	}
		// 	return (this.numberFormatExtend(temp))
		// },

		// fxBufferShown() {
		// 	let temp = this.fxBuffer_prop
		// 	if (this.currency == 'idr') {
		// 		temp = Number(Decimal.mul(temp, this.streamRate_prop))
		// 	}
		// 	return (this.numberFormatExtend(temp))
		// },

		// QWEQWEQWEQWEQWEQWEQWE
		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		weight() {
			return this.weight_prop
		},

		totalGram() {
			return this.totalGram_prop
		},

		totalQuantity() {
			return this.totalQuantity_prop
		},

		value() {
			return this.$parent.value
		},
		valueShown() {
			return this.$parent.valueShown
		},

		fee() {
			// let temp = new Decimal(this.value).mul(this.$store.state.fee).toDecimalPlaces(2)
			// if (temp.lessThan(this.$store.state.feeMin)) {
			// 	return new Decimal(this.$store.state.feeMin)
			// }
			// return temp

			return this.$parent.fee
		},
		feeShown() {
			// let streamingRate = (this.currency == 'idr') ? (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate) : 1

			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend((this.fee).mul(streamingRate).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend((this.fee).mul(streamingRate).toFixed(2))
			// }

			return this.$parent.feeShown
		},

		tax() {
			// let temp = new Decimal(this.value).mul(this.$store.state.tax).toDecimalPlaces(2)
			// if (temp.lessThan(this.$store.state.taxMin)) {
			// 	return new Decimal(this.$store.state.taxMin)
			// }
			// return temp

			return this.$parent.tax
		},
		taxShown() {
			// let streamingRate = (this.currency == 'idr') ? (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate) : 1

			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend((this.tax).mul(streamingRate).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend((this.tax).mul(streamingRate).toFixed(2))
			// }

			return this.$parent.taxShown
		},

		fxBuffer() {
			// let streamingRate = this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate

			// let temp = new Decimal(this.value).mul(streamingRate).mul(5).div(100).toDecimalPlaces(2)
			// if (temp.div(streamingRate).lessThan(0.01)) {
			// 	return Decimal.mul(streamingRate, 0.01)
			// }
			// return temp

			return this.$parent.fxBuffer
		},
		fxBufferShown() {
			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend((this.fxBuffer).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend((this.fxBuffer).toFixed(2))
			// }

			return this.$parent.fxBufferShown
		},

		totalUSD() {
			// let temp = new Decimal(this.value)
			// if (this.direction == 'buy') {
			// 	temp = temp.plus(this.tax).plus(this.fee)
			// 	return temp.toFixed(2)
			// } else {
			// 	temp = temp.minus(this.tax).minus(this.fee)
			// 	return temp.toFixed(2)
			// }

			return this.$parent.totalUSD
		},
		totalUSDShown() {
			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend(new Decimal(this.totalUSD).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend(new Decimal(this.totalUSD).toFixed(2))
			// }

			return this.$parent.totalUSDShown
		},

		totalIDR() {
			// let temp = new Decimal(this.totalUSD)

			// if (this.direction == 'buy') {
			// 	return temp.times(this.streamingRate.stream_buy.cur_rate).toFixed(2)
			// } else {
			// 	return temp.times(this.streamingRate.stream_sell.cur_rate).toFixed(2)
			// }

			return this.$parent.totalIDR
		},
		totalIDRShown() {
			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend(new Decimal(this.totalIDR).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend(new Decimal(this.totalIDR).toFixed(2))
			// }

			return this.$parent.totalIDRShown
		},

		totalUsdLimit() {
			// return Decimal.mul(this.quantity, this.contractPriceLimit)
			// let temp = new Decimal(this.value)
			// if (this.direction == 'buy') {
			// 	temp = temp.plus(this.tax).plus(this.fee)
			// 	return Number(temp.toFixed(2))
			// } else {
			// 	temp = temp.minus(this.tax).minus(this.fee)
			// 	return Number(temp.toFixed(2))
			// }

			return this.$parent.totalUsdLimit
		},
		totalUsdLimitShown() {
			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend(new Decimal(this.totalUsdLimit).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend(new Decimal(this.totalUsdLimit).toFixed(2))
			// }

			return this.$parent.totalUsdLimitShown
		},

		totalIdrLimit() {
			// let temp = new Decimal(this.totalUsdLimit)

			// if (this.direction == 'buy') {
			// 	return temp.times(this.streamingRate.stream_buy.cur_rate).plus(this.fxBuffer).toFixed(2)
			// } else {
			// 	return temp.times(this.streamingRate.stream_sell.cur_rate).toFixed(2)
			// }

			return this.$parent.totalIdrLimit
		},
		totalIdrLimitShown() {
			// if (this.direction == 'buy') {
			// 	return this.numberFormatExtend(new Decimal(this.totalIdrLimit).toFixed(2))
			// } else {
			// 	return this.numberFormatExtend(new Decimal(this.totalIdrLimit).toFixed(2))
			// }

			return this.$parent.totalIdrLimitShown
		},

	},
	watch: {
		timerStart(newValue) {
			if (newValue && this.type_prop == 'market' && this.$store.state.bankType == 'BAGI') {
				this.startFakeTimer()
			} else {
				if (newValue && this.type_prop == 'market' && this.direction == 'buy') {
					this.startTimer()
				} else if (newValue && this.type_prop == 'market' && this.direction == 'sell') {
					this.startFakeTimer()
				}
			}
		},
		type_prop(newValue) {
			if (newValue == 'market') {
				this.displayFormLimit = 'none'
				this.submitButtonDisabled = true
			} else {
				this.displayFormLimit = 'flex'
				this.submitButtonDisabled = false
			}
		}
	},
	created() {

	},
	beforeUpdate() {

	},
	methods: {

		// numberFormatExtend(value, nn = 5, xx = 3, ss = ',', cc = '.') {
		// 	Number.prototype.format = function(n, x, s, c) {
		//     let re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		//       num = String(this);

		//     return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
		// 	}

		// 	return value.format(nn, xx, ss, cc)
		// },

		startTimer() {
			this.submitButtonDisabled = false
			let i = 0
			if (i == 0 && this.$store.state.marketInquiryStatus == 'success') {
				i = 1
				let elem = document.getElementById("progressBar")
				let elem2 = document.getElementById("frontBar")
				let width = 100
				let time = 10
				this.setIntervalData = setInterval(() => {
					if (width <= 0) {
						this.submitButtonDisabled = true
						clearInterval(this.setIntervalData)
						i = 0
						time = 0
						elem2.innerHTML = time.toFixed(1) + "s"
						this.$store.commit('STOP_TIMER')
					} else {
						width -= 0.1
						time -= 0.01
						elem.style.width = width + "%"
						elem2.innerHTML = time.toFixed(1) + "s"
					}
				}, 10)
			}
		},
		startFakeTimer() {
			setTimeout(() => {
				this.$store.dispatch('changeMarketInquiryStatus', 'success')
				this.submitButtonDisabled = false
				let i = 0
				if (i == 0) {
					i = 1
					let elem = document.getElementById("progressBar")
					let elem2 = document.getElementById("frontBar")
					let width = 100
					let time = 10
					this.setIntervalData = setInterval(() => {
						if (width <= 0) {
							this.submitButtonDisabled = true
							clearInterval(this.setIntervalData)
							i = 0
							time = 0
							elem2.innerHTML = time.toFixed(1) + "s"
							this.$store.commit('STOP_TIMER')
						} else {
							width -= 0.1
							time -= 0.01
							elem.style.width = width + "%"
							elem2.innerHTML = time.toFixed(1) + "s"
						}
					}, 10)
				}
			}, 2000)
		},

		submitOrder() {
			this.$emit('submit')
		},
		closeReviewOrder() {
			if (this.$store.state.marketInquiryStatus == 'success' || this.$store.state.marketInquiryStatus == 'loading') {
				clearInterval(this.setIntervalData)
				document.getElementById("progressBar").style.width = 100 + "%"
				document.getElementById("frontBar").innerHTML = '10.0s'
				this.$store.commit('STOP_TIMER')
			}
			this.$emit('close')
		}
	}
}
</script>

<style scoped>
.modalDialog {
	position: fixed;
	font-family: Arial, Helvetica, sans-serif;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.8);
	z-index: 9999;
	opacity: 0;
	-webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in;
	pointer-events: none;
}

.modalDialog:target {
	opacity: 1;
	pointer-events: auto;
}

.modal-open {
	opacity: 1;
	pointer-events: auto;
	margin: 0px 12px;
}

.modalDialog>div {
	max-width: 400px;
	position: relative;
	
	margin-top: 60px;
	margin-right: auto;
	margin-left: auto;

	padding: 1.5em;
	border-radius: 10px;
	background: #191f31;
	background: -moz-linear-gradient(#fff, #999);
	background: -webkit-linear-gradient(#fff, #999);
	background: -o-linear-gradient(#fff, #999);
}

.close {
	background: #606061;
	color: #FFFFFF;
	line-height: 25px;
	position: absolute;
	right: -12px;
	text-align: center;
	top: -10px;
	width: 24px;
	text-decoration: none;
	font-weight: bold;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	-moz-box-shadow: 1px 1px 3px #000;
	-webkit-box-shadow: 1px 1px 3px #000;
	box-shadow: 1px 1px 3px #000;
}

.close:hover {
	background: #00d9ff;
}

.c-prepend-button:hover {
	cursor: pointer;
}

.c-append-button:hover {
	cursor: pointer;
}

.form-control-plaintext {
	padding: 0px 1px 3px 1px;
}

#myProgress {
	position: relative;
	width: 100%;
	height: 30px;
	background-color: #C4C4C4;
	border-radius: 100px;
	overflow: hidden;
}

#myBar {
	margin: 0;
	position: absolute;
	top: 50%;
	-ms-transform: translateY(-50%);
	transform: translateY(-50%) scale(0.97, 1);

	width: 100%;
	height: 19px;
	background-color: transparent;
	text-align: center;
	color: white;
	border-radius: 100px;
	overflow: hidden;

}

#progressBar {
	width: 100%;
	height: 19px;
	background-color: #F0981C;
	/*border-radius: 100px;*/
}

#frontBar {
	position: absolute;
	top: 0;
	color: black;
	background-color: transparent;
	width: 100%;
	text-align: center;
	line-height: 30px;
	font-size: 14px;
	font-weight: bold;
}

.value-field {
	background-color: transparent;
	color: rgba(255, 255, 255, 0.5);
}

.total-value {
	color: gold;
	font-size: 16px;
}

.total-value.total-value-idr {
	line-height: 16px;
}
</style>