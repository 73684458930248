<template>
	<div class="row">
		<div class="col-12 d-flex justify-content-center">
			<div class="card px-2" style="max-width: 800px;">
				<div class="card-header">
					<!-- <h4 class="card-title">WITHDRAWAL DETAILS - FROM</h4> -->
				</div>
				<div class="card-body">
					<form @submit.prevent="submitBullionWithdrawal()">
						<div class="row">
							<div class="col">

								<div class="row" style="max-width: 90ch;">
									<div class="col">
										<div class="row flex-nowrap">
											<div class="col-auto" style="min-width: 152px;">
												<div class="mb-4">Customer</div>
												<div class="mb-4">Account ID</div>
											</div>
											<div class="col text-nowrap">
												<div class="text-left mb-4">: <span class="text-white">{{ this.$store.getters.getFirstName
												}}</span></div>
												<div class="text-left mb-4">: <span class="text-white">{{ this.$store.getters.getHin }}</span>
												</div>
											</div>
										</div>
									</div>

									<div class="col-auto">
										<div class="row">
											<div class="col-auto" style="min-width: 152px;">
												<div class="mb-4">Order Date</div>
												<div class="mb-4">Arrival Date (est.)</div>
											</div>
											<div class="col-auto">
												<div class="text-left mb-4">: <span class="text-white">{{ dateToday }}</span></div>
												<div class="text-left mb-4">: <span class="text-white">{{ arrivalDate }}</span></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="w-100"></div>

							<div class="col-12 col-md-auto py-2">
								<span>Pick Up Location</span>
							</div>

							<div class="col" style="max-width: 75ch;">
								<!-- <template v-if="apiStatusGetPostalCode == 'loading'">
												<Loader
													size_prop = "2em"
												></Loader>
											</template> -->

								<input type="text" name="location" id="locationShown" class="form-control"
									style="font-size: 0.875rem; display: none;" v-model="bullionWithdrawalLocationShown"
									placeholder="Choose location" @focus="chooseLocation()" autocomplete="off">

								<input list="locations" name="location" id="location" class="form-control" style="font-size: 0.875rem;"
									v-model="destination" @focus="showLocations = true" @blur="outOfLocation()"
									placeholder="Choose location" autocomplete="off" required>
								<div v-if="apiStatusGetPostalCode == 'idle' && locations.length > 0 && !bullionWithdrawalLocationShown"
									class="option" style="display: initial !important;">
									<div v-for="location in locations" class="subOption" style="background-color: #313d5e;"
										:key="location.postcode" @click="selectLocation(location)">
										<div>{{ location.city }}</div>
										<small class="text-muted">{{ location.address }}</small>
									</div>
								</div>
								<!-- <datalist v-if="apiStatusGetPostalCode == 'idle' && destination.length > 0" id="locations">
												<template v-for="location in locations">
													<option :key="location.location_id" :value="location.location_name" :label="`${location.address}`"></option>
												</template>
											</datalist> -->
								<div v-else-if="(showLocations || destination.length > 2) && !bullionWithdrawalLocationShown"
									class="option text-center px-2" style="display: initial; background-color: #313d5e;">
									<div v-if="twoWordWarning">Please input 3 or more characters</div>
									<Loader v-else-if="destination.length > 0" size_prop="4em"></Loader>
								</div>
							</div>

						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex justify-content-center">
			<div class="card d-inline-block px-2" style="max-width: 800px">


				<div class="card-body">
					<div class="row justify-content-center justify-content-md-start">

						<div class="col-12 col-md-6" style="max-width: 75ch;">
							<div class="mx-2">
								<div class="row text-white bg-dark rounded text-nowrap mb-3  py-2" style="max-width: 75ch;">
									<div class="col-12">
										<h4 class="mb-2">Holdings</h4>
									</div>
									<div class="col text-white">
										<template v-for="item in totalGold">
											<div :key="'holding1' + item.contractId" v-if="item.status != 'disabled'" class="mb-2">{{
												item.product }}</div>
										</template>
									</div>
									<div class="col text-right text-white">
										<template v-for="item in totalGold">
											<div :key="'holding2' + item.contractId" v-if="item.status != 'disabled'" class="mb-2">{{
												item.totalGram }} gram ({{ item.quantity }})</div>
										</template>
									</div>
								</div>
							</div>
						</div>

						<div class="col-12 col-md-6" style="max-width: 75ch;">
							<div class="mx-2">
								<div class="row text-white bg-dark rounded text-nowrap mb-3  py-2" style="max-width: 75ch;">
									<div class="col-12">
										<h4 class="mb-2">Reserved to Delivery</h4>
									</div>

									<div v-for="item in reservedGold" :key="'reservedGold' + item.contract_id" class="col-12 mb-2">
										<span class="text-left">{{ item.name }}</span>
										<div class="text-right float-right text-white number-font">{{ item.totalGram }} gram
											({{ item.totalQuantity }})</div>
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex justify-content-center">
			<div class="card px-2" style="max-width: 800px;">
				<div class="card-header">
					<h3 class="mb-3">Description</h3>
				</div>

				<div class="card-body">
					<div class="row justify-content-center justify-content-md-start">

						<div class="col-12 col-md-6" style="max-width: 75ch;">

							<div class="row text-white mb-5 mr-md-3" style="max-width: 75ch;">
								<div class="col-12">
									<h4 class="mb-2">Withdraw Quantity</h4>
								</div>
								<div class="col">
									<template v-for="item in totalGold">
										<div :key="'withdraw1' + item.contractId" v-if="item.status != 'disabled'" class=" mb-3"
											style="padding: 3.45px 0px;">{{ item.product }}</div>
									</template>
								</div>
								<div class="col-auto">
									<template v-for="item in totalGold">
										<div :key="'withdraw2' + item.contractId" v-if="item.status != 'disabled'">
											<div class="float-right c-input-field mb-3">
												<button class="button button-counter decrease"
													@click="decreaseBullionQuantityx(item.contractId)">-</button>

												<input class="c-input number-font" type="text"
													:name="`bullionWithdrawalQuantity${item.contractId}`"
													v-model.lazy="bullionWithdrawalQuantity[item.contractId]">

												<button class="button-counter increase"
													@click="increaseBullionQuantity(item.contractId)">+</button>
											</div>
										</div>
									</template>
								</div>
							</div>


						</div>

						<div class="col-12 col-md-6" style="max-width: 75ch;">
							<div class="row text-nowrap">
								<div class="col-auto">
									<div class="mb-4">Weight</div>
									<div class="mb-4">Quantity</div>
									<div class="mb-4">Value Goods</div>
								</div>
								<div class="col pr-0">
									<div class="text-right mb-4"><span class="text-white">{{ bullionWithdrawalTotalWeightShown
									}}</span></div>
									<div class="text-right mb-4"><span class="text-white">{{ bullionWithdrawalTotalQuantityShown
									}}</span></div>
									<div class="text-right mb-4"><span class="text-white">Rp {{ valueGoodsShown }}</span></div>
								</div>
								<div class="col-auto pl-0">
									<div class="mb-4 text-left">
										<pre class="d-inline"> </pre>gram
									</div>
									<div class="mb-4 text-left">
										<pre class="d-inline"> </pre>bars
									</div>
								</div>
							</div>

							<div class="border c-border-white rounded px-3 py-2 mb-3" id="description"
								v-html="bullionWithdrawalDescriptionShown"></div>

						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="col-12 d-flex justify-content-center">
			<div class="card px-2" style="max-width: 800px;">
				<div class="card-header">

				</div>
				<div class="card-body px-0 px-md-3">

					<div class="container px-md-0 ml-md-0" style="max-width: 75ch;">
						<div class="mb-3">
							<span class="text-left">Delivery Cost</span>
							<div class="text-right float-right text-white number-font">Rp {{ deliveryCostInInput }}</div>
						</div>

						<div class="mb-3">
							<span class="text-left">Insurance</span>
							<div class="text-right float-right text-white number-font">Rp {{ insuranceInInput }}</div>
						</div>

						<div class="mb-3">
							<span class="text-left">Duty, Taxes, & Customs</span>
							<div class="text-right float-right text-white number-font">Rp {{ taxAndCustoms }}</div>
						</div>


					</div>
				</div>
			</div>
		</div>

		<div class="col-12 sticky d-flex justify-content-center">
			<div class="card px-2 mb-0" style="box-shadow: 0px 0px 2px white; max-width: 800px;">
				<!-- <div class="card-header">
								
							</div> -->
				<div class="card-body">
					<div class="row">

						<div class="col">
							<div class="">
								<h4 class="m-0">Total Charges</h4>
								<div class="number-font text-gold" style="font-size: 2rem;">Rp {{
									bullionWithdrawalTotalChargesInInput }}</div>
							</div>
						</div>

						<div class="col">
							<div class="float-right">
								<template v-if="apiStatusGetFee == 'loading'">
									<Loader size_prop='5em'></Loader>
								</template>
								<template v-else>
									<button v-if="!isBullionWithdrawalTotalChargesCounted" class="btn c-btn-warning" type="button"
										@click="countTotalCharges()">Count Total Charges</button>
									<button v-else type="button" class="btn btn-lg w-100 c-btn-success"
										@click="submitBullionWithdrawal()">Submit</button>
								</template>
								<br>
								<div style="max-width: 60ch;">

									<a href="javascript:void(0)" class="text-light" @click="showDisclaimer()">
										<i v-if="!disclaimerHasBeenRead" class="far fa-circle"></i>
										<i v-else class="far fa-check-circle" :style="{ color: '#2ec4b6' }"></i>
										Please read our <a href="javascript:void(0)" class="text-white font-weight-bold">Disclaimer</a>
										first
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Disclaimer v-show="disclaimerIsOpen" @close="disclaimerAfterClose">
		</Disclaimer>
	</div>
</template>

<script>
import axios, { abortController } from '@/api/axios.js'
import Swal from 'sweetalert2'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
import Disclaimer from '@/components/Disclaimer.vue'

export default {

	name: 'Bullion',

	components: {
		Loader,
		Disclaimer
	},

	data() {
		const month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
		let dateTodayIso = new Date()

		let dateToday = `${dateTodayIso.getDate()} ${month[dateTodayIso.getMonth()]} ${dateTodayIso.getFullYear()}`
		dateTodayIso.setDate(dateTodayIso.getDate() + 7)
		let arrivalDate = `${dateTodayIso.getDate()} ${month[dateTodayIso.getMonth()]} ${dateTodayIso.getFullYear()}`

		const contractIds = this.$store.state.contractIds

		return {
			dateToday: dateToday,
			arrivalDate: arrivalDate,

			bullionWithdrawalLocationShown: '',
			lastBullionWithdrawalLocationShown: '',

			locations: [],
			oldDestination: '',
			destination: '',
			selectedDestination: {},
			showLocations: false,
			twoWordWarning: false,

			apiStatusGetPostalCode: 'idle',
			cancelTokenApiGetPostalCode: '',

			totalGold: [],

			apiStatusGetFee: 'idle',

			isBullionWithdrawalTotalChargesCounted: false,

			disclaimerIsOpen: false,
			disclaimerHasBeenRead: false,

			bullionWithdrawalOrderDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10),
			bullionWithdrawalArrivalDate: '',
			bullionWithdrawalTime: '00:00',

			bullionWithdrawalQuantity: {},
			bullionWithdrawalMultiple: {
				[contractIds.contract_100g]: 10,
				[contractIds.contract_1kg]: 10,
				[contractIds.contract_1g]: 10,
				[contractIds.contract_001g]: 100,
			},

			feeGetFee: 0,
			feeTaxGetFee: 0,
			insuranceGetFee: 0,
			insuranceTaxGetFee: 0,
		}
	},

	computed: {
		contracts() {
			return this.$store.state.contracts
		},

		holdings() {
			return this.$store.state.holdings
		},

		goldBalance() {
			return this.$store.getters.getGoldBalance
		},

		reservedGold() {
			return this.$store.getters.getReservedDeliveryGold
		},

		bullionWithdrawalTotalWeight() {
			let sum = 0
			let bullionWithdrawalQuantity = this.bullionWithdrawalQuantity
			for (const item in bullionWithdrawalQuantity) {
				sum = Decimal.mul(bullionWithdrawalQuantity[item], this.$store.state.contractConfigs[item].weightInGram).add(sum)
			}

			return Number(sum)
		},
		bullionWithdrawalTotalWeightShown() {
			let sum = 0
			let bullionWithdrawalQuantity = this.bullionWithdrawalQuantity
			for (const item in bullionWithdrawalQuantity) {
				sum = Decimal.mul(bullionWithdrawalQuantity[item], this.$store.state.contractConfigs[item].weightInGram).add(sum)
			}

			return Number(sum)
		},

		bullionWithdrawalTotalQuantity() {
			let sum = 0
			let bullionWithdrawalQuantity = this.bullionWithdrawalQuantity
			for (const item in bullionWithdrawalQuantity) {
				sum = Decimal.add(sum, this.bullionWithdrawalQuantity[item])
			}

			return Number(sum)
		},
		bullionWithdrawalTotalQuantityShown() {
			let sum = 0
			let bullionWithdrawalQuantity = this.bullionWithdrawalQuantity
			for (const item in bullionWithdrawalQuantity) {
				sum = Decimal.add(sum, bullionWithdrawalQuantity[item])
			}

			return Number(sum)
		},

		valueBullionWithdrawal() { //value goods
			let temp = this.gold100gr
			if (temp) {
				if (temp.sell >= 0) {
					return Number(Decimal.mul(this.bullionWithdrawalTotalWeight, temp.sell).mul(this.streamingRate.stream_sell.cur_rate))
				} else {
					return 0
				}
			} else {
				return 0
			}
		},

		insurance() {
			const temp = Number(this.insuranceGetFee).toFixed(2)
			if (temp) {
				return temp
			} else {
				return 0
			}
		},
		insuranceInInput() {
			if (this.isBullionWithdrawalTotalChargesCounted) {
				return this.numberFormatExtend(this.insurance)
			} else {
				return '-'
			}
		},

		valueGoods() {
			return Number((this.valueBullionWithdrawal).toFixed(2))
		},
		valueGoodsShown() {
			return this.numberFormatExtend(this.valueGoods)
		},

		bullionWithdrawalDescription() {
			return `Emas batangan (Gold Bar LBMA 999.9) seberat ${this.bullionWithdrawalTotalWeightShown} gram sejumlah ${this.bullionWithdrawalTotalQuantityShown} batang`
		},

		bullionWithdrawalDescriptionShown() {
			return `Emas batangan (Gold Bar LBMA 999.9) seberat <b class="text-white text-nowrap">${this.bullionWithdrawalTotalWeightShown} gram</b> sejumlah <b class="text-white text-nowrap">${this.bullionWithdrawalTotalQuantityShown} batang</b>`
		},

		deliveryCost() {
			return this.feeGetFee
		},
		deliveryCostInInput() {
			if (this.isBullionWithdrawalTotalChargesCounted) {
				return this.numberFormatExtend(this.deliveryCost)
			} else {
				return '-'
			}
		},

		taxAndCustoms() {
			if (this.isBullionWithdrawalTotalChargesCounted) {
				return Number((this.feeTaxGetFee + this.insuranceTaxGetFee).toFixed(2))
			} else {
				return '-'
			}
		},

		bullionWithdrawalTotalCharges() {
			return Number((this.feeGetFee + this.insuranceGetFee + this.feeTaxGetFee + this.insuranceTaxGetFee).toFixed(2))
		},
		bullionWithdrawalTotalChargesInInput() {
			if (this.isBullionWithdrawalTotalChargesCounted) {
				return this.numberFormatExtend(this.bullionWithdrawalTotalCharges)
			} else {
				return '-'
			}
		},
	},

	watch: {
		selectedDestination() {
			this.isBullionWithdrawalTotalChargesCounted = false
		},

		destination(newValue) {
			if (this.cancelTokenApiGetPostalCode) {
				//cancel api get transactions
				this.cancelTokenApiGetPostalCode.controller.abort()
			}

			if (newValue.length > 2) {
				this.apiStatusGetPostalCode = 'loading'
				this.twoWordWarning = false

				this.cancelTokenApiGetPostalCode = abortController()

				axios.post('/v2/poslog/get/postal_code', {
					city: newValue,
					address: '',
				}, {
					signal: this.cancelTokenApiGetPostalCode.signal
				}).then(response => {
					this.apiStatusGetPostalCode = 'idle'

					this.locations = response.data.data.body.data.body.rs_postcode.r_postcode

				}).catch(() => {
					this.apiStatusGetPostalCode = 'idle'
				})
			} else {
				this.twoWordWarning = true
				this.locations = []
				this.selectedDestination = {}
			}
		},

		bullionWithdrawalQuantity: {
			handler(newValue) {
				let temp = newValue
				for (let item in newValue) {
					let holding = this.holdings.find(obj => obj.contract_id == item)
					if (!holding) {
						continue
					}
					if ((!temp[item] && temp[item] != 0) || isNaN(Number(temp[item]))) {
						temp[item] = 0
						continue
					}

					const goldBalance = this.goldBalance.find(obj => obj.contractId == item)
					const temp2 = new Decimal(temp[item])

					if (temp2.greaterThan(goldBalance.availableQuantity) && temp2.greaterThan(0)) {
						temp[item] = goldBalance.availableQuantity
					} else if (temp2.lessThan(0)) {
						temp[item] = 0
					} else {
						temp[item] = Number(temp2)
					}

				}
				this.$nextTick(() => {
					this.bullionWithdrawalQuantity = temp
				})
			},
			deep: true
		},

		bullionWithdrawalTotalWeightShown() {
			this.isBullionWithdrawalTotalChargesCounted = false

			let tempFeeGetFee = Number(Decimal.ceil(this.bullionWithdrawalTotalWeight).mul(10000))
			this.feeGetFee = (this.bullionWithdrawalTotalWeight > 0 && this.bullionWithdrawalTotalWeight < 1) ? 10000 : tempFeeGetFee

			this.feeTaxGetFee = Number(Decimal.mul(this.feeGetFee, 0.1))
			this.insuranceGetFee = Number(Decimal.mul(this.valueGoods, 0.01))
			this.insuranceTaxGetFee = Number(Decimal.mul(this.insuranceGetFee, 0.1))
		},

		disclaimerHasBeenRead(newValue) {
			if (newValue) {
				this.checkColor = 'lightgreen'
			} else {
				this.checkColor = 'grey'
			}
		}
	},

	created() {
		this.$store.dispatch('setHoldings')

		this.resetBullionWithdrawalQuantity()

		const firstCall = setInterval(() => {
			if (this.holdings.length > 0) {
				clearInterval(firstCall)

				this.setBullionWithdrawal()
			}
		}, 1000)

		// for (let el in this.bullionWithdrawalQuantity) {
		// 	this.$watch(`bullionWithdrawalQuantity.${el}`, function (newValue) {
		// 		if (!newValue || isNaN(newValue)) {
		// 			this.bullionWithdrawalQuantity[el] = 0
		// 			return 0
		// 		}
		// 		newValue = Number(newValue)

		// 		const goldBalance = this.goldBalance.find((obj) => obj.contractId == el)

		// 		const temp = new Decimal(newValue)

		// 		if (temp.greaterThan(goldBalance.availableQuantity)) {
		// 			// this.bullionWithdrawalQuantity[el] = Number(Decimal.mod(totalGram, this.bullionWithdrawalMultiple[el]).sub(totalGram))
		// 			this.bullionWithdrawalQuantity[el] = goldBalance.availableQuantity
		// 		} else if (temp.lessThan(0)) {
		// 			this.bullionWithdrawalQuantity[el] = 0
		// 		} else {
		// 			this.bullionWithdrawalQuantity[el] = Number(temp)
		// 		}
		// 	}, { deep: true })
		// }
	},

	methods: {
		increaseBullionQuantity(contractId) {
			const goldBalance = this.goldBalance.find(obj => obj.contractId == contractId)

			let temp = new Decimal(this.bullionWithdrawalQuantity[contractId]).add(1)

			if (temp.greaterThan(goldBalance.availableQuantity) && temp.greaterThan(0)) {
				this.$set(this.bullionWithdrawalQuantity, contractId, goldBalance.availableQuantity)
				// this.bullionWithdrawalQuantity[contractId] = goldBalance.availableQuantity
			} else if (temp.lessThan(0)) {
				this.$set(this.bullionWithdrawalQuantity, contractId, 0)
				// this.bullionWithdrawalQuantity[contractId] = 0
			} else {
				this.$set(this.bullionWithdrawalQuantity, contractId, Number(temp))
				// this.bullionWithdrawalQuantity[contractId] = Number(temp)
			}
		},
		decreaseBullionQuantityx(contractId) {
			const goldBalance = this.goldBalance.find(obj => obj.contractId == contractId)

			let temp = new Decimal(this.bullionWithdrawalQuantity[contractId]).sub(1)

			if (temp.greaterThan(goldBalance.availableQuantity) && temp.greaterThan(0)) {
				this.$set(this.bullionWithdrawalQuantity, contractId, goldBalance.availableQuantity)
				// this.bullionWithdrawalQuantity[contractId] = goldBalance.availableQuantity
			} else if (temp.lessThan(0)) {
				this.$set(this.bullionWithdrawalQuantity, contractId, 0)
				// this.bullionWithdrawalQuantity[contractId] = 0
			} else {
				this.$set(this.bullionWithdrawalQuantity, contractId, Number(temp))
				// this.bullionWithdrawalQuantity[contractId] = Number(temp)
			}
		},

		resetBullionWithdrawalQuantity() {
			this.$store.state.bullionWithdrawalSequence.forEach(contractId => {
				this.$set(this.bullionWithdrawalQuantity, contractId, 0)
				// this.bullionWithdrawalQuantity[contractId] = 0
			})
		},

		setBullionWithdrawal() {
			const contractOrder = this.$store.state.bullionWithdrawalSequence
			const contracts = this.contracts
			const holdings = this.holdings

			let temp = []
			for (let i = 0; i < contractOrder.length; i++) {
				if (contracts && holdings) {
					const contract = contracts.find(obj => obj.id == contractOrder[i])
					const contractConfig = this.$store.state.contractConfigs[contractOrder[i]]
					let holding = holdings.find(obj => obj.contract_id == contractOrder[i])
					if (contract && holding) {
						temp.push({
							contractId: contractOrder[i],
							status: contract.attributes.contractStatusId,
							product: contractConfig.name,
							quantity: Number(Decimal.div(holding.quantity, contractConfig.weightCorrection)),
							weight: contractConfig.weightInGram,
							weightCorrection: contractConfig.weightCorrection,
							totalGram: Number(Decimal.mul(contractConfig.weightInGram, Decimal.div(holding.quantity, contractConfig.weightCorrection)))
						})
					} else {
						temp.push({
							contractId: contractOrder[i],
							status: 'disabled',
							product: '-',
							quantity: 0,
							weight: 0,
							weightCorrection: 1,
							totalGram: 0,
						})
					}
				} else {
					temp.push({
						contractId: contractOrder[i],
						status: 'disabled',
						product: '-',
						quantity: 0,
						weight: 0,
						weightCorrection: 1,
						totalGram: 0,
					})
				}
			}

			this.totalGold = temp
		},

		showWithdrawDestination() {
			this.withdrawDestinationIsOpen = true
		},

		withdrawDestinationAfterClose(value) {
			if (value == 'giropos') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/pos-giro.png`
			} else if (value == 'gopay') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-gopay-2.jpg`
			} else if (value == 'ovo') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-ovo-3.jpg`
			} else if (value == 'rekpon' || value == 'cimb') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-cimb-3.png`
			} else if (value == 'linkaja') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-linkaja.png`
			} else if (value == 'dana') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-dana-2.png`
			} else if (value == 'isaku') { //isaku
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-isaku.jpg`
			} else if (value == 'bca') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-bca-2.png`
			} else if (value == 'mandiri') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-mandiri-3.png`
			} else if (value == 'bni') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-bni-2.png`
			} else if (value == 'Artha Graha') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-bagi-removebg.png`
				this.withdrawDestinationAccountNumber = this.paymentInfo.withdrawal_acc[this.utilityAccountIndex].account_number
			}

			if (value != '') {
				this.withdrawDestination = value
			}

			this.withdrawDestinationIsOpen = false
		},

		chooseLocation() {
			const elementLocationShown = document.getElementById('locationShown')
			elementLocationShown.style.display = 'none'

			const elementLocation = document.getElementById('location')
			elementLocation.style.display = 'block'
			elementLocation.focus()

			this.bullionWithdrawalLocationShown = ''

		},

		selectLocation(location) {
			this.bullionWithdrawalLocationShown = location.city + ' - ' + location.posCode + ' - ' + location.address
			this.lastBullionWithdrawalLocationShown = location.city + ' - ' + location.posCode + ' - ' + location.address

			this.destination = location.city
			this.oldDestination = location.city

			this.selectedDestination = location

			const elementLocationShown = document.getElementById('locationShown')
			elementLocationShown.style.display = 'block'

			const elementLocation = document.getElementById('location')
			elementLocation.style.display = 'none'
		},

		outOfLocation() {
			this.showLocations = false

			if (this.oldDestination == this.destination) {
				this.bullionWithdrawalLocationShown = this.lastBullionWithdrawalLocationShown

				const elementLocationShown = document.getElementById('locationShown')
				elementLocationShown.style.display = 'block'

				const elementLocation = document.getElementById('location')
				elementLocation.style.display = 'none'
			}
		},

		countTotalCharges() {
			const destination = this.selectedDestination

			const destinationIsEmpty = () => {
				for (let i in destination) return false
				return true
			}

			if (destinationIsEmpty()) {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Please select the pick up location!</span>'
				})
			} else if (this.bullionWithdrawalTotalQuantity <= 0 || this.bullionWithdrawalTotalWeight <= 0) {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Quantity and weight must be greater than zero!</span>'
				})
			}
			// else if ((this.quantity5Gram*5 + this.quantity10Gram*10) > (this.totalGold[0].quantity + this.totalGold[1].quantity) || this.quantity100Gram*100 > this.totalGold[2].quantity || this.quantity1Kg*1000 > this.totalGold[4].quantity || this.quantity1Kg*1000 > this.totalGold[5].quantity) {
			// 	if ((this.quantity5Gram*5 + this.quantity10Gram*10) > (this.totalGold[0].quantity + this.totalGold[1].quantity)) {
			// 		Swal.fire({
			// 			background: '#191f31',
			// 			html: '<span style="color: white;">You don\'t have enough 0.01 gram pool and 1 gram pool gold</span>'
			// 		})
			// 	}else if (this.quantity100Gram*100 > this.totalGold[2].quantity) {
			// 		Swal.fire({
			// 			background: '#191f31',
			// 			html: '<span style="color: white;">You don\'t have enough 100 gram non-pool gold</span>'
			// 		})
			// 	}else if (this.quantity1Kg*1000 > this.totalGold[4].quantity) {
			// 		Swal.fire({
			// 			background: '#191f31',
			// 			html: '<span style="color: white;">You don\'t have enough 1 kg non-pool gold</span>'
			// 		})
			// 	}else if (this.quantity1Kg*1000 > this.totalGold[5].quantity) {
			// 		Swal.fire({
			// 			background: '#191f31',
			// 			html: '<span style="color: white;">You don\'t have enough 10 kg non-pool gold</span>'
			// 		})
			// 	}
			// }
			else {
				this.apiStatusGetFee = 'loading'
				this.apiStatusGetPostalCode = 'loading'

				axios.post('/v2/poslog/get/fee', {
					shipperzipcode: "10710",
					receiverzipcode: destination.posCode,
					weight: this.bullionWithdrawalTotalWeight,
					valuegoods: this.valueGoods
				}).then(response => {
					let temp = response.data.data.body.data.body.rs_fee.r_fee
					if (Array.isArray(temp)) {
						let feePoslog = temp.find(obj => obj.serviceCode == 'PVG')
						if (feePoslog) {
							this.feeGetFee = feePoslog.fee
							this.feeTaxGetFee = feePoslog.feeTax
							this.insuranceGetFee = feePoslog.insurance
							this.insuranceTaxGetFee = feePoslog.insuranceTax

							//search arrival estimation in string
							let index1 = -1 //index for 'hari'||'minggu'
							let index2 = -1 //index for '('
							let serviceName = feePoslog.serviceName.toLowerCase()
							let multiplier = 1
							if (serviceName.search("hari")) {
								index1 = serviceName.search("hari")
							} else {
								index1 = serviceName.search("minggu")
								multiplier = 7
							}
							let stopLoop = false
							let counter = 1
							while (!stopLoop) {
								if (serviceName[index1 - counter++] == '(') {
									index2 = index1 - --counter
									stopLoop = true
								}
							}
							const arrivalEst = serviceName.substring(index2 + 1, index1 - 1) * multiplier
							const orderDate = new Date(this.bullionWithdrawalOrderDate)
							const arrivalEstDate = orderDate.getDate() + arrivalEst
							const arrivalDate = new Date(new Date(orderDate).setDate(arrivalEstDate)).toISOString().slice(0, 10)
							this.bullionWithdrawalArrivalDate = arrivalDate

							this.isBullionWithdrawalTotalChargesCounted = true
						} else {
							Swal.fire({
								background: '#191f31',
								type: 'error',
								title: '<span style="color: white;">Failed!</span>',
								html: '<span style="color: white;">Please select another location</span>'
							})
						}
					} else {
						Swal.fire({
							background: '#191f31',
							type: 'error',
							title: '<span style="color: white;">Failed!</span>',
							html: '<span style="color: white;">Please select another location.</span>'
						})
					}

					this.apiStatusGetFee = 'success'
				}).catch(() => {
					this.apiStatusGetFee = 'error'
				})

				// axios({
				// 	method: 'POST',
				// 	url:process.env.VUE_APP_BACKEND_URL+'/poslog/get/postal_code',
				// 	data: {
				// 		city: destination.location_name,
				// 		address: destination.address
				// 	}
				// }).then(response => {
				// 	this.postalCode = response.data.data[0]

				// 	statusCheck++
				// 	if (statusCheck == 2) {
				// 		this.isBullionWithdrawalTotalChargesCounted = true
				// 	}
				// 	this.apiStatusGetPostalCode = 'success'
				// }).catch(error => {
				// 	this.apiStatusGetPostalCode = 'error'
				// 	if (error.response) {
				// 		/*
				// 		 * The request was made and the server responded with a
				// 		 * status code that falls out of the range of 2xx
				// 		 */
				// 	} else if (error.request) {
				// 		/*
				// 		 * The request was made but no response was received, `error.request`
				// 		 * is an instance of XMLHttpRequest in the browser and an instance
				// 		 * of http.ClientRequest in Node.js
				// 		 */
				// 	} else {
				// 		// Something happened in setting up the request and triggered an Error
				// 	}
				// })
			}
		},

		submitBullionWithdrawal() {
			const featureIsnNotAvailable = false
			const destination = this.selectedDestination
			if (featureIsnNotAvailable) {
				Swal.fire({
					background: '#191f31',
					// title: '<span style="color: white;">This feature will available soon.</span>',
					html: '<span style="color: white;">This feature will be available soon.</span>'
				})
			}
			else if (this.bullionWithdrawalTotalQuantity <= 0 || this.bullionWithdrawalTotalWeight <= 0) {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Quantity and weight must be greater than zero!</span>'
				})
			} else if (!destination) {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Please select the pick up location!</span>'
				}).then(() => {
					setTimeout(function () { document.getElementById('location').focus() }, 280)
				})
			} else if (this.disclaimerHasBeenRead) {

				let contractId = []
				let contractQuantity = []

				let metalInfo = []
				for (let el in this.bullionWithdrawalQuantity) {
					const contract = this.contracts.find(obj => obj.id == el)
					const contractConfig = this.$store.state.contractConfigs[el]

					if (contract.attributes.contractStatusId == 'disabled') {
						continue
					}

					let metalInfoTemp = {
						contract_id: el,
						metal_type: "gold",
						unit: "gr",
						quantity: Number(Decimal.mul(this.bullionWithdrawalQuantity[el], contractConfig.weightCorrection)),
						weight: Number(Decimal.mul(this.bullionWithdrawalQuantity[el], contractConfig.weightInGram))
					}

					let loop = Number(Decimal.mul(this.bullionWithdrawalQuantity[el], contractConfig.weightInGram).div(this.bullionWithdrawalMultiple[el]))
					for (let i = 0; i < loop; i++) {
						metalInfo.push(metalInfoTemp)
					}

					if (this.bullionWithdrawalQuantity.hasOwnProperty(el)) {
						let gold = this.totalGold.find(obj => obj.contractId == el)
						contractId.push(el)
						contractQuantity.push(Decimal.mul(this.bullionWithdrawalQuantity[el], gold.weightCorrection))
					}
				}

				Swal.queue([{
					background: '#191f31',
					title: '<span style="color: white;">Are you sure want to submit?</span>',
					showCancelButton: true,
					confirmButtonText: 'Submit',
					showLoaderOnConfirm: true,
					preConfirm: () => {
						return new Promise((resolve, reject) => {
							axios.post('/v2/poslog/request_withdrawal', {
								user_id: this.$store.state.userId,
								location_id: destination.posCode,
								description: this.bullionWithdrawalDescription,
								metal_info: metalInfo,
								amount_idr: this.bullionWithdrawalTotalCharges,

								// branch_address: destination.address,
								// branch_city: destination.location_name,
								// branch_zipcode: this.postalCode,
								// quantity: this.bullionWithdrawalTotalQuantity,
								// value: this.valueBullionWithdrawal,
								// valuegoods: this.valueGoods,
								// weight: this.bullionWithdrawalTotalWeight,
								// delivery_cost: this.deliveryCost, //fee from get fee api = delivery cost
								// fee_taxes: this.feeGetFee,
								// insurance: this.insuranceGetFee,
								// insurance_taxes: this.insuranceTaxGetFee,
								// total_charge : Number(this.bullionWithdrawalTotalCharges),
								// withdrawal_date : Date.now(),
								// contract_id : contractId,
								// contract_quantity : contractQuantity,
							}).then(response => {
								// const temp = response.data.data.body
								Swal.insertQueueStep({
									background: '#191f31',
									type: 'success',
									title: '<span style="color: white;">Request Withdrawal Success!</span>',
									// title: '<span style="color: white;">Receipt Number:</span>',
									// html: '<span style="color: white;">'+ temp.transref +'</span>',
									onAfterClose: () => {
										//reset bullion withdarwal form and go to track bullion
										this.setBullionWithdrawal()
										this.destination = ''
										this.bullionWithdrawalArrivalDate = ''
										this.$refs.trackBullion.getListBullionWithrawal()
										this.disclaimerHasBeenRead = false
										document.getElementById("track-bullion").click()
									}
								})

								return resolve()
							}).catch(error => {
								let errorTitle = '<span style="color: white;">Something went wrong</span>'
								let errorText = '<span style="color: white;">Please try again later</span>'

								if (error.response) {
									errorTitle = '<span style="color: white;">Something went wrong</span>'
									errorText = '<span style="color: white;">' + error.response.data.message + '</span>'
								} else if (error.request) {
									errorTitle = '<span style="color: white;">Server appears to be offline</span>'
									errorText = '<span style="color: white;">Please try again later</span>'
								} else {
								}

								Swal.insertQueueStep({
									background: '#191f31',
									type: 'error',
									title: errorTitle,
									html: errorText
								})

								return reject()
							})
						})
					}
				}])
			} else {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Please read the Disclaimer first!</span>'
				})
			}
			// Swal.fire({
			// 	background: '#191f31',
			// 	html: '<span style="color: white;">Under development &#9786;</span>'
			// })
		},
		showDisclaimer() {
			this.disclaimerIsOpen = true
		},
		disclaimerAfterClose(value) {
			this.disclaimerIsOpen = false
			if (value == 'agree') {
				this.disclaimerHasBeenRead = true
			}
		}
	}
}
</script>

<style lang="css" scoped>
.button-counter {
	background-color: #1E1E2E;
}

.c-input {
	margin: 0 2px;
	padding: 0 8px;
	border: 1px solid #2b3553;
	background: transparent;
	height: 26.4px;
	border-radius: 4px;
	text-align: center;
	width: 80px;
	min-width: 80px;
	color: white;
}

.bullion-withdrawal-description {
	border: 1px solid #2b3553;
	padding: 12px;
	font-size: 12px;
}

.table-bullion-withdrawal th {
	padding: 12px 24px;
}

.table-bullion-withdrawal {
	width: auto;
	/*background-color: #212529;*/
}

.table-bullion-withdrawal .first-head {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>