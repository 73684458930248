<template>
	<svg :width="width" :height="height" viewBox="0 0 15 16" fill="none">
		<g id="Group 7">
			<ellipse id="Ellipse 85" cx="7.97681" cy="4.47692" rx="3.5" ry="3.5" :fill="color_prop" />
			<path id="Ellipse 84"
				d="M14.9769 15.4968C14.9769 13.826 14.2394 12.2235 12.9267 11.0421C11.6139 9.86059 9.83344 9.19684 7.97693 9.19684C6.12041 9.19684 4.33994 9.86059 3.02718 11.0421C1.71443 12.2235 0.976929 13.826 0.976929 15.4968L7.97693 15.4968H14.9769Z"
				:fill="color_prop" />
		</g>
	</svg>
</template>

<script>
export default {

	name: 'IconUser',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '15px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '16px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: 'black',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	}
}
</script>

<style lang="css" scoped></style>