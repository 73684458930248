<template>
	<div>
		<h4 class="text-center">RECONCILE REPORTING SYSTEM</h4>

		<div class="row">

			<!-- <div class="col-auto mb-2 text-left">
				<FilterClient
					@changeFilterClient="changeFilterClient"
					filterId="fm"
					:clients_prop="clients"
				>
				</FilterClient>
			</div> -->

			<div class="col-auto mb-2 text-left">
				<FilterClient2 @changeFilterClient="changeFilterClient" filterId="fm" :clients_prop="clients">
				</FilterClient2>
			</div>

			<div v-show="tableView != 'client' && tableView != 'lp'" class="col-auto mb-2 text-left">
				<FilterContract class="d-inline-block" filterId="fm" @changeFilterContract="changeFilterContract">
				</FilterContract>
			</div>

			<div class="col-auto mb-2 text-left">
				<FilterDateAndType class="d-inline-block" @changeFilterDateAndType="changeFilterDateAndType">
				</FilterDateAndType>
			</div>

		</div>

		<div class="row">
			<div class="col-12">
				<TopSection :totalRevenue_prop="totalRevenue"></TopSection>
			</div>
		</div>

		<div class="row">
			<div v-if="tableView == 'all'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">ACCOUNT NAME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> ({{ $store.state.currency == 'usd' ? 'USD' : 'IDR'}}) </th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="(rrsStatus != 'loading')">
							<tr v-for="(item, index) in rrsData" class="c-row-table cursor-pointer text-right" :key="item.identity._id"
								@click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ item.identity.fullname }}</td>
								<td>{{ item.identity.trade_account.hin }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.buy.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.buy.totalPriceIdr) }}</td>
								
								<td>{{ item.gold.buy.totalToz }}</td>
								<td>{{ item.gold.buy.total_gram }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.sell.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.sell.totalPriceIdr) }}</td>
								
								<td>{{ item.gold.sell.totalToz }}</td>
								<td>{{ item.gold.sell.total_gram }}</td>

								<td v-if="$store.state.currency == 'idr'">{{ numberFormatExtend(item.gold.total_trade) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.totalTradeIdr) }}</td>
								
								<td>{{ item.gold.totalToz }}</td>
								<td>{{ item.gold.total_gram }}</td>
								<td class="text-center">{{ item.gold.rateRatio }}</td>
							</tr>
							<tr v-if="rrsData.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>

			<div v-if="tableView == 'ib'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">ACCOUNT NAME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> ({{ $store.state.currency == 'usd' ? 'USD' : 'IDR'}}) </th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="(rrsStatus != 'loading')">
							<tr v-for="(item, index) in rrsDataIb" class="c-row-table cursor-pointer text-right"
								:key="item.identity._id" @click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ (item.identity) ? item.identity.fullname : '-' }}</td>
								<td>{{ item.identity.trade_account.hin }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.buy.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.buy.totalPriceIdr) }}</td>
								
								<td>{{ item.gold.buy.totalToz }}</td>
								<td>{{ item.gold.buy.total_gram }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.sell.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.sell.totalPriceIdr) }}</td>
								
								<td>{{ item.gold.sell.totalToz }}</td>
								<td>{{ item.gold.sell.total_gram }}</td>

								<td v-if="$store.state.currency == 'idr'">{{ numberFormatExtend(item.gold.total_trade) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.totalTradeIdr) }}</td>
								
								<td>{{ item.gold.totalToz }}</td>
								<td>{{ item.gold.total_gram }}</td>
								<td class="text-center">{{ item.gold.rateRatio }}</td>
							</tr>
							<tr v-if="rrsDataIb.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>

			<div v-if="tableView == 'client' || tableView == 'lp'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">PRODUCT</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> ({{ $store.state.currency == 'usd' ? 'USD' : 'IDR'}}) </th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="(rrsStatus != 'loading')">
							<tr v-for="(item, index) in clientData" class="c-row-table cursor-pointer text-right" :key="item.name"
								@click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ item.name }}</td>
								<td>{{ selectedClient.hin_abx }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.buy.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.buy.totalPriceIdr) }}</td>
								
								<td>{{ item.buy.totalToz }}</td>
								<td>{{ item.buy.total_gram }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.sell.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.sell.totalPriceIdr) }}</td>
								
								<td>{{ item.sell.totalToz }}</td>
								<td>{{ item.sell.total_gram }}</td>
								<td>{{ item.total_trade }}</td>
								<td>{{ item.totalToz }}</td>
								<td>{{ item.total_gram }}</td>
								<td class="text-center">{{ item.rateRatio }}</td>
							</tr>
							<tr v-if="clientData.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
import FilterClient2 from '@/components/FilterClient2.vue'
import FilterContract from '@/components/FilterContract.vue'
import FilterDateAndType from '@/components/FilterDateAndType.vue'
import ExportAsButton from '@/components/ExportAsButton.vue'
import TopSection from '@/components/reconcile-reporting-system/TopSection.vue'

export default {
	name: 'FullMember',

	props: {
		goldOrderProp: Array
	},

	components: {
		Loader,
		FilterClient2,
		FilterContract,
		FilterDateAndType,
		ExportAsButton,
		TopSection
	},

	data() {
		const today = new Date(Date.now())
		let todayDay = today.getDay()
		let tempDay = new Date(Date.now())
		let tempWeek = new Date(Date.now())
		let tempMonth = new Date(Date.now())
		let tempYear = new Date(Date.now())
		return {
			clientsStatus: 'loading',
			clients: [],
			clientsFilterIb: '',

			grToTozMultiplier: 0.0321507,

			rrsClientStatus: 'loading',
			products: [],

			rrsStatus: 'loading',
			rrsData: [],
			rrsDataIb: [],

			totalTurnOver: {
				usd: 0,
				anotherCurrency: 0,
				toz: 0,
				gram: 0
			},

			reportType: 'day',
			reportMonth: 'january',
			reportYear: '2019',

			startDateDay: new Date(tempDay.setDate(tempDay.getDate() - 1)),
			endDate: `${tempDay.getFullYear()}-${tempDay.getMonth() + 1 < 10 ? '0' : ''}${tempDay.getMonth() + 1}-${tempDay.getDate() + 1 < 10 ? '0' : ''}${tempDay.getDate() + 1}`,

			startDateWeek: new Date(tempWeek.setDate(tempWeek.getDate() - todayDay + 1)),
			weeklyDateList: [],

			startDateMonth: new Date(tempMonth.setDate(1)),
			monthlyDateList: [],

			startDateYear: new Date(new Date(tempYear.setDate(1)).setMonth(0)),
			yearlyDateList: [],

			tableView: 'all',

			selectedClient: 'all',

			selectedContracts: [],

			selectedDateAndType: {
				type: 'all',
				startDate: '',
				endDate: ''
			},

			clientData: [],

			totalRevenue: {
				usd: 0,
				idr: 0,
			},

		}
	},

	computed: {
		// watcherChangeFilter() {
		// 	return `${this.startDateDay}${this.startDateWeek}${this.startDateMonth}${this.startDateYear}${this.endDate}`
		// },

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		productsGlobal() {
			let products = this.$store.getters.PRODUCTS
			return products
		},

		startDateDayInInput: {
			get: function () {
				let temp = this.startDateDay
				return `${temp.getFullYear()}-${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}-${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}`
			},
			set: function (newValue) {
				this.startDateDay = new Date(newValue)
			}
		},

		startDateWeekInInput: {
			get: function () {
				let temp = this.startDateWeek
				if (temp) {
					return `${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateWeek = temp
			}
		},

		startDateMonthInInput: {
			get: function () {
				let temp = this.startDateMonth
				if (temp) {
					return `01/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateMonth = temp
			}
		},

		startDateYearInInput: {
			get: function () {
				let temp = this.startDateYear
				if (temp) {
					return `01/01/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				if (newValue) {
					let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
					this.startDateYear = temp
				}
			}
		},
	},

	watch: {
		// watcherChangeFilter() {
		// 	this.changeFilter()
		// },
	},

	created() {
		this.$store.state.filterContract.forEach((item) => {
			if (item.checked) {
				this.selectedContracts.push(item.contractId)
			}
		})

		// set total revenue
		this.$parent.getTotalRevenue()
			.then((response) => {
				this.totalRevenue.usd = response.revenue.usd
				this.totalRevenue.idr = response.revenue.idr
			}).catch(() => {
				this.totalRevenue.usd = 0
				this.totalRevenue.idr = 0
			})

		const firstCall = setInterval(() => {
			if (this.productsGlobal) {
				clearInterval(firstCall)
				axios({
					method: 'POST',
					url: '/v2/rrs/fm/list/branch',
					data: {
						user_id: this.$store.state.userId
					}
				}).then(response => {
					this.clients = response.data.data.body
				}).catch(error => {
					if (error.response) {
					} else if (error.request) {
					} else {
						// Something happened in setting up the request and triggered an Error
					}
				})
				this.setRrs()
			}
		}, 1000)
	},

	methods: {
		setRrs() {
			let selectedContracts = this.selectedContracts
			let contractsParam = ''

			if (selectedContracts.length > 0) {
				selectedContracts.forEach((item) => {
					contractsParam = contractsParam + `contract[]=${item}&`
				})
			}

			if (contractsParam == '') {
				contractsParam = 'contract[]=&'
			}

			let reportType = this.selectedDateAndType.type
			let startDate = this.selectedDateAndType.startDate
			let endDate = this.selectedDateAndType.endDate

			if (reportType == 'all') {
				reportType = ''
			}

			if (startDate) {
				// startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
				startDate = startDate.toISOString()
			}

			if (endDate) {
				// endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? '0' : ''}${endDate.getMonth() + 1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`
				endDate = endDate.toISOString()
			}

			if (this.tableView == 'all') {
				this.rrsStatus = 'loading'

				axios.post('/v2/rrs/fm?' + contractsParam + 'type=' + reportType + '&startDate=' + startDate + '&endDate=' + endDate, {
					user_id: this.$store.state.userId
				}).then(response => {
					this.rrsData = []
					this.totalTurnOver = {
						usd: 0,
						anotherCurrency: 0,
						toz: 0,
						gram: 0
					}

					if (response.data.data.body[0].identity.trade_account.hin) {
						const processedData = this.$parent.processDataRRSBursa(response.data.data.body)

						this.rrsData = processedData.dataRRS
						this.totalTurnOver = processedData.totalTurnOver
					}

					this.rrsStatus = 'success'
				}).catch(error => {
					if (error.response) {
						if (error.response.data.message == "There are no clients under FM!") {
							this.rrsDataIb = []
							this.totalTurnOver = {
								usd: 0,
								anotherCurrency: 0,
								toz: 0,
								gram: 0
							}
						}
					}

					this.rrsStatus = 'error'
				})
			} else {
				this.rrsStatus = 'loading'

				axios.post('/v2/rrs/fm/get/branch?hin=' + this.selectedClient.trade_account.hin + '&' + contractsParam + 'type=' + reportType + '&startDate=' + startDate + '&endDate=' + endDate, {
					user_id: this.$store.state.userId
				}).then(response => {

					if (this.tableView == 'client' || this.tableView == 'lp') {
						this.clientData = []
						this.totalTurnOver = {
							usd: 0,
							anotherCurrency: 0,
							toz: 0,
							gram: 0
						}

						const processedData = this.$parent.processDataRRSClient(response.data.data.body)

						this.clientData = processedData.dataRRS
						this.totalTurnOver = processedData.totalTurnOver
					} else { //IB
						this.rrsDataIb = []
						this.totalTurnOver = {
							usd: 0,
							anotherCurrency: 0,
							toz: 0,
							gram: 0
						}

						if (response.data.data.body) {
							const processedData = this.$parent.processDataRRSBursa(response.data.data.body, 'ib')

							this.rrsDataIb = processedData.dataRRS
							this.totalTurnOver = processedData.totalTurnOver
						}
					}

					this.rrsStatus = 'success'
				}).catch(error => {
					if (error.response) {
						if (error.response.data.message == "There are no clients under IB!") {
							this.rrsDataIb = []
							this.totalTurnOver = {
								usd: 0,
								anotherCurrency: 0,
								toz: 0,
								gram: 0
							}
						}
					}
					this.rrsStatus = 'error'
				})

			}
		},

		changeFilterClient(clientData) {
			let temp = clientData
			if (temp != 'all') {
				const template = { IB: 'ib', PB: 'fm', LP: 'lp', PC: 'client' }
				this.tableView = template[temp.trade_account.hin.substr(0, 2)]
			} else {
				this.tableView = 'all'
			}

			this.selectedClient = clientData
			this.setRrs()
		},

		changeFilterContract(selectedContracts) {
			this.selectedContracts = selectedContracts
			this.setRrs()
		},

		changeFilterDateAndType(data) {
			this.selectedDateAndType = data
			this.setRrs()
		},

		clearStartDate(value) {
			if (value == 'week') {
				this.startDateWeek = ''
			} else if (value == 'month') {
				this.startDateMonth = ''
			} else if (value == 'year') {
				this.startDateYear = ''
			}
		}
	}
}
</script>
