<template>
	<div class="c-running flex flex-nowrap flex--align-items-center text-center gap-12">
		<div class="text-nowrap color-red-c">
			<i class="fas fa-lg fa-arrow-circle-up color-red-c va-baseline fs-14-c"> </i>
			{{ valueBuy }}
		</div>

		<div class="text-nowrap color-green-c">
			<i class="fas fa-lg fa-arrow-circle-down color-green-c va-baseline fs-14-c"> </i>
			{{ valueSell }}
			<Tooltip type_prop="2" textPosition_prop="bottom" text_prop="Indikator yang menunjukan pergerakan harga" />
		</div>

	</div>
</template>

<script>
/*eslint-env jquery*/
import Decimal from 'decimal.js'

import Tooltip from './Tooltip.vue'

export default {
	name: 'RunningPriceV2',
	props: {
		valueSell_prop: {
			type: Number,
			default: 0
		},

		valueBuy_prop: {
			type: Number,
			default: 0
		},
	},

	components: {
		Tooltip,
	},

	data() {
		return {
			contractId: this.$store.state.contractIds.contract_1g, // use contract 1g
		}
	},
	computed: {
		product() {
			const contracts = this.$store.state.contracts
			const products = this.$store.state.products

			if (!contracts || !products) {
				return null
			}

			const contract = this.$store.state.contracts.find(obj => obj.id == this.contractId)
			const product = this.$store.state.products.find(obj => obj.id == contract.attributes.productId)

			return product || null
		},
		gold() {
			const golds = this.$store.state.golds
			if (!golds) {
				return null
			}

			return golds[this.contractId] || null
		},

		valueSell() {
			if (!this.gold || !this.product) {
				return '-'
			}

			return Decimal.div(this.gold.contractPriceBuy, this.product.attributes.toz).toDecimalPlaces(2).toNumber()
		},

		valueBuy() {
			if (!this.gold || !this.product) {
				return '-'
			}

			return Decimal.div(this.gold.contractPriceSell, this.product.attributes.toz).toDecimalPlaces(2).toNumber()
		},
	}
}
</script>

<style scoped>
.c-running {
	background-color: var(--grey-1-color);
	color: white;
	padding: 0.5em 0.75em;
	height: 2.5em;
	font-size: 1rem;
	margin: 0;

	/* border: 1px solid #9E9E9E; */
	border-radius: 4px;
}

.c-running-inner {
	padding: 0.55em;
}

.c-running-inner.c-running-sell {
	color: #F44336;
}

.c-running-inner.c-running-buy {
	color: #43A047;
}
</style>