import Vue from 'vue'
import axios from '@/api/axios.js'
import App from '@/App.vue'
import router from './router.js'
import store from '@/store'

import customMixin from '@/mixin/customMixin.js'

import '../public/assets/css/flex.css'
import '../public/assets/css/box-model.css'

Vue.config.productionTip = false

export const bus = new Vue()

Vue.mixin(customMixin)

const token = localStorage.getItem('token')
if (token) {
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

import axiosInterceptors from '@/api/axiosInterceptors.js'
axiosInterceptors()

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
