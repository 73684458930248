<template>
	<div class="content">
		<div class="row">
			<div class="col-12">
				<div class="card card-chart">
					<div class="card-body px-0 px-md-5" style="min-height: 478px">
						<div class="loader-chart">
							<Loader size_prop="7em" borderSize_prop="0.4em"></Loader>
						</div>
						<TradingChart chart_width="100%" chart_height="480" defaultSymbolAnchor_prop="FX_IDC-XAUIDRG"
							defaultSymbol_prop="FX_IDC:XAUIDRG" :showTitle_prop="false"></TradingChart>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-header">
						<h3 class="title d-inline">Contracts</h3>
					</div>
					<div class="card-body">
						<div :class="animatedBackgroundClass">
							<div v-if="setTradeBoxStatus == 'loading' && $store.state.setHoldingsApiStatus != 'success'"
								class="loader-chart">
								<Loader size_prop="5em" borderSize_prop="0.4em"></Loader>
							</div>
							<div v-else-if="tradeBox" class="row justify text-center">
								<div v-for="(item, index) in tradeBox" v-bind:key="index" class="col mb-4">
									<TradeBox @click.native="updateTradeTicket(item.id, index)" data-toggle="modal"
										data-target="#exampleModal" :contractId_prop="Number(item.id)"> </TradeBox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-5">
				<div class="card card-tasks" style="height: 689px">
					<div class="card-header">
						<h3 class="title d-inline">News <span style="color: gold">Gold</span></h3>
					</div>
					<div class="card-body">
						<div class="table-full-width table-responsive" style="height: 580px; max-height: 1000px" id="scrollbar1">
							<table class="table">
								<tbody>
									<tr v-for="item in news" :key="item.title">
										<td>
											<p class="title news-title">
												<a :href="item.url" target="_blank">{{ item.title }}</a>
											</p>
											<p class="text-muted">{{ item.description }}</p>
											<p class="text-muted">{{ item.pubDate }}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-7">
				<div class="card">
					<div class="card-header">
						<h3 class="card-title">Economic Calendar</h3>
					</div>
					<div v-if="environment != 'development'" class="card-body">
						<EconomicCalendar width="100%" height="600"></EconomicCalendar>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Header</div>
          <div class="card-body">
            <div class="slider">
              <img class="slider-item" :src="`${publicPath}assets/img/logo-ptpos-3.png`" style="height: auto; width: 500px;">
              <img class="slider-item" :src="`${publicPath}assets/img/logo-ptpos-3.png`" style="height: auto; width: 500px;">
              <img class="slider-item" :src="`${publicPath}assets/img/logo-ptpos-3.png`" style="height: auto; width: 500px;">
              <img class="slider-item" :src="`${publicPath}assets/img/logo-ptpos-3.png`" style="height: auto; width: 500px;">
              <img class="slider-item" :src="`${publicPath}assets/img/logo-ptpos-3.png`" style="height: auto; width: 500px;">
            </div>
          </div>
        </div>
      </div>
    </div> -->
		<TradeTicket v-if="tradeTicket" :contractId_prop="tradeTicket.contractId" :gold_id_props="tradeTicket.goldId"
			:product_is_active_prop="tradeTicket.productIsActive" :ticker_prop="tradeTicket.ticker" :name="tradeTicket.name"
			:weight="tradeTicket.weight" :weightCorection_prop="tradeTicket.weightCorrection" :buy_price="tradeTicket.buyPrice"
			:sell_price="tradeTicket.sellPrice" :openSellOrders_prop="openSellOrders"></TradeTicket>
	</div>
</template>

<script>
/*eslint-env jquery*/
import axios from '@/api/axios.js'

import PerfectScrollbar from 'perfect-scrollbar'
import TradeBox from '@/components/TradeBox.vue'
import TradeTicket from '@/components/TradeTicket.vue'
import TradingChart from '@/components/TradingChart.vue'
import EconomicCalendar from '@/components/EconomicCalendar.vue'
import Loader from '@/components/Loader.vue'
import { bus } from '../main'
import { Decimal } from 'decimal.js'

export default {
	name: 'Dashboard',
	components: {
		TradeBox,
		TradeTicket,
		TradingChart,
		EconomicCalendar,
		Loader,
	},
	data() {
		return {
			environment: process.env.NODE_ENV,
			publicPath: process.env.BASE_URL,
			tradeTicket: {
				goldId: '',
				productId: '',
				name: '',
				weight: 0,
				buyPrice: 0,
				sellPrice: 0,
				contractId: '',
			},

			srcStream1: '',
			srcStream2: '',

			tradeBox: [],
			streamPlayButtonDisplay: 'block',
			streamNone: '',

			news: [],

			animatedBackgroundClass: 'animated-background',

			setTradeBoxStatus: 'loading',

			openSellOrders: {},
		}
	},
	computed: {
		accountLevel() {
			return this.$store.getters.getAccountLevel
		},

		contracts() {
			let contract = this.$store.state.contracts
			return contract
		},
		products() {
			let products = this.$store.getters.PRODUCTS
			return products
		},
		golds() {
			let golds = this.$store.state.golds
			return golds
		},
		currency() {
			let currency = this.$store.state.currency
			return currency
		},

		srcVideo() {
			let video = this.$store.state.srcVideo
			// console.log(video)
			return video
		},
	},
	watch: {
		// contracts() {
		// 	this.setTradeBoxBefore()
		// },
		// golds() {
		// 	this.setTradeBoxBefore()
		// },
		// products() {
		// 	this.setTradeBoxBefore()
		// },
		// currency() {
		// 	this.setTradeBoxBefore()
		// },
	},
	created() {
		const firstCall = setInterval(() => {
			if (this.contracts && this.products && this.golds) {
				clearInterval(firstCall)

				this.setTradeBoxBefore()
				let rssUrls = {
					name: 'Commodities & Futures News',
					url: 'https://www.investing.com/rss/news_11.rss',
				}
				// Feed.load(`${process.env.VUE_APP_PROXY}/${rssUrls.url}`, (err, rss) => {
				//   this.news = rss.items
				// })

				fetch(`${process.env.VUE_APP_PROXY}/${rssUrls.url}`)
					.then((response) => response.text())
					.then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
					.then((data) => {
						const items = data.querySelectorAll('item')
						items.forEach((el) => {
							this.news.push({
								title: el.querySelector('title').innerHTML,
								url: el.querySelector('link').innerHTML,
								description: el.querySelector('title').innerHTML,
								pubDate: el.querySelector('pubDate').innerHTML,
							})
						})
					})

				// const bus = new Vue()
				bus.$on('reload', () => {
					this.streamNone = 1
					this.$refs.video.load()
					let playPromise = this.$refs.video.play()
					if (playPromise !== undefined) {
						playPromise
							.then((response) => {
								// Automatic playback started!
								// Show playing UI.
								console.log('HAHAHAHAH', response)
							})
							.catch((error) => {
								// Auto-play was prevented
								// Show paused UI.
								console.log('HIHIHIHIHI', error)
							})
					}
				})
			}
		}, 1000)

		this.$store.dispatch('setHoldings')
		this.setOpenSellOrders()
	},
	beforeMount() { },
	mounted() {
		const ps1 = new PerfectScrollbar('#scrollbar1', {
			wheelPropagation: false,
		})
		ps1.update()

		// const ps2 = new PerfectScrollbar('.slider')
		// ps2.update()
	},
	methods: {
		// changeVideo(urlVideo) {
		//   fetch(urlVideo)
		//   .then(response => response.blob())
		//   .then(blob => {
		//     this.$refs.video.srcObject = blob;
		//     console.log('HEHEHEHE', blob)
		//     return this.$refs.video.play();
		//   })
		//   .then(response2 => {
		//     // Video playback started ;)
		//     console.log('HAHAHAHA', response2)
		//   })
		//   .catch(error => {
		//     // Video playback failed ;(
		//     console.log('HIHIHIHIHI', error, urlVideo)
		//   })
		// },
		setTradeBoxBefore() {
			this.setTradeBoxStatus = 'loading'

			this.tradeBox = []
			const contracts = this.contracts
			const streamingRate = this.$store.getters.getStreamingRate
			const golds = this.golds

			let arr = []

			if (golds && contracts) {
				for (let i = 0; i < contracts.length; i++) {
					if (contracts[i].attributes.contractStatusId != 'active') {
						continue
					}

					arr.push({
						id: contracts[i].id,
					})
				}

				this.setTradeBoxAfter(arr)
			}
		},
		setTradeBoxAfter(arr) {
			const contractIds = this.$store.state.contractIds
			const tradeBoxOrder = [contractIds.contract_001g, contractIds.contract_1g, contractIds.contract_100g, contractIds.contract_1kg]

			let tradeBoxTemp = []
			for (let j = 0; j < tradeBoxOrder.length; j++) {
				for (let i = 0; i < arr.length; i++) {
					if (arr[i].id == tradeBoxOrder[j]) {
						tradeBoxTemp.push(arr[i])
						arr.splice(i, 1)

						this.setTradeBoxStatus = 'idle'
						this.animatedBackgroundClass = 'animated-background-finish'
						break
					}
				}
			}

			this.tradeBox = tradeBoxTemp
		},
		updateTradeTicket(contractId, tradeBoxIndex) {
			const contract = this.contracts.find((obj) => obj.id == contractId)
			const contractConfig = this.$store.getters.getContractConfigs(contractId)
			const tradeBox = this.tradeBox[tradeBoxIndex]

			let tradeTicketTemp = {}

			tradeTicketTemp.name = contractConfig.tradeTicketName
			tradeTicketTemp.weight = contractConfig.weightInGram
			tradeTicketTemp.ticker = contract.attributes.ticker

			if (!contract) {
				tradeTicketTemp.goldId = '0'
				tradeTicketTemp.productIsActive = false
				tradeTicketTemp.weight = 0
			} else if (tradeBox) {
				tradeTicketTemp.ticker = contract.attributes.ticker
				tradeTicketTemp.productIsActive = contract.attributes.contractStatusId
				tradeTicketTemp.goldId = tradeBox.id
				tradeTicketTemp.buyPrice = tradeBox.buy
				tradeTicketTemp.sellPrice = tradeBox.sell
				tradeTicketTemp.weightCorrection = contractConfig.weightCorrection
				tradeTicketTemp.contractId = contract.id
				// this.$store.dispatch('setOrderInTradeTicket', contract.id)
			}

			this.tradeTicket = tradeTicketTemp
		},

		setOpenSellOrders() {
			const listContract = this.$store.state.contractIds
			let contractParam = ''
			for (let key in listContract) {
				contractParam += 'contractId[]=' + listContract[key] + '&'
			}

			axios
				.post('/v2/igx/orders?order_type=limit&' + contractParam + 'status=submit', {
					user_id: this.$store.state.userId,
				})
				.then((response) => {
					if (!response.data.data.body) {
						return
					}

					const orders = response.data.data.body.filter((obj) => obj.attributes.direction == 'sell')

					const uniqueContract = [...new Set(orders.map((obj) => obj.attributes.contractId))]

					let temp = {}
					for (let i = 0; i < uniqueContract.length; i++) {
						const orderWithId = orders.filter((obj) => obj.attributes.contractId == uniqueContract[i])

						let quantityTemp = 0
						orderWithId.forEach((order) => {
							quantityTemp += Decimal.div(order.attributes.quantity, this.$store.getters.getContractConfigs(uniqueContract[i]).weightCorrection).toNumber()
						})

						this.$set(temp, uniqueContract[i], quantityTemp)
					}

					// const temp = {
					//   [this.$store.state.contractIds.contract_100g]: orders.filter(obj => obj.attributes.contractId == this.$store.state.contractIds.contract_100g),
					//   [this.$store.state.contractIds.contract_1kg]: orders.filter(obj => obj.attributes.contractId == this.$store.state.contractIds.contract_1kg),
					//   [this.$store.state.contractIds.contract_1g]: orders.filter(obj => obj.attributes.contractId == this.$store.state.contractIds.contract_1g),
					//   [this.$store.state.contractIds.contract_001g]: orders.filter(obj => obj.attributes.contractId == this.$store.state.contractIds.contract_001g)
					// }

					this.openSellOrders = temp
				})
				.catch((error) => {
				})
		},

		playStream() {
			this.streamPlayButtonDisplay = 'none'
			this.srcStream1 = 'https://livenewschat.eu/stocks/'
			this.srcStream2 = ''
		},
		changeStream(value) {
			this.streamPlayButtonDisplay = 'none'
			if (value == 'stream1') {
				this.srcStream1 = 'https://livenewschat.eu/stocks/'
				this.srcStream2 = ''
			} else {
				this.srcStream1 = ''
				this.srcStream2 = 'https://livenewschat.eu/business/'
			}
		},
		refreshStream() {
			if (this.srcStream1 !== '') {
				document.getElementById('videoFrame1').src = document.getElementById('videoFrame1').src
			} else {
				document.getElementById('videoFrame2').src = document.getElementById('videoFrame2').src
			}
		},
		goTo(url) {
			window.open(url, '_blank')
			// window.location.assign(url)
		},
	},
}
</script>

<style scoped>
.main-video {
	margin-top: 49px;
	width: 100%;
}

.c-trade-box {}

.stream-play-button {
	margin-top: 100px;
}

.stream-play-button:hover {
	cursor: pointer;
}

/*#frameContainer {
    pointer-events: ;
  }*/

.slider {
	/*background-color: black;*/
	height: 300px;
	display: flex;
	overflow-x: auto;
}

.slider-item {}

.news-title a {
	color: rgba(255, 255, 255, 0.8);
}

.news-title:hover {
	cursor: pointer;
	text-decoration: underline;
}

.c-nav-tabs>li {
	float: none;
	text-align: center;
	display: inline-block;
}

.loader-chart {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@keyframes placeHolderShimmer {
	0% {
		background-position: -800px 0;
	}

	100% {
		background-position: 800px 0;
	}
}

.animated-background-finish {
	background-color: transparent;
	min-height: 189px;
	/*margin-bottom: 25px;*/
}

.animated-background {
	min-height: 189px;
	margin-bottom: 25px;
	border-radius: 0.26rem;

	animation-duration: 2s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background-color: #f6f7f8;
	background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.1) 50%, transparent 100%);
	background-size: 800px 104px;
	height: 70px;
	position: relative;
}

.css-dom:empty {
	width: 280px;
	height: 220px;
	border-radius: 6px;
	box-shadow: 0 10px 45px rgba(0, 0, 0, 0.2);
	background-repeat: no-repeat;

	background-image: radial-gradient(circle 16px, lightgray 99%, transparent 0), linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray), linear-gradient(#fff, #fff);

	background-size: 32px 32px, 200px 32px, 180px 32px, 230px 16px, 100% 40px, 280px 100%;

	background-position: 24px 30px, 66px 30px, 24px 90px, 24px 142px, 0 180px, 0 0;
}
</style>
