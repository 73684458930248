<template>
	<div id="openModal" :class="modalClass">
		<div class="row c-modal-head">
			<div class="col-12 text-center">
				<span class="c-title">DETAIL - {{ data_prop.transactionId }}</span>
			</div>
		</div>

		<div class="row">
			<div v-if="apiStatusGetDetailBullionWithdrawal == 'loading'" class="col-12 text-center">
				<Loader
					size_prop="10em"
				></Loader>
			</div>
			<div v-else-if="detailBullionWithdrawal" class="col">
				<div class="mb-2">
					<span class="text-left">Hin</span>
					<div class="text-right float-right text-white">{{ $store.getters.getHin}}</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Receipt Number</span>
					<div class="text-right float-right text-white">{{ detailBullionWithdrawal.receipt_number || detailBullionWithdrawal.ref_id.substring(2, 9) + detailBullionWithdrawal.transaction_id.substring(3, 7) }}</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Date of Shipment</span>
					<div class="text-right float-right text-white">{{ detailBullionWithdrawal.shipping_date || '-' }}</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Destination</span>
					<div class="text-right float-right text-white">-</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Quantity</span>
					<div class="text-right float-right text-white">{{ `${detailBullionWithdrawal.total_item}  ${(detailBullionWithdrawal.total_item != 1) ? 'bars' : 'bar'} `}}</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Weight</span>
					<div class="text-right float-right text-white">{{ detailBullionWithdrawal.total_weight }} gram</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Value Goods</span>
					<div class="text-right float-right text-white">Rp. {{ detailBullionWithdrawal.value_goods }}</div>
				</div>

				<div class="mb-2">
					<span class="text-left">Total Charges</span>
					<div class="text-right float-right text-white">Rp. {{  }}</div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center c-modal-body">
			<div class="col-auto text-center">

				<div v-show="apiStatusGetDetailBullionWithdrawal == 'idle'" id="qrcode" class="p-2 bg-white">
				</div>
			</div>
		</div>
		<div class="row c-modal-foot">
			<div class="col-6">
				<button id="buttonCloseDetailOrder" type="button" class="btn btn-sm btn-secondary" @click="closeModal()">Close</button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from '@/api/axios.js'

	import Loader from '@/components/Loader.vue'

	export default {

	  name: 'DetailBullionWithdrawal',

	  props: {
			data_prop: Object
		},

		components: {
			Loader
		},

	  data () {
	    return {
	    	modalDialog: true,
	    	'modal-open': true,

	    	scriptQR: null,

	    	detailBullionWithdrawal: {},
	    	apiStatusGetDetailBullionWithdrawal: 'loading',
	    }
	  },

	  computed: {
	    modalClass() {
				return {
					modalDialog: true,
					'modal-open': true
				}
			},
	  },

	  watch: {
	  	data_prop() {
	  		if (this.scriptQR) {
					new QRCode(document.getElementById("qrcode"), this.data_prop.transactionId)
					this.setDetailBullionWithdrawal()
	  		}
	  	}
	  },

	  created() {
	  	this.scriptQR = document.createElement('script')
		  this.scriptQR.src = "https://cdnjs.cloudflare.com/ajax/libs/qrcodejs/1.0.0/qrcode.js"
		  document.body.appendChild(this.scriptQR)
	  },

	  mounted() {
	  	// const firstCallDetailBullion = setInterval(() => {
	  	// 	if (this.scriptQR) {
			// 		clearInterval(firstCallDetailBullion)
			// 		new QRCode(document.getElementById("qrcode"), this.data_prop.transactionId)
	  	// 	}
	  	// }, 300)
	  },

	  methods: {
			closeModal() {
				this.$emit('close')
				if (document.getElementById("qrcode")) {
					document.getElementById("qrcode").innerHTML = ""
				}
			},

			setDetailBullionWithdrawal() {
				this.apiStatusGetDetailBullionWithdrawal = 'loading'

				axios({
					method: 'GET',
					url: '/v2/poslog/detail_withdrawal/' + this.data_prop.transactionId,
				}).then(response => {
					this.apiStatusGetDetailBullionWithdrawal = 'idle'

					this.detailBullionWithdrawal = response.data.data.body
					
				}).catch(error => {
					this.apiStatusGetDetailBullionWithdrawal = 'idle'

					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}

				})
			}
	  }
	}
</script>

<style lang="css" scoped>
	.modalDialog {
		position: fixed;
		font-family: Arial, Helvetica, sans-serif;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		padding-left: 20px;
		padding-right: 20px;
		overflow-y: scroll;
		background: rgba(0,0,0,0.8);
		z-index: 1050;
		opacity:0;
		-webkit-transition: opacity 400ms ease-in;
		-moz-transition: opacity 400ms ease-in;
		transition: opacity 400ms ease-in;
		pointer-events: none;
	}

	.modalDialog:target {
		opacity:1;
		pointer-events: auto;
	}
	
	.modal-open {
		opacity: 1;
		pointer-events: auto;	
	}

	.modalDialog > div {
		position: relative;
		max-width: 550px;
		margin-right: auto;
		margin-left: auto;
		padding: 10px 20px;
		background: #191f31;
		background: -moz-linear-gradient(#fff, #999);
		background: -webkit-linear-gradient(#fff, #999);
		background: -o-linear-gradient(#fff, #999);
	}

	.c-modal-head {
		border-radius: 7px 7px 0px 0px;
		margin-top: 30px;
		border-bottom: 1px solid white;
	}
	.c-modal-body {

	}
	.c-modal-foot {
		border-radius: 0px 0px 7px 7px;
		margin-bottom: 30px;
		border-top: 1px solid white;
	}

	.c-title {
		font-size: 1.5em;
	}
</style>