<template>
	<nav v-if="maxPage > 0" :aria-label="paginationName_prop">
		<ul class="pagination">
			<li class="page-item">
				<a class="c-page-link px-1" aria-label="Start" @click="start()">
					<span aria-hidden="true">&#10094;&#10094;</span>
				</a>
			</li>

			<li class="page-item">
				<a class="c-page-link px-1" aria-label="Previous" @click="previous()">
					<span aria-hidden="true">&#10094;</span>
				</a>
			</li>

			<li v-if="selected >= 3 && maxPage > 3" class="page-item">
				<a class="c-page-link px-1">
					<span aria-hidden="true">...</span>
				</a>
			</li>

			<li v-for="n in (maxPage >= 3 ? 3 : maxPage)" :key="n" :class="{'page-item': true, active: transformSelected(n) == selected}">
				<a class="page-link" @click="klik(transformSelected(n))">{{ transformSelected(n) }}</a>
			</li>

			<li v-if="selected <= maxPage - 2 && maxPage > 3" class="page-item">
				<a class="c-page-link px-1">
					<span aria-hidden="true">...</span>
				</a>
			</li>
			
			<li class="page-item">
				<a class="c-page-link px-1" aria-label="Next" @click="next()">
					<span aria-hidden="true">&#10095;</span>
				</a>
			</li>

			<li class="page-item">
				<a class="c-page-link px-1" aria-label="End" @click="end()">
					<span aria-hidden="true">&#10095;&#10095;</span>
				</a>
			</li>
			
			<li v-if="status_prop != 'idle'">
				<Loader
					size_prop="2.5em"
					borderSize_prop="5px"
				></Loader>
			</li>
		</ul>
	</nav>
</template>

<script>
	import Decimal from 'decimal.js'

	import Loader from '@/components/Loader.vue'

	export default {
		name: 'Pagination2',
		props: {
			paginationName_prop: {
				type: String,
				default: 'pagination'
			},
			totalData_prop: {
				type: Number,
				default: 10
			},
			status_prop: {
				type: String,
				default: 'idle'
			}
		},

		components: {
			Loader
		},

		data() {
			return {
				selected: 1
			}
		},

		computed: {
			maxPage() {
				return Decimal.div(this.totalData_prop, 10).ceil().toNumber()
			}
		},

		watch: {
			selected(newValue) {
				this.$emit('changePage', (newValue - 1) * 10)
			}
		},
		methods: {
			klik(selectedValue) {
				this.selected = selectedValue
			},

			start() {
				this.selected = 1
			},

			previous() {
				let temp = this.selected
				temp--
				if (temp < 1) {
					this.selected = 1
				}else {
					this.selected = temp
				} 
			},

			next() {
				let temp = this.selected
				temp++
				if (temp > this.maxPage) {
					this.selected = this.maxPage
				}else {
					this.selected = temp
				} 
			},

			end() {
				this.selected = this.maxPage
			},

			transformSelected(n) {
				if (this.maxPage >= 3) {
					return (this.selected <= 1 ? this.selected + n - 1 : (this.selected >= this.maxPage ? this.selected + n - 3 : this.selected + n - 2))
				}else {
					return n
				}
			},

			resetPagination() {
				this.selected = 1
			}
		}
	}
</script>

<style scoped>
	.c-page-link {
		border: 0;
    border-radius: 30px !important;
    transition: all .3s;
    margin: 0 3px;
    min-width: 30px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    text-transform: uppercase;
    outline: none;
	}
	.page-item.active a.page-link {
		background-color: var(--theme-color);
		background-image: none;
	}

	.page-item.active a.page-link:hover {
		background-color: var(--theme-color);
		background-image: none;
	}
</style>