<template>
	<div v-if="isModalOpen" class="modal-c" @click.self="closeModal()">

		<!-- Modal content -->
		<div class="modal-content-c">
			<slot></slot>
		</div>

	</div>
</template>

<script>
export default {

	name: 'Modal',

	props: {
		cancelable_prop: {
			type: Boolean,
			default: true
		},
	},

	data() {
		return {
			isModalOpen: false,
		}
	},

	methods: {
		openModal() {
			this.isModalOpen = true
		},
		closeModal() {
			if (this.cancelable_prop) {
				this.isModalOpen = false
				this.$emit('close')
			}
		},
		forceCloseModal() { // ignore cancelable_prop
			this.isModalOpen = false
			this.$emit('close')
		},
	},
}
</script>

<style lang="css" scoped>
/* The Modal (background) */
.modal-c {
	display: block;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 9999;

	/* Sit on top */
	padding-top: 60px;

	/* Location of the box */
	left: 0;
	top: 0;
	width: 100%;
	/* Full width */
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	background-color: rgb(0, 0, 0);
	/* Fallback color */
	background-color: rgba(0, 0, 0, 0.4);
	/* Black w/ opacity */
}

/* Modal Content */
.modal-content-c {
	display: inline-block;
	position: absolute;

	left: 50%;
	transform: translateX(-50%);

	/* border: 1px solid #888; */
	border-radius: 6px;
	background-color: var(--grey-1-color);
	padding: 1.5em;
	min-width: min(28.5em, 100%);
	color: white;

	box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);
}

/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}
</style>