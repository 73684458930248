<template>
	<div class="filter-status ws-nowrap">
		<button :class="buttonOpenClass" tabindex="-1"
			@click="filterStatus('submit')">Open</button>
		<button :class="buttonFilledClass" tabindex="-1"
			@click="filterStatus('fill')">Filled</button>
		<button :class="buttonKilledClass" tabindex="-1"
			@click="filterStatus('cancel')">Killed</button>
	</div>
</template>

<script>
export default {
	name: 'FilterStatus',
	props: {
		filterStatus_prop: {
			type: String,
			default: 'submit'
		}
	},
	data() {
		return {
			activeOpen: true,
			activeFilled: false,
			activeKilled: false,
		}
	},
	computed: {
		buttonOpenClass() {
			return {
				'c-btn-active': this.activeOpen
			}
		},
		buttonFilledClass() {
			return {
				'c-btn-active': this.activeFilled
			}
		},
		buttonKilledClass() {
			return {
				'c-btn-active': this.activeKilled
			}
		}
	},
	watch: {
		filterStatus_prop(newValue) {
			if (newValue == 'cancel') {
				this.activeOpen = false
				this.activeFilled = false
				this.activeKilled = true
			} else if (newValue == 'fill') {
				this.activeOpen = false
				this.activeFilled = true
				this.activeKilled = false
			} else {
				this.activeOpen = true
				this.activeFilled = false
				this.activeKilled = false
			}
		}
	},
	methods: {
		filterStatus(value) {
			this.$emit('filterStatus', value)
		}
	}
}
</script>

<style scoped>
.filter-status {
	background-color: #1e1e2c;
	display: inline-block;
	padding: 2px;
	border: 1px solid #2b3553;
	border-radius: 4px;
	height: auto;
	vertical-align: middle;
}

button {
	background-color: #1e1e2c;
	color: white;
	border: 0px;
	/*border-radius: 5px;*/
	padding: 5px 15px;
	height: 2.375em;
}

button:hover {
	/* background-color: #3b3b56; */
	cursor: pointer;
	filter: brightness(125%);
}

button:active {
	background-color: #2e3d61;
}

.c-btn-active {
	background-color: var(--theme-color);
	border-radius: 2px;
}</style>