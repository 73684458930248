export default {
	methods: {
		getListSeparator() {
			var list = ['a', 'b'], str
			if (list.toLocaleString) {
				str = list.toLocaleString()
				if (str.indexOf(';') > 0 && str.indexOf(',') == -1) {
					return ';'
				}
			}
			return ','
		},

		exportJsonToCsv(data, filename) {
			let csvData = this.convertToCSV(data)
			this.downloadCsv(filename, csvData)
		},

		convertToCSV(objArray) {
			const array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
			let str = ''

			for (let i = 0; i < array.length; i++) {
				let line = ''

				for (let index in array[i]) {
					if (line !== '') line += ','
					line += array[i][index]
				}

				str += line + '\r\n'
			}

			return str
		},

		exportToCSV(data, filename) {
			const separator = this.getListSeparator()
			let csv = ''
			let keys = Object.keys(data[0])
			let values = Object.values(data)
			let row = keys.join(',')
			csv += row + '\r\n'
			values.forEach((item) => {
				row = ''
				keys.forEach((key) => {
					row += item[key] + ','
				})
				row = row.slice(0, -1)
				csv += row
				csv += '\r\n'
			})
			this.downloadCsv(filename, csv)
		},

		downloadCsv(filename, csvData) {
			const element = document.createElement("a")

			element.setAttribute("href", `data:text/csv;charset=utf-8,${csvData}`)
			element.setAttribute("download", filename)
			element.style.display = "none"

			document.body.appendChild(element)
			element.click()
			document.body.removeChild(element)
		}
	}
}