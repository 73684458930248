<template>
	<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 612 792" xml:space="preserve" :style="svgStyle">
    <g class="md2-appear">
      <polygon stroke="green" stroke-miterlimit="10" points="13.5,67 174.8,67 260.7,221.1 191,331.2 "></polygon>
      <polygon stroke="#FFFFFF" stroke-miterlimit="10" points="273.4,546.5 175.8,725 13.1,725 194,432.7 "></polygon>
      <polygon stroke="#FFFFFF" stroke-miterlimit="10" points="419.5,67 575.2,67 382.3,391.9 598.9,725 457.7,725 225.5,381.3 "></polygon>
      <polygon stroke="#FFFFFF" stroke-miterlimit="10" points="129.1,385.7 50.8,262.6 90.8,262.6 169.1,385.7 118,458.2 78.1,458.2 "></polygon>
    </g>
  </svg>
</template>

<script>
	export default {
		name: 'LogoAbx',
		props: {
			inActive_prop: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			svgStyle() {
				let color = '#f05a28'
				if (this.inActive_prop) {
					color = 'grey'
				}
				return `
					fill: ${color};
				`
			}
		}
	}
</script>

<style scoped>
	svg {
		position: relative;
		border-radius: 12%;
		text-align: center !important;
		display: inline-block;
		/*margin-top: -14px;*/
		background: transparent;
		width: 94px;
		height: 94px;
		/*padding: 8px;*/
		fill: #f05a28;
	}
</style>