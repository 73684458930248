<template>
	<div>
		<div id="openModal" :class="modalClass">
			<div v-if="this.getDetailOrderStatus == 'error'">
				<div class="row">
					<div class="col-12 text-center">
						<h2>Data cannot be displayed at the moment. Please try again in a few minutes.</h2>
					</div>
					<div class="col-12 text-center">
						<button type="button" class="w-100 btn c-btn-theme" @click="closeModal()">Close</button>
					</div>
				</div>
			</div>
			<div v-else-if="this.getDetailOrderStatus == 'idle'">
				<!-- <a title="Close" class="close">X</a> -->
				<!-- <div class="row">
					<div class="col-12 mt-3 text-center" style="color: gold;">
						<big>{{ name }}</big>
					</div>
				</div> -->

				<div class="flex flex--justify-content-between flex--align-items-center gap-10 color-white-c">
					<div class="fs-24-c flex-1">Detail Order</div>
					<div class="flex-1 text-right">
						<div>{{ $store.getters.getContractConfigs(contractId_prop).codeName }}
							<span class="fs-10-c color-muted-c">
								{{ ticker_prop.includes('KAU_USD') || ticker_prop.includes('MGAU') ? 'KAU' : ' ' }}
							</span>
						</div>
						<div class="fw-700">{{ name }}</div>
					</div>
					<div><img class="" :src="`${publicPath}assets/icon/gold.png`" alt="" width="20" /></div>
					<!-- <h4 class="modal-title" id="exampleModalLabel" style="color: white">Trade Ticket</h4> -->
				</div>

				<hr class="bg-white-c">

				<div class="row color-white-c" :style="{ display: displayFormEdit }">
					<div class="container rounded text-left value-field mb-0 pb-0">
						<div class="col-12">
							<div class="mb-3">
								<span class="text-left">Account</span>
								<div class="text-right float-right text-white">{{ account }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Direction</span>
								<div class="text-right float-right text-uppercase font-weight-bold"
									:style="{ color: (direction_prop == 'sell') ? 'red' : 'green' }">{{ direction_prop }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Valid Until</span>
								<div class="text-right float-right text-white">{{ validUntil_prop }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Original Quantity</span>
								<div class="text-right float-right text-white number-font">{{ quantity }}</div>
							</div>
							<div class="mb-3">
								<span class="text-left">Original Price Per Gram</span>
								<div class="text-right float-right text-white number-font">{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ numberFormatExtend(pricePerGram) }}</div>
							</div>
							<div class="mb-3">
								<span class="text-left">Original Contract Price</span>
								<div class="text-right float-right text-white number-font">{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ numberFormatExtend(contractPrice) }}</div>
							</div>

						</div>

						<hr style="background-color: gray;">

						<div class="container rounded text-left">

							<div v-if="validity_prop == 'GTD'" class="mb-3">
								<span class="text-left">Expire Date</span>
								<input type="datetime-local" name="" class="float-right rounded c-input c-date-picker"
									v-model="limitOrderDate" :min="minExpiryDateInInput" onkeydown="return false">
							</div>

							<div class="mb-3" style="line-height: 26px;">
								<span class="text-left">Quantity</span>
								<div class="float-right c-input-field">
									<button class="button button-counter decrease" @click="decreaseQuantityIntEdit()">-</button>
									<input ref="quantityMarket" class="c-input number-font text-white c-hide-spinner" type="number" name=""
										v-model.number="quantityIntEditInInput">
									<button class="button-counter increase" @click="increaseQuantityIntEdit()">+</button>
								</div>
							</div>

							<div v-if="(this.$store.state.contractConfigs[contractId_prop] || {}).hasQuantityDecimal" class="mb-3"
								style="line-height: 26px;">
								<span class="text-left" style="color: transparent;">-</span>
								<div class="float-right c-input-field">
									<button class="button button-counter decrease" @click="decreaseQuantityDecimalEdit()">-</button>
									<input ref="quantityMarket" class="c-input number-font text-white c-hide-spinner" type="text" name=""
										v-model.number="quantityDecimalEditInInput">
									<button class="button-counter increase" @click="increaseQuantityDecimalEdit()">+</button>
								</div>
							</div>

							<div v-if="(this.$store.state.contractConfigs[contractId_prop] || {}).hasQuantityDecimal" class="mb-3">
								<span class="text-left">Total Quantity</span>
								<div class="text-right float-right text-white">{{ quantityEdit }}</div>
							</div>

							<div class="mb-3" style="line-height: 26px;">
								<span class="text-left">Price Gram</span>
								<div class="float-right c-input-field">
									<button class="button button-counter decrease" @click="decreasePricePerGramEdit()">-</button>
									<input class="c-input number-font text-white c-hide-spinner" type="text" name=""
										v-model.number="pricePerGramEditInInput">
									<button class="button-counter increase" @click="increasePricePerGramEdit()">+</button>
								</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Contract Price</span>
								<div class="text-right float-right text-white number-font">
									{{ (currency == 'idr') ? 'Rp' : '$' }} {{ contractPriceEdit }}
								</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Contract Value</span>
								<div class="text-right float-right text-white">{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ contractValueEditShown }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Fee</span>
								<div class="text-right float-right text-white number-font">{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ feeEditShown }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Tax</span>
								<div class="text-right float-right text-white number-font">{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ taxEditShown }}</div>
							</div>

							<div>
								<span class="text-left" style="font-size: 16px;">Total</span>
								<div class="text-right float-right number-font total-value">$ {{ totalUsdLimitEditShown }}</div>
								<div class="text-right number-font total-value total-value-idr">Rp {{ totalIdrLimitEditShown }}</div>
							</div>
							<div v-if="direction == 'buy'" class="mt-1">
								<span class="text-right">FX Buffer <span class="text-gold number-font">/5%</span></span>
								<div class="text-right float-right text-white number-font">Rp {{ fxBufferEditShown }}</div>
							</div>

						</div>

						<div v-if="priceOzt15PercentAway"
							:class="`row alert alert-danger ${$store.getters.getContractConfigs(contractId_prop).contractType != 'kau' ? 'bg-gold' : ''} mt-4 mb-0 mx-16-c px-2 py-2`">
							<div class="col-auto" style="font-size: 2.2em;">&#9888;</div>
							<div class="col text-center pl-2">
								The price of this order is {{ Decimal.mul(minMaxLimitPrice, 100).toNumber() }}% away from current market
								price.
							</div>
						</div>
					</div>

				</div>

				<!-- OPEN -->
				<div class="row" :style="{ display: displayFormOriginalOpen }">
					<div class="col-12">
						<div class="container rounded text-left value-field ">

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Order ID</span>
								<div class="text-right float-right text-white">{{ orderId }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Status</span>
								<div class="text-right float-right text-white text-capitalize">{{ status }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Direction</span>
								<div
									:class="`${direction_prop == 'buy' ? 'color-green-c' : 'color-red-c'} text-right float-right text-white fw-700 text-uppercase`">
									{{ direction_prop }}
								</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Order Type</span>
								<div class="text-right float-right text-white text-capitalize">{{ type }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Valid Until</span>
								<div class="text-right float-right text-white text-capitalize">{{ validUntil }}</div>
							</div>

							<div class="mb-16-c">
								<span class="text-left text color-white-c">Hub</span>
								<div class="text-right float-right text-white">JAK</div>
							</div>


							<div class="mb-6-c">
								<span class="text-left text color-white-c">Quantity</span>
								<div class="text-right float-right text-white">{{ quantity }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Remain / Total Gram</span>
								<div class="text-right float-right text-white">{{ data_prop.remain }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Price Per Gram</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ numberFormatExtend(pricePerGram) }}
								</div>
							</div>

							<div class="mb-16-c">
								<span class="text-left text color-white-c">Contract Price</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ numberFormatExtend(contractPrice) }}
								</div>
							</div>


							<div class="mb-6-c">
								<span class="text-left text color-white-c">Contract Value</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ numberFormatExtend(contractValueShown) }}
								</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Fee</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ feeShown }}
								</div>
							</div>

							<div class="">
								<span class="text-left text color-white-c">Tax</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ taxShown }}
								</div>
							</div>

							<div v-if="direction == 'buy'" class="mt-6-c">
								<span class="text-left text color-white-c">Fx Buffer / 5%</span>
								<div class="text-right float-right text-white">Rp {{ fxBufferShown }}</div>
							</div>

							<div class="mt-16-c mb-6-c fw-700">
								<span class="text-left text color-white-c">Estimated Price</span>
								<div class="text-right float-right text-white">$ {{ totalUsdLimitShown }}</div>
								<div class="text-right color-white-c mt-1">Rp {{ totalIdrLimitShown }}</div>
							</div>

						</div>
					</div>
				</div>

				<div class="row" :style="{ display: displayFormOriginalFilled }">
					<div class="col-12">
						<div class="container rounded text-left value-field ">
							<div class="mb-6-c">
								<span class="text-left text color-white-c">Transaction ID</span>
								<div class="text-right float-right text-white">{{ data_prop.transactionId }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Order ID</span>
								<div class="text-right float-right text-white">{{ orderId }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Status</span>
								<div class="text-right float-right text-white text-capitalize">Matched</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Direction</span>
								<div
									:class="`${direction_prop == 'buy' ? 'color-green-c' : 'color-red-c'} text-right float-right text-white fw-700 text-uppercase`">
									{{ direction_prop }}
								</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Order Type</span>
								<div class="text-right float-right text-white text-capitalize">{{ type }}</div>
							</div>

							<div class="mb-16-c">
								<span class="text-left text color-white-c">Hub</span>
								<div class="text-right float-right text-white">JAK</div>
							</div>


							<div class="mb-6-c">
								<span class="text-left text color-white-c">Quantity</span>
								<div class="text-right float-right text-white">{{ quantity }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Total Gram</span>
								<div class="text-right float-right text-white">{{ totalGram }}</div>
							</div>

							<div class="mb-16-c">
								<span class="text-left text color-white-c">Price Per Gram</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp ' + numberFormatExtend(data_prop.pricePerGramIdr) : '$ ' +
										numberFormatExtend(data_prop.pricePerGram) }}
								</div>
							</div>


							<div class="mb-6-c">
								<span class="text-left text color-white-c">Metal Value of Trade</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp ' + numberFormatExtend(data_prop.metalValueOfTradeIdr) : '$ ' +
										numberFormatExtend(data_prop.metalValueOfTrade) }}
								</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Fee</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp ' + numberFormatExtend(data_prop.feeIdr) : '$ ' +
										numberFormatExtend(data_prop.tax, 3) }}
								</div>
							</div>

							<div class="">
								<span class="text-left text color-white-c">Tax</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp ' + numberFormatExtend(data_prop.taxIdr) : '$ ' +
										numberFormatExtend(data_prop.tax, 3) }}
								</div>
							</div>

							<div v-if="direction == 'buy'" class="mt-6-c">
								<span class="text-left text color-white-c">Fx Buffer / 5%</span>
								<div class="text-right float-right text-white">Rp {{ fxBufferShown }}</div>
							</div>

							<div class="mt-16-c mb-6-c fw-700">
								<span class="text-left text color-white-c">Settlement Amount</span>
								<div class="text-right float-right text-white">$ {{ numberFormatExtend(data_prop.settlementAmount) }}
								</div>
								<div class="text-right color-white-c mt-1">Rp {{ numberFormatExtend(data_prop.settlementAmountIdr) }}
								</div>
							</div>

							<!-- =========================== -->
							<!-- 
							<div class="mb-3">
								<span class="text-left">Account</span>
								<div class="text-right float-right text-white">{{ account }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Order ID</span>
								<div class="text-right float-right text-white">{{ orderId }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Status</span>
								<div class="text-right float-right text-white text-uppercase">{{ status }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Direction</span>
								<div class="text-right float-right text-uppercase font-weight-bold"
									:style="{ color: (direction == 'sell') ? 'red' : 'green' }">{{ direction }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Type</span>
								<div class="text-right float-right text-white text-uppercase">{{ type }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Valid Until</span>
								<div class="text-right float-right text-white text-uppercase">{{ validUntil }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Total Gram</span>
								<div class="text-right float-right text-white">{{ numberFormatExtend(totalGram) }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Quantity</span>
								<div class="text-right float-right text-white">{{ quantity }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Fee</span>
								<div class="text-right float-right text-white">{{ feeFilled }}</div>
							</div>

							<div class="mb-3">
								<span class="text-left">Tax</span>
								<div class="text-right float-right text-white">{{ taxFilled }}</div>
							</div>

							<div>
								<span class="text-left" style="font-size: 16px;">Total</span>
								<div class="text-right float-right number-font total-value">$ {{ totalUsdFilled }}</div>
								<div class="text-right number-font total-value total-value-idr">Rp {{ totalIdrFilled }}</div>
							</div> -->

						</div>
					</div>

				</div>

				<div class="row" :style="{ display: displayFormOriginalKilled }">
					<div class="col-12">
						<div class="container rounded text-left value-field ">

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Order ID</span>
								<div class="text-right float-right text-white">{{ orderId }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Status</span>
								<div class="text-right float-right text-white text-capitalize">{{ status }}</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Direction</span>
								<div
									:class="`${direction_prop == 'buy' ? 'color-green-c' : 'color-red-c'} text-right float-right text-white fw-700 text-uppercase`">
									{{ direction_prop }}
								</div>
							</div>

							<div class="mb-6-c">
								<span class="text-left text color-white-c">Order Type</span>
								<div class="text-right float-right text-white text-capitalize">{{ type }}</div>
							</div>

							<div class="mb-16-c">
								<span class="text-left text color-white-c">Hub</span>
								<div class="text-right float-right text-white">JAK</div>
							</div>


							<div class="mb-6-c">
								<span class="text-left text color-white-c">Quantity</span>
								<div class="text-right float-right text-white">{{ quantity }}</div>
							</div>

							<div class="mb-16-c">
								<span class="text-left text color-white-c">Total Gram</span>
								<div class="text-right float-right text-white">{{ totalGram }}</div>
							</div>


							<div class="mb-6-c fw-700">
								<span class="text-left text color-white-c">Contract Value</span>
								<div class="text-right float-right text-white">
									{{ (currency == 'idr') ? 'Rp' : '$' }}
									{{ numberFormatExtend(contractValueShown) }}
								</div>
							</div>

						</div>
					</div>
				</div>

				<hr>

				<div class="" :style="{ display: displayEditRowButton }">
					<div class="flex flex--justify-content-between flex--same-width gap-8">
						<button id="buttonCloseEditOrder" type="button" class="btn c-btn-black"
							@click="cancelEditForm()">Cancel</button>

						<!-- <button id="buttonCloseTradeTicket" type="button" class="col btn c-btn-danger px-0 mr-3" data-dismiss="modal" @click="resetValue()">Close</button> -->
						<button type="button" class="btn c-btn-theme" @click="updateOrder()">Submit</button>
					</div>
				</div>

				<div class="" :style="{ display: displayOriginalRowButton }">
					<div v-if="status_prop == 'submit'" class="flex flex--justify-content-between flex--same-width gap-8 mb-8-c">
						<button id="buttonCloseDetailOrder" type="button" class="btn c-btn-black color-red-c"
							@click="deleteOrder(orderId)">KILL</button>
						<button id="buttonCloseDetailOrder" type="button" class="btn c-btn-black color-theme-c"
							@click="showEditForm()">EDIT</button>
					</div>

					<button id="buttonCloseDetailOrder" type="button" class="w-100 btn c-btn-theme"
						@click="closeModal()">Close</button>

					<!-- <div class="col-6 text-left">
						<button id="buttonCloseDetailOrder" type="button" class="btn btn-sm btn-secondary"
							@click="closeModal()">Close</button>
					</div>
					<div v-if="this.$store.getters.getAccountLevel != 'ib' && this.$store.getters.getAccountLevel != 'fm' && this.$store.getters.getAccountLevel != 'ccp' && this.$store.getters.getAccountLevel != 'lp' && this.$store.getters.getBrokerName != 'pospaygold' " class="col-6 text-right">
						<button type="button" class="btn btn-sm btn-warning mr-2" :style="{ display: displayEdit }" @click="showEditForm()">Edit</button>
						<button type="button" class="btn btn-sm btn-danger" :style="{ display: displayKill }" @click="deleteOrder(orderId)">Kill</button>
					</div> -->
				</div>

			</div>
			<div v-else>
				<div class="row">
					<div class="col-12 text-center my-5">
						<Loader size_prop="10em"></Loader>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import Swal from 'sweetalert2'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
export default {
	name: 'DetailOrder',
	props: {
		data_prop: {
			type: Object,
			default: () => {
				return {}
			}
		},
		account_prop: {
			type: String,
			default: 'Account'
		},
		ticker_prop: {
			type: String,
			default: 'KAU_USD'
		},
		name: {
			type: String,
			default: 'Default'
		},
		orderId_prop: {
			type: String,
			default: ''
		},
		contractId_prop: {
			type: Number,
			default: -1
		},
		status_prop: {
			type: String,
			default: 'submit'
		},
		direction_prop: {
			type: String,
			default: 'buy'
		},
		type_prop: {
			type: String,
			default: 'limit'
		},
		validity_prop: {
			type: String,
			default: 'GTC'
		},
		validUntil_prop: {
			type: String,
			default: 'Until Cancel'
		},
		pricePerGram_prop: {
			type: Number,
			default: 0
		},
		totalGram_prop: {
			type: Number,
			default: 0
		},
		contractPrice_prop: {
			type: Number,
			default: 0
		},
		quantity_prop: {
			type: Number,
			default: 0
		},
		contractValue_prop: {
			type: Number,
			default: 0
		},
		modalOpen_prop: {
			type: Boolean,
			default: false
		},
		feeFilled_prop: {
			type: Number,
			default: -1,
		},
		taxFilled_prop: {
			type: Number,
			default: -1,
		},
		totalUsdFilled_prop: {
			type: Number,
			default: -1,
		}
	},
	components: {
		Loader
	},
	data() {
		return {
			Decimal: Decimal,
			publicPath: process.env.BASE_URL,

			formControl: false,
			formControlPlaintext: true,
			originalQuantity: 0,
			originalPricePerOzt: 0,
			originalContractPrice: 0,
			displayFormOriginal: 'flex',
			displayFormEdit: 'none',
			displayOriginalRowButton: 'block',
			displayEditRowButton: 'none',
			varia: true,
			displayFormOriginalOpenValue: '-',
			displayFormOriginalFilledValue: '-',
			displayFormOriginalKilledValue: '-',
			limitOrderDate: '',
			limitOrderTime: '',

			quantityIntEdit: 0,
			quantityDecimalEdit: 0,

			pricePerGramEdit: 0,

			// minExpiryDateInInput:`${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' : ''}${today.getMonth() + 1}-${today.getDate() + 1 < 10 ? '0' : ''}${today.getDate() + 1}`
		}
	},
	computed: {
		displayFormOriginalOpen: {
			get: function () {
				if (this.displayFormOriginalOpenValue == '-' && this.displayFormOriginalFilledValue == '-' && this.displayFormOriginalKilledValue == '-') {
					if (this.status_prop == 'submit') {
						return 'flex'
					} else {
						return 'none'
					}
				} else {
					return this.displayFormOriginalOpenValue
				}
			},
			set: function (value) {
				this.displayFormOriginalOpenValue = value
			},
		},
		displayFormOriginalFilled: {
			get: function () {
				if (this.displayFormOriginalOpenValue == '-' && this.displayFormOriginalFilledValue == '-' && this.displayFormOriginalKilledValue == '-') {
					if (this.status_prop == 'fill') {
						return 'flex'
					} else {
						return 'none'
					}
				} else {
					return this.displayFormOriginalFilledValue
				}
			},
			set: function (value) {
				this.displayFormOriginalFilledValue = value
			}
		},
		displayFormOriginalKilled: {
			get: function () {
				if (this.displayFormOriginalOpenValue == '-' && this.displayFormOriginalFilledValue == '-' && this.displayFormOriginalKilledValue == '-') {
					if (this.status_prop == 'cancel') {
						return 'flex'
					} else {
						return 'none'
					}
				} else {
					return this.displayFormOriginalKilledValue
				}
			},
			set: function (value) {
				this.displayFormOriginalKilledValue = value
			}
		},
		modalOpen() {
			return this.modalOpen_prop
		},
		modalClass() {
			return {
				modalDialog: true,
				'modal-open': true
			}
		},
		inputClass() {
			return {
				'form-control': this.formControl,
				'form-control-plaintext': this.formControlPlaintext,
				'form-control-sm': true,
				'px-2': true,
			}
		},

		currency() {
			return this.$store.state.currency
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		streamingRateSell() {
			if (!this.data_prop.rate) {
				return this.streamingRate.stream_sell.cur_rate
			} else {
				return this.data_prop.rate
			}
		},

		streamingRateBuy() {
			if (!this.data_prop.rate) {
				return this.streamingRate.stream_buy.cur_rate
			} else {
				return this.data_prop.rate
			}
		},

		streamingRateDynamic() {
			if (!this.data_prop.rate) {
				return (this.currency == 'idr') ? (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate) : 1
			} else {
				return (this.currency == 'idr') ? this.data_prop.rate : 1
			}
		},

		products() {
			return this.$store.getters.PRODUCTS
		},

		minMaxLimitPrice() {
			const contractConfig = this.$store.getters.getContractConfigs(this.contractId_prop)
			if (contractConfig.contractType == 'kau') {
				return this.$store.state.minMaxLimitPriceKAU
			}
			return this.$store.state.minMaxLimitPriceIGX
		},

		goldBalance() {
			const temp = this.$store.getters.getGoldBalanceByContractId(this.contractId_prop)

			return temp
		},

		sellPrice() {
			let gold = this.$store.getters.goldById(this.contractId_prop)
			if (gold) {
				if (gold.sell < 0 || gold.id == 0) {
					if (gold.buy < 0 || gold.id == 0) {
						return '-'
					} else {
						return gold.buy
					}
				} else {
					return gold.sell
				}
			} else {
				return '-'
			}
		},
		buyPrice() {
			let gold = this.$store.getters.goldById(this.contractId_prop)
			if (gold) {
				if (gold.buy < 0 || gold.id == 0) {
					if (gold.sell < 0 || gold.id == 0) {
						return '-'
					} else {
						return gold.sell
					}
				} else {
					return gold.buy
				}
			} else {
				return '-'
			}
		},

		account() {
			return this.account_prop
		},
		orderId() {
			return this.orderId_prop
		},
		status() {
			if (this.status_prop == 'submit') {
				return 'submit'
			} else if (this.status_prop == 'fill') {
				return 'fill'
			} else {
				return 'cancel'
			}
		},
		direction() {
			return this.direction_prop
		},
		type() {
			return this.type_prop
		},
		validUntil() {
			return this.validUntil_prop
		},
		pricePerGram() {
			return Decimal.mul(this.pricePerGram_prop, this.streamingRateDynamic).toFixed(2)
		},
		totalGram() {
			return this.totalGram_prop
		},
		contractPrice() {
			return Decimal.mul(this.contractPrice_prop, this.streamingRateDynamic).toFixed(2)
		},
		quantity() {
			return this.quantity_prop
		},

		contractValue() {
			return new Decimal(this.contractValue_prop).toFixed(2)
		},

		contractValueShown() {
			return Decimal.mul(this.contractValue, this.streamingRateDynamic).toFixed(2)
		},

		fee() {
			if (!this.data_prop.fee) {
				return Number(this.data_prop.fee)
			} else {
				let temp = new Decimal(this.contractValue).mul(this.$store.state.fee).toDecimalPlaces(3)
				if (temp.lessThan(this.$store.state.feeMin)) {
					return new Decimal(this.$store.state.feeMin)
				}
				return temp
			}
		},
		feeShown() {
			let streamingRate = this.streamingRateDynamic

			if (this.currency == 'usd') {
				return this.numberFormatExtend(Decimal.mul(this.fee, streamingRate).toFixed(3), 3)
			} else {
				return this.numberFormatExtend(Decimal.mul(this.fee, streamingRate).toFixed(2))
			}
		},

		tax() {
			if (!this.data_prop.tax) {
				return Number(this.data_prop.tax)
			} else {
				let temp = new Decimal(this.fee).mul(this.$store.state.tax).toDecimalPlaces(3)
				if (temp.lessThan(this.$store.state.taxMin)) {
					return new Decimal(this.$store.state.taxMin)
				}
				return temp
			}
		},
		taxShown() {
			let streamingRate = this.streamingRateDynamic

			if (this.currency == 'usd') {
				return this.numberFormatExtend(Decimal.mul(this.tax, streamingRate).toFixed(3), 3)
			} else {
				return this.numberFormatExtend(Decimal.mul(this.tax, streamingRate).toFixed(2))
			}

		},

		fxBuffer() {
			let temp = new Decimal(this.contractValue).mul(this.$store.state.fxBuffer).toDecimalPlaces(2)
			if (new Decimal(this.contractValue).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.fxBufferMin)) {
				return new Decimal(this.$store.state.fxBufferMin)
			}

			return temp
		},
		fxBufferShown() {
			return this.numberFormatExtend(Decimal.mul(this.streamingRateBuy, this.fxBuffer).toFixed(2))
		},

		totalUsdLimit() {
			return Number(this.data_prop.paymentUsd)
		},
		totalUsdLimitShown() {
			return this.numberFormatExtend(new Decimal(this.totalUsdLimit).toFixed(2))
		},

		totalIdrLimit() {
			return Number(this.data_prop.paymentIdr)
		},
		totalIdrLimitShown() {
			return this.numberFormatExtend(new Decimal(this.totalIdrLimit).toFixed(2))
		},

		displayEdit() {
			let display = ''
			if (this.type_prop == 'limit' && this.status_prop == 'submit') {
				display = 'initial'
			} else {
				display = 'none'
			}
			return display
		},
		displayKill() {
			let display = ''
			if (this.status_prop == 'submit') {
				display = 'initial'
			} else {
				display = 'none'
			}
			return display
		},
		feeFilled() {
			if (!this.data_prop.fee) {
				return '-'
			}
			return this.data_prop.fee.toFixed(3)
		},
		taxFilled() {
			if (!this.data_prop.tax) {
				return '-'
			}
			return this.data_prop.tax.toFixed(3)
		},
		totalUsdFilled() {
			if (!this.data_prop.paymentUsd) {
				return '-'
			}
			return this.numberFormatExtend(this.data_prop.paymentUsd.toFixed(2))
		},
		totalIdrFilled() {
			if (!this.data_prop.paymentIdr) {
				return '-'
			}
			return this.numberFormatExtend(this.data_prop.paymentUsd.toFixed(2))
		},

		minExpiryDateInInput() {
			const dateNow = new Date()

			const day = dateNow.getDate() < 10 ? '0' + dateNow.getDate() : dateNow.getDate()
			const month = dateNow.getMonth() + 1 < 10 ? '0' + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1
			const year = dateNow.getFullYear() < 10 ? '0' + dateNow.getFullYear() : dateNow.getFullYear()
			const hours = dateNow.getHours() < 10 ? '0' + dateNow.getHours() : dateNow.getHours()
			const minutes = dateNow.getMinutes() < 10 ? '0' + dateNow.getMinutes() : dateNow.getMinutes()

			const dateTemp = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes

			return dateTemp
		},

		expiryDate() {
			let date = new Date(this.limitOrderDate) // default GTD
			if (this.validity_prop == 'GTC') {
				let tempToday = new Date(Date.now())
				date = new Date(tempToday.setMonth(tempToday.getMonth() + 1)) // set +1 month from now
			}

			return date
		},

		pnlUsdEdit() {
			if (this.$store.state.setHoldingsApiStatus == 'success' && this.contractId_prop != '0' && this.contractId_prop) {
				const holding = this.$store.getters.getHoldingByContractId(this.contractId_prop)
				const tempPnl = this.$store.getters.getEstimatePNLUsdByContractIdForJfxChamp(this.contractId_prop, this.pricePerGramEdit)
				const pnlPerGram = Decimal.div(tempPnl, Decimal.mul(holding.quantity, this.$store.state.contractConfigs[this.contractId_prop].weightInGram)).toDecimalPlaces(4).toNumber()

				return Decimal.mul(pnlPerGram, this.gram).toDecimalPlaces(4, Decimal.ROUND_FLOOR).toNumber()
			}

			return null
		},
		pnlIdrEdit() {
			if (this.$store.state.setHoldingsApiStatus == 'success' && this.contractId_prop != '0' && this.contractId_prop) {
				const holding = this.$store.getters.getHoldingByContractId(this.contractId_prop)
				const tempPnl = this.$store.getters.getEstimatePNLIdrByContractIdForJfxChamp(this.contractId_prop, this.pricePerGramEdit)
				const pnlPerGram = Decimal.div(tempPnl, Decimal.mul(holding.quantity, this.$store.state.contractConfigs[this.contractId_prop].weightInGram)).toDecimalPlaces(4).toNumber()

				return Decimal.mul(pnlPerGram, this.gram).toDecimalPlaces(4, Decimal.ROUND_FLOOR).toNumber()
			}

			return null
		},

		quantityIntEditInInput: {
			get: function () {
				return this.quantityIntEdit
			},
			set: function (newValue) {
				let temp = Number(newValue)
				if (!Number.isInteger(temp)) {
					temp = Math.round(temp)
				}
				if (temp < 0) {
					this.quantityIntEdit = 0
				} else {
					this.quantityIntEdit = temp
				}
			}
		},
		quantityDecimalEditInInput: {
			get: function () {
				return this.quantityDecimalEdit
			},
			set: function (newValue) {
				let temp = new Decimal(newValue)
				if (temp.greaterThanOrEqualTo(1)) {
					this.quantityDecimalEdit = new Decimal(0.9)
				} else if (temp.lessThan(0)) {
					this.quantityDecimalEdit = new Decimal(0)
				} else {
					this.quantityDecimalEdit = new Decimal(temp.toFixed(1, Decimal.ROUND_FLOOR))
				}
			}
		},
		quantityEdit() {
			return Decimal.add(this.quantityIntEdit, this.quantityDecimalEdit)
		},

		pricePerGramEditInInput: {
			get: function () {
				return this.pricePerGramEdit
			},
			set: function (newValue) {
				let temp = new Decimal(newValue)
				if (temp.lessThan(0)) {
					this.pricePerGramEdit = new Decimal(0)
				}
				else {
					this.pricePerGramEdit = new Decimal(temp.toFixed(2))
				}
			}
		},

		contractPriceEdit() {
			return this.numberFormatExtend(Decimal.mul(Decimal.div(this.contractPrice_prop, this.pricePerGram_prop), this.pricePerGramEdit).toDecimalPlaces(2).mul(this.streamingRateDynamic).toFixed(2))
		},
		gram() {
			if (Decimal.mul(this.quantityEdit, this.$store.state.contractConfigs[this.contractId_prop].weightInGram).greaterThanOrEqualTo(0)) {
				return Decimal.mul(this.quantityEdit, this.$store.state.contractConfigs[this.contractId_prop].weightInGram)
			} else {
				return 0
			}
		},
		gramShown() {
			return this.numberFormatExtend(this.gram)
		},

		contractValueEdit() {
			let temp = (this.$store.state.contractConfigs[this.contractId_prop] || {}).weightInGram
			if (!temp) {
				return 0
			}
			temp = new Decimal(temp)

			let price = this.pricePerGramEdit

			if (price == '-') {
				return 0
			} else if (!(temp.equals(0.01))) {
				if (this.direction == 'buy') {
					return Decimal.mul(this.gram, price).toDecimalPlaces(2)
				} else {
					return Decimal.mul(this.gram, price).toDecimalPlaces(2)
				}
			} else {
				if (this.direction == 'buy') {
					return Decimal.mul(this.quantityEdit, price).toDecimalPlaces(2)
				} else {
					return Decimal.mul(this.quantityEdit, price).toDecimalPlaces(2)
				}
			}
		},
		contractValueEditShown() {
			return this.numberFormatExtend(new Decimal(this.contractValueEdit).mul(this.streamingRateDynamic).toFixed(2))
		},

		feeEdit() {
			let temp = new Decimal(this.contractValueEdit).times(this.$store.state.fee).toDecimalPlaces(3)
			if (new Decimal(this.contractValueEdit).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.feeMin)) {
				return new Decimal(this.$store.state.feeMin)
			}
			return temp
		},
		feeEditShown() {
			if (this.currency == 'usd') {
				return this.numberFormatExtend((this.feeEdit).times(this.streamingRateDynamic).toFixed(3), 3)
			} else {
				return this.numberFormatExtend((this.feeEdit).times(this.streamingRateDynamic).toFixed(2))
			}
		},

		taxEdit() {
			let temp = new Decimal(this.feeEdit).times(this.$store.state.tax).toDecimalPlaces(3)
			if (new Decimal(this.contractValueEdit).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.taxMin)) {
				return new Decimal(this.$store.state.taxMin)
			}
			return temp
		},
		taxEditShown() {
			if (this.currency == 'usd') {
				return this.numberFormatExtend((this.taxEdit).times(this.streamingRateDynamic).toFixed(3), 3)
			} else {
				return this.numberFormatExtend((this.taxEdit).times(this.streamingRateDynamic).toFixed(2))
			}
		},

		fxBufferEdit() {
			let temp = new Decimal(this.contractValueEdit).times(this.$store.state.fxBuffer).toDecimalPlaces(2)
			if (new Decimal(this.contractValueEdit).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.fxBufferMin)) {
				return new Decimal(this.$store.state.fxBufferMin)
			}
			return temp
		},
		fxBufferEditShown() {
			return this.numberFormatExtend(Decimal.mul(this.streamingRateBuy, this.fxBufferEdit).toFixed(2))
		},

		totalUsdLimitEdit() {
			let temp = new Decimal(this.contractValueEdit)
			if (this.direction == 'buy') {
				temp = temp.plus(this.taxEdit).plus(this.feeEdit)
			} else {
				temp = temp.minus(this.taxEdit).minus(this.feeEdit)
			}
			return Number(temp.toFixed(2))
			// return this.numberFormatExtend((Decimal.div(this.contractPrice_prop, this.pricePerGram_prop).mul(this.pricePerGramEdit).mul(this.quantityEdit)).toFixed(2))
		},
		totalUsdLimitEditShown() {
			return this.numberFormatExtend(this.totalUsdLimitEdit)
		},

		totalIdrLimitEdit() {
			let temp = new Decimal(this.totalUsdLimitEdit)

			if (this.direction == 'buy') {
				return temp.times(this.streamingRateBuy).plus(Decimal.mul(this.streamingRateBuy, this.fxBufferEdit)).toFixed(2)
			} else {
				return temp.times(this.streamingRateSell).toFixed(2)
			}

			// return this.numberFormatExtend((Decimal.div(this.contractPrice_prop, this.pricePerGram_prop).mul(this.pricePerGramEdit).mul(this.quantityEdit).mul(this.data_prop.rate)).toFixed(2))
		},
		totalIdrLimitEditShown() {
			return this.numberFormatExtend(this.totalIdrLimitEdit)
		},

		getDetailOrderStatus() {
			return this.$store.state.getDetailOrderStatus
		},

		priceOzt15PercentAway() {
			let price = '-'
			if (this.direction == 'buy') {
				price = this.buyPrice
			} else {
				price = this.sellPrice
			}
			if (price != '-') {
				price = new Decimal(price)
				let temp = new Decimal(this.pricePerGramEdit)

				if (temp.lessThanOrEqualTo(price.minus(price.times(this.minMaxLimitPrice))) || temp.greaterThanOrEqualTo(price.plus(price.times(this.minMaxLimitPrice)))) {
					return true
				}
			}
			return false
		},
	},

	watch: {
		quantity_prop(newValue) {
			this.quantityIntEdit = Math.floor(newValue)

			this.quantityDecimalEdit = Decimal.mod(newValue, 1)
			if (!this.$store.state.contractConfigs[this.contractId_prop].hasQuantityDecimal) {
				this.quantityDecimalEdit = 0
			}
		},
		pricePerGram_prop(newValue) {
			this.pricePerGramEdit = newValue
		},
		validUntil_prop(newValue) {
			this.limitOrderDate = `${newValue.substr(0, 4)}-${newValue.substr(5, 2)}-${newValue.substr(8, 2)} ${newValue.substr(11, 2)}:${newValue.substr(14, 2)}`
		},

		limitOrderDate(newValue) {
			const now = new Date()
			const expiryDate = new Date(newValue)

			const temp = new Date(now.setHours(now.getHours() + 1))

			if (expiryDate < temp) {
				const day = temp.getDate() < 10 ? '0' + temp.getDate() : temp.getDate()
				const month = temp.getMonth() + 1 < 10 ? '0' + (temp.getMonth() + 1) : temp.getMonth() + 1
				const year = temp.getFullYear() < 10 ? '0' + temp.getFullYear() : temp.getFullYear()
				const hours = temp.getHours() < 10 ? '0' + temp.getHours() : temp.getHours()
				const minutes = temp.getMinutes() < 10 ? '0' + temp.getMinutes() : temp.getMinutes()

				this.limitOrderDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
			}
		}
	},

	created() {
		this.limitOrderDate = `${this.validUntil_prop.substr(0, 4)}-${this.validUntil_prop.substr(5, 2)}-${this.validUntil_prop.substr(8, 2)} ${this.validUntil_prop.substr(11, 2)}:${this.validUntil_prop.substr(14, 2)}`
	},

	methods: {
		numberFormat(value) {
			let val = (value / 1)
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
		},
		showEditForm() {
			if (this.$store.state.marketStatus == 'close') {
				Swal.fire({
					background: '#191f31',
					title: '<span style="color: white;">The market is currently closed</span>',
					// html: '<span style="color: white;"></span>'
				})
			} else {
				this.limitOrderDate = `${this.validUntil_prop.substr(0, 4)}-${this.validUntil_prop.substr(5, 2)}-${this.validUntil_prop.substr(8, 2)} ${this.validUntil_prop.substr(11, 2)}:${this.validUntil_prop.substr(14, 2)}`
				// this.limitOrderTime = {hour: this.validUntil_prop.substr(11, 5)}

				this.displayFormEdit = 'flex'
				this.displayOriginalRowButton = 'none'
				this.displayEditRowButton = 'block'

				this.displayFormOriginalOpen = 'none'
				this.displayFormOriginalFilled = 'none'
				this.displayFormOriginalKilled = 'none'
			}

		},
		cancelEditForm() {
			// reset quantity and price per gram
			this.quantityIntEdit = Math.floor(this.quantity_prop)
			this.pricePerGramEdit = this.pricePerGram_prop

			this.displayFormEdit = 'none'
			this.displayOriginalRowButton = 'block'
			this.displayEditRowButton = 'none'

			if (this.status_prop == 'submit') {
				this.displayFormOriginalOpen = 'flex'
				this.displayFormOriginalFilled = 'none'
				this.displayFormOriginalKilled = 'none'
			} else if (this.status_prop == 'fill') {
				this.displayFormOriginalOpen = 'none'
				this.displayFormOriginalFilled = 'flex'
				this.displayFormOriginalKilled = 'none'
			} else {
				this.displayFormOriginalOpen = 'none'
				this.displayFormOriginalFilled = 'none'
				this.displayFormOriginalKilled = 'flex'
			}

			// this.quantityIntEdit = Math.floor(this.quantity_prop)
			// this.quantityDecimalEdit = 0
		},

		increaseQuantityIntEdit() {
			let temp = Number(this.quantityIntEdit)
			temp += 1
			if (temp < 0) {
				this.quantityIntEdit = 0
			} else {
				this.quantityIntEdit = temp
			}
		},
		decreaseQuantityIntEdit() {
			let temp = Number(this.quantityIntEdit)
			temp -= 1
			if (temp < 0) {
				this.quantityIntEdit = 0
			} else {
				this.quantityIntEdit = temp
			}
		},
		increaseQuantityDecimalEdit() {
			let temp = new Decimal(this.quantityDecimalEdit)
			temp = temp.plus(0.1)
			if (temp.greaterThanOrEqualTo(1)) {
				this.quantityDecimalEdit = new Decimal(0.9)
			} else if (temp.lessThan(0)) {
				this.quantityDecimalEdit = new Decimal(0)
			} else {
				this.quantityDecimalEdit = temp
			}
		},
		decreaseQuantityDecimalEdit() {
			let temp = new Decimal(this.quantityDecimalEdit)
			temp = temp.sub(0.1)
			if (temp.greaterThanOrEqualTo(1)) {
				this.quantityDecimalEdit = new Decimal(0.9)
			} else if (temp.lessThan(0)) {
				this.quantityDecimalEdit = new Decimal(0)
			} else {
				this.quantityDecimalEdit = temp
			}
		},

		increasePricePerGramEdit() {
			let temp = new Decimal(this.pricePerGramEdit)
			if (this.contractId_prop == this.$store.state.contractIds.contract_001g) {
				temp = temp.plus(0.01)
			}
			else if (this.contractId_prop == this.$store.state.contractIds.contract_1g) {
				temp = temp.plus(0.01)
			}
			else {
				temp = temp.plus(0.01)
			}

			if (temp < 0) {
				this.pricePerGramEdit = new Decimal(0)
			} else {
				this.pricePerGramEdit = temp
			}
		},
		decreasePricePerGramEdit() {
			let temp = new Decimal(this.pricePerGramEdit)
			if (this.contractId_prop == this.$store.state.contractIds.contract_001g) {
				temp = temp.sub(0.01)
			}
			else if (this.contractId_prop == this.$store.state.contractIds.contract_1g) {
				temp = temp.sub(0.01)
			}
			else {
				temp = temp.sub(0.01)
			}

			if (temp.lessThan(0)) {
				this.pricePerGramEdit = new Decimal(0)
			} else {
				this.pricePerGramEdit = temp
			}
		},

		closeModal() {
			// this.varia = false
			this.displayFormOriginalOpen = '-'
			this.displayFormOriginalFilled = '-'
			this.displayFormOriginalKilled = '-'
			this.$emit('close')
		},
		deleteOrder() {
			let isError = false
			Swal.fire({
				background: '#191f31',
				title: '<span style="color: white;">Are you sure you wish to remove this order?</span>',
				html: "<span style='color: white;'>You won't be able to revert this!</span>",
				showCancelButton: true,
				confirmButtonText: 'Submit',
				showLoaderOnConfirm: true,
				preConfirm: () => {
					return axios.post('/v2/igx/delete_order?order_id=' + this.orderId, {
						user_id: this.$store.state.userId,
					}).then(response => {
					}).catch(error => {
						isError = true


						return error
					})
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) => {
				if (isError) {
					const error = result

					let errorTitle = 'Something went wrong.'
					let errorText = 'Please try again!'
					if (error.data) {
						errorTitle = error.data.err_message
						errorText = ''
					}

					Swal.fire({
						background: '#191f31',
						type: 'error',
						title: '<span style="color: white;">' + errorTitle + '</span>',
						html: '<span style="color: white;">' + errorText + '</span>',
					}).then((result) => {
						if (result.value) {
							this.closeModal()
							location.reload()
						}
					})
				} else if (result.value) {
					Swal.fire({
						background: '#191f31',
						type: 'success',
						title: '<span style="color: white;">Deleted!</span>',
						html: '<span style="color: white;">Your order has been deleted.</span>',
					}).then((result) => {
						if (result.value) {
							this.closeModal()
							location.reload()
						}
					})
				}
			})
		},
		updateOrder() {
			if (this.priceOzt15PercentAway && this.$store.getters.getContractConfigs(this.contractId_prop).contractType == 'kau') {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">The price of this order is ' + this.numberFormatExtend(Decimal.mul(this.minMaxLimitPrice, 100).toFixed(2)) + '% away from current market price.</span>'
				})

				return
			}

			if (new Decimal(this.totalUsdLimitEdit).lessThanOrEqualTo(0)) {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Order total must be greater than zero.</span>',
				})

				return
			}
			
			// check if quantity edit more than available quantity that can be SELL
			if (this.direction == 'sell' && new Decimal(this.quantityEdit).greaterThan(this.goldBalance.availableQuantity)) {
				Swal.fire({
					background: '#191f31',
					html: "<span style='color: white;'>You don't have enough " + this.$store.getters.getContractConfigs(this.contractId_prop).name + ' gold.</span>',
				})

				return
			}

			// check available trade balance
			if (new Decimal(this.$store.state.availableTradeBalanceIdr).lessThan(this.totalIdrLimitEdit) && this.direction == 'buy') {
				Swal.fire({
					background: '#191f31',
					html: "<span style='color: white;'>You don't have enough available trade balance.</span>",
				})

				return
			}

			const fxBufferIdr = Decimal.mul(this.fxBufferEdit, this.streamingRateBuy).toNumber(2)

			let isError = false
			Swal.fire({
				background: '#191f31',
				title: '<span style="color: white;">Are you sure want to update this order?</span>',
				showCancelButton: true,
				confirmButtonText: 'Submit',
				showLoaderOnConfirm: true,
				preConfirm: () => {
					return new Promise((resolve) => {
						axios.patch('/v2/igx/edit_order?order_id=' + this.orderId_prop, {
							user_id: this.$store.state.userId,
							quantity: String(Decimal.mul(this.quantityEdit, this.$store.state.contractConfigs[this.contractId_prop].weightCorrection)),
							limit_price: Number(Decimal.div(this.pricePerGramEdit, this.$store.state.contractConfigs[this.contractId_prop].weightCorrection).times( this.$store.state.contractConfigs[this.contractId_prop].weightMultiplication )),
							expired_date: this.expiryDate,
							direction: this.direction,
							fee: this.feeEdit,
							tax: this.taxEdit,
							fx_buffer_idr: fxBufferIdr,
							payment_idr: this.totalIdrLimitEdit,
							payment_usd: this.totalUsdLimitEdit,
							est_pnl_idr: this.pnlIdrEdit,
							est_pnl_usd: this.pnlUsdEdit
						}).then(response => {
							return resolve(response)
						}).catch(error => {
							isError = true

							if (error.response) {
								return resolve(error.response)
							} else if (error.request) {
								return resolve(error.request)
							} else {
								return resolve(error.message)
							}
						})
					})
				},
				allowOutsideClick: () => !Swal.isLoading()
			}).then((result) => {
				if (isError) {
					Swal.fire({
						background: '#191f31',
						type: 'error',
						title: '<span style="color: white;">Failed!</span>',
						// html: '<span style="color: white;">Your order has been updated</span>',
					}).then((result) => {
						if (result.value) {
							document.getElementById("buttonCloseEditOrder").click()
							this.closeModal()
							location.reload()
						}
					})
				} else if (result.value) {
					Swal.fire({
						background: '#191f31',
						type: 'success',
						title: '<span style="color: white;">Success!</span>',
						html: '<span style="color: white;">Your order has been updated</span>',
					}).then((result) => {
						if (result.value) {
							document.getElementById("buttonCloseEditOrder").click()
							this.closeModal()
							location.reload()
						}
					})
				}
			})
		}
	}
}
</script>

<style scoped>
.modalDialog {
	position: fixed;
	font-family: Arial, Helvetica, sans-serif;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.8);
	z-index: 1050;
	opacity: 0;
	-webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in;
	pointer-events: none;
}

.modalDialog:target {
	opacity: 1;
	pointer-events: auto;
}

.modal-open {
	opacity: 1;
	pointer-events: auto;
}

.modalDialog>div {
	max-width: 400px;
	position: relative;
	margin: 2% auto;
	padding: 1.5em;
	border-radius: 5px;
	background: #25273A;
	background: -moz-linear-gradient(#fff, #999);
	background: -webkit-linear-gradient(#fff, #999);
	background: -o-linear-gradient(#fff, #999);
	color: rgba(255, 255, 255, 0.5);
}

.close {
	background: #606061;
	color: #FFFFFF;
	line-height: 25px;
	position: absolute;
	right: -12px;
	text-align: center;
	top: -10px;
	width: 24px;
	text-decoration: none;
	font-weight: bold;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	-moz-box-shadow: 1px 1px 3px #000;
	-webkit-box-shadow: 1px 1px 3px #000;
	box-shadow: 1px 1px 3px #000;
}

.close:hover {
	background: #00d9ff;
}

.c-prepend-button:hover {
	cursor: pointer;
}

.c-append-button:hover {
	cursor: pointer;
}

.form-control-plaintext {
	padding: 0px 1px 2.5px 1px;
}

input {
	text-transform: uppercase;
}

.c-select {
	padding-top: 1px;
	padding-bottom: 0px;
	padding-left: 5px;
	font-size: 12px;
}

input.c-disabled {
	color: rgb(255, 255, 255, 0.8);
}

.c-input {
	margin: 0 2px;
	padding: 0 8px;
	border: 2px solid #171721;
	background: transparent;
	height: 26.4px;
	border-radius: 4px;
	text-align: center;
	width: 93px;
	min-width: 80px;
}

.c-input:focus {
	border-color: gold;
	outline: none;
}

.button-counter {
	margin: 0;
	/*padding: 0;*/
	border: 0;
	height: 26.4px;
	width: 26.4px;
	background-color: #171721;
	color: gold;
	font-weight: bold;
	border-radius: 4px;
}

.button-counter:hover {
	cursor: pointer;
}

.button-counter:active {
	background-color: gold;
	color: #25273A;
}

.button-counter:focus {
	outline: 0;
}

.value-field {
	background-color: transparent;
	padding: 0;
}

.total-value {
	color: gold;
	font-size: 16px;
}

.total-value.total-value-idr {
	line-height: 16px;
}

.c-input.c-date-picker {
	position: relative;
	padding-left: 7px;
	padding-right: 6.5px;
	margin: 0;
	width: 180px;
	font-size: 12px;
	outline: 0;
	text-align: left;
	border: 0;
	background-color: #171721;
	cursor: text;
	color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	position: relative;
	margin-left: 0;
	right: -3.5px;
	color: transparent;
	background: none;
	z-index: 1;
	outline: 0;
	cursor: pointer;
}

input[type="date"]:before {
	color: white;
	background: none;
	display: block;
	content: '\0023CD';
	position: absolute;
	top: 0px;
	right: 7px;
	color: gold;
}
</style>
