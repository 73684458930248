<template>
	<div class="modal text-center px-1" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
		aria-hidden="true">
		<div class="modal-overlay" id="modal-overlay"></div>
		<div class="modal-dialog" role="document">
			<div v-show="showTradeTicket" class="modal-content bg-white-c">
				<div class="modal-header justify-content-between align-items-center c-modal-header gap-10">
					<div class="fs-24-c flex-1">Trade Ticket</div>
					<div class="flex-1 text-right">
						<div>
							{{ codeName }}
						</div>
						<div class="fw-700">{{ name }}
							<span class="fs-10-c fw-400 color-muted-c">
								{{ ticker_prop.includes('KAU_USD') || ticker_prop.includes('MGAU') ? 'KAU' : ' ' }}
							</span>
						</div>
					</div>
					<div><img class="" :src="`${publicPath}assets/icon/gold.png`" alt="" width="20" /></div>
					<!-- <h4 class="modal-title" id="exampleModalLabel" style="color: white">Trade Ticket</h4> -->
				</div>
				<div class="modal-body py-3 c-modal-body">

					<div v-if="accountLevel != 'ib' && accountLevel != 'fm' && this.$store.getters.getBrokerName != 'pospaygold'"
						class="balance-section mb-18-c">
						<div class="flex flex--justify-content-between cursor-hover-pointer mb-8-c" @click="expandBalance()">
							<div>
								<img class="logobagi ml-0 mr-2" :src="`${publicPath}assets/img/logo-bagi-removebg.png`" alt="" />
								Balance
							</div>
							<div class="bg-black-c circle px-8-c">
								<IconArrow :direction_prop="arrowDirectionBalance" />
							</div>
						</div>

						<div class="balance-content">
							<div class="flex flex--justify-content-between">
								<div>RPED Balance</div>
								<div v-if="this.$store.state.setBalancesApiStatus == 'loading'" class="">
									<Loader size_prop="1.9em" borderSize_prop="0.4em"></Loader>
								</div>
								<div v-else class="">Rp {{ numberFormatExtend(balanceIdr.toFixed(2)) }}</div>
							</div>

							<div class="flex flex--justify-content-between">
								<div>Available Trade RPED
									<Tooltip text_prop="Available Trade RPED" />
								</div>
								<div v-if="this.$store.state.setBalancesApiStatus == 'loading'" class="">
									<Loader size_prop="1.9em" borderSize_prop="0.4em"></Loader>
								</div>

								<div v-if="this.$store.state.setBalancesApiStatus != 'loading'" class="">Rp {{
									numberFormatExtend(this.$store.state.availableTradeBalanceIdr.toFixed(2)) }}</div>
							</div>
							<div v-if="this.$store.state.setBalancesApiStatus != 'loading'" class="text-right">
								<LogoAbx class="logoabx ml-0 mr-2" style=""></LogoAbx>
								$ {{ numberFormatExtend(this.$store.state.availableTradeBalanceUsd.toFixed(2)) }}
							</div>
						</div>
					</div>

					<div
						v-if="accountLevel != 'ib' && accountLevel != 'fm' && this.$store.getters.getBrokerName != 'pospaygold' && direction == 'sell'"
						class="holdings-section mb-24-c">
						<div class="flex flex--justify-content-between cursor-hover-pointer mb-8-c" @click="expandHoldings()">
							<div>Holdings</div>
							<div class="bg-black-c circle px-8-c">
								<IconArrow :direction_prop="arrowDirectionHoldings" />
							</div>
						</div>

						<div class="holdings-content flex flex--direction-col">
							<template v-if="goldBalance">
								<div class="flex flex--justify-content-between">
									<span class="text-left">Holdings</span>
									<div class="text-right float-right number-font">{{ holdings }}
									</div>
								</div>

								<div class="flex flex--justify-content-between">
									<span class="text-left">On Process</span>
									<div class="text-right float-right number-font">{{ goldBalance.onProcess }}</div>
								</div>

								<div class="flex flex--justify-content-between">
									<span class="text-left">Open Sell Orders</span>
									<div class="text-right float-right number-font">{{ goldBalance.reservedQuantity || 0 }}
									</div>
								</div>

								<div class="flex flex--justify-content-between">
									<span class="text-left">Evws in Progress</span>
									<div class="text-right float-right number-font">{{ evwsInProgress }}</div>
								</div>

								<div class="flex flex--justify-content-between">
									<span class="text-left">Withdrawals in Progress</span>
									<div class="text-right float-right number-font">{{ withdrawalsInProgress }}</div>
								</div>
							</template>

							<Loader v-else size_prop="1.9em" borderSize_prop="0.4em"></Loader>
						</div>
					</div>

					<div class="flex flex--justify-content-between gap-8 flex--same-width mb-8-c">
						<button :class="`btn W-100 ${direction == 'sell' ? 'c-btn-danger' : 'c-btn-black'} border-radius-4`"
							@click="changeDirection('sell')">
							<span class="fw-700 d-inline">SELL</span> <span class="fw-400 d-inline">{{ sellPriceShow }}</span>
						</button>
						<button :class="`btn W-100 ${direction == 'buy' ? 'c-btn-success' : 'c-btn-black'} border-radius-4`"
							@click="changeDirection('buy')">
							<span class="fw-700 d-inline">BUY</span> <span class="fw-400 d-inline">{{ buyPriceShow }}</span>
						</button>
					</div>

					<div class="order-type mb-16-c">
						<div :class="`${orderType == 'market' ? 'active' : ''} order-type__child cursor-hover-pointer`"
							@click="orderTypeFunction(1)">MARKET</div>
						<div :class="`${orderType == 'limit' ? 'active' : ''} order-type__child cursor-hover-pointer`"
							@click="orderTypeFunction(2)">LIMIT</div>
					</div>

					<!-- <div class="row justify-content-center mb-2">
						<div class="col-auto">
							<ul class="nav nav-pills nav-pills-primary" role="tablist">
								<li class="nav-item c-li-pill">
									<a class="nav-link active c-pill" @click="orderTypeFunction(1)" data-toggle="tab" href="#link-market" role="tablist" aria-expanded="true"> MARKET </a>
								</li>
								<li class="nav-item">
									<a class="nav-link c-pill" @click="orderTypeFunction(2)" data-toggle="tab" href="#link-limit" role="tablist" aria-expanded="false"> LIMIT </a>
								</li>
							</ul>
						</div>
					</div>
					<div class="row justify-content-center mb-3">
						<div class="col-auto pr-1">
							<div :class="buyButtonClass" style="background-color: #e71d36" @click="changeDirection('sell')">
								<div class="row text-center">
									<div class="col-12">
										<div class="c-value" id="sell-price-market">{{ sellPriceShow }}</div>
										<div class="c-description">SELL</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-auto pl-1">
							<div :class="sellButtonClass" style="background-color: #2ec4b6" @click="changeDirection('buy')">
								<div class="row text-center">
									<div class="col-12">
										<div class="c-value" id="buy-price-market">{{ buyPriceShow }}</div>
										<div class="c-description">BUY</div>
									</div>
								</div>
							</div>
						</div>
					</div> -->

					<!-- <div v-if="direction == 'sell'" class="container text-left value-field mb-2 py-3" style="color: rgba(255, 255, 255, 0.5); border-top: 1px solid #c4c4c4; border-bottom: 1px solid #c4c4c4">
						<div>
							<span class="text-left">Holdings</span>
							<div class="text-right float-right number-font">
								<Loader v-if="holdings == null" size_prop="14px" borderSize_prop="1px"></Loader>
								<span v-else>
									{{ holdings }}
								</span>
							</div>
						</div>

						<div>
							<span class="text-left">Open Sell Orders</span>
							<div class="text-right float-right number-font">{{ openSellOrders_prop[contractId_prop] || 0 }}</div>
						</div>

						<div>
							<span class="text-left">Evws in Progress</span>
							<div class="text-right float-right number-font">{{ evwsInProgress }}</div>
						</div>

						<div>
							<span class="text-left">Withdrawals in Progress</span>
							<div class="text-right float-right number-font">{{ withdrawalsInProgress }}</div>
						</div>
					</div> -->

					<!-- 	<div v-if="direction == 'sell'" class="row px-5 mb-2">
						<div class="col text-left">
							<h5 class="m-0"><b>{{holdings}}</b> HOLDINGS</h5>
							<h5 class="m-0"><b>{{openSellOrders}}</b> OPEN SELL ORDERS</h5>
							<h5 class="m-0"><b>{{evwsInProgress}}</b> EVWS IN PROGRESS</h5>
							<h5 class="m-0"><b>{{withdrawalsInProgress}}</b> WITHDRAWALS IN PROGRESS</h5>
						</div>
					</div> -->

					<div class="rounded py-0">
						<!-- Market -->
						<div v-if="orderType == 'market'" class="tab-pane active" id="link-market" aria-expanded="true">
							<div class="rounded text-left value-field mb-2 py-3 px-1">

								<template v-if="product_is_active_prop == 'active'">
									<div class="mb-3">
										<span class="text-left">Quantity</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease" @click="decreaseQuantityInt()">-</button>
											<input ref="quantityMarket" class="c-input number-font" type="number" name=""
												v-model.number="quantityIntInInput" />
											<button class="button-counter increase" @click="increaseQuantityInt()">+</button>
										</div>
									</div>

									<div v-if="this.$store.state.contractConfigs[contractId_prop].hasQuantityDecimal" class="mb-3">
										<span class="text-left" style="color: transparent">.</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease" @click="decreaseQuantityDecimal()">-</button>
											<span>
												<input class="c-input number-font" type="number" name=""
													v-model.number="quantityDecimalInInput" />
											</span>
											<button class="button-counter increase" @click="increaseQuantityDecimal()">+</button>
										</div>
									</div>

									<div v-if="this.$store.state.contractConfigs[contractId_prop].hasQuantityDecimal">
										<span class="text-left">Total Quantity</span>
										<div class="text-right float-right text-white">{{ quantityShown }}</div>
									</div>
								</template>
								<template v-else>
									<div class="mb-3">
										<span class="text-left">Quantity</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease">-</button>
											<input class="c-input number-font" type="number" name="" value="0" disabled />
											<button class="button-counter increase">+</button>
										</div>
									</div>
								</template>

								<div>
									<span class="text-left">Total Gram</span>
									<div class="text-right float-right text-white">{{ gramShown }}</div>
								</div>

								<div>
									<span class="text-left position-relative">
										Contract Value
										<Tooltip text_prop="Jumlah harga (contract price * quantity) sebelum terjadi transaksi" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										valueShown }}</div>
								</div>

								<div>
									<span class="text-left position-relative" title="test">
										Fee
										<Tooltip text_prop="Fee sebesar 1% dari Contract Value atau minimal Rp 500" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										feeShown }}</div>
								</div>

								<div class="">
									<span class="text-left position-relative">
										Tax
										<Tooltip text_prop="Tax sebesar 11% dari Fee" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										taxShown }}</div>
								</div>

								<div v-if="direction == 'sell'">
									<span class="text-left position-relative">
										Storage Fee
										<Tooltip
											text_prop="Biaya yang dibebankan ke peserta perdagangan untuk setiap kepemilikan fisik emas. (2%*(Contract value * lama penyimpanan fisik emas (hari) / 365)). Biaya ini dipotongkan ke hasil penjual emas" />

									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} 0</div>
								</div>

								<div class="mb-3"></div>

								<div>
									<span class="text-left" style="font-size: 16px">Total</span>
									<div class="text-right float-right number-font total-value">$ {{ totalUSDShown }}</div>
									<div class="text-right number-font total-value total-value-idr">Rp {{ totalIDRShown }}</div>
								</div>
							</div>
						</div>

						<!-- Limit -->
						<div v-if="orderType == 'limit'" class="tab-pane" id="link-limit" aria-expanded="false">
							<div class="rounded text-left value-field mb-2 py-3 px-1">

								<div class="mb-3">
									<span class="text-left">Validity</span>

									<div class="c-select-box float-right rounded">
										<span>
											{{ validityTemp == 'GTC' ? 'Until Cancel' : 'For Date / Time' }}
										</span>
										<select class="c-select" v-model="validityTemp">
											<option value="GTC">Until Cancel</option>
											<option value="GTD">For Date / Time</option>
										</select>
									</div>
								</div>

								<div v-if="validityTemp == 'GTD'" class="mb-3">
									<span class="text-left">Expire Date</span>
									<input type="datetime-local" name="" class="float-right rounded c-input c-date-picker"
										v-model="limitOrderDate" :min="minExpiryDateInInput" onkeydown="return false" />
								</div>

								<template v-if="product_is_active_prop == 'active'">
									<div class="mb-3">
										<span class="text-left">Quantity</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease" @click="decreaseQuantityInt()">-</button>
											<input ref="quantityMarket" class="c-input number-font" type="number" name=""
												v-model.number="quantityIntInInput" />
											<button class="button-counter increase" @click="increaseQuantityInt()">+</button>
										</div>
									</div>

									<div v-if="this.$store.state.contractConfigs[contractId_prop].hasQuantityDecimal" class="mb-3">
										<span class="text-left" style="color: transparent">-</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease" @click="decreaseQuantityDecimal()">-</button>
											<input ref="quantityDecimalMarket" class="c-input number-font" type="number" name=""
												v-model.number="quantityDecimalInInput" />
											<button class="button-counter increase" @click="increaseQuantityDecimal()">+</button>
										</div>
									</div>

									<div v-if="this.$store.state.contractConfigs[contractId_prop].hasQuantityDecimal" class="mb-3">
										<span class="text-left">Total Quantity</span>
										<div class="text-right float-right text-white">{{ quantityShown }}</div>
									</div>

									<div class="mb-3">
										<span class="text-left">Price Gram</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease" @click="decreasePriceOzt()">-</button>
											<input class="c-input number-font" type="text" name="" v-model.number="priceOztInInput" />
											<button class="button-counter increase" @click="increasePriceOzt()">+</button>
										</div>
									</div>
								</template>

								<template v-else>
									<div class="mb-3">
										<span class="text-left">Quantity</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease">-</button>
											<input class="c-input number-font" type="number" name="" value="0" disabled />
											<button class="button-counter increase">+</button>
										</div>
									</div>

									<div class="mb-3">
										<span class="text-left">Price Gram</span>
										<div class="float-right c-input-field">
											<button class="button button-counter decrease">-</button>
											<input class="c-input number-font" type="number" name="" value="0" disabled />
											<button class="button-counter increase">+</button>
										</div>
									</div>
								</template>

								<div>
									<span class="text-left">Total Gram</span>
									<div class="text-right float-right text-white">{{ gramShown }}</div>
								</div>

								<div class="">
									<span class="text-left position-relative">
										Contract Price
										<Tooltip text_prop="Harga fisik emas per satuan berat kontrak" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										contractPriceLimitShown }}</div>
								</div>

								<div class="">
									<span class="text-left position-relative">
										Contract Value
										<Tooltip text_prop="Jumlah harga (contract price * quantity) sebelum terjadi transaksi" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										valueShown }}</div>
								</div>

								<div class="">
									<span class="text-left position-relative">
										Fee
										<Tooltip text_prop="Fee sebesar 1% dari Contract Value atau minimal Rp 500" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										feeShown }}</div>
								</div>

								<div class="">
									<span class="text-left position-relative">
										Tax
										<Tooltip text_prop="Tax sebesar 11% dari Fee" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} {{
										taxShown }}</div>
								</div>

								<div v-if="direction == 'buy'">
									<span class="text-left position-relative">
										FX Buffer <span class="">/5%</span>
										<Tooltip
											text_prop="Nominal yang digunakan untuk antisipasi jika terjadi kenaikan nilai tukar IDR-USD yang mempengaruhi transaksi limit-beli (sebesar 5% dari Contract Value)" />
									</span>
									<div class="text-right float-right text-white number-font">Rp {{ fxBufferShown }}</div>
								</div>

								<div v-if="direction == 'sell'">
									<span class="text-left position-relative">
										Est. Storage Fee
										<Tooltip
											text_prop="Biaya yang dibebankan ke peserta perdagangan untuk setiap kepemilikan fisik emas. (2%*(Contract value * lama penyimpanan fisik emas (hari) / 365)). Biaya ini dipotongkan ke hasil penjual emas" />
									</span>
									<div class="text-right float-right text-white number-font">{{ currency == 'idr' ? 'Rp' : '$' }} 0</div>
								</div>

								<div class="mb-3"></div>

								<div>
									<span class="text-left position-relative fs-16-c">
										Estimate Price
										<Tooltip v-if="direction == 'sell'"
											text_prop="Perkiraan jumlah harga (contract value - (fee + tax + storage fee) yang harus dibayarkan" />
										<Tooltip v-else
											text_prop="Perkiraan jumlah harga (contract value + fee + tax) yang harus dibayarkan" />
									</span>
									<div class="text-right float-right number-font total-value">$ {{ totalUsdLimitShown }}</div>
									<div class="text-right number-font total-value total-value-idr">Rp {{ totalIdrLimitShown }}</div>
								</div>
							</div>

							<div v-if="priceOzt15PercentAway"
								:class="`row alert alert-danger ${$store.getters.getContractConfigs(contractId_prop).contractType != 'kau' ? 'bg-gold' : ''} mx-0 px-2 py-2`">
								<div class="col-auto fs-24-c">&#9888;</div>
								<div class="col pl-2">The price of this order is {{ Decimal.mul(minMaxLimitPrice, 100).toNumber() }}% away
									from current market price.</div>
							</div>
						</div>

					</div>
				</div>
				<div class="modal-footer bg-grey-1">
					<div v-if="this.$store.state.marketInquiryStatus == 'loading'" class="col-12 text-center">
						<Loader size_prop="3em"> </Loader>
					</div>
					<template v-else>
						<button class="w-100 btn c-btn-black color-gold-c mb-8-c" @click="openDepth()">DEPTH</button>

						<div class="flex flex--justity-content-between flex--same-width gap-8">
							<button id="buttonCloseTradeTicket" type="button" class="btn c-btn-black color-theme-c" data-dismiss="modal"
								@click="resetValue()">CLOSE</button>

							<template
								v-if="this.$store.getters.getAccountLevel == 'client' && this.$store.getters.getBrokerName == 'metalgo'">
								<button v-if="product_is_active_prop == 'active' && !isReviewOrderButtonDisabled" class="btn c-btn-theme"
									@click="showReviewOrder()">REVIEW ORDER</button>

								<span v-else class="col d-inline-block" title="This contract is still not available">
									<button class="btn c-btn-theme" disabled>REVIEW ORDER</button>
								</span>
							</template>

							<template v-else class="">
								<button class="btn c-btn-theme" disabled>REVIEW ORDER</button>
							</template>
						</div>
					</template>
				</div>
			</div>
		</div>

		<!-- <button class="btn" @click="openAlert()">HAHAHAAH</button> -->

		<div v-if="isContractStatusDisabled && isOpen" class="close-market">
			<h4 class="close-market-font rounded">
				Pre Order Trading Contract <br />
				Please contact your Brokerage firm for this transaction <span class="close-market-exit"
					@click="exitCloseMarket()">x</span>
			</h4>
		</div>

		<DepthModal v-show="isDepthOpen" @closeDepth="closeDepth()" :contractId_prop="contractId_prop"></DepthModal>

		<ReviewOrder v-show="reviewOrder.reviewOpen" @submit="confirmSubmit()" @close="closeReviewOrder"
			:account_prop="reviewOrder.account" :name="reviewOrder.name" :orderId_prop="reviewOrder.orderId"
			:status_prop="reviewOrder.status" :direction_prop="reviewOrder.direction" :type_prop="reviewOrder.type"
			:validUntil_prop="reviewOrder.validUntil" :pricePerOzt_prop="reviewOrder.pricePerOzt"
			:totalOzt_prop="reviewOrder.totalOzt" :contractPrice_prop="Number(reviewOrder.contractPrice)"
			:quantity_prop="reviewOrder.quantity" :contractValue_prop="reviewOrder.contractValue" :fee_prop="reviewOrder.fee"
			:tax_prop="reviewOrder.tax" :fxBuffer_prop="reviewOrder.fxBuffer" :streamRate_prop="reviewOrder.streamRate"
			:gold_id_prop="gold_id_props" :pricePerGramLimit_prop="Number(priceOzt)" :weight_prop="Number(weight)"
			:totalGram_prop="Number(gram)" :totalQuantity_prop="Number(quantity)"></ReviewOrder>

		<Alert ref="alertRef" @closeAlert="closeAlert" />
	</div>
</template>

<script>
/*eslint-env jquery*/
/* eslint-disable */
import axios from '@/api/axios.js'
import PerfectScrollbar from 'perfect-scrollbar'
import Swal from 'sweetalert2'
import { Decimal } from 'decimal.js'

import Alert from '@/components/Alert.vue'
// import store from '@/store'
// import DepthOrder from '@/components/DepthOrder.vue'
import ReviewOrder from '@/components/ReviewOrder.vue'
import DepthModal from '@/components/tradeticket/DepthModal.vue'
// import OrderTradeTicket from '@/components/OrderTradeTicket.vue'
import Loader from '@/components/Loader.vue'
// import BuyButton from '@/components/BuyButton.vue'
import LogoAbx from '@/components/svg/logo-abx.vue'

import Tooltip from '@/components/Tooltip.vue'
import IconArrow from '@/components/icons/IconArrow.vue'

export default {
	name: 'TradeTicket',
	props: {
		contractId_prop: {
			type: String,
			default: '0',
		},
		ticker_prop: {
			type: String,
			default: '-',
		},
		name: {
			type: String,
			default: '-',
		},
		sell_price: {
			type: Number,
			default: -1,
		},
		buy_price: {
			type: Number,
			default: -1,
		},
		gold_id_props: {
			type: String,
			default: '0',
		},
		weight: {
			type: Number,
			default: 0,
		},
		weightCorection_prop: {
			type: Number,
			default: 1,
		},
		product_is_active_prop: {
			type: String,
			default: 'disabled',
		},
		openSellOrders_prop: Object,
	},
	components: {
		Alert,
		// DepthOrder,
		ReviewOrder,
		DepthModal,
		// OrderTradeTicket,
		Loader,
		// BuyButton,
		LogoAbx,

		Tooltip,
		IconArrow
	},
	data() {
		const today = new Date(Date.now())

		return {
			Decimal: Decimal,
			publicPath: process.env.BASE_URL,

			showTradeTicket: true,

			isDepthOpen: false,

			balanceUsdValue: 0,
			direction: 'buy',
			quantityInt: 1,
			quantityDecimal: new Decimal(0),
			orderType: 'market', //market or limit
			limitPrice: new Decimal(0),
			validity: 'GTC', //GTC or GTD
			idForm: 'formMarket',
			reviewOrder: {
				reviewOpen: false,
			},
			buyButtonActive_class: true,
			sellButtonActive_class: false,
			priceOzt: new Decimal(0),
			limitOrderDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10),
			limitOrderTime: { hour: '00:00' },
			dataSubmitMarket: {},
			isOpen: true,
			setIntervalGetDepositCooldown: '',

			// minExpiryDateInInput:`${today.getFullYear()}-${today.getMonth() + 1 < 10 ? '0' : ''}${today.getMonth() + 1}-${today.getDate() + 1 < 10 ? '0' : ''}${today.getDate() + 1}`,

			validityTemp: 'GTC',

			arrowDirectionBalance: 'down',
			arrowDirectionHoldings: 'down',

			alertType: 'confirm',
		}
	},
	computed: {
		hin() {
			let account = this.$store.state.account
			if (account) {
				return account.hin
			} else {
				return '-'
			}
		},

		goldBalance() {
			const temp = this.$store.getters.getGoldBalanceByContractId(this.contractId_prop)

			return temp
		},

		accountLevel() {
			return this.$store.getters.getAccountLevel
		},

		currency() {
			return this.$store.state.currency
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		weightMultiplication() {
			let temp = this.$store.state.contractConfigs[this.contractId_prop]
			if (temp) {
				return temp.weightMultiplication
			}
			return 1
		},

		codeName() {
			let temp = this.$store.state.contractConfigs[this.contractId_prop]
			if (temp) {
				return temp.codeName
			}
			return ''
		},

		minMaxLimitPrice() {
			const contractConfig = this.$store.getters.getContractConfigs(this.contractId_prop)
			if (contractConfig.contractType == 'kau') {
				return this.$store.state.minMaxLimitPriceKAU
			}
			return this.$store.state.minMaxLimitPriceIGX
		},

		openSellOrder() {
			const temp = this.openSellOrders_prop[this.contractId_prop]
			if (temp) return temp

			return 0
		},

		minExpiryDateInInput() {
			const dateNow = new Date()

			const day = dateNow.getDate() < 10 ? '0' + dateNow.getDate() : dateNow.getDate()
			const month = dateNow.getMonth() + 1 < 10 ? '0' + (dateNow.getMonth() + 1) : dateNow.getMonth() + 1
			const year = dateNow.getFullYear() < 10 ? '0' + dateNow.getFullYear() : dateNow.getFullYear()
			const hours = dateNow.getHours() < 10 ? '0' + dateNow.getHours() : dateNow.getHours()
			const minutes = dateNow.getMinutes() < 10 ? '0' + dateNow.getMinutes() : dateNow.getMinutes()

			const dateTemp = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes

			return dateTemp
		},

		expiryDate() {
			// let dayMap = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu' ,'Fri' ,'Sat']
			let monthMap = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
			let date = new Date(this.limitOrderDate)
			let time = this.limitOrderTime

			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDay()
			let hour = date.getHours()
			let minute = date.getMinutes()
			let second = date.getSeconds()

			let dateTime = `${date.getDate()} ${monthMap[date.getMonth()]} ${date.getFullYear()} 00:00:00 GMT+07:00`
			let wow = new Date(dateTime)

			return date
		},
		sellPrice() {
			const golds = this.$store.state.golds
			if (golds && this.contractId_prop) {
				let gold = golds[this.contractId_prop]

				if (!gold) return '-'

				if (gold.sell < 0 || gold.id == 0) {
					if (gold.buy < 0 || gold.id == 0) {
						return '-'
					} else {
						return gold.buy
					}
				} else {
					return gold.sell
				}
			} else {
				return '-'
			}
		},
		buyPrice() {
			const golds = this.$store.state.golds
			if (golds && this.contractId_prop) {
				let gold = golds[this.contractId_prop]

				if (!gold) return '-'

				if (gold.buy < 0 || gold.id == 0) {
					if (gold.sell < 0 || gold.id == 0) {
						return '-'
					} else {
						return gold.sell
					}
				} else {
					return gold.buy
				}
			} else {
				return '-'
			}
		},
		sellPriceShow() {
			const decimalPoint = this.$store.getters.getContractConfigs(this.gold_id_props).tbTtDecimalPlace

			if (this.sellPrice == '-') {
				return '-'
			} else {
				const currency = this.$store.state.currency
				if (currency == 'usd') {
					return this.numberFormatExtend(new Decimal(this.sellPrice).toFixed(decimalPoint), decimalPoint)
				} else {
					return this.numberFormatExtend(Decimal.mul(this.sellPrice, this.streamingRate.stream_sell.cur_rate).toFixed(decimalPoint), decimalPoint)
				}
			}
		},
		buyPriceShow() {
			const decimalPoint = this.$store.getters.getContractConfigs(this.gold_id_props).tbTtDecimalPlace

			if (this.buyPrice == '-') {
				return '-'
			} else {
				const currency = this.$store.state.currency
				if (currency == 'usd') {
					return this.numberFormatExtend(new Decimal(this.buyPrice).toFixed(decimalPoint), decimalPoint)
				} else {
					return this.numberFormatExtend(Decimal.mul(this.buyPrice, this.streamingRate.stream_buy.cur_rate).toFixed(decimalPoint), decimalPoint)
				}
			}
		},
		buyButtonClass() {
			return {
				'c-direction': true,
				rounded: true,
				buySellButtonActive: this.buyButtonActive_class,
			}
		},
		sellButtonClass() {
			return {
				'c-direction': true,
				rounded: true,
				buySellButtonActive: this.sellButtonActive_class,
			}
		},

		isReviewOrderButtonDisabled() {
			const contractConfig = this.$store.getters.getContractConfigs(this.contractId_prop)

			if ((contractConfig.isReviewOrderButtonDisabledBuyMarket && this.direction == 'buy' && this.orderType == 'market') || (contractConfig.isReviewOrderButtonDisabledBuyLimit && this.direction == 'buy' && this.orderType == 'limit') || (contractConfig.isReviewOrderButtonDisabledSellMarket && this.direction == 'sell' && this.orderType == 'market') || (contractConfig.isReviewOrderButtonDisabledSellLimit && this.direction == 'sell' && this.orderType == 'limit')) {
				return true
			} else {
				return false
			}
		},

		balanceUsd() {
			return this.$store.state.balanceUsd
		},
		balanceIdr() {
			return this.$store.state.balanceIdr
		},

		pnlUsd() {
			if (this.$store.state.setHoldingsApiStatus == 'success' && this.contractId_prop != '0' && this.contractId_prop) {
				const holding = this.$store.getters.getHoldingByContractId(this.contractId_prop)

				let tempPnl
				if (this.orderType == 'market') {
					tempPnl = this.$store.getters.getEstimatePNLUsdByContractIdForJfxChamp(this.contractId_prop)
				} else {
					tempPnl = this.$store.getters.getEstimatePNLUsdByContractIdForJfxChamp(this.contractId_prop, this.priceOzt)
				}

				const pnlPerGram = Decimal.div(tempPnl, Decimal.mul(holding.quantity, this.$store.state.contractConfigs[this.contractId_prop].weightInGram)).toDecimalPlaces(4).toNumber()


				this.$store.commit('setPnlEtc', {
					pnlHoldingUsd: tempPnl,
					totalGramHolding: Decimal.mul(holding.quantity, this.$store.state.contractConfigs[this.contractId_prop].weightInGram).toNumber(),
					pnlPerGramUsd: pnlPerGram,

				})

				return Decimal.mul(pnlPerGram, this.gram).toDecimalPlaces(4, Decimal.ROUND_FLOOR).toNumber()
			}

			return null
		},
		pnlIdr() {
			if (this.$store.state.setHoldingsApiStatus == 'success' && this.contractId_prop != '0' && this.contractId_prop) {
				const holding = this.$store.getters.getHoldingByContractId(this.contractId_prop)

				let tempPnl
				if (this.orderType == 'market') {
					tempPnl = this.$store.getters.getEstimatePNLIdrByContractIdForJfxChamp(this.contractId_prop)
				} else {
					tempPnl = this.$store.getters.getEstimatePNLIdrByContractIdForJfxChamp(this.contractId_prop, this.priceOzt)
				}

				const pnlPerGram = Decimal.div(tempPnl, Decimal.mul(holding.quantity, this.$store.state.contractConfigs[this.contractId_prop].weightInGram)).toDecimalPlaces(4).toNumber()


				this.$store.commit('setPnlEtc', {
					pnlHoldingIdr: tempPnl,
					totalGramHoldingIdr: Decimal.mul(holding.quantity, this.$store.state.contractConfigs[this.contractId_prop].weightInGram).toNumber(),
					pnlPerGramIdr: pnlPerGram,

				})

				return Decimal.mul(pnlPerGram, this.gram).toDecimalPlaces(4, Decimal.ROUND_FLOOR).toNumber()
			}

			return null
		},

		quantity() {
			return Decimal.add(this.quantityInt, this.quantityDecimal)
		},
		quantityShown() {
			return this.numberFormatExtend(this.quantity)
		},
		gram() {
			if (Decimal.mul(this.quantity, this.weight).greaterThanOrEqualTo(0)) {
				return Decimal.mul(this.quantity, this.weight)
			} else {
				return 0
			}
		},
		gramShown() {
			return this.gram
		},

		value() {
			let price = '-'
			if (this.orderType == 'market') {
				if (this.direction == 'buy') {
					price = this.buyPrice
				} else {
					price = this.sellPrice
				}
			} else {
				price = this.priceOzt
			}

			if (price == '-') {
				return 0
			}

			if (this.direction == 'buy') {
				return Decimal.mul(this.quantity, price).times(this.$store.getters.getContractConfigs(this.contractId_prop).weightMultiplication).toDecimalPlaces(2)
			} else {
				return Decimal.mul(this.quantity, price).times(this.$store.getters.getContractConfigs(this.contractId_prop).weightMultiplication).toDecimalPlaces(2)
			}
		},
		valueIdr() {
			let price = '-'
			if (this.orderType == 'market') {
				if (this.direction == 'buy') {
					price = this.buyPrice
				} else {
					price = this.sellPrice
				}
			} else {
				price = this.priceOzt
			}

			if (price == '-') {
				return 0
			}

			let streamingRate = (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate)

			return Decimal.mul(this.quantity, price).times(this.$store.getters.getContractConfigs(this.contractId_prop).weightMultiplication).times(streamingRate).toDecimalPlaces(2)
		},
		valueShown() {
			if (this.currency == 'usd') {
				return this.numberFormatExtend(new Decimal(this.value).toFixed(2))
			} else {
				return this.numberFormatExtend(new Decimal(this.valueIdr).toFixed(2))
			}
		},

		fee() {
			let temp = new Decimal(this.value).mul(this.$store.state.fee).toDecimalPlaces(3)
			if (new Decimal(this.value).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.feeMin)) {
				return new Decimal(this.$store.state.feeMin)
			}
			return temp
		},
		feeShown() {
			let streamingRate = this.currency == 'idr' ? (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate) : 1

			if (this.currency == 'usd') {
				return this.numberFormatExtend(this.fee.mul(streamingRate).toFixed(3), 3)
			} else {
				return this.numberFormatExtend(this.fee.mul(streamingRate).toFixed(2))
			}
		},

		tax() {
			let temp = new Decimal(this.fee).mul(this.$store.state.tax).toDecimalPlaces(3)
			if (new Decimal(this.value).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.taxMin)) {
				return new Decimal(this.$store.state.taxMin)
			}
			return temp
		},
		taxShown() {
			let streamingRate = this.currency == 'idr' ? (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate) : 1

			if (this.currency == 'usd') {
				return this.numberFormatExtend(this.tax.mul(streamingRate).toFixed(3), 3)
			} else {
				return this.numberFormatExtend(this.tax.mul(streamingRate).toFixed(2))
			}
		},

		fxBuffer() {
			let temp = new Decimal(this.value).mul(this.$store.state.fxBuffer).toDecimalPlaces(2)
			if (new Decimal(this.value).lessThanOrEqualTo(0)) {
				return new Decimal(0)
			} else if (temp.lessThan(this.$store.state.fxBufferMin)) {
				return new Decimal(this.$store.state.fxBufferMin)
			}
			return temp
		},
		fxBufferShown() {
			let streamingRate = this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate

			if (this.direction == 'buy') {
				return this.numberFormatExtend(Decimal.mul(this.fxBuffer, streamingRate).toFixed(2))
			} else {
				return this.numberFormatExtend(Decimal.mul(this.fxBuffer, streamingRate).toFixed(2))
			}
		},

		totalUSD() {
			let temp = new Decimal(this.value)
			if (this.direction == 'buy') {
				temp = temp.plus(this.tax).plus(this.fee)
				return temp.toFixed(2)
			} else {
				temp = temp.minus(this.tax).minus(this.fee)
				return temp.toFixed(2)
			}
		},
		totalIDR() {
			let temp = new Decimal(this.totalUSD)

			if (this.direction == 'buy') {
				return temp.times(this.streamingRate.stream_buy.cur_rate).toFixed(2)
			} else {
				return temp.times(this.streamingRate.stream_sell.cur_rate).toFixed(2)
			}
		},

		priceOztInInput: {
			get: function () {
				return this.priceOzt.toNumber()
			},
			set: function (newValue) {
				let temp = new Decimal(newValue)
				if (temp.lessThan(0)) {
					this.priceOzt = new Decimal(0)
				} else {
					this.priceOzt = new Decimal(temp.toFixed(2))
				}
			},
		},

		priceOzt15PercentAway() {
			let price = '-'
			if (this.direction == 'buy') {
				price = this.buyPrice
			} else {
				price = this.sellPrice
			}
			if (price != '-') {
				price = new Decimal(price)
				let temp = new Decimal(this.priceOzt)

				if (temp.lessThanOrEqualTo(price.minus(price.times(this.minMaxLimitPrice))) || temp.greaterThanOrEqualTo(price.plus(price.times(this.minMaxLimitPrice)))) {
					return true
				}
			}
			return false
		},

		contractPriceLimitUsd() {
			return Decimal.mul(this.weightMultiplication, this.priceOzt).toDecimalPlaces(2)
		},

		contractPriceLimitShown() {
			let streamingRate = this.currency == 'idr' ? (this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate) : 1

			if (this.direction == 'buy') {
				return this.numberFormatExtend(Decimal.mul(this.contractPriceLimitUsd, streamingRate).toFixed(2))
			} else {
				return this.numberFormatExtend(Decimal.mul(this.contractPriceLimitUsd, streamingRate).toFixed(2))
			}
		},

		totalUsdLimit() {
			// return Decimal.mul(this.quantity, this.contractPriceLimitUsd)
			let temp = new Decimal(this.value)
			if (this.direction == 'buy') {
				temp = temp.plus(this.tax).plus(this.fee)
				return Number(temp.toFixed(2))
			} else {
				temp = temp.minus(this.tax).minus(this.fee)
				return Number(temp.toFixed(2))
			}
		},
		totalIdrLimit() {
			let temp = new Decimal(this.totalUsdLimit)
			const fxBufferIdr = new Decimal(this.fxBuffer).times(this.streamingRate.stream_buy.cur_rate).toDecimalPlaces(2)

			if (this.direction == 'buy') {
				return temp.times(this.streamingRate.stream_buy.cur_rate).plus(fxBufferIdr).toFixed(2)
			} else {
				return temp.times(this.streamingRate.stream_sell.cur_rate).toFixed(2)
			}
		},

		alert15PercentAwayStyle() {
			let display = 'none'
			if (this.priceOzt15PercentAway) {
				display = 'block'
			}
			return {
				color: '#4D0E00',
				display: display,
			}
		},

		totalUSDShown() {
			if (this.direction == 'buy') {
				return this.numberFormatExtend(new Decimal(this.totalUSD).toFixed(2))
			} else {
				return this.numberFormatExtend(new Decimal(this.totalUSD).toFixed(2))
			}
		},
		totalIDRShown() {
			if (this.direction == 'buy') {
				return this.numberFormatExtend(new Decimal(this.totalIDR).toFixed(2))
			} else {
				return this.numberFormatExtend(new Decimal(this.totalIDR).toFixed(2))
			}
		},

		totalUsdLimitShown() {
			if (this.direction == 'buy') {
				return this.numberFormatExtend(new Decimal(this.totalUsdLimit).toFixed(2))
			} else {
				return this.numberFormatExtend(new Decimal(this.totalUsdLimit).toFixed(2))
			}
		},
		totalIdrLimitShown() {
			if (this.direction == 'buy') {
				return this.numberFormatExtend(new Decimal(this.totalIdrLimit).toFixed(2))
			} else {
				return this.numberFormatExtend(new Decimal(this.totalIdrLimit).toFixed(2))
			}
		},

		quantityIntInInput: {
			get: function () {
				return this.quantityInt
			},
			set: function (newValue) {
				let temp = Number(newValue)
				if (!Number.isInteger(temp)) {
					temp = Math.round(temp)
				}
				if (temp < 0) {
					this.quantityInt = 0
				} else {
					this.quantityInt = temp
				}
			},
		},
		quantityDecimalInInput: {
			get: function () {
				return this.quantityDecimal
			},
			set: function (newValue) {
				let temp = new Decimal(newValue)
				if (temp.greaterThanOrEqualTo(1)) {
					this.quantityDecimal = new Decimal(0.9)
				} else if (temp.lessThan(0)) {
					this.quantityDecimal = new Decimal(0)
				} else {
					this.quantityDecimal = new Decimal(temp.toFixed(1))
				}
			},
		},

		isContractStatusDisabled() {
			if (this.ticker_prop.includes('K1KG')) {
				return true
			} else {
				return false
			}
		},

		holdings() {
			if (this.goldBalance) {
				return this.goldBalance.quantity
			}

			return null
		},

		evwsInProgress() {
			return 0
		},

		withdrawalsInProgress() {
			return 0
		},
	},

	watch: {
		quantityIntInInput(newValue) {
			this.$refs.quantityMarket.style.width = `${newValue.toString().length * 10 + 4}px`
		},

		// quantityDecimal(newValue) {
		// 	this.quantity = this.quantityInt + newValue
		// }
		ticker_prop() {
			this.quantityDecimal = 0
			let temp = 0
			this.isOpen = true
			if (this.direction == 'buy') {
				temp = this.buyPrice
			} else {
				temp = this.sellPrice
			}
			if (temp == '-') {
				this.priceOzt = new Decimal(0)
			} else {
				this.priceOzt = new Decimal(temp).toDecimalPlaces(2)
			}
		},

		limitOrderDate(newValue) {
			const now = new Date()
			const expiryDate = new Date(newValue)

			const temp = new Date(now.setHours(now.getHours() + 1))

			if (expiryDate < temp) {
				const day = temp.getDate() < 10 ? '0' + temp.getDate() : temp.getDate()
				const month = temp.getMonth() + 1 < 10 ? '0' + (temp.getMonth() + 1) : temp.getMonth() + 1
				const year = temp.getFullYear() < 10 ? '0' + temp.getFullYear() : temp.getFullYear()
				const hours = temp.getHours() < 10 ? '0' + temp.getHours() : temp.getHours()
				const minutes = temp.getMinutes() < 10 ? '0' + temp.getMinutes() : temp.getMinutes()

				this.limitOrderDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
			}
		},
	},

	created() {
		// this.quantity = this.quantityInt + this.quantityDecimal
		let temp = 0
		if (this.direction == 'buy') {
			temp = this.buyPrice
		} else {
			temp = this.sellPrice
		}
		if (temp == '-') {
			this.priceOzt = new Decimal(0)
		} else {
			this.priceOzt = new Decimal(temp)
		}
	},

	mounted() {
		// alert setting
		this.$refs.alertRef.changeType('confirmation')
		this.$refs.alertRef.changeText('Are you sure want to submit this order?')
		this.$refs.alertRef.changeButtonNoText('NO')
		this.$refs.alertRef.changeButtonYesText('YES')
		this.$refs.alertRef.changeNext('submit')
	},

	methods: {
		openAlert() {
			this.$refs.alertRef.openModal()
		},
		closeAlert(payload) {
			if (payload.payload.status == 'yes' && payload.next == 'submit') {
				this.$refs.alertRef.changeType('loading')
				this.$refs.alertRef.openModal()

				this.typeOfSubmit()
			}

			else if (payload.payload.status == 'success' && payload.next == 'backToTrade') {
				document.getElementById('buttonCloseTradeTicket').click()

				location.reload()
			}

			else if (payload.payload.status == 'error') {
				this.showTradeTicket = true
			}
		},
		confirmSubmit() {
			this.$refs.alertRef.changeType('confirmation')
			this.$refs.alertRef.changeText('Are you sure want to submit this order?')
			this.$refs.alertRef.changeButtonNoText('NO')
			this.$refs.alertRef.changeButtonYesText('YES')
			this.$refs.alertRef.changeNext('submit')

			this.$refs.alertRef.openModal()
		},

		openDepth() {
			this.isDepthOpen = true
		},
		closeDepth() {
			this.isDepthOpen = false
		},
		// checkContractPriceDecimal() {
		// 	let temp = (this.priceOzt * 100).toFixed(0)
		// 	let temp2 = +temp/100
		// 	this.priceOzt = temp2.toFixed(2)
		// },
		increaseQuantityInt() {
			let temp = Number(this.quantityInt)
			temp += 1
			if (temp < 0) {
				this.quantityInt = 0
			} else {
				this.quantityInt = temp
			}
			// if (this.ticker_prop.includes('MGAU')) {
			// 	let temp = this.quantityInt
			// 	temp += 1
			// 	if (temp < 0) {
			// 		this.quantityInt = 0
			// 	}else {
			// 		this.quantityInt = temp
			// 	}
			// }else {
			// 	++this.quantity
			// }
		},
		decreaseQuantityInt() {
			let temp = Number(this.quantityInt)
			temp -= 1
			if (temp < 0) {
				this.quantityInt = 0
			} else {
				this.quantityInt = temp
			}
			// if (this.ticker_prop.includes('MGAU')) {
			// 	let temp = this.quantityInt
			// 	temp -= 1
			// 	if (temp < 0) {
			// 		this.quantityInt = 0
			// 	}else {
			// 		this.quantityInt = temp
			// 	}
			// }else {
			// 	let temp = this.quantity-1
			// 	if (temp <= 1) {
			// 		this.quantity = 1
			// 	}else {
			// 		this.quantity = temp
			// 	}
			// }
		},
		increaseQuantityDecimal() {
			// let temp = Number(this.quantityDecimal) * 10
			// temp = (temp + 1) / 10
			let temp = new Decimal(this.quantityDecimal)
			temp = temp.plus(0.1)
			if (temp.greaterThanOrEqualTo(1)) {
				this.quantityDecimal = new Decimal(0.9)
			} else if (temp.lessThan(0)) {
				this.quantityDecimal = new Decimal(0)
			} else {
				this.quantityDecimal = temp
			}
		},
		decreaseQuantityDecimal() {
			// let temp = Number(this.quantityDecimal) * 10
			// temp = (temp - 1) / 10
			let temp = new Decimal(this.quantityDecimal)
			temp = temp.sub(0.1)
			if (temp.greaterThanOrEqualTo(1)) {
				this.quantityDecimal = new Decimal(0.9)
			} else if (temp.lessThan(0)) {
				this.quantityDecimal = new Decimal(0)
			} else {
				this.quantityDecimal = temp
			}
		},

		increasePriceOzt() {
			let temp = new Decimal(this.priceOzt)
			if (this.contractId_prop == this.$store.state.contractIds.contract_001g) {
				temp = temp.plus(0.01)
			} else if (this.contractId_prop == this.$store.state.contractIds.contract_1g) {
				temp = temp.plus(0.01)
			} else {
				temp = temp.plus(0.01)
			}

			if (temp < 0) {
				this.priceOzt = new Decimal(0)
			} else {
				this.priceOzt = temp
			}
		},
		decreasePriceOzt() {
			let temp = new Decimal(this.priceOzt)
			if (this.contractId_prop == this.$store.state.contractIds.contract_001g) {
				temp = temp.sub(0.01)
			} else if (this.contractId_prop == this.$store.state.contractIds.contract_1g) {
				temp = temp.sub(0.01)
			} else {
				temp = temp.sub(0.01)
			}

			if (temp.lessThan(0)) {
				this.priceOzt = new Decimal(0)
			} else {
				this.priceOzt = temp
			}
		},

		increaseLimitPrice() {
			this.limitPrice = Decimal.add(this.limitPrice, 0.5)
		},
		decreaseLimitPrice() {
			let temp = new Decimal(this.limitPrice)
			if (temp.lessThan(0)) {
				this.limitPrice = 0
			} else {
				this.limitPrice = Decimal.sub(this.limitPrice, 0.5)
			}
		},

		marketSubmit() {
			const streamingRate = this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate
			const fxBufferIdr = this.fxBuffer.times(streamingRate)

			const dataPayload = {
				user_id: this.$store.state.userId,
				contract_id: String(this.contractId_prop),
				direction: this.direction,
				quantity: Number(Decimal.mul(this.quantity, this.weightCorection_prop)),
				order_type: this.orderType,
				limit_price: 0,
				validity: 'GTC',
				fee: this.fee.toNumber(),
				tax: this.tax.toNumber(),
				fx_buffer_idr: fxBufferIdr.toNumber(),
				payment_idr: Number(this.totalIDR),
				payment_usd: Number(this.totalUSD),
				est_pnl_idr: this.pnlIdr,
				est_pnl_usd: this.pnlUsd,
				type: 'TRADE', //TRADE, GTMU, GTMH
				is_feature: true,
				to_feature: 'TRADE', //TRADE, GTMU, GTMH
				pnlEtc: this.$store.state.pnlEtc,
			}

			if (this.$store.state.contractConfigs[this.contractId_prop].contractType == 'kau') {
				this.postKauOrders(dataPayload)
			} else {
				axios.post('/v2/igx/add_order', dataPayload)
					.then((response) => {
						let r1 = (((response || {}).data || {}).attributes || {}).status
						if (r1 == 'cancel') {
							throw new Error('cancel')
						}

						this.closeReviewOrder()
						this.showTradeTicket = false

						this.$refs.alertRef.changeType('success')
						this.$refs.alertRef.changeText('Order success')
						this.$refs.alertRef.changeButtonYesText('OK')
						this.$refs.alertRef.changeNext('backToTrade')
						this.$refs.alertRef.openModal()
					})
					.catch((error) => {
						let errorType = 'error'
						let errorTitle = '<span style="color: white;">Something went wrong</span>'
						let errorText = '<span style="color: white;">Please try again later</span>'

						if (error.message == 'cancel') {

							errorTitle = '<span style="color: white;">Order Unmatched</span>'
							errorText = '<span style="color: white;"></span>'

						} else if (error.response) {

							errorTitle = '<span style="color: white;">Something went wrong</span>'
							if (error.response.data.data) {
								if (error.response.data.data.err_message) {
									errorText = '<span style="color: white;">' + error.response.data.data.message + '</span>'
								} else if (error.response.data.data.message) {
									errorText = '<span style="color: white;">' + error.response.data.data.message + '</span>'
								} else {
									errorText = '<span style="color: white;">' + error.response.data.data[0].detail + '</span>'
								}
							} else {
								errorText = '<span style="color: white;">' + error.response.data.message + '</span>'
							}

						} else if (error.request) {

							errorTitle = '<span style="color: white;">Server appears to be offline</span>'
							errorText = '<span style="color: white;">Please try again later</span>'

						} else {
							// Something happened in setting up the request and triggered an Error
						}

						this.closeReviewOrder()
						this.showTradeTicket = false

						this.$refs.alertRef.changeType('error')
						this.$refs.alertRef.changeText('Order failed')
						this.$refs.alertRef.changeButtonYesText('OK')
						this.$refs.alertRef.changeNext('backToTrade')
						this.$refs.alertRef.openModal()

					})
			}
		},

		limitSubmit() {
			let expiredDate = new Date(this.expiryDate).toISOString() // default GTD
			if (this.validityTemp == 'GTC') {
				const dateTemp = new Date()
				expiredDate = new Date(dateTemp.setMonth(dateTemp.getMonth() + 1)).toISOString() // set +1 month from now
			}

			const streamingRate = this.direction == 'buy' ? this.streamingRate.stream_buy.cur_rate : this.streamingRate.stream_sell.cur_rate
			const fxBufferIdr = this.fxBuffer.times(streamingRate)
			const weightMultiplication = this.$store.getters.getContractConfigs(this.contractId_prop).weightMultiplication

			const dataPayload = {
				user_id: this.$store.state.userId,
				contract_id: String(this.contractId_prop),
				direction: this.direction,
				quantity: Number(Decimal.mul(this.quantity, this.weightCorection_prop)),
				order_type: this.orderType,
				limit_price: Decimal.div(this.priceOzt, this.weightCorection_prop).times(weightMultiplication).toNumber(),
				validity: this.validityTemp,
				expired_date: expiredDate,
				fee: this.fee.toNumber(),
				tax: this.tax.toNumber(),
				fx_buffer_idr: fxBufferIdr.toNumber(),
				payment_idr: Number(this.totalIdrLimit),
				payment_usd: Number(this.totalUsdLimit),
				est_pnl_idr: this.pnlIdr,
				est_pnl_usd: this.pnlUsd,
				type: 'TRADE', //TRADE, GTMU, GTMH
				is_feature: true,
				to_feature: 'TRADE', //TRADE, GTMU, GTMH
				pnlEtc: this.$store.state.pnlEtc,
			}

			if (this.$store.state.contractConfigs[this.contractId_prop].contractType == 'kau') {
				this.postKauOrders(dataPayload)
			} else {
				axios.post('/v2/igx/add_order', dataPayload)
					.then((response) => {
						this.closeReviewOrder()
						this.showTradeTicket = false

						this.$refs.alertRef.changeType('success')
						this.$refs.alertRef.changeText('Order success')
						this.$refs.alertRef.changeButtonYesText('OK')
						this.$refs.alertRef.changeNext('backToTrade')
						this.$refs.alertRef.openModal()

					})
					.catch((error) => {
						let errorTitle = '<span style="color: white;">Something went wrong</span>'
						let errorText = '<span style="color: white;">Please try again later</span>'

						if (error.response) {
							/*
							 * The request was made and the server responded with a
							 * status code that falls out of the range of 2xx
							 */

							errorTitle = '<span style="color: white;">Something went wrong</span>'
							if (error.response.status == 497) {
								errorText = '<span style="color: white;"> Under maintenance </span>'
							} else if (error.response.data.data) {
								if (error.response.data.data.err_message) {
									errorText = '<span style="color: white;">' + error.response.data.data.message + '</span>'
								} else if (error.response.data.data.message) {
									errorText = '<span style="color: white;">' + error.response.data.data.message + '</span>'
								} else {
									errorText = '<span style="color: white;">' + error.response.data.data[0].detail + '</span>'
								}
							} else {
								errorText = '<span style="color: white;">' + error.response.data.message + '</span>'
							}
						} else if (error.request) {
							/*
							 * The request was made but no response was received, `error.request`
							 * is an instance of XMLHttpRequest in the browser and an instance
							 * of http.ClientRequest in Node.js
							 */

							errorTitle = '<span style="color: white;">Server appears to be offline</span>'
							errorText = '<span style="color: white;">Please try again later</span>'
						} else {
							// Something happened in setting up the request and triggered an Error
						}

						this.closeReviewOrder()
						this.showTradeTicket = false

						this.$refs.alertRef.changeType('error')
						this.$refs.alertRef.changeText('Order failed')
						this.$refs.alertRef.changeButtonYesText('OK')
						this.$refs.alertRef.changeNext('backToTrade')
						this.$refs.alertRef.openModal()

					})
			}
		},

		postKauOrders(dataPayload) {
			axios.post('/v2/igx/add_kau_order', dataPayload)
				.then((response) => {
					this.closeReviewOrder()
					this.showTradeTicket = false

					this.$refs.alertRef.changeType('success')
					this.$refs.alertRef.changeText('Order success')
					this.$refs.alertRef.changeButtonYesText('OK')
					this.$refs.alertRef.changeNext('backToTrade')
					this.$refs.alertRef.openModal()
				})
				.catch((error) => {
					let errorTitle = '<span style="color: white;">Something went wrong</span>'
					let errorText = '<span style="color: white;">Please try again later</span>'

					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */

						errorTitle = '<span style="color: white;">Something went wrong</span>'
						if (error.response.data.data) {
							if (error.response.data.data.err_message) {
								errorText = '<span style="color: white;">' + error.response.data.data.message + '</span>'
							} else if (error.response.data.data.message) {
								errorText = '<span style="color: white;">' + error.response.data.data.message + '</span>'
							} else {
								errorText = '<span style="color: white;">' + error.response.data.data[0].detail + '</span>'
							}
						} else {
							errorText = '<span style="color: white;">' + error.response.data.message + '</span>'
						}
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */

						errorTitle = '<span style="color: white;">Server appears to be offline</span>'
						errorText = '<span style="color: white;">Please try again later</span>'
					} else {
						// Something happened in setting up the request and triggered an Error
					}

					this.closeReviewOrder()
					this.showTradeTicket = false

					this.$refs.alertRef.changeType('error')
					this.$refs.alertRef.changeText('Order failed')
					this.$refs.alertRef.changeButtonYesText('OK')
					this.$refs.alertRef.changeNext('backToTrade')
					this.$refs.alertRef.openModal()

				})
		},

		typeOfSubmit() {
			if (this.orderType == 'market') {
				this.marketSubmit()
			} else {
				this.limitSubmit()
			}
		},
		orderTypeFunction(type, e) {
			if (type == 1) {
				this.orderType = 'market'
				this.idForm = 'formMarket'
			} else {
				this.orderType = 'limit'
				this.idForm = 'formLimit'
			}
		},
		until(until) {
			if (until == 1) {
				this.validity = 'GTC'
			} else {
				this.validity = 'GTD'
			}
		},
		showReviewOrder() {
			let totalUSD,
				totalIDR,
				priceTemp = 0
			let tempStream = 1

			let balance = new Decimal(this.$store.state.availableTradeBalanceUsd)
			let balanceIdr = new Decimal(this.$store.state.availableTradeBalanceIdr)

			if (this.orderType == 'market') {
				totalUSD = new Decimal(this.totalUSD)
				totalIDR = new Decimal(this.totalIDR)
			} else {
				totalUSD = new Decimal(this.totalUsdLimit)
				totalIDR = new Decimal(this.totalIdrLimit)
			}

			let amount = new Decimal(totalUSD)
			let amountIdr = new Decimal(totalIDR)

			if (this.direction == 'buy') {
				priceTemp = this.buyPrice
				// currencyCodeFrom = 'IDR'
				// currencyCodeTo = 'USD'

				if (this.currency == 'idr') {
					tempStream = this.streamingRate.stream_buy.cur_rate
				}
			} else {
				priceTemp = this.sellPrice
				// currencyCodeFrom = 'USD'
				// currencyCodeTo = 'IDR'

				if (this.currency == 'idr') {
					tempStream = this.streamingRate.stream_sell.cur_rate
				}
			}

			if (this.$store.state.marketStatus == 'close') {
				Swal.fire({
					background: '#191f31',
					title: '<span style="color: white;">The market is currently closed</span>',
				})
			} else if (this.isContractStatusDisabled) {
				Swal.fire({
					background: '#191f31',
					title: '<span style="color: white;">Pre Order Trading Contract.</span>',
					html: '<span style="color: white;">Please contact your Brokerage firm for this transaction.</span>',
				})
			} else if (totalUSD.lessThanOrEqualTo(0)) {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">Order total must be greater than zero.</span>',
				})
			}
			// else if (totalIDR.lessThanOrEqualTo(15000)) {
			// 	Swal.fire({
			// 		background: '#191f31',
			// 		html: '<span style="color: white;">Total order must be greater than Rp 15,000.</span>'
			// 	})
			// }
			// else if (this.orderType == 'limit' && this.priceOzt15PercentAway && this.contractId_prop == this.$store.state.contractIds.contract_001g) {
			// 	Swal.fire({
			// 		background: '#191f31',
			// 		html: '<span style="color: white;">The price of this order is ' + this.numberFormatExtend(Decimal.mul(this.$store.state.minMaxLimitPriceKAU, 100).toFixed(2)) + '% away from current market price.</span>'
			// 	})
			// }
			else if (this.orderType == 'limit' && this.priceOzt15PercentAway && this.$store.getters.getContractConfigs(this.contractId_prop).contractType == 'kau') {
				Swal.fire({
					background: '#191f31',
					html: '<span style="color: white;">The price of this order is ' + this.numberFormatExtend(Decimal.mul(this.minMaxLimitPrice, 100).toFixed(2)) + '% away from current market price.</span>',
				})
			} else if (balance.lessThan(amount) && this.direction == 'buy') {
				Swal.fire({
					background: '#191f31',
					html: "<span style='color: white;'>You don't have enough available trade balance.</span>",
				})
			} else if (balanceIdr.lessThan(amountIdr) && this.direction == 'buy') {
				Swal.fire({
					background: '#191f31',
					html: "<span style='color: white;'>You don't have enough available trade balance.</span>",
				})
			}
			// else if((balanceIdrTemp.sub(amountIdrTemp.add(100000))).lessThan(0) && this.direction == 'buy') {
			// 	Swal.fire({
			// 		background: '#191f31',
			// 		html: "<span style='color: white;'>Saldo anda sudah mencapai saldo minimum untuk bertransakasi.</span>"
			// 	})
			// }
			else if (this.direction == 'sell' && new Decimal(this.quantity).greaterThan(this.goldBalance.availableQuantity)) {
				Swal.fire({
					background: '#191f31',
					html: "<span style='color: white;'>You don't have enough " + this.$store.getters.getContractConfigs(this.contractId_prop).name + ' gold.</span>',
				})
			} else {
				let contractPriceTemp

				let stream = this.streamingRate
				this.reviewOrder.streamRate = 14000
				if (stream) {
					let currate
					if (this.direction == 'buy') {
						this.reviewOrder.streamRate = stream.stream_buy.cur_rate
					} else {
						this.reviewOrder.streamRate = stream.stream_sell.cur_rate
					}
				}

				this.reviewOrder.account = '???'
				this.reviewOrder.direction = this.direction
				this.reviewOrder.name = this.name
				this.reviewOrder.type = this.orderType
				if (this.validityTemp == 'GTC') {
					this.reviewOrder.validUntil = 'CANCELLED'
				} else {
					let month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
					let hours = this.expiryDate.getHours() < 10 ? `0${this.expiryDate.getHours()}` : this.expiryDate.getHours()
					let minutes = this.expiryDate.getMinutes() < 10 ? `0${this.expiryDate.getMinutes()}` : this.expiryDate.getMinutes()
					this.reviewOrder.validUntil = this.expiryDate.getDate() + ' ' + month[this.expiryDate.getMonth()] + ' ' + this.expiryDate.getFullYear() + ' ' + hours + ':' + minutes
				}

				if (this.orderType == 'market') {
					if (this.ticker_prop.includes('KAU_USD')) {
						this.reviewOrder.contractPrice = Number(Decimal.mul(priceTemp, tempStream))
					} else {
						this.reviewOrder.contractPrice = Number(Decimal.mul(this.weight, priceTemp).times(tempStream))
					}
					this.reviewOrder.pricePerOzt = Number(priceTemp)
				} else {
					this.reviewOrder.contractPrice = Number(this.contractPriceLimitUsd)
					this.reviewOrder.pricePerOzt = Number(this.priceOzt)
				}

				this.reviewOrder.totalOzt = Number(this.gram)
				this.reviewOrder.quantity = Number(this.quantity)
				this.reviewOrder.contractValue = Number(this.value)
				this.reviewOrder.fee = Number(this.fee)
				this.reviewOrder.tax = Number(this.tax)
				this.reviewOrder.fxBuffer = Number(this.fxBuffer)
				this.reviewOrder.reviewOpen = true

				// if (this.orderType == 'market' && this.$store.state.bankType == 'BAGI') {
				// 	this.$store.dispatch('changeMarketInquiryStatus', 'loading')
				// 	this.$store.commit('START_TIMER')
				// }else {
				// 	if (this.orderType == 'market' && this.direction == 'buy') {
				// 		this.$store.dispatch('changeMarketInquiryStatus', 'loading')
				// 		axios({
				// 			method: 'POST',
				// 			url: process.env.VUE_APP_BACKEND_URL+'/forex/market_rate_inquiry',
				// 			data: {
				// 				counterFlagFX: 'Y',
				// 				currencyCodeFrom: currencyCodeFrom,
				// 				currencyCodeTo: currencyCodeTo,
				// 				amount: String(amount.times(100).ceil())
				// 			}
				// 			// cancelToken: new CancelToken(function executor(c) {
				// 		 //		// An executor function receives a cancel function as a parameter
				// 		 //		cancel = c
				// 		 //	})
				// 		}).then(response => {
				// 			let marketInquiry = response.data.data
				// 			this.dataSubmitMarket.counterFlagFX = marketInquiry.result.counterFlagFX
				// 			this.dataSubmitMarket.exchangeRate = marketInquiry.result.exchangeRate
				// 			this.dataSubmitMarket.flag = marketInquiry.result.flag
				// 			this.dataSubmitMarket.currencyCodeFrom = marketInquiry.result.from.currencyCode
				// 			this.dataSubmitMarket.amountFrom = marketInquiry.result.from.amount
				// 			this.dataSubmitMarket.currencyCodeTo = marketInquiry.result.to.currencyCode
				// 			this.dataSubmitMarket.amountTo = marketInquiry.result.to.amount
				// 			this.dataSubmitMarket.dealNumber = marketInquiry.result.dealInformation.dealNumber
				// 			this.dataSubmitMarket.dealSequenceId = marketInquiry.result.dealInformation.dealSequenceId
				// 			this.dataSubmitMarket.dealAgentId = marketInquiry.result.dealInformation.dealAgentId

				// 			this.$store.commit('CHANGE_MARKET_INQUIRY_STATUS', 'success')
				// 			this.$store.commit('START_TIMER')
				// 		}).catch(error => {
				// 			this.$store.dispatch('changeMarketInquiryStatus', 'error')
				// 			if (error.response) {
				// 				/*
				// 				 * The request was made and the server responded with a
				// 				 * status code that falls out of the range of 2xx
				// 				 */
				// 			} else if (error.request) {
				// 				/*
				// 				 * The request was made but no response was received, `error.request`
				// 				 * is an instance of XMLHttpRequest in the browser and an instance
				// 				 * of http.ClientRequest in Node.js
				// 				 */
				// 			} else {
				// 				// Something happened in setting up the request and triggered an Error
				// 			}
				// 		})
				// 	}else if (this.orderType == 'market' && this.direction == 'sell') {
				// 		this.$store.dispatch('changeMarketInquiryStatus', 'loading')
				// 		this.$store.commit('START_TIMER')
				// 	}
				// }
			}
		},
		closeReviewOrder() {
			if (this.$store.state.marketInquiryStatus == 'loading' && this.orderType == 'market') {
				// cancel()
				this.$store.commit('CHANGE_MARKET_INQUIRY_STATUS', 'idle')
			}
			this.reviewOrder.reviewOpen = false
		},
		changeDirection(direction) {
			if (direction == 'sell') {
				this.direction = 'sell'
				this.buyButtonActive_class = false
				this.sellButtonActive_class = true
			} else {
				this.direction = 'buy'
				this.buyButtonActive_class = true
				this.sellButtonActive_class = false
			}
		},
		getOrderInTradeTicket() {
			// this.$refs.orderTradeTicketRef.getOrder()
		},
		resetValue() {
			this.quantityDecimal = 0
			this.isOpen = true
		},
		refreshBalance() {
			location.reload()
		},
		exitCloseMarket() {
			this.isOpen = false
		},
		setGetDepositCooldown() {
			this.$store.commit('CHANGE_GET_DEPOSIT_COOLDOWN_STATUS', true)
			localStorage.setItem('getDepositCooldownExpiredTime', Date.now() + 120000) // 2 minutes in milisecond
			clearInterval(this.setIntervalGetDepositCooldown)
			this.setIntervalGetDepositCooldown = setInterval(() => {
				let dateExpired = Number(localStorage.getItem('getDepositExecuteTime'))
				let dateNow = Date.now()
				if (dateExpired) {
					if (dateNow > dateExpired && !this.$store.state.getDepositIsCooldown) {
						localStorage.removeItem('getDepositExecuteTime')
						clearInterval(this.setIntervalGetDepositCooldown)

						this.$store.dispatch('setBalance')
					}
				}
			}, 1000)
		},

		expandBalance() {
			const element = document.getElementsByClassName('balance-section')
			element[0].classList.toggle('expanded')

			if (element[0].classList.contains('expanded')) {
				this.arrowDirectionBalance = 'up'
			} else {
				this.arrowDirectionBalance = 'down'
			}
		},
		expandHoldings() {
			const element = document.getElementsByClassName('holdings-section')
			element[0].classList.toggle('expanded')

			if (element[0].classList.contains('expanded')) {
				this.arrowDirectionHoldings = 'up'
			} else {
				this.arrowDirectionHoldings = 'down'
			}
		},
	},
}
</script>

<style scoped>
.modal {
	color: white;
	position: fixed;
	/*top: -233px;*/
	/*transform: translate(-50%, -50%);*/
}

.modal-overlay {
	position: fixed;
	background-color: rgb(0, 0, 0, 0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal-dialog {
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto;
	/* max-width: fit-content; */
	max-width: 25em;
	min-width: min(400px, 100%);
}

.modal.show .modal-dialog {
	-webkit-transform: none;
	transform: none;
}

.modal-footer {
	display: block;
}

.modal-footer>* {
	margin-left: 0 !important;
}

.modal-content .modal-footer button {
	padding-left: 0;
	padding-right: 0;
}

.c-modal-header {
	background-color: #25273a;
	margin-bottom: 1px;
	padding-bottom: 1.5em;
}

.c-modal-body {
	background-color: #25273a;
	/*margin-bottom: 1px;*/
}

/* ================================================== */

.balance-section {
	color: white;
}

.balance-content {
	display: none;
}

.balance-section.expanded>.balance-content {
	display: block;
}

.holdings-section {
	color: white;
}

.holdings-content {
	display: none;
}

.holdings-section.expanded>.holdings-content {
	display: flex;
}

.order-type {
	display: flex;
	justify-content: space-between;

	height: 2.75em;
	border-bottom: 1px solid var(--theme-color);
	gap: 8px;
}

.order-type>* {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1 0;
}

.order-type>.active {
	background-color: var(--theme-color);
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

/* .active::after {
		content: '';
		display: block;
		position: absolute;
		background-color: var(--primary-color);
		bottom: calc(0px - var(--px-space-30));
		left: 0;
		right: 0;
		height: var(--px-space-02);
		border-radius: var(--px-space-02) var(--px-space-02) 0px 0px;
	} */

.logoabx {
	height: 20px;
	width: auto;
}

.logobagi {
	height: 25.59px;
}

.c-balance {
	background-color: #2e3d61;
	display: inline-block;
	/*height: 34px;*/
	color: white;
	/* margin: auto; */
	width: 100%;
}

.c-pill {
	line-height: 0.6;
	width: 115px;
	height: 30px;
}

/*	.c-input-group-prepend {
		background-color: #1d1f2e;
		line-height: 38px;
	}*/
.input-group-prepend {
	line-height: 38px;
	border-right: 1px solid #2b3553;
}

.c-prepend-button,
.c-append-button {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
																	supported by Chrome and Opera */
}

.c-prepend-button:hover,
.c-append-button:hover {
	cursor: pointer;
}

.c-prepend-button:active,
.c-append-button:active {
	border-color: #e14eca;
}

.input-group .form-control:not(:first-child):not(:last-child) {
	border-radius: 0;
	border-right: 0;
}

/*.c-input-group-append {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		line-height: 38px;
		border-bottom: 0;
		border-right: 0;
		border-top: 0;
		border-left: 0;
	}	*/
.input-group-append {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	line-height: 38px;
	border-bottom: 0;
	border-right: 0;
	border-top: 0;
}

.form-control+.input-group-append .input-group-text {
	background-color: #1d1f2e;
	line-height: 16px;
}

.input-group-prepend .input-group-text,
.input-group-prepend .input-group-text {
	background-color: #1d1f2e;
	line-height: 16px;
}

label {
	color: gold;
}

input {
	color: white;
}

.c-direction {
	width: 115px;
	height: 59px;
	color: white;
	padding: 10px 0px;
	font-family: Open Sans;
	font-weight: bold;
	font-size: 15px;
	line-height: 19px;
}

.c-direction:hover {
	cursor: pointer;
}

.buySellButtonActive {
	background-color: grey !important;
	color: rgb(255, 255, 255, 0.7);
}

.c-value {
	font-size: 18px;
}

.c-description {
	font-size: 11px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0;
	/* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
	-moz-appearance: textfield;
	/* Firefox */
}

.bootstrap-datetimepicker-widget {
	z-index: 999 !important;
}

.c-select {
	background: #25273a;
	padding-top: 1px;
	padding-bottom: 0px;
	padding-left: 5px;
	font-size: 12px;
}

input.c-disabled {
	color: rgb(255, 255, 255, 0.8);
}

.fa-sync-alt {
	position: absolute;
	color: white;
	position: absolute;
	/*top: 50%;	 position the top	edge of the element at the middle of the parent */
	left: 50%;
	/* position the left edge of the element at the middle of the parent */
	transform: translate(-50%, 0%);
	/* This is a shorthand of translateX(-50%) and translateY(-50%) */
	bottom: -20px;
	font-size: 12px;
	background-color: #2e3d61;
	/*padding: 5px 7px;*/
	/*border-radius: 50%;*/
}

.fa-sync-alt:active {
	color: lightgrey;
}

.fa-sync-alt:hover {
	cursor: pointer;
}

.c-input-group-text {
	font-size: 0.8em;
}

.close-market {
	position: fixed;
	bottom: 0;
	z-index: 1;
	height: 180px;
	width: 100%;
	background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
	text-align: center;
}

.close-market-font::before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	content: '\f024';
	margin-right: 15px;
}

.close-market-font {
	position: relative;
	vertical-align: middle;
	color: #222;
	background-color: #777;
	display: inline-block;
	margin-top: 30px;
	padding: 10px 20px;
}

.close-market-exit {
	color: #f05a28 !important;
	cursor: pointer;
	position: absolute;
	top: -5px;
	right: -7px;
	background-color: #1a1e34;
	border-radius: 20px;
	width: 20px;
	height: 20px;
	margin: 0px;
	display: inline-block;
	font-weight: bold;
	line-height: 1em;
	transition: color 0.1s ease-in-out;
	border: 1px #777 solid;
}

.value-field {
	background-color: transparent;
}

.total-value {
	font-size: 16px;
}

.total-value.total-value-idr {
	line-height: 16px;
}

.c-input {
	margin: 0 2px;
	padding: 0 8px;
	border: 2px solid #171721;
	background: transparent;
	height: 26.4px;
	border-radius: 4px;
	text-align: center;
	width: 93px;
	min-width: 93px;
}

.c-input:focus {
	border-color: gold;
	outline: none;
}

.button-counter {
	margin: 0;
	/*padding: 0;*/
	border: 0;
	height: 26.4px;
	width: 26.4px;
	background-color: #171721;
	color: gold;
	font-weight: bold;
	border-radius: 4px;
}

.button-counter:hover {
	cursor: pointer;
}

.button-counter:active {
	background-color: gold;
	color: #25273a;
}

.button-counter:focus {
	outline: 0;
}

.c-select-box {
	position: relative;
	padding-right: 5px;
	border: 0;
	background-color: #171721;
	height: 26.4px;
	color: gold;
	width: 150px;
	outline: 0;
	cursor: pointer;

	position: relative;
	overflow: hidden;
	z-index: 0;
}

.c-select-box:after {
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 6px solid gold;
	position: absolute;
	top: 40%;
	right: 8px;
	content: '';
	cursor: pointer;
	z-index: 0;
}

.c-select-box>span {
	position: absolute;
	color: white;
	font-size: 12px;
	height: 100%;
	padding: 2px 8px;
}

.c-select-box select {
	width: 150px;
	border: 0;
	position: relative;
	background: none;
	color: transparent;
	font-size: 12px;
	padding-left: 5px;
	outline: 0;
	cursor: pointer;
	z-index: 1;
}

/*.c-select {
		padding-right: 5px;
		border: 0;
		background-color: #25273A;
		height: 26.4px;
		color: gold;
		width: 137px;
		outline: 0;
		cursor: pointer;
	}*/

.c-select>option {
	color: white;
	background-color: #171721;
}

.c-input.c-date-picker {
	position: relative;
	padding-left: 7px;
	padding-right: 6.5px;
	margin: 0;
	width: 180px;
	font-size: 12px;
	outline: 0;
	text-align: left;
	border: 0;
	background-color: #171721;
	cursor: text;
}

/*input[type="date"]::-webkit-calendar-picker-indicator {
		margin: 0px;
		filter: invert() hue-rotate(90deg);
	}*/

input[type='date']::-webkit-calendar-picker-indicator {
	position: relative;
	margin-left: 0;
	right: -3.5px;
	color: transparent;
	background: none;
	z-index: 1;
	outline: 0;
	cursor: pointer;
}

input[type='date']:before {
	color: white;
	background: none;
	display: block;
	content: '\0023CD';
	position: absolute;
	top: 0px;
	right: 7px;
	color: gold;
}
</style>
