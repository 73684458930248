<template>
  <div>
    <div class="navbar-minimize-fixed">
      <button class="minimize-sidebar btn btn-link btn-just-icon">
        <i class="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
        <i class="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
      </button>
    </div>
    <Sidebar></Sidebar>
    <div class="main-panel">
      <div v-if="this.$store.state.marketStatus == 'close' && isOpen" class="close-market">
        <h4 class="close-market-font rounded">The market is currently closed <span class="close-market-exit"
            @click="exitCloseMarket()">x</span></h4>
      </div>
      <Navbar></Navbar>

      <router-view></router-view>

      <Footer></Footer>
    </div>

    <LiveChatWidget license="14332458" visibility="minimized" group="1" :chatBetweenGroups="false"
      v-on:ready="liveChatHideGreeting" :customerName="this.$store.getters.getFirstName"
      :customerEmail="this.$store.getters.getEmail" />
  </div>
</template>

<script>
import axiosOld from 'axios'
import axios from '@/api/axios.js'

import { LiveChatWidget } from '@livechat/widget-vue/v2'

import Navbar from '@/components/layout/Navbar.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
import Footer from '@/components/layout/Footer.vue'
import Swal from 'sweetalert2'
// import PerfectScrollbar from 'perfect-scrollbar'

export default {
  name: 'Dash',
  components: {
    Navbar,
    Sidebar,
    Footer,
    LiveChatWidget
  },
  data() {
    return {
      webName: process.env.VUE_APP_TITLE,
      pageName: this.$route.name,
      isOpen: true,

      swalActivated: false,

      userActivityThrottlerTimeout: null,

      apiStatusRefreshToken: 'idle'
    }
  },
  async created() {
    // change theme color CSS
    const r = document.querySelector(':root')
    if (this.$store.getters.getAppName == 'PosPayGold') { // pospaygold
      r.style.setProperty('--theme-color', getComputedStyle(r).getPropertyValue('--orange-pospaygold'))
    } else if (this.$store.getters.getAppName == 'NUNomics') { // nunomics
      r.style.setProperty('--theme-color', getComputedStyle(r).getPropertyValue('--hijau-nu'))
    } else if (this.$store.getters.getAppName == 'BMTDigi') { // bmtdigi
      r.style.setProperty('--theme-color', getComputedStyle(r).getPropertyValue('--orange-bmt'))
    } else if (this.$store.getters.getAppName == 'AGIGold') { // AGIGold
      r.style.setProperty('--theme-color', getComputedStyle(r).getPropertyValue('--biru-agi'))
    }

    // remove Google reCaptcha logo
    const reCaptchaBadge = window.document.getElementsByClassName("grecaptcha-badge")
    if (reCaptchaBadge.length > 0) {
      reCaptchaBadge[0].style.visibility = 'hidden'
    }

    let loginExpiredTime = Number(localStorage.getItem('loginExpiredTime'))
    if (!loginExpiredTime) {
      loginExpiredTime = Date.now() + this.$store.state.loginExpiredTimeState
      this.resetUserActivityTimeout()
    }

    if (Date.now() > loginExpiredTime) {
      this.$store.dispatch('AUTH_LOGOUT')
        .then(() => {
          this.$router.push({ name: 'Login' }, () => {
            window.location.reload()
          })
        })
    } else {
      this.activateActivityTracker()

      await this.checkRefreshToken()

      this.$store.dispatch('setContracts')
      this.$store.dispatch('setProducts')
      this.$store.dispatch('setAccountInformation')
      this.$store.dispatch('setBalances')
      this.$store.dispatch('setGoldBalances')

      this.everyThreeSeconds()
      this.setStreamingRate()
    }

  },

  mounted() {
  },

  beforeDestroy() {
    window.removeEventListener("mousemove", this.userActivityThrottler)
    window.removeEventListener("scroll", this.userActivityThrottler)
    window.removeEventListener("keydown", this.userActivityThrottler)
    window.removeEventListener("resize", this.userActivityThrottler)

    clearTimeout(this.userActivityThrottlerTimeout)
  },
  methods: {
    everyThreeSeconds() {
      const token = window.localStorage.getItem('token')
      if (!token) return

      setTimeout(async () => {
        await this.$store.dispatch('checkCloseMarket')

        try {
          if (this.$store.state.marketStatus == 'close') {
            if (this.$store.state.setEndOfDayPricesApiStatus != 'success') {
              await this.$store.dispatch('setEndOfDayPrices')
            }
          } else {
            await this.$store.dispatch('setDepths')
            await this.$store.dispatch('setGolds')
          }

          await this.$store.dispatch('setOhlc')

          this.everyThreeSeconds()
        } catch (err) {
          this.everyThreeSeconds()
        }

        // this.everyThreeSeconds()
      }, 3000)
    },

    async setStreamingRate() {
      const token = window.localStorage.getItem('token')
      if (!token) return

      let nextGetStreamingRate = this.$store.state.nextGetStreamingRate
      if (!nextGetStreamingRate || !this.$store.state.streamingRate) {
        await this.$store.dispatch('setStreamingRate')
        nextGetStreamingRate = this.$store.state.nextGetStreamingRate
      }

      // if nextGetStreamingRate is less than current time, wait for 5 second, then get streaming rate again
      if (nextGetStreamingRate <= new Date().getTime()) {
        setTimeout(() => {
          this.$store.dispatch('setStreamingRate'
          ).then(() => {
            this.setStreamingRate()
          })
        }, 5000)

        return
      }

      setTimeout(() => {
        this.$store.dispatch('setStreamingRate')
          .then(() => {
            this.setStreamingRate()
          })
      }, Math.max(nextGetStreamingRate - new Date().getTime(), 0))
    },

    exitCloseMarket() {
      this.isOpen = false
    },

    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler)
      window.addEventListener("scroll", this.userActivityThrottler)
      window.addEventListener("keydown", this.userActivityThrottler)
      window.addEventListener("resize", this.userActivityThrottler)

    },
    resetUserActivityTimeout() {
      localStorage.setItem('loginExpiredTime', Date.now() + this.$store.state.loginExpiredTimeState)
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout && (this.$store.getters.getAccountLevel != 'fm' && this.$store.getters.getAccountLevel != 'ib')) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          const loginExpiredTime = Number(localStorage.getItem('loginExpiredTime'))

          if (Date.now() < loginExpiredTime) {
            this.resetUserActivityTimeout()

          } else if (!this.swalActivated && Date.now() >= loginExpiredTime) {
            this.swalActivated = true

            this.$store.dispatch('AUTH_LOGOUT').then(() => {
              Swal.fire({
                background: '#191f31',
                title: '<span style="color: white;">Your session has expired</span>',
              }).then(() => {
                clearTimeout(this.userActivityThrottlerTimeout)
                this.userActivityThrottlerTimeout = null

                this.swalActivated = false

                this.$router.push({ name: 'Login' }, () => {
                  window.location.reload()
                })
              })
            })
          }

          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, 3000)
      }
    },

    // refresh token every 2.5 minutes
    checkRefreshToken() {
      // const intervalRefreshToken = setInterval(() => {
      //   const token = localStorage.getItem('token')
      //   if (!token) {
      //     clearInterval(intervalRefreshToken)
      //   }

      //   let refreshTokenTime = Number(localStorage.getItem('refreshTokenTime'))
      //   if ((refreshTokenTime !== undefined && refreshTokenTime != 0 && Date.now() > refreshTokenTime && this.$store.state.apiStatusRefreshToken == 'idle') || this.$store.state.isRefreshTokenFirstTime) {
      //     this.$store.commit('changeRefreshTokenFirstTime', false)
      //     this.$store.dispatch('refreshToken')
      //   }
      // }, 1000)

      return new Promise((resolve) => {
        let nextRefreshToken = this.$store.state.refreshTokenTime
        if (!nextRefreshToken) {
          nextRefreshToken = Date.now() + this.$store.state.refreshTokenTimeState
          localStorage.setItem('refreshTokenTime', Date.now() + this.$store.state.refreshTokenTimeState) // next 210 second = 3.5 minutes
        }

        const timeNow = new Date().getTime()

        let nextTime = 0
        if (nextRefreshToken - timeNow > 0) {
          nextTime = nextRefreshToken - timeNow
        }

        setTimeout(() => {
          this.$store.dispatch('refreshToken')
          const refreshTokenInterval = setInterval(() => {
            this.$store.dispatch('refreshToken')

            if (!this.$store.state.token) {
              clearInterval(refreshTokenInterval)
            }
          }, 210000)
        }, nextTime)

        return resolve()
      })
    },

    liveChatHideGreeting() {
      window.LiveChatWidget.call("hide_greeting")
    },
  }
}
</script>

<style scoped>
#container {
  background: green;
}

.close-market {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 20%;
  width: 100%;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1));
  text-align: center;
}

.close-market-font::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  content: "\f024";
  margin-right: 15px;
}

.close-market-font {
  position: relative;
  vertical-align: middle;
  color: #222;
  background-color: #777;
  display: inline-block;
  margin-top: 30px;
  padding: 10px 20px;
}

.close-market-exit {
  color: #f05a28 !important;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -7px;
  background-color: #1a1e34;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin: 0px;
  display: inline-block;
  font-weight: bold;
  line-height: 1em;
  transition: color .1s ease-in-out;
  border: 1px #777 solid;
}
</style>