<template>
  <div class="wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
  // import axios from '@/api/axios.js'
  export default {
    name: 'App',
    created() {
      // axios.interceptors.response.use(function (response) {
      //   // Any status code that lie within the range of 2xx cause this function to trigger
      //   // Do something with response data
      //   return new Promise(function () {
      //     if (response.data.status === 401) {
      //       // if you ever get an unauthorized, logout the user
      //       this.$store.dispatch('AUTH_LOGOUT').then(() => {
      //         this.$router.push({name: 'Login'})
      //       })
      //     }
      //     throw response
      //   })
      // }, function (err) {
      //   return new Promise(function () {
      //     if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      //       // if you ever get an unauthorized, logout the user
      //       this.$store.dispatch('AUTH_LOGOUT').then(() => {
      //         this.$router.push({name: 'Login'})
      //       })
      //     }
      //     throw err
      //   })
      // })
    }
  }
</script>

<!-- <style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style> -->
