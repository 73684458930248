import { render, staticRenderFns } from "./TradingChart.vue?vue&type=template&id=3e4cdf9a&"
import script from "./TradingChart.vue?vue&type=script&lang=js&"
export * from "./TradingChart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports