<template>
	<div class="content">
		<div class="modal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h3 v-if="tradingType == 'kinesis'" class="modal-title" id="exampleModalLabel">BULLION WITHDRAWAL (KAU)</h3>
						<h3 v-else class="modal-title" id="exampleModalLabel">BULLION WITHDRAWAL</h3>
						<button type="button" class="close" data-dismiss="modal" aria-hidden="true">
							<i class="tim-icons icon-simple-remove"></i>
						</button>
					</div>
					<div class="modal-body">
						<div class="alert alert-warning fade show" role="alert">
							No holdings are available for withdrawal in this marketplace for this account.
							<button type="button" class="close" data-dismiss="alert" aria-label="Close">
								<i class="tim-icons icon-simple-remove"></i>
							</button>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
						<button type="button" class="btn btn-success">Transfer</button>
					</div>
				</div>
			</div>
		</div>
		<ul class="nav nav-pills nav-pills-primary justify-content-center" role="tablist">
			<li class="nav-item" @click="changeTabActive('cash')">
				<a :class="`nav-link ${tabActive == 'cash' && 'active'}`" data-toggle="tab" href="#link1" role="tablist"
					:aria-expanded="`${tabActive == 'cash' ? 'true' : 'false'}`">
					Cash
				</a>
			</li>
			<li class="nav-item" @click="changeTabActive('credit')">
				<a :class="`nav-link ${tabActive == 'credit' && 'active'}`" data-toggle="tab" href="#link2" role="tablist"
					:aria-expanded="`${tabActive == 'credit' ? 'true' : 'false'}`">
					Credit
				</a>
			</li>
			<li class="nav-item" @click="changeTabActive('bullion')">
				<a :class="`nav-link ${tabActive == 'bullion' && 'active'}`" data-toggle="tab" href="#link3" role="tablist"
					:aria-expanded="`${tabActive == 'bullion' ? 'true' : 'false'}`">
					Bullion
				</a>
			</li>
			<li class="nav-item" @click="changeTabActive('trackBullion')">
				<a id="track-bullion" :class="`nav-link ${tabActive == 'trackBullion' && 'active'}`" data-toggle="tab"
					href="#link4" role="tablist" :aria-expanded="`${tabActive == 'trackBullion' ? 'true' : 'false'}`">
					Track Bullion
				</a>
			</li>
		</ul>

		<div class="tab-content tab-space">

			<!-- Cash -->
			<div :class="`tab-pane ${tabActive == 'cash' && 'active'}`" id="link1"
				:aria-expanded="`${tabActive == 'cash' ? 'true' : 'false'}`">
				<!-- <div id="myProgress">
					<div id="myBar">
					</div>
					<div id="frontBar">
						10.00
					</div>
				</div> -->
				<!-- <button @click="move()">Click Me</button> -->
				<div class="row">
					<div v-if="paymentInformationStatus == 'loading'" class="col-sm-12 col-lg-6 text-center mb-4">
						<Loader size_prop='10em'></Loader>
					</div>
					<div v-else class="col-sm-12 col-lg-6 text-center">
						<template v-if="payrefDisplay == 'initial'">
							<h4>COMMODITY ACCOUNT (RPED)</h4>

							<dl class="row text-left">
								<dt class="col-4 offset-2">BANK NAME</dt>
								<dd class="col-6">{{ paymentInfo.apd.bank_name }}</dd>

								<dt class="col-4 offset-2">BSB/BANK CODE</dt>
								<!-- <dd class="col-6">{{paymentInfo.apd.swift_code}}</dd> -->
								<dd class="col-6">{{ paymentInfo.apd.bank_code || '037' }}</dd>

								<dt class="col-4 offset-2">ACCOUNT NUMBER</dt>
								<dd class="col-6">{{ paymentInfo.apd.account_number }}</dd>

								<dt class="col-4 offset-2">ACCOUNT NAME</dt>
								<dd class="col-6">{{ paymentInfo.apd.account_name }}</dd>

								<dt class="col-4 offset-2">BANK ADDRESS</dt>
								<!-- <dd class="col-6">-</dd> -->
								<dd class="col-6">{{ paymentInfo.apd.bank_address || '-' }}</dd>

								<dt class="col-4 offset-2" :style="{ display: payrefDisplay }">PAYMENT REFERENCE</dt>
								<dd class="col-6" :style="{ display: payrefDisplay }">{{ this.$store.getters.getHin }}</dd>
							</dl>
						</template>
						<template v-else>
							<h4>DEPOSIT TO BANK ACCOUNT</h4>
							<dl class="row text-left">
								<dt class="col-4 offset-2">BANK NAME</dt>
								<dd class="col-6">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].bank_name || '-' }}</dd>

								<dt class="col-4 offset-2">BSB/BANK CODE</dt>
								<dd class="col-6">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].bank_code || '037' }}</dd>

								<dt class="col-4 offset-2">ACCOUNT NUMBER</dt>
								<dd class="col-6">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].account_number || '-' }}</dd>

								<dt class="col-4 offset-2">ACCOUNT NAME</dt>
								<dd class="col-6">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].account_name || '-' }}</dd>

								<dt class="col-4 offset-2">BANK ADDRESS</dt>
								<dd class="col-6">{{ paymentInfo.withdrawal_acc[utilityAccountIndex].bank_address || '-' }}</dd>

								<dt class="col-4 offset-2" :style="{ display: payrefDisplay }">PAYMENT REFERENCE</dt>
								<dd class="col-6" :style="{ display: payrefDisplay }">{{ this.$store.getters.getHin }}</dd>
							</dl>
						</template>
					</div>

					<div class="col-sm-12 col-lg-6 col text-center">
						<div class="tab-content">
							<div class="tab-pane active" id="nav2-link1" aria-expanded="false">
								<h4>CASH TRANSACTION HISTORY</h4>
							</div>
							<div class="tab-pane" id="nav2-link2" aria-expanded="true">
								<h4>WITHDRAW CASH INTO TRADING ACCOUNT</h4>
							</div>
						</div>
						<ul class="nav nav-pills nav-pills-default nav-justified" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" data-toggle="tab" href="#nav2-link1" role="tablist" aria-expanded="true"
									@click="showDeposit">
									Last 10 Transaction
								</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" data-toggle="tab" href="#nav2-link2" role="tablist" aria-expanded="false"
									@click="showWithdraw">
									Withdraw
								</a>
							</li>
						</ul>

						<hr>

						<div class="row mb-0" style="color: rgb(255, 255, 255, 0.8);">
							<div class="col-6 text-right">
								<b>US ${{ availableTradeBalanceUsd }}</b>
							</div>
							<div class="col-6 text-left">
								<b>IDR {{ availableTradeBalanceIdr }}</b>
							</div>
						</div>
						<p>AVAILABLE WITHDRAW BALANCE</p>
						<br>
						<div id="deposit" :style="{ display: depositDisplay }">
							<table class="table table-striped">
								<thead>
									<tr>
										<th scope="col">DATE</th>
										<th scope="col">TRANSACTION ID</th>
										<th scope="col">DEBIT</th>
										<th scope="col">CREDIT</th>
									</tr>
								</thead>
								<tbody>
									<template v-if="balanceHistory.length > 0">
										<tr v-for="(item, index) in balanceHistory" :key="index">
											<td>{{ item.date }}</td>
											<td>{{ item.transactionId }}</td>
											<td>{{ item.debit }}</td>
											<td>{{ item.credit }}</td>
										</tr>
									</template>
									<template v-else>
										<tr>
											<td></td>
											<td></td>
											<td></td>
											<td></td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
						<div id="withdraw" :style="{ display: withdrawDisplay }">
							<form @submit.prevent="reqWithdrawal()">
								<div class="form-group row">
									<label for="paymentUtilityBankName" class="col-auto col-form-label">To</label>
									<div v-if="paymentInfo" class="col-auto text-left">
										<!-- <button class="btn px-3" type="button" @click="showWithdrawDestination()">
											<img v-if="withdrawDestinationLogo != ''" :src="withdrawDestinationLogo" style="height: auto; width: 30px;">
											<span class="d-inline mx-3 text-uppercase">{{withdrawDestination}}</span>
										</button> -->

										<div class="btn-group dropright">
											<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuWithdrawDestination"
												data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												<img v-if="withdrawDestinationLogo != ''" :src="withdrawDestinationLogo"
													style="height: auto; width: 30px;">
												<span class="d-inline mx-3 text-uppercase">{{ withdrawDestination }}</span>
											</button>
											<div v-if="this.$store.state.bankType != 'BAGI'" class="dropdown-menu"
												aria-labelledby="dropdownMenuWithdrawDestination">
												<span class="dropdown-item-text text-white">BANK</span>
												<button :class="['dropdown-item', withdrawDestination == 'cimb' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('cimb')">
													<img :src="`${publicPath}assets/img/logo-cimb-3.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">CIMB Niaga</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'bca' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('bca')">
													<img :src="`${publicPath}assets/img/logo-bca-2.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">BCA</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'mandiri' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('mandiri')">
													<img :src="`${publicPath}assets/img/logo-mandiri-3.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Mandiri</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'bni' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('bni')">
													<img :src="`${publicPath}assets/img/logo-bni-2.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">BNI</span>
												</button>

												<div class="dropdown-divider"></div>

												<span class="dropdown-item-text">E-WALLET</span>
												<button :class="['dropdown-item', withdrawDestination == 'giropos' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('giropos')">
													<img :src="`${publicPath}assets/img/pos-giro.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Giropos</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'rekpon' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('rekpon')">
													<img :src="`${publicPath}assets/img/logo-cimb-3.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Rekpon</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'ovo' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('ovo')">
													<img :src="`${publicPath}assets/img/logo-ovo-3.jpg`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Ovo</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'gopay' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('gopay')">
													<img :src="`${publicPath}assets/img/logo-gopay-2.jpg`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Gopay</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'linkaja' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('linkaja')">
													<img :src="`${publicPath}assets/img/logo-linkaja.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Linkaja</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'dana' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('dana')">
													<img :src="`${publicPath}assets/img/logo-dana-2.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Dana</span>
												</button>
												<button :class="['dropdown-item', withdrawDestination == 'isaku' ? 'active' : '']" type="button"
													@click="withdrawDestinationAfterClose('isaku')">
													<img :src="`${publicPath}assets/img/logo-isaku.jpg`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Isaku</span>
												</button>
												<!-- <button class="dropdown-item" type="button">Another action</button>
												<button class="dropdown-item" type="button">Something else here</button> -->
											</div>
											<div v-else class="dropdown-menu" aria-labelledby="dropdownMenuWithdrawDestination">
												<span class="dropdown-item-text text-white pl-3">BANK</span>
												<button :class="['dropdown-item', withdrawDestination == 'Artha Graha' ? 'active' : '']"
													type="button" @click="withdrawDestinationAfterClose('Artha Graha')">
													<img :src="`${publicPath}assets/img/logo-bagi-removebg.png`" style="height: 30px; width: auto;">
													<span class="d-inline mx-3 text-uppercase">Artha Graha</span>
												</button>
											</div>
										</div>

										<!-- <a v-if="withdrawDestinationLogo != ''" class="ml-3" style="color: #4f7bff; text-decoration: underline;" href="#" @click.prevent="showWithdrawDestination">Change...</a> -->
										<!-- <select class="custom-select" style="width: initial;" v-model="utilityAccountIndex">
											<option v-for="(item, index) of paymentInfo.utility_account" :key="item.bank_name" :value="index">{{item.bank_name}}</option>
										</select> -->
									</div>
								</div>

								<div v-show="showPhoneNumber" class="form-group row mb-3">
									<label for="phoneNumber" class="col-auto form-control-md col-form-label">{{ labelWithdraw }}</label>
									<div class="col">
										<input type="text" class="form-control c-disabled" id="phoneNumber"
											v-model="withdrawDestinationAccountNumber" disabled>
									</div>
								</div>

								<div class="input-group mb-2">
									<div class="input-group-prepend">
										<span class="input-group-text">US $</span>
									</div>
									<input type="text" class="form-control c-hide-spinner" v-model.number="withdrawUsdInInput">
									<div class="input-group-append">
										<span class="c-decrease-button input-group-text" @click="decreaseWithdrawUsd">-</span>
										<span class="c-increase-button input-group-text" @click="increaseWithdrawUsd">+</span>
									</div>
								</div>

								<div class="input-group mb-1">
									<div class="input-group-prepend">
										<span class="input-group-text">IDR</span>
									</div>
									<input type="text" class="form-control" v-model.number="withdrawIdrInInput">
									<div class="input-group-append">
										<span class="c-decrease-button input-group-text" @click="decreaseWithdrawIdr">-</span>
										<span class="c-increase-button input-group-text" @click="increaseWithdrawIdr">+</span>
									</div>
								</div>
								<small v-for="item in withdrawUsdError" :key="'error' + item"
									class="c-text-danger float-left font-weight-bold mb-3" style="padding-left: 9px">{{ item }}</small>

								<div class="form-group mt-2">
									<textarea class="form-control" placeholder="Optional description" rows="5" style="max-height: 200px;"
										v-model="withdrawDescription"></textarea>
								</div>
								<hr>
								<button type="submit" class="btn c-btn-theme" :disabled="this.$store.getters.getAccountLevel != 'client'">WITHDRAW</button>
							</form>
						</div>
					</div>
				</div>
			</div>

			<!-- Credit -->
			<div :class="`tab-pane ${tabActive == 'credit' && 'active'}`" id="link2"
				:aria-expanded="`${tabActive == 'credit' ? 'true' : 'false'}`">
				<div class="row justify-content-center">
					<div class="col-12 col-md-7 col-lg-5 text-center">
						<h4 v-if="tradingType == 'kinesis'">CREDIT DETAILS (KAU)</h4>
						<h4 v-else>CREDIT DETAILS</h4>
						<div class="row" style="color: rgb(255, 255, 255, 0.8);">
							<div class="col-6 text-right">
								<b>US $0.00</b>
							</div>
							<div class="col-6 text-left">
								<b>IDR 0.00</b>
							</div>
						</div>
						<div class="row mb-1">
							<div class="col">
								<p>AVALAIBLE CREDIT</p>
							</div>
						</div>
						<div class="row mb-0" style="color: rgb(255, 255, 255, 0.8);">
							<div class="col-6 text-right">
								<b>US $0.00</b>
							</div>
							<div class="col-6 text-left">
								<b>IDR 0.00</b>
							</div>
						</div>
						<div class="row">
							<div class="col">
								<p>CREDIT LIMIT</p>
							</div>
						</div>
						<hr>
						<h4>REQUEST NEW CREDIT LIMIT</h4>
						<div class="row">
							<div class="col">
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text">US $</span>
									</div>
									<input type="number" class="form-control c-hide-spinner" v-model="reqCreditLimitUSD">
									<div class="input-group-append">
										<span class="c-decrease-button input-group-text" @click="decreaseReqCreditLimit">-</span>
										<span class="c-increase-button input-group-text" @click="increaseReqCreditLimit">+</span>
									</div>
								</div>
								<div class="input-group mb-3">
									<div class="input-group-prepend">
										<span class="input-group-text">IDR</span>
									</div>
									<input type="number" class="form-control c-hide-spinner" v-model="reqCreditLimitIDR" readonly>
								</div>
								<div class="form-group">
									<textarea class="form-control" placeholder="Optional description" rows="5"
										style="max-height: 200px;"></textarea>
								</div>
								<hr>
								<button type="button" class="btn btn-success px-3" @click="reviewCreditChange()"><i
										class="tim-icons icon-check-2"></i>&ensp;REVIEW CREDIT CHANGE REQUEST</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Bullion -->
			<div :class="`tab-pane ${tabActive == 'bullion' && 'active'}`" id="link3"
				:aria-expanded="`${tabActive == 'bullion' ? 'true' : 'false'}`">

				<Bullion></Bullion>
			</div>

			<!-- Track Bullion -->
			<div :class="`tab-pane ${tabActive == 'trackBullion' && 'active'}`" id="link4"
				:aria-expanded="`${tabActive == 'trackBullion' ? 'true' : 'false'}`">
				<div class="row">
					<div class="col-12">
						<TrackBullion ref="trackBullion">
						</TrackBullion>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios, { abortController } from '@/api/axios.js'
import Swal from 'sweetalert2'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
import Bullion from '@/components/transfer/bullion/Bullion.vue'
import TrackBullion from '@/components/TrackBullion.vue'
import Disclaimer from '@/components/Disclaimer.vue'

export default {
	name: 'Transfer',
	components: {
		Loader,
		Bullion,
		TrackBullion,
		Disclaimer
	},
	data() {
		const month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
		let dateTodayIso = new Date()

		let dateToday = `${dateTodayIso.getDate()} ${month[dateTodayIso.getMonth()]} ${dateTodayIso.getFullYear()}`
		dateTodayIso.setDate(dateTodayIso.getDate() + 7)
		let arrivalDate = `${dateTodayIso.getDate()} ${month[dateTodayIso.getMonth()]} ${dateTodayIso.getFullYear()}`

		const contractIds = this.$store.state.contractIds

		return {
			dateToday: dateToday,
			arrivalDate: arrivalDate,

			publicPath: process.env.BASE_URL,

			tabActive: 'cash',

			depositUsd: 0,

			withdrawUsd: 0,
			withdrawIdr: 0,
			withdrawDescription: '',

			reqCreditLimitUSD: 0,
			depositDisplay: 'block',
			withdrawDisplay: 'none',
			payrefDisplay: 'initial',
			balanceHistory: [],
			withdrawUsdError: [],
			paymentInfo: null,
			utilityAccountIndex: 0,
			paymentInformationStatus: 'idle',

			bullionWithdrawalQuantity: {},
			bullionWithdrawalMultiple: {
				[contractIds.contract_100g]: 10,
				[contractIds.contract_1kg]: 10,
				[contractIds.contract_1g]: 10,
				[contractIds.contract_001g]: 100,
			},
			totalGold: [],
			withdrawDestination: 'CHOOSE...',
			withdrawDestinationLogo: '',
			withdrawDestinationIsOpen: false,

			withdrawDestinationAccountNumber: '',

			bullionWithdrawalOrderDate: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10),
			bullionWithdrawalArrivalDate: '',
			bullionWithdrawalTime: '00:00',

			locations: [],
			destination: '',
			oldDestination: '',
			selectedDestination: {},
			twoWordWarning: false,
			showLocations: false,
			bullionWithdrawalLocationShown: '',
			lastBullionWithdrawalLocationShown: '',
			postalCode: [],
			isBullionWithdrawalTotalChargesCounted: false,

			disclaimerIsOpen: false,
			disclaimerHasBeenRead: false,
			checkColor: 'grey',

			cancelTokenApiGetPostalCode: '',
			apiStatusGetPostalCode: 'idle',
			apiStatusGetFee: 'idle',

			feeGetFee: 0,
			feeTaxGetFee: 0,
			insuranceGetFee: 0,
			insuranceTaxGetFee: 0,
		}
	},
	computed: {
		hin() {
			return this.$store.getters.getHin
		},
		firstName() {
			return this.$store.getters.getFirstName
		},
		gold100gr() {
			return this.$store.getters.goldById(1)
		},
		streamingRate() {
			return this.$store.getters.getStreamingRate
		},
		currency() {
			return this.$store.state.currency
		},

		tradingType() {
			return this.$store.state.tradingType
		},
		availableTradeBalanceUsd() {
			return this.numberFormatExtend(this.$store.state.availableTradeBalanceUsd.toFixed(2))
		},
		availableTradeBalanceIdr() {
			return this.numberFormatExtend(this.$store.state.availableTradeBalanceIdr.toFixed(2))
		},

		showPhoneNumber() {
			if (this.withdrawDestination != 'giropos' && this.withdrawDestination != '' && this.withdrawDestination != 'CHOOSE...') {
				return true
			} else {
				return true
			}
		},
		labelWithdraw() {
			let temp = 'Account Number'
			switch (this.withdrawDestination) {
				case 'cimb':
				case 'bca':
				case 'mandiri':
				case 'bni':
				case 'Artha Graha':
					temp = 'Account Number'
					break
				case 'giropos':
				case 'rekpon':
				case 'ovo':
				case 'gopay':
				case 'linkaja':
				case 'dana':
				case 'isaku':
					temp = 'Phone Number / ID'
					break
				default:
					temp = 'Account Number'
			}
			return temp
		},

		withdrawUsdInInput: {
			get: function () {
				return Number(this.withdrawUsd.toFixed(2))
			},
			set: function (newValue) {
				this.withdrawUsd = newValue
				this.withdrawIdr = Number(Decimal.mul(newValue, this.streamingRate.stream_sell.cur_rate))
			}
		},
		withdrawIdrInInput: {
			get: function () {
				return Number(this.withdrawIdr.toFixed(0))
			},
			set: function (newValue) {
				this.withdrawIdr = newValue
				this.withdrawUsd = Number(Decimal.div(newValue, this.streamingRate.stream_sell.cur_rate))
			}
		},

		reqCreditLimitIDR() {
			return this.reqCreditLimitUSD * this.streamingRate.stream_buy.cur_rate
		},
		utilityBankName: {
			get: function () {
				return this.utilityAccountIndex
			},
			set: function (val) {
				this.utilityAccountIndex == val
			}
		},

		

	},

	watch: {
		currency() {
			this.balanceHistory = []
			// this.getDepositWithdrawalHistory()
			this.getLastTenTransactions()
		},
	},

	created() {
		this.tabActive = window.localStorage.getItem('tabActiveTransfer') || 'cash'

		// this.utilityAccountIndex = (this.$store.state.bankType == "BAGI") ? 0 : 1		

		this.getLastTenTransactions()

		// this.getDepositWithdrawalHistory()

		this.paymentInformationStatus = 'loading'
		axios.post('/v2/igx/payment_information', {
			user_id: this.$store.state.userId
		}).then(response => {
			this.paymentInfo = response.data.data.body
			this.paymentInformationStatus = 'success'
		}).catch(() => {
			this.paymentInformationStatus = 'error'
		})

		// axios({
		// 	method: 'get',
		// 	url: '/v2/get/location',
		// }).then((response) => {
		// 	let data = response.data.data.filter(obj => obj.active == true)

		// 	this.locations = data.sort((a, b) => {
		// 		let nameA = a.location_name.toUpperCase() // ignore upper and lowercase
		// 		let nameB = b.location_name.toUpperCase() // ignore upper and lowercase
		// 		if (nameA < nameB) {
		// 			return -1
		// 		}
		// 		if (nameA > nameB) {
		// 			return 1
		// 		}

		// 		// names must be equal
		// 		return 0
		// 	})
		// }).catch(error => {
		// 	if (error.response) {
		// 		/*
		// 		 * The request was made and the server responded with a
		// 		 * status code that falls out of the range of 2xx
		// 		 */
		// 	} else if (error.request) {
		// 		/*
		// 		 * The request was made but no response was received, `error.request`
		// 		 * is an instance of XMLHttpRequest in the browser and an instance
		// 		 * of http.ClientRequest in Node.js
		// 		 */
		// 	} else {
		// 		// Something happened in setting up the request and triggered an Error
		// 	}
		// })
	},

	methods: {
		move() {
			let i = 0
			if (i == 0) {
				i = 1
				let elem = document.getElementById("myBar")
				let elem2 = document.getElementById("frontBar")
				let width = 0
				let time = 10
				let id = setInterval((() => {
					if (width >= 100) {
						clearInterval(id)
						i = 0
					} else {
						width += 0.1
						time -= 0.01
						elem.style.width = width + "%"
						elem2.innerHTML = time.toFixed(2) + ""
					}
				}), 10)
			}
		},
		numberFormatExtend(value) {
			let val = (value / 1)
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
		},

		changeTabActive(tabName) {
			this.tabActive = tabName
			window.localStorage.setItem('tabActiveTransfer', tabName)
		},

		increaseDeposit() {
			let temp = Number(this.depositUsd) + 1
			if (temp <= 0) {
				this.depositUsd = 0
			} else {
				this.depositUsd = temp
			}
		},
		decreaseDeposit() {
			let temp = Number(this.depositUsd) - 1
			if (temp < 0) {
				this.depositUsd = 0
			} else {
				this.depositUsd = temp
			}
		},

		decreaseWithdrawUsd() {
			let temp = Number(this.withdrawUsd) - 1
			if (temp <= 0) {
				this.withdrawUsd = 0
				this.withdrawIdr = 0
			} else {
				this.withdrawUsd = temp
				this.withdrawIdr = Number(Decimal.mul(temp, this.streamingRate.stream_sell.cur_rate))
			}
		},
		increaseWithdrawUsd() {
			let temp = Number(this.withdrawUsd) + 1
			if (temp <= 0) {
				this.withdrawUsd = 0
				this.withdrawIdr = 0
			} else {
				this.withdrawUsd = temp
				this.withdrawIdr = Number(Decimal.mul(temp, this.streamingRate.stream_sell.cur_rate))
			}
		},

		decreaseWithdrawIdr() {
			let temp = Number(this.withdrawIdr) - 10000
			if (temp <= 0) {
				this.withdrawIdr = 0
				this.withdrawUsd = 0
			} else {
				this.withdrawIdr = temp
				this.withdrawUsd = Number(Decimal.div(temp, this.streamingRate.stream_sell.cur_rate))
			}
		},
		increaseWithdrawIdr() {
			let temp = Number(this.withdrawIdr) + 10000
			if (temp <= 0) {
				this.withdrawIdr = 0
				this.withdrawUsd = 0
			} else {
				this.withdrawIdr = temp
				this.withdrawUsd = Number(Decimal.div(temp, this.streamingRate.stream_sell.cur_rate))
			}
		},

		increaseReqCreditLimit() {
			let temp = Number(this.reqCreditLimitUSD) + 1
			if (temp <= 0) {
				this.reqCreditLimitUSD = 0
			} else {
				this.reqCreditLimitUSD = temp
			}
		},
		decreaseReqCreditLimit() {
			let temp = Number(this.reqCreditLimitUSD) - 1
			if (temp <= 0) {
				this.reqCreditLimitUSD = 0
			} else {
				this.reqCreditLimitUSD = temp
			}
		},

		showDeposit() {
			this.depositDisplay = 'block'
			this.withdrawDisplay = 'none'
			this.payrefDisplay = 'initial'
		},
		showWithdraw() {
			this.withdrawDisplay = 'block'
			this.depositDisplay = 'none'
			this.payrefDisplay = 'none'
		},
		reviewCreditChange() {
			Swal.fire({
				background: '#191f31',
				html: '<span style="color: white;"> Syarat dan ketentuan berlaku untuk menggunakan fasilitas ini </span>'
			})
		},

		// createdAt: "2022-06-02T07:13:24.982Z"  created_at: "2022-06-02T07:13:26.167Z"

		getLastTenTransactions() {
			axios.post('/v2/igx/last_transactions?skip=0&limit=10&type=bank', {
				user_id: this.$store.state.userId
			}).then(response => {
				let streamingRate = 1
				if (this.currency == 'usd') {
					streamingRate = this.streamingRate.stream_sell.cur_rate
				}

				let history = response.data.data.body
				for (let i = 0; i < 10; i++) {
					if (!history[i]) {
						break
					}

					let dateTemp = new Date(history[i].created_at)
					let date = {
						year: dateTemp.getFullYear(),
						month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
						date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
						hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
						minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
					}

					let indexOfStart = history[i].reff_transaction.indexOf("-")
					let transactionId = history[i].reff_transaction.substring(indexOfStart + 1)

					let debit = history[i].type == 'debit' ? Decimal.mul(history[i].amount, -1).dividedBy(streamingRate).toFixed(2) : '-'
					let credit = history[i].type == 'credit' ? Decimal.div(history[i].amount, streamingRate).toFixed(2) : '-'

					this.balanceHistory.push({
						date: `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
						transactionId: transactionId,
						debit: debit,
						credit: credit
					})
				}

			})
		},

		getDepositWithdrawalHistory() {
			axios.get('/v2/abx/account/deposit_withdrawal_history'
			).then(response => {
				let history = response.data.data
				let deposit, withdrawal, debit, credit, dateTemp
				for (let i = 0; i < 10; i++) {
					dateTemp = new Date(history.balance_history[i].attributes.createdAt)
					let date = {
						year: dateTemp.getFullYear(),
						month: (dateTemp.getMonth() + 1 < 10) ? `0${dateTemp.getMonth() + 1}` : dateTemp.getMonth() + 1,
						date: (dateTemp.getDate() < 10) ? `0${dateTemp.getDate()}` : dateTemp.getDate(),
						hours: (dateTemp.getHours() < 10) ? `0${dateTemp.getHours()}` : dateTemp.getHours(),
						minutes: (dateTemp.getMinutes() < 10) ? `0${dateTemp.getMinutes()}` : dateTemp.getMinutes()
					}
					withdrawal = history.withdrawal.find(obj => obj.id == history.balance_history[i].id)
					deposit = history.deposit.find(obj => obj.id == history.balance_history[i].id)
					if (withdrawal) {
						if (this.currency == 'idr') {
							debit = this.numberFormatExtend((withdrawal.attributes.amount * this.streamingRate.stream_sell.cur_rate).toFixed(2))
						} else {
							debit = this.numberFormatExtend(withdrawal.attributes.amount.toFixed(2))
						}
						credit = '-'
					} else {
						debit = '-'
						if (this.currency == 'idr') {
							credit = this.numberFormatExtend((deposit.attributes.amount * this.streamingRate.stream_sell.cur_rate).toFixed(2))
						} else {
							credit = this.numberFormatExtend(deposit.attributes.amount.toFixed(2))
						}
					}
					this.balanceHistory.push({
						date: `${date.year}/${date.month}/${date.date} ${date.hours}:${date.minutes}`,
						debit: debit,
						credit: credit
					})
				}
			})
		},

		reqWithdrawal() {
			let notError = true
			this.withdrawUsdError = []
			if (this.withdrawIdr < 10000) {
				notError = false
				this.withdrawUsdError.push('Value must be greater than 10000')
			}

			if (!this.withdrawDestinationAccountNumber) {
				Swal.fire({
					background: '#191f31',
					// title: '<span style="color: white;">Pre Order Trading Contract.</span>',
					html: '<span style="color: white;">Please choose your withdraw destination.</span>'
				})
			}
			else if (notError) {
				let balanceValidationIsOk = false
				Swal.queue([{
					background: '#191f31',
					title: '<span style="color: white;">Are you sure want to withdraw?</span>',
					html: '<span style="color: white;">This process may apply for withdrawal bank fee.</span>',
					showCancelButton: true,
					confirmButtonText: 'Submit',
					showLoaderOnConfirm: true,
					preConfirm: () => {
						balanceValidationIsOk = true
						return true
						// return axios({
						// 	method: 'POST',
						// 	url: '/v2/user/balance_validation',
						// 	data: {
						// 		amount_idr: this.totalIdrLimit,
						// 		direction: this.direction
						// 	}
						// }).then(response => {
						// 	balanceValidationIsOk = true

						// }).catch(error => {
						// 	let errorTitle = '<span style="color: white;">Something went wrong</span>'
						// 	let errorText = '<span style="color: white;">Please try again later</span>'

						// 	if (error.response) {
						// 		/*
						// 		 * The request was made and the server responded with a
						// 		 * status code that falls out of the range of 2xx
						// 		 */

						// 		errorTitle = '<span style="color: white;">Something went wrong</span>'
						// 		errorText = '<span style="color: white;">' + error.response.data.message + '</span>'
						// 	} else if (error.request) {
						// 		/*
						// 		 * The request was made but no response was received, `error.request`
						// 		 * is an instance of XMLHttpRequest in the browser and an instance
						// 		 * of http.ClientRequest in Node.js
						// 		 */

						// 		errorTitle = '<span style="color: white;">Server appears to be offline</span>'
						// 		errorText = '<span style="color: white;">Please try again later</span>'
						// 	} else {
						// 		// Something happened in setting up the request and triggered an Error
						// 	}
						// 	Swal.insertQueueStep({
						// 		background: '#191f31',
						// 		type: 'error',
						// 		title: errorTitle,
						// 		html: errorText
						// 	})
						// })
					},
					onAfterClose: () => {
						if (balanceValidationIsOk) {
							Swal.showLoading()
							axios.post('/v2/igx/request_withdrawal', {
								user_id: this.$store.state.userId,
								// amount_usd: (this.withdrawUsd),
								amount_idr: this.withdrawIdr,
								description: this.withdrawDescription
							}).then(response => {
								Swal.hideLoading()
								Swal.fire({
									background: '#191f31',
									type: 'success',
									title: '<span style="color: white;">Transfer success</span>'
								}).then(() => {
									location.reload()
								})

							}).catch(error => {
								Swal.hideLoading()
								let errorTitle = '<span style="color: white;">Something went wrong</span>'
								let errorText = '<span style="color: white;">Please try again later</span>'

								if (error.response) {
									errorTitle = '<span style="color: white;">Something went wrong</span>'
									errorText = '<span style="color: white;">' + error.response.data.message + '</span>'
								} else if (error.request) {
									errorTitle = '<span style="color: white;">Server appears to be offline</span>'
									errorText = '<span style="color: white;">Please try again later</span>'
								}

								Swal.fire({
									background: '#191f31',
									type: 'error',
									title: errorTitle,
									html: errorText
								}).then(() => {
									location.reload()
								})
							})
						}
					}
				}])
			}
		},

		withdrawDestinationAfterClose(value) {
			if (value == 'giropos') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/pos-giro.png`
			} else if (value == 'gopay') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-gopay-2.jpg`
			} else if (value == 'ovo') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-ovo-3.jpg`
			} else if (value == 'rekpon' || value == 'cimb') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-cimb-3.png`
			} else if (value == 'linkaja') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-linkaja.png`
			} else if (value == 'dana') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-dana-2.png`
			} else if (value == 'isaku') { //isaku
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-isaku.jpg`
			} else if (value == 'bca') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-bca-2.png`
			} else if (value == 'mandiri') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-mandiri-3.png`
			} else if (value == 'bni') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-bni-2.png`
			} else if (value == 'Artha Graha') {
				this.withdrawDestinationLogo = `${this.publicPath}assets/img/logo-bagi-removebg.png`
				this.withdrawDestinationAccountNumber = this.paymentInfo.withdrawal_acc[this.utilityAccountIndex].account_number
			}

			if (value != '') {
				this.withdrawDestination = value
			}

			this.withdrawDestinationIsOpen = false
		},
	}
}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
.c-hide-spinner::-webkit-outer-spin-button,
.c-hide-spinner::-webkit-inner-spin-button {
	/* display: none; <- Crashes Chrome on hover */
	-webkit-appearance: none;
	margin: 0;
	/* <-- Apparently some margin are still there even though it's hidden */
}

/* Firefox */
.c-hide-spinner[type=number] {
	-moz-appearance: textfield;
}

.c-decrease-button,
.c-increase-button {
	padding-right: 15px !important;
	padding-left: 15px !important;
	text-align: center;
	transition: 0s;

	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
																	supported by Chrome and Opera */
}

.c-decrease-button:hover,
.c-increase-button:hover {
	cursor: pointer;
}

.c-decrease-button:active,
.c-increase-button:active {
	background-color: white;
	color: black;
}

.c-decrease-button {}

.c-increase-button {
	border-left: 1px solid #2b3553;
}

#myProgress {
	position: relative;
	width: 100%;
	background-color: #4CAF50;
}

#myBar {
	width: 0%;
	height: 30px;
	background-color: #ddd;
	text-align: center;
	color: white;
}

#frontBar {
	position: absolute;
	top: 0;
	color: black;
	background-color: transparent;
	width: 100%;
	text-align: center;
	line-height: 30px;
	font-weight: bold;
}

input.c-disabled {
	color: rgb(255, 255, 255, 0.8);
}

.c-input-group-text {
	font-size: 12px;
}

.c-select {
	background-color: #27293d;
	border: 1px solid #2b3553;
}

.c-description {
	width: 300px;
	max-width: 300px;
}

.button-counter {
	background-color: #1E1E2E;
}

.c-input {
	margin: 0 2px;
	padding: 0 8px;
	border: 1px solid #2b3553;
	background: transparent;
	height: 26.4px;
	border-radius: 4px;
	text-align: center;
	width: 80px;
	min-width: 80px;
	color: white;
}

.bullion-withdrawal-description {
	border: 1px solid #2b3553;
	padding: 12px;
	font-size: 12px;
}

.table-bullion-withdrawal th {
	padding: 12px 24px;
}

.table-bullion-withdrawal {
	width: auto;
	/*background-color: #212529;*/
}

.table-bullion-withdrawal .first-head {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
</style>
