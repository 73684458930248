<template>
	<svg :width="width" :height="height" viewBox="0 0 10 10" fill="none" >
		<path id="Vector"
			d="M5 0C7.7615 0 10 2.2385 10 5C10 7.7615 7.7615 10 5 10C2.2385 10 0 7.7615 0 5C0 2.2385 2.2385 0 5 0ZM5 1C3.93913 1 2.92172 1.42143 2.17157 2.17157C1.42143 2.92172 1 3.93913 1 5C1 6.06087 1.42143 7.07828 2.17157 7.82843C2.92172 8.57857 3.93913 9 5 9C6.06087 9 7.07828 8.57857 7.82843 7.82843C8.57857 7.07828 9 6.06087 9 5C9 3.93913 8.57857 2.92172 7.82843 2.17157C7.07828 1.42143 6.06087 1 5 1ZM4.995 4C5.274 4 5.5 4.226 5.5 4.505V7.067C5.59531 7.12204 5.66981 7.20698 5.71192 7.30867C5.75404 7.41036 5.76143 7.5231 5.73294 7.62941C5.70445 7.73572 5.64168 7.82967 5.55437 7.89667C5.46705 7.96367 5.36006 8 5.25 8H5.005C4.93868 8 4.87301 7.98694 4.81174 7.96156C4.75048 7.93618 4.6948 7.89898 4.64791 7.85209C4.60102 7.8052 4.56382 7.74953 4.53844 7.68826C4.51306 7.62699 4.5 7.56132 4.5 7.495V5C4.36739 5 4.24021 4.94732 4.14645 4.85355C4.05268 4.75979 4 4.63261 4 4.5C4 4.36739 4.05268 4.24021 4.14645 4.14645C4.24021 4.05268 4.36739 4 4.5 4H4.995ZM5 2.5C5.13261 2.5 5.25979 2.55268 5.35355 2.64645C5.44732 2.74021 5.5 2.86739 5.5 3C5.5 3.13261 5.44732 3.25979 5.35355 3.35355C5.25979 3.44732 5.13261 3.5 5 3.5C4.86739 3.5 4.74021 3.44732 4.64645 3.35355C4.55268 3.25979 4.5 3.13261 4.5 3C4.5 2.86739 4.55268 2.74021 4.64645 2.64645C4.74021 2.55268 4.86739 2.5 5 2.5Z"
			:fill="color_prop" />
	</svg>
</template>

<script>
export default {

	name: 'IconInfo',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '12px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '12px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: '#8F92A1',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	}
}
</script>

<style lang="css" scoped></style>