<template>
	<div>
		<div :id="'clientsFilterSpan' + filterId" style="font-size: 0.75em; position: fixed; visibility: hidden;">{{clientSelected}}</div>

		<input :list="'listClients' + filterId" name="clientsFilter" onfocus="this.value=''" @focusout="outOfFocus()" onchange="this.blur();" :id="'clientsFilter' + filterId" class="form-control" :style="'height: 33px; min-width: 10px;' + inputWidth" v-model="clientSelected" placeholder="Filter Client" required>

		<template v-if="clients_prop.length > 0 || names_prop.length > 0">
			<datalist v-if="clients_prop.length > 0" :id="'listClients' + filterId">
				<option value="House"></option>
				<option v-for="client in clients_prop" :key="client._id + filterId" :value="`${client.trade_account.hin} - ${client.fullname}`"></option>
			</datalist>

			<datalist v-else :id="'listClients' + filterId">
				<option v-for="(name, index) in names_prop" :key="hins_prop[index] + filterId" :value="hins_prop[index] + '-' + name"></option>
			</datalist>
		</template>

	</div>
</template>

<script>
	export default {
		name: 'FilterClient',

		props: {
			filterId: {
				type: String,
				default: String(Math.random())
			},

			clients_prop: {
				type: Array,
				default: () => { return [] }
			},

			names_prop: {
				type: Array,
				default: () => { return [] }
			},

			hins_prop: {
				type: Array,
				default: () => { return [] }
			}
		},

		data() {
			return {
				clients: [],
				clientSelected: 'House',
				tempClientSelected: 'House',
				inputWidth: '',
			}
		},

		computed: {
		},

		watch: {
			clientSelected(newValue) {
				if (newValue.includes('House')) {
					this.tempClientSelected = newValue
					this.$emit('changeFilterClient', 'all')
				}else {
					let indexSpace = newValue.indexOf(' ')
					let hin = newValue.substr(0, indexSpace)
					let selectedClient = this.clients_prop.find(obj => obj.trade_account.hin == hin)

					if (selectedClient) {
						this.tempClientSelected = newValue
						this.$emit('changeFilterClient', selectedClient)
					}
				}

				setTimeout(() => {
					let el = document.getElementById('clientsFilterSpan' + this.filterId)
					this.inputWidth = String('width:' + (el.clientWidth + 60) + 'px;')
				}, 100)
			}
		},

		mounted() {
			// let totalChar = this.clientSelected.length
			// let el = document.getElementById('clientsFilterSpan' + this.filterId)
			// let style = window.getComputedStyle(el, null).getPropertyValue('font-size')
			// let fontSize = parseFloat(style)
			// this.inputWidth = String('width:' + (totalChar * fontSize) + 'px;')
		},

		methods: {
			outOfFocus() {
				if (document.getElementById('clientsFilter' + this.filterId).value == '') {
					document.getElementById('clientsFilter' + this.filterId).value = this.clientSelected
				}else if (document.getElementById('clientsFilter' + this.filterId).value != this.tempClientSelected) {
					this.clientSelected = this.tempClientSelected
					setTimeout(() => {
						let el = document.getElementById('clientsFilterSpan' + this.filterId)
						this.inputWidth = String('width:' + (el.clientWidth + 60) + 'px;')
					}, 2000)
				}
			}
		}
	}
</script>