import Vue from 'vue'
import { Decimal } from 'decimal.js'

export default {
	loggedIn(state) {
		return state.token !== null
	},
	TOKEN(state) {
		return state.token
	},
	SERVER_URL(state) {
		return state.serverURL
	},
	BACKEND_URL(state) {
		return state.backendURL
	},
	USER(state) {
		return state.user
	},
	isAuthenticated(state) {
		return !!state.token
	},
	authStatus(state) {
		return state.status
	},

	getAppName(state) {
		return (state.authInfo || {}).app_name || '-'
	},

	getContractConfigs: (state) => (contractId) => {
		const contractConfig = state.contractConfigs[contractId]

		if (!contractConfig) {
			return {
				contractStatus: 'disabled',

				weightInGram: -1,
				weightMultiplication: -1,
				weightCorrection: 1,
				depthTTDecimalPlace: 2,
				tbTtDecimalPlace: 2,
				depthQuantityDecimalPlace: 0,
				hasQuantityDecimal: false,
				contractType: 'igx',
				codeName: '-',
				tradeTicketName: '-',
				name: '-',
				tradeBoxName: '-',
				rrsName: '-',

				ohlcId: -1,
			}
		}

		return contractConfig
	},

	getAccountLevel(state, getters) {
		if (getters.getHin == '-') {
			return 'ccp'
		}

		let temp = state.account
		const template = { IB: 'ib', PB: 'fm', LP: 'lp', CP: 'lp', PC: 'client' }
		if (temp) {
			return template[temp.hin.substr(0, 2)]
		} else {
			return '-'
		}
	},

	getReferralCode(state) {
		const account = state.account
		if (account) {
			return account.referralCode || '-'
		}

		return '-'
	},

	getBrokerName(state) {
		const brokerName = state.authInfo

		if (!brokerName) {
			return '-'
		}

		return brokerName.app_name.toLowerCase()
	},

	getPhoneNumber(state) {
		let accountInfo = state.account
		if (accountInfo) {
			return state.account.phoneNumber
		}

		return '-'
	},

	//what is this vvv ?
	getSomething(state) {
		let accountInfo = state.account
		if (accountInfo) {
			if (accountInfo.phoneNumber == '628150000038') {
				return 'jfx'
			}
			else if (accountInfo.phoneNumber == '628150000039') {
				return 'kbi'
			}
			else {
				return 'normal'
			}
		}

		return 'normal'
	},

	depthById: (state) => (depthId) => {
		let temp = state.depths

		// if (depthId == '6') {
		// 	temp = state.kinesisDepth
		// }else {
		// 	temp = state.depths
		// }

		if (temp) {
			// if (depthId == '6') {
			// 	temp.buy = temp.buy.map(obj=> ({ ...obj, quantity: obj.amount }))
			// 	temp.sell = temp.sell.map(obj=> ({ ...obj, quantity: obj.amount }))
			// 	return temp
			// }else {
			// 	return state.depths[depthId]
			// }

			return temp[depthId]
		} else {
			return null
		}
	},

	goldById: (state) => (goldId) => {
		let gold = state.golds
		if (gold) {
			let oneGold = gold[goldId]
			if (oneGold) {
				//set sell price
				if (oneGold.sell < 0 && oneGold.buy >= 0) {
					if (state.currency == 'idr' && (oneGold.id == 1 || oneGold.id == 3)) {
						oneGold.sell = Number(oneGold.buy)
					} else {
						oneGold.sell = oneGold.buy
					}
				} else {
					if (state.currency == 'idr' && (oneGold.id == 1 || oneGold.id == 3)) {
						oneGold.sell = Number(oneGold.sell)
					}
				}

				//set buy price
				if (oneGold.buy < 0 && oneGold.sell >= 0) {
					if (state.currency == 'idr' && (oneGold.id == 1 || oneGold.id == 3)) {
						oneGold.buy = Number(oneGold.buy)
					} else {
						oneGold.buy = oneGold.sell
					}
				} else {
					if (state.currency == 'idr' && (oneGold.id == 1 || oneGold.id == 3)) {
						oneGold.buy = Number(oneGold.buy)
					}
				}

				return oneGold
			} else {
				return null
			}

		} else {
			return null
		}
	},

	getGoldByContractId: (state) => (contractId) => {
		let golds = state.golds
		if (golds) {
			return golds[contractId]
		} else {
			return null
		}
	},

	getOhlcByContractId: (state) => (contractId) => {
		const ohlc = state.ohlc
		if (ohlc) {
			return ohlc[contractId]
		} else {
			return null
		}
	},

	PRODUCTS(state) {
		let products = state.products
		let arr = []
		if (products) {
			products.forEach((product) => {
				if (product) {
					arr.push(product)
				}
			})
			return arr
		} else {
			return null
		}
	},
	productById: (state, getters) => (productId) => {
		let products = getters.PRODUCTS
		if (products) {
			let product = products.find(obj => obj.id == productId)
			if (product) {
				return product
			}
			return null
		}
		return null
	},
	getStreamingRate(state) {
		let streamingRate = structuredClone(state.streamingRate)
		if (!streamingRate || (streamingRate || {}).stream_buy == 0) {
			return {
				stream_buy: {
					cur_rate: -1
				},
				stream_sell: {
					cur_rate: -1
				}
			}
		}

		return {
			stream_buy: {
				cur_rate: streamingRate.stream_buy.cur_rate
			},
			stream_sell: {
				cur_rate: streamingRate.stream_sell.cur_rate
			}
		}
	},
	getHin(state) {
		let account = state.account
		if (account) {
			return account.hin
		} else {
			return '-'
		}
	},
	getEmail(state) {
		let account = state.account
		if (account) {
			return account.email
		} else {
			return '-'
		}
	},
	getFirstName(state) {
		let account = state.account
		if (account) {
			return account.firstName
		} else {
			return '-'
		}
	},
	getHinKinesis(state) {
		let account = state.accountKinesis
		if (account) {
			return account.hin
		} else {
			return '-'
		}
	},

	getTotalGold(state, getters) {
		// const contractOrder = [7, 5, 1, 3] //contract id
		const holdings = state.holdings

		let totalGold = []

		if (holdings) {
			holdings.forEach((holding, index) => {
				const contractConfig = getters.getContractConfigs(holding.contract_id)

				const tempTotalGold = {
					contractId: holding.contract_id,
					product: contractConfig.name,
					quantity: Number(Decimal.div(holding.quantity, contractConfig.weightCorrection)),
					weight: Number(contractConfig.weightInGram),
					weightCorrection: Number(contractConfig.weightCorrection),
					totalGram: Number(Decimal.mul(contractConfig.weightInGram, Decimal.div(holding.quantity, contractConfig.weightCorrection))),
				}

				Vue.set(totalGold, index, tempTotalGold)
			})

			return totalGold
		} else {
			return null
		}
	},

	getContractStatus(state) {
		const contracts = state.contracts
		let contractStatus = {
			goldMicro: {
				contractId: 5,
				status: 'disabled',
			},
			gold1Gram: {
				contractId: 6,
				status: 'disabled',
			},
			gold100Gram: {
				contractId: 1,
				status: 'disabled',
			},
			gold10Oz: {
				contractId: 2,
				status: 'disabled',
			},
			gold1Kg: {
				contractId: 3,
				status: 'disabled',
			},
			gold10Kg: {
				contractId: 4,
				status: 'disabled',
			},
		}

		if (contracts) {
			contracts.forEach((contract) => {
				let index = contractStatus.findIndex(obj => obj.contractId == contract.id)
				contractStatus[index].quantity = contract.attributes.value
			})

			return contractStatus
		} else {
			return null
		}
	},

	// HOLDING vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
	getHolding(state) { // holdings after the QUANTITY has been corrected
		const holdings = state.holdings

		let newHoldings = []
		holdings.forEach((holding) => {
			const contractConfig = state.contractConfigs[holding.contract_id]
			newHoldings.push({
				...holding,
				contractId: holding.contract_id,
				quantity: Decimal.div(holding.quantity, contractConfig.weightCorrection).toNumber(),
			})
		})

		return newHoldings
	},
	getHoldingByContractId: (state, getters) => (contractId) => {
		const holdings = getters.getHolding
		if (holdings) {
			return holdings.find(obj => obj.contractId == contractId)
		} else {
			return null
		}
	},

	getMetalValueAtDateUsdByContractId: (state, getters) => (contractId, limitPrice = -1) => {
		if (!state.golds) {
			return new Decimal(-1)
		}

		const tempGold = state.golds[contractId]
		if (!tempGold) {
			return new Decimal(-1)
		}

		let goldPriceNow = new Decimal(tempGold.contractPriceSell)

		if (new Decimal(limitPrice).toNumber() != -1) {
			goldPriceNow = Decimal.mul(limitPrice, state.contractConfigs[contractId].weightMultiplication)
		}

		// goldPriceNow should be contract price. Meaning, the price per contract's weight

		const holding = getters.getHoldingByContractId(contractId)
		let temp = new Decimal(goldPriceNow).times(holding.quantity)

		if (contractId == 0 || temp.equals(0)) {
			return new Decimal(0)
		}

		let fee = Decimal.mul(temp, state.fee).toDecimalPlaces(3)
		if (fee.lessThan(state.feeMin)) {
			fee = new Decimal(state.feeMin)
		}

		let tax = Decimal.mul(fee, state.tax).toDecimalPlaces(3)
		if (tax.lessThan(state.taxMin)) {
			tax = new Decimal(state.taxMin)
		}

		return Decimal.sub(temp, Decimal.add(fee, tax)).toDecimalPlaces(2)
	},

	getMetalValueAtDateIdrByContractId: (state, getters) => (contractId, limitPrice = -1) => {
		if (!state.golds) {
			return new Decimal(-1)
		}

		const tempGold = state.golds[contractId]
		if (!tempGold) {
			return new Decimal(-1)
		}

		let goldPriceNow = new Decimal(tempGold.contractPriceSell)
		if (limitPrice != -1) {
			goldPriceNow = Decimal.mul(limitPrice, state.contractConfigs[contractId].weightMultiplication)
		}

		const streamingRate = getters.getStreamingRate
		goldPriceNow = Decimal.mul(goldPriceNow, streamingRate.stream_sell.cur_rate).toDecimalPlaces(2)

		// goldPriceNow should be contract price. Meaning, the price per contract's weight

		const holding = getters.getHoldingByContractId(contractId)
		let temp = new Decimal(goldPriceNow).times(holding.quantity)

		if (contractId == 0 || temp.equals(0)) {
			return new Decimal(0)
		}

		let feeTax = temp.times(state.feeTaxMultiplier).toDecimalPlaces(2)
		if (temp.lessThanOrEqualTo(state.totalIdrMin)) {
			feeTax = Decimal.mul(state.feeTaxMin, streamingRate.stream_sell.cur_rate).toDecimalPlaces(2)
		}

		return temp.minus(feeTax).toDecimalPlaces(2)
	},

	getEstimatePNLUsdByContractId: (state, getters) => (contractId, limitPrice = -1) => {
		const holding = getters.getHoldingByContractId(contractId)

		let tempAMC
		if (getters.getAccountLevel == 'fm' || getters.getAccountLevel == 'ib') {
			tempAMC = holding.averageTotalCostIncFeeAndTaxUsd
		} else {
			tempAMC = holding.avg_total_cost
		}

		const metalValueAtDate = getters.getMetalValueAtDateUsdByContractId(contractId, limitPrice)
		if (metalValueAtDate.lessThan(0) || isNaN(holding.quantity)) {
			return '-'
		}

		if (holding.quantity == 0) {
			return new Decimal(0)
		}

		return Decimal.sub(metalValueAtDate, (tempAMC || 0)).toDecimalPlaces(2)
	},
	getEstimatePNLIdrByContractId: (state, getters) => (contractId, limitPrice = -1) => {
		const holding = getters.getHoldingByContractId(contractId)

		let tempAMC
		if (getters.getAccountLevel == 'fm' || getters.getAccountLevel == 'ib') {
			tempAMC = holding.averageTotalCostIncFeeAndTaxIdr
		} else {
			tempAMC = holding.avg_total_cost_idr
		}

		const metalValueAtDate = getters.getMetalValueAtDateIdrByContractId(contractId, limitPrice)
		if (metalValueAtDate.lessThan(0) || isNaN(holding.quantity)) {
			return '-'
		}

		if (holding.quantity == 0) {
			return new Decimal(0)
		}

		return Decimal.sub(metalValueAtDate, (tempAMC || 0)).toDecimalPlaces(2)
	},

	// for JFX championship vvvvvvvvvvvvv

	getMetalValueAtDateUsdByContractIdForJfxChamp: (state, getters) => (contractId, limitPrice = -1) => {
		if (!state.golds) {
			return new Decimal(-1)
		}

		const tempGold = state.golds[contractId]
		if (!tempGold) {
			return new Decimal(-1)
		}

		let goldPriceNow = new Decimal(tempGold.contractPriceSell)

		if (new Decimal(limitPrice).toNumber() != -1) {
			goldPriceNow = Decimal.mul(limitPrice, state.contractConfigs[contractId].weightMultiplication)
		}

		// goldPriceNow should be contract price. Meaning, the price per contract's weight

		const holding = getters.getHoldingByContractId(contractId)
		let temp = new Decimal(goldPriceNow).times(holding.quantity)

		if (contractId == 0 || temp.equals(0)) {
			return new Decimal(0)
		}

		let fee = Decimal.mul(temp, state.fee).toDecimalPlaces(4)
		if (fee.lessThan(state.feeMin)) {
			fee = new Decimal(state.feeMin)
		}

		let tax = Decimal.mul(fee, state.tax).toDecimalPlaces(4)
		if (tax.lessThan(state.taxMin)) {
			tax = new Decimal(state.taxMin)
		}


		state.pnlEtc = {
			...state.pnlEtc,
			pricePerGramUsd: new Decimal(limitPrice).toNumber() == -1 ? Decimal.div(tempGold.contractPriceSell, state.contractConfigs[contractId].weightMultiplication).toNumber() : new Decimal(limitPrice).toNumber(),
			metalValueAtDateUsd: temp.toNumber(),
			feeTaxUsd: fee.plus(tax).toNumber(),
			metalValueAtDateWithFeeTaxUsd: Decimal.sub(temp, fee.plus(tax)).toDecimalPlaces(4).toNumber(),
		}

		return Decimal.sub(temp, Decimal.add(fee, tax)).toDecimalPlaces(4)
	},

	getMetalValueAtDateIdrByContractIdForJfxChamp: (state, getters) => (contractId, limitPrice = -1) => {
		if (!state.golds) {
			return new Decimal(-1)
		}

		const tempGold = state.golds[contractId]
		if (!tempGold) {
			return new Decimal(-1)
		}

		let goldPriceNow = tempGold.contractPriceSell

		if (new Decimal(limitPrice).toNumber() != -1) {
			goldPriceNow = Decimal.mul(limitPrice, state.contractConfigs[contractId].weightMultiplication)
		}

		const streamingRate = getters.getStreamingRate
		goldPriceNow = Decimal.mul(goldPriceNow, streamingRate.stream_sell.cur_rate)

		// goldPriceNow should be contract price. Meaning, the price per contract's weight

		const holding = getters.getHoldingByContractId(contractId)
		let temp = new Decimal(goldPriceNow).times(holding.quantity)

		if (contractId == 0 || temp.equals(0)) {
			return new Decimal(0)
		}

		let feeTax = temp.times(state.feeTaxMultiplier).toDecimalPlaces(4)
		if (temp.lessThanOrEqualTo(state.totalIdrMin)) {
			feeTax = Decimal.mul(state.feeTaxMin, streamingRate.stream_sell.cur_rate).toDecimalPlaces(4)
		}


		state.pnlEtc = {
			...state.pnlEtc,
			streamingRate: streamingRate.stream_sell.cur_rate,
			pricePerGramIdr: new Decimal(limitPrice).toNumber() == -1 ? Decimal.div(tempGold.contractPriceSell, state.contractConfigs[contractId].weightMultiplication).toNumber() : new Decimal(limitPrice).toNumber(),
			metalValueAtDateIdr: temp.toNumber(),
			feeTaxIdr: feeTax.toNumber(),
			metalValueAtDateWithFeeTaxIdr: Decimal.sub(temp, feeTax).toDecimalPlaces(4).toNumber(),
		}

		return Decimal.sub(temp, feeTax).toDecimalPlaces(4)
	},

	getEstimatePNLUsdByContractIdForJfxChamp: (state, getters) => (contractId, limitPrice = -1) => {
		const holding = getters.getHoldingByContractId(contractId)

		let tempAMC
		if (getters.getAccountLevel == 'fm' || getters.getAccountLevel == 'ib') {
			tempAMC = holding.averageTotalCostIncFeeAndTaxUsd
		} else {
			tempAMC = holding.avg_total_cost
		}

		const metalValueAtDate = getters.getMetalValueAtDateUsdByContractIdForJfxChamp(contractId, limitPrice)
		if (metalValueAtDate.lessThan(0) || isNaN(holding.quantity)) {
			return '-'
		}

		if (holding.quantity == 0) {
			return new Decimal(0)
		}

		state.pnlEtc = {
			...state.pnlEtc,
			averageTotalCostUsd: tempAMC,
		}

		return Decimal.sub(metalValueAtDate, (tempAMC || 0)).toDecimalPlaces(4)
	},

	getEstimatePNLIdrByContractIdForJfxChamp: (state, getters) => (contractId, limitPrice = -1) => {
		const holding = getters.getHoldingByContractId(contractId)

		let tempAMC
		if (getters.getAccountLevel == 'fm' || getters.getAccountLevel == 'ib') {
			tempAMC = holding.averageTotalCostIncFeeAndTaxIdr
		} else {
			tempAMC = holding.avg_total_cost_idr
		}

		const metalValueAtDate = getters.getMetalValueAtDateIdrByContractIdForJfxChamp(contractId, limitPrice)
		if (metalValueAtDate.lessThan(0) || isNaN(holding.quantity)) {
			return '-'
		}

		if (holding.quantity == 0) {
			return new Decimal(0)
		}

		state.pnlEtc = {
			...state.pnlEtc,
			averageTotalCostIdr: tempAMC,
		}

		return Decimal.sub(metalValueAtDate, (tempAMC || 0)).toDecimalPlaces(4)
	},

	// for JFX championship ^^^^^^^^^^^^^^^


	// HOLDING ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

	getGoldBalance(state) { // goldBalance after the QUANTITY and WEIGHT has been corrected
		const goldBalance = state.goldBalance
		const reservedGoldOrder = state.reservedDeliveryGoldOrder

		let newGoldBalance = []
		reservedGoldOrder.forEach((order) => {
			const contractConfig = state.contractConfigs[order.contractId]
			const gold = goldBalance.find((obj) => obj.contract_id == order.contractId)

			newGoldBalance.push({
				contractId: gold.contract_id,
				product: gold.product,
				availableQuantity: Decimal.div(gold.availabe_qty, contractConfig.weightCorrection).toNumber(),
				quantity: Decimal.div(gold.quantity, contractConfig.weightCorrection).toNumber(),
				onProcess: Decimal.div(gold.on_process, contractConfig.weightCorrection).toNumber(),
				reservedDeliveryQuantity: Decimal.div(gold.reserved_delivery_qty, contractConfig.weightCorrection).toNumber(),
				reservedQuantity: Decimal.div(gold.reserved_qty, contractConfig.weightCorrection).toNumber(),
			})
		})

		return newGoldBalance
	},
	getGoldBalanceByContractId: (state, getters) => (contractId) => {
		const goldBalance = getters.getGoldBalance
		if (goldBalance) {
			return goldBalance.find(obj => obj.contractId == contractId)
		} else {
			return null
		}
	},

	getReservedGold(state) {
		const products = state.products
		const reservedGoldOrder = state.reservedGoldOrder
		const reservedGold = state.reservedGold

		let temp = []

		if (reservedGold.length > 0 && products) {
			reservedGoldOrder.forEach(obj1 => {
				let temp2 = reservedGold.find(obj2 => obj2.attributes.contractId == obj1.contractId)
				const contractConfig = state.contractConfigs[obj1.contractId]
				temp2 = {
					...temp2,
					'totalGram': Number(Decimal.mul(Decimal.div(contractConfig.weightInGram, contractConfig.weightCorrection), temp2.attributes.value)),
					'totalQuantity': Number(Decimal.div(temp2.attributes.value, contractConfig.weightCorrection)),
					'name': obj1.name,
				}
				temp.push(temp2)
			})
		}

		return temp
	},

	getReservedDeliveryGold(state) {
		const reservedDeliveryGoldOrder = state.reservedDeliveryGoldOrder
		const goldBalance = state.goldBalance

		let temp = []

		if (goldBalance.length > 0) {
			reservedDeliveryGoldOrder.forEach(obj1 => {
				const contractConfig = state.contractConfigs[obj1.contractId]

				let temp2 = goldBalance.find(obj2 => obj2.contract_id == obj1.contractId)
				temp2 = {
					...temp2,
					'totalGram': Number(Decimal.mul(Decimal.div(contractConfig.weightInGram, contractConfig.weightCorrection), temp2.reserved_delivery_qty)),
					'totalQuantity': Number(Decimal.div(temp2.reserved_delivery_qty, contractConfig.weightCorrection)),
					'name': obj1.name,
				}
				temp.push(temp2)
			})
		}

		return temp
	},



	marketInquiryIsLoading(state) {
		return (state.marketInquiryStatus == 'loading') ? true : false
	},
}
