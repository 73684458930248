<template>
	<div class="content">
		<div class="row">
			<div class="col-12">
				<div class="card card-chart">
					<div class="card-header">
						<div class="row">
							<div class="col-sm-6 text-left">
								<!-- <h5 class="card-category">Total Shipments</h5> -->
								<h2 class="card-title">Trading Chart</h2>
							</div>
						</div>
					</div>
					<div class="card-body px-0 px-md-4">
						<TradingChart
							chart_width="100%"
							chart_height="700"
							defaultSymbolAnchor_prop="XAUUSDG"
							defaultSymbol_prop="XAUUSDG"
							title_prop="XAUUSDG"
						></TradingChart>	
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import TradingChart from '@/components/TradingChart.vue'

	export default {
		name: 'TradingChartView',
		components: {
			TradingChart
		}
	}
</script>

<style scoped>
	.card-body {
		/*height: 1000px;*/
	}
</style>