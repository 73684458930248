<template>
	<svg :width="width_prop" :height="height_prop" viewBox="0 0 20 20" fill="none" >
		<g id="fa6-solid:file-pdf">
			<path id="Vector"
				d="M3 4C3 2.89688 3.89688 2 5 2H10V6C10 6.55312 10.4469 7 11 7H15V11.5H8.5C7.39687 11.5 6.5 12.3969 6.5 13.5V18H5C3.89688 18 3 17.1031 3 16V4ZM15 6H11V2L15 6ZM8.5 13H9.5C10.4656 13 11.25 13.7844 11.25 14.75C11.25 15.7156 10.4656 16.5 9.5 16.5H9V17.5C9 17.775 8.775 18 8.5 18C8.225 18 8 17.775 8 17.5V13.5C8 13.225 8.225 13 8.5 13ZM9.5 15.5C9.91563 15.5 10.25 15.1656 10.25 14.75C10.25 14.3344 9.91563 14 9.5 14H9V15.5H9.5ZM12.5 13H13.5C14.3281 13 15 13.6719 15 14.5V16.5C15 17.3281 14.3281 18 13.5 18H12.5C12.225 18 12 17.775 12 17.5V13.5C12 13.225 12.225 13 12.5 13ZM13.5 17C13.775 17 14 16.775 14 16.5V14.5C14 14.225 13.775 14 13.5 14H13V17H13.5ZM16 13.5C16 13.225 16.225 13 16.5 13H18C18.275 13 18.5 13.225 18.5 13.5C18.5 13.775 18.275 14 18 14H17V15H18C18.275 15 18.5 15.225 18.5 15.5C18.5 15.775 18.275 16 18 16H17V17.5C17 17.775 16.775 18 16.5 18C16.225 18 16 17.775 16 17.5V13.5Z"
				:fill="color_prop" />
		</g>
	</svg>
</template>

<script>
export default {

	name: 'IconPDF',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '20px',
		},

		height_prop: {
			type: String,
			default: '20px',
		},

		color_prop: {
			type: String,
			default: '#007BFF',
		}
	},

	data() {
		return {
		}
	},

	computed: {
	},

	data() {
		return {

		}
	}
}
</script>

<style lang="css" scoped></style>