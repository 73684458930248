<template>
	<div>
		<div class="modalDialog modal-open">
			<div class="content">
				<div class="depth-header flex flex--justify-content-between flex--align-items-center gap-10">
					<div class="fs-24-c flex-1 text-left">Depth</div>
					<div class="flex-1 text-right">
						<div>
							{{ $parent.codeName }}
						</div>
						<div class="fw-700">{{ $parent.name }}
							<span class="fs-10-c fw-400 color-muted-c">
								{{ $parent.ticker_prop.includes('KAU_USD') || $parent.ticker_prop.includes('MGAU') ? 'KAU' : ' ' }}
							</span>
						</div>
					</div>
					<div><img class="" :src="`${$parent.publicPath}assets/icon/gold.png`" alt="" width="20" /></div>
				</div>

				<div v-if="depth" class="depth-content flex flex--justify-content-between flex--same-width gap-16 mb-24-c">
					<div>
						<div class="color-red-c text-left mb-12-c">SELL</div>

						<div class="depth-table-header flex flex--justify-content-between mb-8-c pb-6-c">
							<div class="fs-12-c">Price</div>
							<div class="fs-12-c">Qty / Lots</div>
						</div>

						<table class="w-100">
							<tr v-for="n in (depth.buy.length >= depth.sell.length) ? depth.buy.length : depth.sell.length"
								v-bind:key="n.id">
								<template v-if="n <= depth.buy.length">
									<td class="color-red-c text-right w-50 pr-8-c">
										{{ numberFormatExtend(Decimal.mul(Decimal.mul(depth.buy[n - 1].price,
											contractConfig.weightCorrection), streamingRateSell).toFixed(contractConfig.depthTTDecimalPlace || 2),
											contractConfig.depthTTDecimalPlace || 2) }}
									</td>
									<td class="text-right w-50">
										{{ Decimal.div(depth.buy[n - 1].quantity,
											contractConfig.weightCorrection).toDecimalPlaces(contractConfig.depthQuantityDecimalPlace).toNumber()
										}}
									</td>
								</template>
							</tr>
						</table>

						<!-- <div v-for="n in (depth.buy.length >= depth.sell.length) ? depth.buy.length : depth.sell.length"
							v-bind:key="n.id" class="flex  flex--justify-content-between flex--same-width text-left gap-12 mb-6-c">
							<template v-if="n <= depth.buy.length">
								<div class="color-red-c text-right">
									{{ numberFormatExtend(Decimal.mul(Decimal.mul(depth.buy[n - 1].price,
										contractConfig.weightCorrection), streamingRateSell).toFixed(contractConfig.depthTTDecimalPlace || 2),
										contractConfig.depthTTDecimalPlace || 2) }}
								</div>
								<div class="text-right">
									{{ Decimal.div(depth.buy[n - 1].quantity,
										contractConfig.weightCorrection).toDecimalPlaces(contractConfig.depthQuantityDecimalPlace).toNumber() }}
								</div>
							</template>
						</div> -->
					</div>

					<div>
						<div class="color-green-c text-left mb-12-c">BUY</div>

						<div class="depth-table-header flex flex--justify-content-between mb-8-c pb-6-c">
							<div class="fs-12-c">Price</div>
							<div class="fs-12-c">Qty / Lots</div>
						</div>

						<table class="w-100">
							<tr v-for="n in (depth.buy.length >= depth.sell.length) ? depth.buy.length : depth.sell.length"
								v-bind:key="n.id">
								<template v-if="n <= depth.sell.length">
									<td class="color-green-c text-right w-50 pr-8-c">
										{{ numberFormatExtend(Decimal.mul(Decimal.mul(depth.sell[n - 1].price,
											contractConfig.weightCorrection),
											streamingRateBuy).toFixed(contractConfig.depthTTDecimalPlace || 2), contractConfig.depthTTDecimalPlace
										|| 2) }}
									</td>
									<td class="text-right w-50">
										{{ Decimal.div(depth.sell[n - 1].quantity,
											contractConfig.weightCorrection).toDecimalPlaces(contractConfig.depthQuantityDecimalPlace).toNumber()
										}}
									</td>
								</template>
							</tr>
						</table>
						<!-- 
						<div v-for="n in (depth.buy.length >= depth.sell.length) ? depth.buy.length : depth.sell.length"
							v-bind:key="n.id" class="flex flex--justify-content-between flex--same-width text-left gap-12 mb-6-c">
							<template v-if="n <= depth.sell.length">
								<div class="color-green-c text-right">
									{{ numberFormatExtend(Decimal.mul(Decimal.mul(depth.sell[n - 1].price, contractConfig.weightCorrection),
										streamingRateBuy).toFixed(contractConfig.depthTTDecimalPlace || 2), contractConfig.depthTTDecimalPlace
									|| 2) }}
								</div>
								<div class="text-right">
									{{ Decimal.div(depth.sell[n - 1].quantity,
										contractConfig.weightCorrection).toDecimalPlaces(contractConfig.depthQuantityDecimalPlace).toNumber() }}
								</div>
							</template>
						</div> -->
					</div>
				</div>

				<!-- <table v-if="depth" class="table text-right mb-0">
					<tbody>
						<tr class="c-row-table"
							v-for="n in (depth.buy.length >= depth.sell.length) ? depth.buy.length : depth.sell.length"
							v-bind:key="n.id">
							<template v-if="n <= depth.sell.length">
								<td style="color: green !important;">{{
									numberFormatExtend(Decimal.mul(Decimal.mul(depth.sell[n - 1].price, contractConfig.weightCorrection),
										streamingRateBuy).toFixed(contractConfig.depthTTDecimalPlace || 2), contractConfig.depthTTDecimalPlace
									|| 2) }}</td>
								<td style="color: yellow !important;">{{ Decimal.div(depth.sell[n - 1].quantity,
									contractConfig.weightCorrection).toNumber() }}</td>
							</template>
							<template v-else>
								<td></td>
								<td></td>
							</template>
							<template v-if="n <= depth.buy.length">
								<td style="color: red !important;">{{ numberFormatExtend(Decimal.mul(Decimal.mul(depth.buy[n - 1].price,
									contractConfig.weightCorrection), streamingRateSell).toFixed(contractConfig.depthTTDecimalPlace || 2),
									contractConfig.depthTTDecimalPlace || 2) }}</td>
								<td style="color: yellow !important;">{{ Decimal.div(depth.buy[n - 1].quantity,
									contractConfig.weightCorrection).toNumber() }}</td>
							</template>
							<template v-else>
								<td></td>
								<td></td>
							</template>
						</tr>
					</tbody>
				</table> -->
				<button class="w-100 btn c-btn-black color-theme-c" @click="closeDepth()">BACK TO TRADE TICKET</button>
			</div>
		</div>
	</div>
</template>

<script>
import Decimal from 'decimal.js'

export default {
	name: 'DepthModal',
	props: {
		contractId_prop: {
			type: String,
			default: '1'
		},
		type_prop: {
			type: String,
			default: 'depth'
		}
	},
	data() {
		return {
			Decimal: Decimal,
		}
	},
	computed: {

		depth() {
			let depth = this.$store.getters.depthById(this.contractId_prop)
			return depth
		},

		currency() {
			return this.$store.state.currency
		},

		product() {
			const products = this.$store.getters.PRODUCTS
			return products.find(obj => obj.attributes.contractId == this.contractId_prop)
		},

		contractConfig() {
			return this.$store.state.contractConfigs[this.contractId_prop]
		},

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		streamingRateBuy() {
			if (this.currency == 'idr') {
				return this.streamingRate.stream_buy.cur_rate
			}

			return 1
		},
		streamingRateSell() {
			if (this.currency == 'idr') {
				return this.streamingRate.stream_sell.cur_rate
			}

			return 1
		}
	},
	created() {

	},
	methods: {
		closeDepth() {
			this.$emit('closeDepth')
		},
	}
}
</script>

<style scoped>
.modalDialog {
	position: fixed;
	font-family: Arial, Helvetica, sans-serif;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.8);
	z-index: 9999;
	opacity: 0;
	-webkit-transition: opacity 400ms ease-in;
	-moz-transition: opacity 400ms ease-in;
	transition: opacity 400ms ease-in;
	pointer-events: none;
}

.modalDialog:target {
	opacity: 1;
	pointer-events: auto;
}

.modalDialog>.content {
	padding: 24px;
}

.modal-open {
	opacity: 1;
	pointer-events: auto;
	margin: 0px 12px;
}

.modalDialog>div {
	max-width: max-content;
	min-width: 400px;
	position: relative;
	margin: 2% auto;
	padding: 5px 20px 13px 20px;
	border-radius: 10px;
	background: #191f31;
	background: -moz-linear-gradient(#fff, #999);
	background: -webkit-linear-gradient(#fff, #999);
	background: -o-linear-gradient(#fff, #999);
}

.depth-header {
	margin-bottom: 1.3125em;
	padding-bottom: 1.5em;
	border-bottom: white solid 1px;
}

.depth-table-header {
	border-bottom: solid 1px white;
}

.depth-content {
	max-height: 50vh;
	padding-right: 12px;
	overflow-y: scroll;
}

</style>
