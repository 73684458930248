<template>
	<div class="c-balance">
		<template v-if="!isHideBalance">
			<LogoAbx v-if="currency == 'usd'" class="mr-2" style="height: 30px; width: 30px;"></LogoAbx>
			<img v-else class="logo-bagi" :src="`${publicPath}assets/img/logo-bagi.png`" alt="">

			<template v-if="this.$store.state.setBalancesApiStatus == 'loading'">
				<Loader class="mr-16-c" size_prop='2.2em' borderSize_prop='0.4em'></Loader>
			</template>
			<template v-else>
				<span class="text-nowrap mr-12-c d-inline-block">
					<template v-if="currency == 'idr'">Rp</template>
					<template v-else>$</template>
					{{ balanceShow }}
				</span>
			</template>
			<!-- <template v-else>
				<img :src="`${publicPath}assets/img/logo-kau.png`" class="mr-2" style="height: 30px; border-radius: 0;">
				<template :class="{ 'mr-3': false }">
					<template v-if="currency == 'idr'">Rp</template>
					<template v-else>$</template>
					{{ kinesisBalanceShow }}
				</template>
			</template> -->
		</template>

		<div class="rectangle mr-8-c" title="show/hide balance" @click="toggleBalance()">
			<IconEyeClose v-if="isHideBalance" :sizeMultiplier_prop="1.5" color_prop="white" />
			<IconEyeOpen v-if="!isHideBalance" :sizeMultiplier_prop="1.5" color_prop="white" />
		</div>

		<div class="rectangle" title="refresh page" @click="refreshBalance()">
			<IconRefresh />
		</div>

		<!-- <i id="eye" :class="`far fa-eye${eye_icon} mr-10-c`" @click="toggleBalance()" title="show/hide balance"></i>
		<i :class="{ 'reload-icon': true, 'fas': true, 'fa-sync-alt': true, 'c-fa-disabled': getDepositIsCooldown }"
			@click="refreshBalance()" title="refresh page"></i> -->
	</div>
</template>

<script>
/*eslint-env jquery*/
import LogoAbx from '@/components/svg/logo-abx.vue'
import Loader from '@/components/Loader.vue'

import IconEyeClose from '@/components/icons/IconEyeClose.vue'
import IconEyeOpen from '@/components/icons/IconEyeOpen.vue'
import IconRefresh from '@/components/icons/IconRefresh.vue'

export default {
	name: 'Balance',
	props: {
		hin_prop: {
			type: String,
			default: '-'
		}
	},

	components: {
		LogoAbx,
		Loader,

		IconEyeClose,
		IconEyeOpen,
		IconRefresh,
	},

	data() {
		return {
			publicPath: process.env.BASE_URL,
			eye_icon: '',
			eye_title: 'Hide',
			isHideBalance: false,

		}
	},

	computed: {
		hin() {
			return this.hin_prop
		},

		currency() {
			let currency = this.$store.state.currency
			return currency
		},

		balance() {
			let currency = this.currency
			if (currency == 'usd') {
				return this.$store.state.balanceUsd
			} else {
				return this.$store.state.balanceIdr
			}
		},

		kinesisBalance() {
			let currency = this.currency
			if (currency == 'usd') {
				return this.$store.state.kinesisBalanceUsd
			} else {
				return this.$store.state.kinesisBalanceIdr
			}
		},

		balanceShow() {
			return this.numberFormatExtend(this.balance.toFixed(2))
		},

		kinesisBalanceShow() {
			return this.numberFormatExtend(this.kinesisBalance.toFixed(2))
		},

		getDepositIsCooldown() {
			return this.$store.state.getDepositIsCooldown
		}
	},

	created() {
	},
	mounted() {
	},
	methods: {
		numberFormatExtend(value) {
			let val = (value / 1)
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
		},

		toggleBalance() {
			// let demo_bgc = document.getElementById("demo").style
			if (this.eye_icon == '') {
				// demo_bgc.backgroundColor = "transparent"
				this.eye_icon = '-slash'
				this.eye_title = 'Show'
				this.balance_paddingTop = 10
			} else {
				// demo_bgc.backgroundColor = "rgb(46,61,97)"
				this.eye_icon = ''
				this.eye_title = 'Hide'
				this.balance_paddingTop = 5
			}
			this.isHideBalance = (this.isHideBalance) ? false : true
		},

		refreshBalance() {
			if (!this.$store.state.getDepositIsCooldown) {
				this.$store.dispatch('setBalances')
			}
		},
	}
}
</script>

<style scoped>
.c-balance {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--grey-1-color);
	color: white;

	font-size: 1rem;
	border-radius: 4px;
	padding: 0.5em 0.75em;
	height: 2.5em;
}

.c-font {
	color: white;
}

.c-eye {
	padding-right: 0;
	padding-left: 0;
	color: white;
}

.c-eye:hover {
	cursor: pointer;
}

.fa-sync-alt:active {
	color: lightgrey;
}

.reload-icon {
	font-size: 0.75rem;
}

.logo-bagi {
	width: 2.125em;
	height: auto;
	margin-right: 0.375em;
	border-radius: none;
}

.rectangle {
	background-color: #131722;
	border-radius: 3px;
	padding: 2px 4px;
}
</style>
