<template>
	<svg :class="direction_prop" :width="width" :height="height" viewBox="0 0 10 7" fill="none" >
		<path id="Vector"
			d="M7.86816 0.99623L7.86813 0.996256L5.03641 3.77682L2.20469 0.996257L2.20472 0.99623L2.20156 0.993241C2.03956 0.839598 1.8235 0.755326 1.60012 0.757232C1.37673 0.759138 1.16215 0.847089 1.00284 1.00352C0.843377 1.1601 0.752087 1.37288 0.75011 1.59628C0.748134 1.81969 0.83565 2.03404 0.992365 2.19336L0.992338 2.19339L0.995439 2.19643L4.43179 5.5707L4.43181 5.57072C4.59294 5.7289 4.8105 5.81696 5.03641 5.81696C5.26232 5.81696 5.47988 5.7289 5.64101 5.57072L5.64104 5.5707L9.07738 2.19643L9.07741 2.19641C9.23865 2.03804 9.33008 1.82229 9.33008 1.59635C9.33008 1.37041 9.23865 1.15466 9.07741 0.996283L9.07736 0.99623C8.91622 0.838058 8.69867 0.749998 8.47276 0.749998C8.24685 0.749998 8.02929 0.838058 7.86816 0.99623Z"
			:fill="color_prop" :stroke="color_prop" stroke-width="0.5" />
	</svg>
</template>

<script>
export default {

	name: 'IconArrow',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '12px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '9px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: 'white',
		},

		direction_prop: {
			type: String,
			default: 'down',
		}
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	},

	data() {
		return {

		}
	}
}
</script>

<style lang="css" scoped>
.left {
	transform: rotate(90deg);
}

.up {
	transform: rotate(180deg);
}

.right {
	transform: rotate(-90deg);
}
</style>