<template>
	<div>
		<div class="row text-right mb-2">
			<div class="col">
				<Pagination2 ref="paginationOrders"
					:totalData_prop =	"totalBullionWithdrawals"
					@changePage = "getListBullionWithrawal"
				>
				</Pagination2>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				
				<table class="table table-striped">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th style="border-right: 2px solid #1e1e2e;">RECEIPT NUMBER</th>
							<th style="border-right: 2px solid #1e1e2e;">DATE OF SHIPMENT</th>
							<th style="border-right: 2px solid #1e1e2e;">QUANTITY (BAR/S)</th>
							<th style="border-right: 2px solid #1e1e2e;">TOTAL WEIGHT (GRAM)</th>
							<th style="border-right: 2px solid #1e1e2e;">VALUE GOODS</th>
							<th style="border-right: 2px solid #1e1e2e;">STATUS</th>
							<th style="border-right: 2px solid #1e1e2e;">ACTION</th>
						</tr>
					</thead>
					<tbody>
						<tr v-if="apiStatusGeListBullionWithdrawal == 'loading'">
							<td class="text-center" colspan="8" rowspan="3">
								<Loader
									size_prop="10em"
								></Loader>
							</td>
						</tr>
						<tr v-else-if="packages.length < 0">
					    <td colspan="8" class="text-center">No data available in table</td>
						</tr>
						<tr v-else v-for="(listBullionWithdrawal, index) in listBullionWithdrawals" :key="listBullionWithdrawal.order_id">
							<td class="text-center">{{ ((pageNumber - 1) * 10) + index + 1 }}</td>
							<td class="text-center">{{ listBullionWithdrawal.receipt_number || listBullionWithdrawal.ref_id.substring(2, 9) + listBullionWithdrawal.transaction_id.substring(3, 7) }}</td>
							<td class="text-center">{{ listBullionWithdrawal.shipping_date || '-' }}</td>
							<td class="text-right">{{ listBullionWithdrawal.total_item }}</td>
							<td class="text-right">{{ listBullionWithdrawal.total_weight }}</td>
							<td class="text-right">Rp. {{numberFormatExtend(listBullionWithdrawal.value_goods)}}</td>
							<td class="text-center">{{ trackBullionStatus(listBullionWithdrawal.status) }}</td>
							<td class="text-center">
								<button class="btn btn-sm" @click="openDetailBullionWithdrawal(listBullionWithdrawal.transaction_id, listBullionWithdrawal.status)">
									DETAIL
								</button>
							</td>
						</tr>
					</tbody>
					<tfoot>
					</tfoot>
				</table>

			</div>
		</div>

		<TrackBullionHistory
			v-show = "trackBullionHistoryIsOpen"
			@close = "trackBullionHistoryIsOpen = false"
			:data_prop = "bullionWithdrawalDetail"
		>
		</TrackBullionHistory>

		<DetailBullionWithdrawal
			v-show = "detailBullionWithdrawalIsOpen"
			@close = "detailBullionWithdrawalIsOpen = false"
			:data_prop = "dataForDetailBullionWithdrawal"
		>	
		</DetailBullionWithdrawal>

	</div>
</template>

<script>
	import axios from '@/api/axios.js'

	import Loader from '@/components/Loader.vue'
	import TrackBullionHistory from '@/components/TrackBullionHistory.vue'
	import DetailBullionWithdrawal from '@/components/DetailBullionWithdrawal.vue'
	import Pagination2 from "@/components/Pagination2.vue"

	export default {
		name: 'TrackBullion',
		components: {
			Loader,
			TrackBullionHistory,
			DetailBullionWithdrawal,
			Pagination2
		},
		data() {
			return {
				packages: [],
				apiStatusGeListBullionWithdrawal: 'idle',
				trackBullionHistoryIsOpen: false,
				listBullionWithdrawals: [],
				bullionWithdrawalDetail: {},

				detailBullionWithdrawalIsOpen: false,
				dataForDetailBullionWithdrawal: {},

				totalBullionWithdrawals: 1,
				pageNumber: 1,
			}
		},

		computed: {
		},

		created() {
			this.getListBullionWithrawal()
		},

		methods: {
			numberFormatExtend(value) {
				let val = (value / 1)
				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
			},

			openTrackBullionHistory(index1, index2) {
				this.bullionWithdrawalDetail = this.listBullionWithdrawals[index1][index2]

				this.trackBullionHistoryIsOpen = true
			},

			openDetailBullionWithdrawal(transactionId, status) {
				this.dataForDetailBullionWithdrawal = {
					transactionId: transactionId,
					status: status
				}

				this.detailBullionWithdrawalIsOpen = true
			},

			paginationOrder(arr) {
				const itemPerPage = 10
				let arrTemp1 = [],
						arrTemp2 = []
				for (let i = 0; i < arr.length; i++) {
					arrTemp1.push(arr[i])
					if ((i+1) % itemPerPage == 0 || (i == arr.length - 1 && (i+1) % itemPerPage != 0)) {
						arrTemp2.push(arrTemp1)
						arrTemp1 = []
					}
				}
				return arrTemp2
			},

			getListBullionWithrawal(skip = 0, limit = 10) {
				this.apiStatusGeListBullionWithdrawal = 'loading'

				this.pageNumber = (skip / 10) + 1

				axios({
					method: 'POST',
					url: '/v2/poslog/list_withdrawal?date_start&date_end&offset=' + skip + '&limit=' + limit,
					data: {
						user_id: this.$store.state.userId
					}
				})
				.then(response => {
					// let sortedlistBullionWithdrawals = response.data.data.data.slice().sort((a, b) => new Date(b.withdrawal_date) - new Date(a.withdrawal_date))
					// let sortedlistBullionWithdrawals = response.data.data.data

					this.totalBullionWithdrawals = response.data.data.count

					this.listBullionWithdrawals = response.data.data.body
					this.apiStatusGeListBullionWithdrawal = 'idle'
				})
				.catch(error => {
					this.apiStatusGeListBullionWithdrawal = 'idle'

					if (error.response) {
						/*
						 * The request was made and the server responded with a
						 * status code that falls out of the range of 2xx
						 */
					} else if (error.request) {
						/*
						 * The request was made but no response was received, `error.request`
						 * is an instance of XMLHttpRequest in the browser and an instance
						 * of http.ClientRequest in Node.js
						 */
					} else {
						// Something happened in setting up the request and triggered an Error
					}
				})
			},

			trackBullionStatus(statusCode) {
				let temp = "Declined"

				// switch(statusCode) {
				//   case "001":
				//   case "002":
				//   case "003":
				//   case "004":
				//     temp = "Vault Processing"
				//     break;
				//   case "005":
				//     temp = "Shipping Confirm"
				//     break;
				//   case "006":
				//     temp = "On Delivery"
				//     break;
				//   case "007":
				//     temp = "KPRK Received"
				//     break;
				//   case "008":
				//     temp = "Delivered"
				//     break;
				//   default:
				//     temp = "Declined"

				switch(statusCode) {
				  case "001":
				  case "002":
				  case "003":
				  case "004":
				  case "005":
				  case "006":
				  case "007":
				  case "008":
				    temp = "On Process"
				    break;
				  case "009":
				    temp = "Delivered"
				    break;
				  default:
				    temp = "On Process"
				}

				return temp
			}
		}
	}
</script>
