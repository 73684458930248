<template>
	<div>
		<h4 class="text-center">RECONCILE REPORTING SYSTEM</h4>

		<div class="row">

			<!-- <div class="col-auto mb-2 text-left">
				<FilterClient
					@changeFilterClient="changeFilterClient"
					filterId="ib"
					:clients_prop="clients"
				>
				</FilterClient>
			</div> -->

			<div class="col-auto mb-2 text-left">
				<FilterClient2 @changeFilterClient="changeFilterClient" filterId="fm" :clients_prop="clients">
				</FilterClient2>
			</div>

			<div v-show="tableView != 'client' && tableView != 'lp'" class="col-auto mb-2 text-left">
				<FilterContract class="d-inline-block" filterId="ib" @changeFilterContract="changeFilterContract">
				</FilterContract>
			</div>

			<div class="col-auto mb-2 text-left">
				<FilterDateAndType class="d-inline-block" @changeFilterDateAndType="changeFilterDateAndType">
				</FilterDateAndType>
			</div>


			<!-- <div class="col-auto mb-2 text-left">
				<form class="form-inline">
				  <div class="form-group">
				    <label for="dateRange" class="sr-only">Report Type</label>
				    <input type="text" disabled class="form-control-plaintext py-0" id="reportTypeClient" style="width: 109px;" value="Report Type">
				  </div>
					<select class="custom-select py-0 pl-2 pr-0" style="height: 33px; width: 80px; background-color: transparent; border-color:  rgb(43, 53, 83); font-size: 13px;" v-model="reportType">
					  <option value="day" style="background-color: #1a1e34;">DAILY</option>
					  <option value="week" style="background-color: #1a1e34;">WEEKLY</option>
					  <option value="month" style="background-color: #1a1e34;">MONTHLY</option>
					  <option value="year" style="background-color: #1a1e34;">YEARLY</option>
					</select>
				</form>
			</div>

			<div class="col-auto mb-2">
				<form class="form-inline">
				  <div class="form-group">
				    <label for="dateRange" class="sr-only">Report Period</label>
				    <input type="text" disabled class="form-control-plaintext py-0" id="reportPeriodClient" style="width: 120px;" value="Report Period">
				  </div>

				  <template v-if="reportType == 'day'">
						<div class="form-group mr-2">
							<label for="filterStartDate" class="sr-only">Start Date</label>
							<input type="date" class="form-control py-0" id="filterStartDateClient" style="height: 33px;" v-model="startDateDayInInput">
						</div>
						<div class="form-group">
							<label for="filterEndDate" class="sr-only">End Date</label>
							<input type="date" class="form-control py-0" id="filterEndDateCliend" style="height: 33px;" v-model="endDate">
						</div>
					</template>

					<template v-if="reportType == 'week'">
						<input list="weeklyDateList" name="location" id="startDateWeek" class="form-control" style="height: 33px;" v-model.lazy="startDateWeekInInput" onfocus="this.value=''" onchange="this.blur();" required>
						<datalist id="weeklyDateList">
							<option v-for="item in weeklyDateList " :key="`${item}Week`" :value="item"></option>
						</datalist>
					</template>

					<template v-if="reportType == 'month'">
						<input list="monthlyDateList" name="location" id="startDateMonth" class="form-control" style="height: 33px;" v-model.lazy="startDateMonthInInput" onfocus="this.value=''" onchange="this.blur();" required>
						<datalist id="monthlyDateList">
							<option v-for="item in monthlyDateList " :key="`${item}Month`" :value="item"></option>
						</datalist>
					</template>

					<template v-if="reportType == 'year'">
						<input list="yearlyDateList" name="location" id="startDateYear" class="form-control" style="height: 33px;" v-model.lazy="startDateYearInInput" onfocus="this.value=''" onchange="this.blur();" required>
						<datalist id="yearlyDateList">
							<option v-for="item in yearlyDateList " :key="`${item}Year`" :value="item"></option>
						</datalist>
					</template>

				</form>
			</div> -->

		</div>

		<div class="row">
			<div class="col-12">
				<TopSection :totalRevenue_prop="totalRevenue"></TopSection>
			</div>
		</div>

		<div class="row">
			<div v-if="tableView == 'all'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">ACCOUNT NAME</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> ({{ $store.state.currency == 'usd' ? 'USD' : 'IDR' }}) </th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="rrsStatus == 'loading'">
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr v-for="(item, index) in rrsIb" class="c-row-table cursor-pointer text-right" :key="item.identity._id"
								@click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ (item.identity) ? item.identity.fullname : '-' }}</td>
								<td>{{ item.identity.trade_account.hin }}</td>
								
								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.buy.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.buy.totalPriceIdr) }}</td>

								<td>{{ item.gold.buy.totalToz }}</td>
								<td>{{ item.gold.buy.total_gram }}</td>
								
								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.sell.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.sell.totalPriceIdr) }}</td>

								<td>{{ item.gold.sell.totalToz }}</td>
								<td>{{ item.gold.sell.total_gram }}</td>
								
								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.gold.total_trade) }}</td>
								<td v-else>{{ numberFormatExtend(item.gold.totalTradeIdr) }}</td>

								<td>{{ item.gold.totalToz }}</td>
								<td>{{ item.gold.total_gram }}</td>
								<td class="text-center">{{ item.gold.rateRatio }}</td>
							</tr>
							<tr v-if="rrsIb.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>

			<div v-if="tableView == 'client' || tableView == 'lp'" class="col-12 table-responsive">
				<table class="table table-striped text-nowrap">
					<thead class="text-center" style="background-color: #0f0f17">
						<tr>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">NO.</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">PRODUCT</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">HIN</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">BUY</th>
							<th colspan="3" style="border-right: 2px solid #1e1e2e; border-bottom: 2px solid #1e1e2e;">SELL</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL TRADE <br> VOLUME <br> ({{ $store.state.currency == 'usd' ? 'USD' : 'IDR' }}) </th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> TOZ</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">TOTAL <br> GRAM</th>
							<th rowspan="2" style="border-right: 2px solid #1e1e2e;">RATE <br> RATIO <br> (%)</th>
						</tr>
						<tr>
							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>

							<th style="border-right: 2px solid #1e1e2e;">{{ currencySymbol }}</th>
							<th style="border-right: 2px solid #1e1e2e;">TOZ</th>
							<th style="border-right: 2px solid #1e1e2e;">GRAM</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="rrsStatus == 'loading'">
							<tr>
								<td class="text-center" colspan="14">
									<Loader size_prop="10em"></Loader>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr v-for="(item, index) in clientData" class="c-row-table cursor-pointer text-right" :key="item.name"
								@click="showDetailClient()">
								<td class="text-center">{{ index + 1 }}</td>
								<td class="text-left">{{ item.name }}</td>
								<td>{{ selectedClient.hin_abx }}</td>

								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.buy.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.buy.totalPriceIdr) }}</td>

								<td>{{ item.buy.totalToz }}</td>
								<td>{{ item.buy.total_gram }}</td>
								
								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.sell.total_price) }}</td>
								<td v-else>{{ numberFormatExtend(item.sell.totalPriceIdr) }}</td>

								<td>{{ item.sell.totalToz }}</td>
								<td>{{ item.sell.total_gram }}</td>
								
								<td v-if="$store.state.currency == 'usd'">{{ numberFormatExtend(item.total_trade) }}</td>
								<td v-else>{{ numberFormatExtend(item.totalTradeIdr) }}</td>

								<td>{{ item.totalToz }}</td>
								<td>{{ item.total_gram }}</td>
								<td class="text-center">{{ item.rateRatio }}</td>
							</tr>
							<tr v-if="clientData.length < 1">
								<td colspan="13" class="text-center">No data available in table</td>
							</tr>
						</template>
					</tbody>
					<tfoot>

					</tfoot>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '@/api/axios.js'
import { Decimal } from 'decimal.js'

import Loader from '@/components/Loader.vue'
import FilterClient2 from '@/components/FilterClient2.vue'
import FilterContract from '@/components/FilterContract.vue'
import FilterDateAndType from '@/components/FilterDateAndType.vue'
import ExportAsButton from '@/components/ExportAsButton.vue'
import TopSection from '@/components/reconcile-reporting-system/TopSection.vue'

export default {
	name: 'IB',

	props: {
		goldOrderProp: Array
	},

	components: {
		Loader,
		FilterClient2,
		FilterContract,
		FilterDateAndType,
		ExportAsButton,
		TopSection
	},

	data() {
		const today = new Date(Date.now())
		let todayDay = today.getDay()
		let tempDay = new Date(Date.now())
		let tempWeek = new Date(Date.now())
		let tempMonth = new Date(Date.now())
		let tempYear = new Date(Date.now())
		return {
			clientsStatus: 'loading',
			clients: [],
			clientsFilterIb: '',

			grToTozMultiplier: 0.0321507,

			rrsClientStatus: 'loading',
			products: [],

			rrsStatus: 'loading',
			rrsIb: [],

			totalTurnOver: {
				usd: 0,
				anotherCurrency: 0,
				toz: 0,
				gram: 0
			},

			reportType: 'day',
			reportMonth: 'january',
			reportYear: '2019',

			startDateDay: new Date(tempDay.setDate(tempDay.getDate() - 1)),
			endDate: `${tempDay.getFullYear()}-${tempDay.getMonth() + 1 < 10 ? '0' : ''}${tempDay.getMonth() + 1}-${tempDay.getDate() + 1 < 10 ? '0' : ''}${tempDay.getDate() + 1}`,

			startDateWeek: new Date(tempWeek.setDate(tempWeek.getDate() - todayDay + 1)),
			weeklyDateList: [],

			startDateMonth: new Date(tempMonth.setDate(1)),
			monthlyDateList: [],

			startDateYear: new Date(new Date(tempYear.setDate(1)).setMonth(0)),
			yearlyDateList: [],

			tableView: 'all',

			selectedClient: 'all',

			selectedContracts: [],

			selectedDateAndType: {
				type: 'all',
				startDate: '',
				endDate: ''
			},

			clientData: [],
			totalTurnOverClient: {
				usd: 0,
				anotherCurrency: 0,
				toz: 0,
				gram: 0
			},

			totalRevenue: {
				usd: 0,
				idr: 0,
			},
		}
	},

	computed: {
		// watcherChangeFilter() {
		// 	return `${this.startDateDay}${this.startDateWeek}${this.startDateMonth}${this.startDateYear}${this.endDate}`
		// },

		currencySymbol() {
      return this.$store.state.currency == 'idr' ? 'Rp' : '$'
    },

		streamingRate() {
			return this.$store.getters.getStreamingRate
		},

		productsGlobal() {
			let products = this.$store.getters.PRODUCTS
			return products
		},

		startDateDayInInput: {
			get: function () {
				let temp = this.startDateDay
				return `${temp.getFullYear()}-${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}-${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}`
			},
			set: function (newValue) {
				this.startDateDay = new Date(newValue)
			}
		},

		startDateWeekInInput: {
			get: function () {
				let temp = this.startDateWeek
				if (temp) {
					return `${temp.getDate() < 10 ? '0' : ''}${temp.getDate()}/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateWeek = temp
			}
		},

		startDateMonthInInput: {
			get: function () {
				let temp = this.startDateMonth
				if (temp) {
					return `01/${temp.getMonth() + 1 < 10 ? '0' : ''}${temp.getMonth() + 1}/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
				this.startDateMonth = temp
			}
		},

		startDateYearInInput: {
			get: function () {
				let temp = this.startDateYear
				if (temp) {
					return `01/01/${temp.getFullYear()}`
				} else {
					return ''
				}
			},
			set: function (newValue) {
				if (newValue) {
					let temp = new Date(`${newValue.substr(6, 4)}-${newValue.substr(3, 2)}-${newValue.substr(0, 2)}`)
					this.startDateYear = temp
				}
			}
		},
	},

	watch: {
		// watcherChangeFilter() {
		// 	this.changeFilter()
		// },
		// reportType(newValue) {
		// 	if (newValue == 'week') {
		// 		if (this.weeklyDateList.length < 1) {

		// 			let todayDate = new Date(Date.now())
		// 			let todayDay = todayDate.getDay()
		// 			let nearestMondayDate = new Date(todayDate.setDate(todayDate.getDate() - todayDay + 1))

		// 			for (let i = 0; i < 500; i++) {
		// 				this.weeklyDateList.push(`${nearestMondayDate.getDate() < 10 ? '0' : ''}${nearestMondayDate.getDate()}/${nearestMondayDate.getMonth() + 1 < 10 ? '0' : ''}${nearestMondayDate.getMonth() + 1}/${nearestMondayDate.getFullYear()}`)
		// 				nearestMondayDate = new Date(nearestMondayDate.setDate(nearestMondayDate.getDate() - 7))
		// 			}

		// 		}
		// 	}else if (newValue == 'month') {
		// 		if (this.monthlyDateList.length < 1) {

		// 			let todayDate = new Date(Date.now())
		// 			let todayMonth = todayDate.getMonth() // 0 = January
		// 			let monthDate = new Date(todayDate.getFullYear() + '-' + (todayMonth + 1 < 10 ? '0' : '') + String(todayMonth + 1) + '-01')

		// 			for (let i = 0; i < 241; i++) {
		// 				this.monthlyDateList.push(`01/${monthDate.getMonth() + 1 < 10 ? '0' : ''}${monthDate.getMonth() + 1}/${monthDate.getFullYear()}`)
		// 				monthDate =  new Date(monthDate.setMonth(monthDate.getMonth() - 1))
		// 			}

		// 		}
		// 	}else {
		// 		if (this.yearlyDateList.length < 1) {

		// 			let todayDate = new Date(Date.now())
		// 			let yearDate = new Date(todayDate.getFullYear() + '-01-01')

		// 			for (let i = 0; i < 241; i++) {
		// 				this.yearlyDateList.push(`01/01/${yearDate.getFullYear()}`)
		// 				yearDate =  new Date(yearDate.setFullYear(yearDate.getFullYear() - 1))
		// 			}

		// 		}
		// 	}

		// 	this.changeFilter()
		// }
	},

	created() {
		// set total revenue
		this.$parent.getTotalRevenue(
		).then((response) => {
			this.totalRevenue.usd = this.numberFormatExtend(new Decimal(response.revenue.usd).toDecimalPlaces(2).toNumber())
			this.totalRevenue.idr = this.numberFormatExtend(new Decimal(response.revenue.idr).toDecimalPlaces(2).toNumber())
		}).catch(() => {
			this.totalRevenue.usd = '-'
			this.totalRevenue.idr = '-'
		})

		axios.post('/v2/rrs/ib/list/client', {
			user_id: this.$store.state.userId
		}).then(response => {
			this.clients = response.data.data.body
		}).catch(error => {
		})

		this.setRrs()
	},

	methods: {
		setRrs() {
			let selectedContracts = this.selectedContracts
			let contractsParam = ''

			if (selectedContracts.length > 0) {
				selectedContracts.forEach((item) => {
					contractsParam = contractsParam + `contract[]=${item}&`
				})
			}

			if (contractsParam == '') {
				contractsParam = 'contract[]=&'
			}

			let reportType = this.selectedDateAndType.type
			let startDate = this.selectedDateAndType.startDate
			let endDate = this.selectedDateAndType.endDate

			if (reportType == 'all') {
				reportType = ''
			}

			if (startDate) {
				// startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
				startDate = startDate.toISOString()
			}

			if (endDate) {
				// endDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1 < 10 ? '0' : ''}${endDate.getMonth() + 1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`
				endDate = endDate.toISOString()
			}

			if (this.tableView == 'all') {
				this.rrsStatus = 'loading'

				axios.post('/v2/rrs/ib?' + contractsParam + 'type=' + reportType + '&startDate=' + startDate + '&endDate=' + endDate, {
					user_id: this.$store.state.userId
				}).then(response => {
					this.rrsIb = []
					this.totalTurnOver = {
						usd: 0,
						anotherCurrency: 0,
						toz: 0,
						gram: 0
					}

					if (response.data.data.body) {
						const processedData = this.$parent.processDataRRSBursa(response.data.data.body, 'ib')

						this.rrsIb = processedData.dataRRS
						this.totalTurnOver = processedData.totalTurnOver
					}

					this.rrsStatus = 'success'
				}).catch(error => {
					if (error.response) {
						if (error.response.data.message == "There are no clients under IB!") {
							this.rrsDataIb = []
							this.totalTurnOver = {
								usd: 0,
								anotherCurrency: 0,
								toz: 0,
								gram: 0
							}
						}
					}

					this.rrsStatus = 'error'
				})
			} else {
				this.rrsStatus = 'loading'

				const selectedHIN = this.selectedClient.hin_abx || this.selectedClient.trade_account.hin

				axios.post('/v2/rrs/ib/client?hin=' + selectedHIN + '&' + contractsParam + 'type=' + reportType + '&startDate=' + startDate + '&endDate=' + endDate, {
					user_id: this.$store.state.userId
				}).then(response => {
					this.clientData = []
					this.totalTurnOver = {
						usd: 0,
						anotherCurrency: 0,
						toz: 0,
						gram: 0
					}

					const processedData = this.$parent.processDataRRSClient(response.data.data.body)

					this.clientData = processedData.dataRRS
					this.totalTurnOver = processedData.totalTurnOver

					this.rrsStatus = 'success'
				}).catch(error => {
					this.rrsStatus = 'error'
				})

			}
		},

		// changeFilter() {
		// 	let startDate = this.startDateDay
		// 	let endDate = this.endDate

		// 	if (this.reportType == 'day') {
		// 		startDate = this.startDateDay
		// 	}else if (this.reportType == 'week') {
		// 		startDate = this.startDateWeek
		// 	}else if (this.reportType == 'month') {
		// 		startDate = this.startDateMonth
		// 	}else {
		// 		startDate = this.startDateYear
		// 	}

		// 	if (startDate) {
		// 		startDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
		// 	}

		// 	this.rrsClientStatus = 'loading'
		// 	this.products = []
		// 	axios({
		// 		method: 'get',
		// 		url:process.env.VUE_APP_BACKEND_URL+'/rss/client?type='+ this.reportType +'&startDate='+ startDate +'&endDate='+ endDate
		// 	}).then(response => {
		// 		let temp = response.data.data
		// 		temp.contract_micro.name = 'DG001 0.01 GR POOL'
		// 		temp.contract_1gr.name = 'DG1 1 GR POOL'
		// 		temp.contract_100gr.name = 'DG100 100 GR NON POOL'
		// 		temp.contract_1kg.name = 'DG1K 1 KG NON POOL'
		// 		temp.contract_10kg.name = 'DG10K 10 KG NON POOL'

		// 		temp.contract_micro.weight = 0.01
		// 		temp.contract_1gr.weight = 1
		// 		temp.contract_100gr.weight = 100
		// 		temp.contract_1kg.weight = 1000
		// 		temp.contract_10kg.weight = 10000

		// 		this.products = [temp.contract_micro, temp.contract_1gr, temp.contract_100gr, temp.contract_1kg, temp.contract_10kg]

		// 		let tempUsd = 0
		// 		let tempGram = 0
		// 		this.products.forEach((product, index) => {
		// 			let pembagi = product.buy.total_price
		// 			this.products[index].buy.total_gram = Number((product.buy.total_gram * product.weight).toFixed(2))
		// 			this.products[index].sell.total_gram = Number((product.sell.total_gram * product.weight).toFixed(2))
		// 			this.products[index].total_gram = Number((product.total_gram * product.weight).toFixed(2))

		// 			if (pembagi == 0) {
		// 				pembagi = 1
		// 			}
		// 			this.products[index].rateRatio = (product.sell.total_price / pembagi) * 100

		// 			tempUsd += product.total_trade
		// 			tempGram += product.total_gram
		// 		})

		// 		this.totalTurnOver = {
		// 			usd: tempUsd,
		// 			anotherCurrency: tempUsd * this.streamingRate.stream_buy.cur_rate,
		// 			toz: tempGram * this.grToTozMultiplier,
		// 			gram: tempGram,
		// 		}

		// 		this.rrsClientStatus = 'success'
		// 	}).catch(error => {
		// 		if (error.response) {
		// 			/*
		// 			 * The request was made and the server responded with a
		// 			 * status code that falls out of the range of 2xx
		// 			 */
		// 		} else if (error.request) {
		// 			/*
		// 			 * The request was made but no response was received, `error.request`
		// 			 * is an instance of XMLHttpRequest in the browser and an instance
		// 			 * of http.ClientRequest in Node.js
		// 			 */
		// 		} else {
		// 			// Something happened in setting up the request and triggered an Error
		// 		}

		// 		this.rrsClientStatus = 'error'
		// 	})		
		// },
		changeFilterClient(clientData) {
			let temp = clientData
			if (temp != 'all') {
				const template = { IB: 'ib', PB: 'fm', LP: 'lp', PC: 'client' }
				this.tableView = template[temp.trade_account.hin.substr(0, 2)]
			} else {
				this.tableView = 'all'
			}

			this.selectedClient = clientData
			this.setRrs()
		},

		changeFilterContract(selectedContracts) {
			this.selectedContracts = selectedContracts
			this.setRrs()
		},

		changeFilterDateAndType(data) {
			this.selectedDateAndType = data
			this.setRrs()
		},

		clearStartDate(value) {
			if (value == 'week') {
				this.startDateWeek = ''
			} else if (value == 'month') {
				this.startDateMonth = ''
			} else if (value == 'year') {
				this.startDateYear = ''
			}
		}
	}
}
</script>
