<template>
	<div class="content">
		<ul class="nav nav-pills nav-pills-primary justify-content-center" role="tablist">
			<li class="nav-item">
				<a class="nav-link active" data-toggle="tab" href="#nav1-link3" role="tablist" aria-expanded="true">
					NOTIFICATION
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-link1" role="tablist" aria-expanded="false">
					DOCUMENTATION
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-link2" role="tablist" aria-expanded="false">
					SUPPORT REQUEST
				</a>
			</li>
			<li class="nav-item">
				<a class="nav-link" data-toggle="tab" href="#nav1-link4" role="tablist" aria-expanded="false">
					FAQ
				</a>
			</li>
		</ul>
		<div class="tab-content tab-space">
			<!-- Notification -->
			<div class="tab-pane active text-center" id="nav1-link3" aria-expanded="true">
				<!-- <LiveChatWidget></LiveChatWidget> -->
			</div>

			<!-- Documentation -->
			<div class="tab-pane" id="nav1-link1" aria-expanded="false">
				<div class="row justify-content-center">
					<div class="col-md-4">
						<div class="form-group">
							<label for="general">GENERAL</label>
							<button class="form-control btn btn-info btn-simple text-left" id="general" aria-describedby="emailHelp">
								<span class="d-inline">QUICK GUIDE</span>
								<span class="d-inline float-right text-muted">PDF&ensp;<i class="tim-icons icon-paper"></i></span>
							</button>
						</div>
						<br>
						<div class="form-group">
							<label for="marketing">MARKETING</label>
							<button class="form-control btn btn-info btn-simple text-left" id="marketing">
								<span class="d-inline">BROCHURE</span>
								<span class="d-inline float-right text-muted">PDF&ensp;<i class="tim-icons icon-paper"></i></span>
							</button>
						</div>
						<br>
						<div class="form-group">
							<label for="quality">QUALITY ASSURANCE FRAMEWORK</label>
							<button class="form-control btn btn-info btn-simple text-left" id="quality">PLEASE CONTACT YOUR MEMBER FOR THE LATEST VERSION</button>
						</div>
						<br>
						<div class="form-group">
							<label for="account">ACCOUNT AGREEMENT</label>
							<button class="form-control btn btn-info btn-simple text-left" id="account">PLEASE CONTACT YOUR MEMBER FOR THE LATEST VERSION</button>
						</div>
					</div>
				</div>
			</div>

			<!-- Support Request -->
			<div class="tab-pane" id="nav1-link2" aria-expanded="false">
				<!-- <ul class="nav nav-tabs justify-content-center" role="tablist">
					<li class="nav-item">
						<a class="nav-link active" data-toggle="tab" href="#" role="tablist" aria-expanded="true">
							General
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#" role="tablist" aria-expanded="false">
							Trading
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#" role="tablist" aria-expanded="false">
							Tech Support
						</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" data-toggle="tab" href="#" role="tablist" aria-expanded="false">
							Feedback
						</a>
					</li>
				</ul>
				<br> -->
				<div class="container" style="max-width: 65ch;">
					<label for="topic">Topic</label>
					<select id="topic" class="form-control mb-3">
					  <option value="1">General</option>
					  <option value="2">Trading</option>
					  <option value="3">Tech Support</option>
					  <option value="3">Feedback</option>
					</select>

					<div class="form-group mb-3">
						<label for="message" class="text-left">Message</label>
						<textarea id="message" class="form-control" placeholder="Your message" rows="5" style="min-height: 200px;"></textarea>
					</div>

					<div class="text-center">
						<button type="button" class="btn c-btn-theme">SEND REQUEST</button>
					</div>
					
				</div>

				<div class="text-center my-3" style="font-size: 1rem; opacity: 0.3;">
					-- OR --
				</div>

				<div class="text-center">
					<button class="btn c-btn-theme" @click="showChatWidget()">CHAT WITH CUSTOMER SERVICE</button>
				</div>
			</div>

			<!-- FAQ -->
			<div class="tab-pane" id="nav1-link4" aria-expanded="false">
			</div>
		</div>
	</div>
</template>
<style scoped>
	.nav li a {
		padding-top: 5px;
		padding-bottom: 5px;
	}
</style>

<script>
	// import LiveChatWidget from '@/components/liveChatWidget.vue'

	export default {
		name: 'Help',
		components: {
			// LiveChatWidget
		},

		mounted() {

		},

		beforeDestroy() {
			// alert("HAHAHAHAHA")
		},

		methods: {
			showChatWidget() {
				window.LiveChatWidget.call("maximize")
			}
		}
	}
</script>