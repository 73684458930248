<template>
	<div class="card">
		<!-- <div class="card-header">
			<h4 class="text-center"></h4>
			<div class="row">
				<div class="col-12 col-md-6">
					<dl class="row mb-0">
						<dt class="col-6">USER ACCESS</dt>
						<dd class="col-6">
							<p v-if="this.$store.getters.getAccountLevel == 'client'">: CLIENT</p>
							<p v-else style="text-transform: uppercase;">: {{$parent.tableView}}</p>
						</dd>


						<template v-if="this.$store.getters.getAccountLevel == 'client'">
							<dt class="col-6">HIN</dt>
							<dd class="col-6">
								<p>: {{ this.$store.getters.getHin }} </p>
							</dd>

							<dt class="col-6">ACCOUNT NAME</dt>
							<dd class="col-6">
								<p>: {{ this.$store.getters.getFirstName }} </p>
							</dd>

							<dt class="col-6">STORAGE FEE</dt>
							<dd class="col-6">
								<p>: -</p>
							</dd>
						</template>
						<template v-else>
							<dt class="col-6">CURRENT REVENUE</dt>
							<dd class="col-6">
								<p>: USD {{Number(($parent.totalTurnOver.usd * 0.003).toFixed(2))}}</p>
								<p>: IDR {{Number(($parent.totalTurnOver.usd * 0.003 * streamingRate.stream_buy.cur_rate).toFixed(2))}}</p>
							</dd>
						</template>
					</dl>
				</div>

				<div class="col-12 col-md-6">
					<dl class="row mb-0">
						<dt class="col-auto">TOTAL TURN OVER</dt>
						<dd class="col-6">
							<dl class="row mb-0">
								<dt class="col-12"></dt>
								<dd class="col-9"><p>USD {{ Number(($parent.totalTurnOver.usd).toFixed(2)) }}</p></dd>

								<dt class="col-12"></dt>
								<dd class="col-9"><p>IDR {{ Number(($parent.totalTurnOver.usd * this.streamingRate.stream_buy).toFixed(2)) }}</p></dd>

								<dt class="col-12"></dt>
								<dd class="col-9"><p>TOZ {{ Number(($parent.totalTurnOver.toz).toFixed(2)) }}</p></dd>

								<dt class="col-12"></dt>
								<dd class="col-9"><p>GRAM {{ ($parent.totalTurnOver.gram).toFixed(2) }}</p></dd>
							</dl>
						</dd>
					</dl>
				</div>
			</div>
		</div> -->
		
		<div class="card-body text-white">
			<div class="row flex-wrap">

				<div class="col-auto">
					<div class="row flex-nowrap">
						<div class="col-auto text-nowrap">
							<div class="mb-3">User Access</div>

							<template v-if="this.$store.getters.getAccountLevel == 'client'">
								<div class="mb-3">HIN</div>
								<div class="mb-3">Account Name</div>
								<div class="mb-4">Storage Fee</div>
							</template>
							<div v-else class="mb-4">Current Revenue</div>
						</div>

						<div class="col-auto">
							<div class="mb-3">
								<p v-if="this.$store.getters.getAccountLevel == 'client'">: CLIENT</p>
								<p v-else style="text-transform: uppercase;">: {{ $parent.tableView }}</p>
							</div>

							<template v-if="this.$store.getters.getAccountLevel == 'client'">
								<div class="mb-3">: {{ this.$store.getters.getHin }}</div>
								<div class="mb-3">: {{ this.$store.getters.getFirstName }}</div>
								<div class="mb-3">: -</div>
							</template>

							<div v-else class="mb-3">
								<p>: $ {{ totalRevenueUsd }}</p>
								<p>: Rp {{ totalRevenueIdr }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="col" style="max-width: 100px;"></div>

				<div class="col-auto">
					<div class="row">
						<div class="col-12">
							<div class="mb-3">Total Turn Over :</div>
						</div>
					</div>

					<div class="row text-nowrap">
						<div class="col-auto">
							<div class="mb-3 text-right">$ {{ numberFormatExtend($parent.totalTurnOver.usd) }}</div>
							<div class="text-right">Rp {{ numberFormatExtend(Decimal.mul($parent.totalTurnOver.usd, this.streamingRate.stream_buy.cur_rate).toDecimalPlaces(2).toNumber()) }}</div>
						</div>

						<div class="col-auto border-left">
							<div class="row text-nowrap">
								<div class="col-auto">
									<div class="mb-3">Toz</div>
									<div class="">Gram</div>
								</div>
								<div class="col-auto text-right">
									<div class="mb-3"> {{ numberFormatExtend($parent.totalTurnOver.toz, 10) }}</div>
									<div class=""> {{ numberFormatExtend($parent.totalTurnOver.gram) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>


			</div>
		</div>
	</div>
</template>

<script>
	import {Decimal} from 'decimal.js'

	export default {
		name: 'TopSection',
		props: {
			contractId_prop: {
				type: String,
				default: '0'
			},

			totalRevenue_prop: {
				type: Object,
				default() {
					return {
						usd: 0,
						idr: 0,
					}
				}
			}
		},

		components: {

		},

		data() {
			return {
				Decimal: Decimal,
				accountLevel: this.$store.getters.getAccountLevel,
				tableView: 'CLIENT',
			}
		},

		computed: {
			streamingRate() {
				return this.$store.getters.getStreamingRate
			},

			percentRevenue() {
				if (this.accountLevel == 'ib') {
					return this.$store.state.percentRevenueIb
				}else {
					return this.$store.state.percentRevenueFm
				}
			},

			totalRevenueUsd() {
				if (!this.totalRevenue_prop) {
					return '-'
				}

				if (!this.totalRevenue_prop.usd || this.totalRevenue_prop.usd == '-') {
					return '-'
				}

				return this.numberFormatExtend(new Decimal(this.totalRevenue_prop.usd).toDecimalPlaces(2).toNumber())
			},

			totalRevenueIdr() {
				if (!this.totalRevenue_prop) {
					return '-'
				}

				if (!this.totalRevenue_prop.idr || this.totalRevenue_prop.idr == '-') {
					return '-'
				}

				return this.numberFormatExtend(new Decimal(this.totalRevenue_prop.idr).toDecimalPlaces(2).toNumber())
			},

		},

		created() {
			if (this.$store.getters.getAccountLevel != 'client') {
				this.tableView = 'all'
			}
		},

		methods: {
			// numberFormatExtend(value) {
			// 	let val = (value)
			// 	return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') //comma separator
			// },
		}
	}
</script>