<template>
	<div class="dropdown">
	  <button class="btn btn-info dropdown-toggle py-2 m-0" style="height: 33px;" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	    Export <i class="fas fa-download ml-2"></i>
	  </button>
	  <div class="dropdown-menu"  aria-labelledby="dropdownMenuButton">
	    <a class="dropdown-item" href="#">Export to PDF</a>
	    <a class="dropdown-item" href="#">Export to CSV</a>
	  </div>
	</div>
</template>

<script>
	export default {
		name: 'ExportAsButton'
	}
</script>

<style scoped>
	.dropdown-toggle:after {
		display: none;
	}
</style>