<template>
	<svg :width="width" :height="height" viewBox="0 0 72 72" fill="none" >
		<g id="icon-park-solid:check-one">
			<g id="Mask group">
				<mask id="mask0_104_1054" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="4" width="64"
					height="64">
					<g id="Group">
						<g id="Group_2">
							<path id="Vector"
								d="M36 66C39.9404 66.0051 43.843 65.2315 47.4834 63.7235C51.1239 62.2155 54.4304 60.003 57.213 57.213C60.003 54.4304 62.2155 51.1239 63.7235 47.4834C65.2315 43.843 66.0051 39.9404 66 36C66.0051 32.0596 65.2314 28.1571 63.7234 24.5166C62.2154 20.8762 60.0029 17.5697 57.213 14.787C54.4304 11.997 51.1239 9.78449 47.4834 8.27652C43.843 6.76855 39.9404 5.99489 36 5.99999C32.0596 5.99497 28.1571 6.76867 24.5167 8.27664C20.8762 9.7846 17.5697 11.9971 14.787 14.787C11.9971 17.5697 9.78463 20.8762 8.27667 24.5166C6.7687 28.1571 5.995 32.0596 6.00003 36C5.99492 39.9404 6.76858 43.843 8.27655 47.4834C9.78452 51.1239 11.9971 54.4304 14.787 57.213C17.5697 60.0029 20.8762 62.2154 24.5167 63.7234C28.1571 65.2313 32.0596 66.005 36 66Z"
								fill="white" stroke="white" stroke-width="4" stroke-linejoin="round" />
							<path id="Vector_2" d="M24 36L33 45L51 27" stroke="black" stroke-width="4" stroke-linecap="round"
								stroke-linejoin="round" />
						</g>
					</g>
				</mask>
				<g mask="url(#mask0_104_1054)">
					<path id="Vector_3" d="M0 0H72V72H0V0Z" :fill="color_prop" />
				</g>
			</g>
		</g>
	</svg>
</template>

<script>
export default {

	name: 'IconSuccess',

	props: {
		sizeMultiplier_prop: {
			type: Number,
			default: 1,
		},

		width_prop: {
			type: String,
			default: '74px', // "px" or "em" only
		},

		height_prop: {
			type: String,
			default: '74px', // "px" or "em" only
		},

		color_prop: {
			type: String,
			default: getComputedStyle(document.documentElement).getPropertyValue('--theme-color'),
		},
	},

	data() {
		return {
		}
	},

	computed: {
		width() {
			return Number(this.width_prop.substr(0, this.width_prop.length - 2)) * this.sizeMultiplier_prop + this.width_prop.substr(-2)
		},

		height() {
			return Number(this.height_prop.substr(0, this.height_prop.length - 2)) * this.sizeMultiplier_prop + this.height_prop.substr(-2)
		},
	},

	data() {
		return {

		}
	}
}
</script>

<style lang="css" scoped>
.up {
	transform: rotate(90deg);
}

.right {
	transform: rotate(180deg);
}

.down {
	transform: rotate(-90deg);
}</style>