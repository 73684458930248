import axios from '@/api/axios.js'
import Swal from 'sweetalert2' // eslint-disable-line no-unused-vars

const logoutSessionExpired = () => {
  if (store.state.swalSessionExpiredOn == 'off') {
    store.state.swalSessionExpiredOn == 'on'

    store.dispatch('AUTH_LOGOUT').then(() => {
      Swal.fire({
        background: '#191f31',
        title: '<span style="color: white;">Your session has expired..</span>',
      }).then(() => {
        this.$router.push({ name: 'Login' }, () => {
          window.location.reload()
        })
        store.state.swalSessionExpiredOn == 'off'
      })
    })
  }
}

const axiosInterceptors = () => {
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    let statusCode = (((response || {}).data || {}).data || {}).status
    if (statusCode === 498) {
      logoutSessionExpired()

      let error = {
        response: response
      }

      throw error
    }
    else if (statusCode == 499) {
      const originalRequest = response.config
      return setTimeout(() => {
        originalRequest.headers.common['Authorization'] = 'Bearer ' + store.state.token

        if (originalRequest.url == '/user/refresh_token') {
          logoutSessionExpired()

          return response
        }

        return (axios(originalRequest))
      }, 3000)
    }
    else if (response.data.status === 401) {
      // if you ever get an unauthorized, logout the user
      logoutSessionExpired()

      let error = {
        response: response
      }
      if (response.data.data.status == 403) {
        error.response = response.data
      }

      throw error
    } else {
      return response
    }
  }, function (err) {

    if (err.response) {
      /*
      * The request was made and the server responded with a
      * status code that falls out of the range of 2xx
      */
    } else if (err.request) {
      /*
      * The request was made but no response was received, `error.request`
      * is an instance of XMLHttpRequest in the browser and an instance
      * of http.ClientRequest in Node.js
      */
    } else {
      // Something happened in setting up the request and triggered an Error
    }

    let statusCode = Number((((err || {}).response || {}).data || {}).status)

    if (statusCode == 498) {
      logoutSessionExpired()

      return Promise.reject(err)
    } else if (statusCode == 499) {
      const originalRequest = err.config



      setTimeout(() => {
        if (originalRequest.url == '/user/refresh_token') {
          logoutSessionExpired()

          return Promise.reject(err)
        }

        originalRequest.headers.common['Authorization'] = 'Bearer ' + store.state.token

        return Promise.reject((axios(originalRequest)))
      }, 3000)
    } else if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      // if you ever get an unauthorized, logout the user
      logoutSessionExpired()
    }

    return Promise.reject(err)
  })
}

export default axiosInterceptors