import Vue from 'vue'

export default {
  setContracts_mut(state, payload) {
    state.contracts = payload
  },

  setProducts_mut(state, payload) {
    state.products = payload
  },

  setProfile_mut(state, payload) {
    state.profile = payload
  },

  setAccountInformation_mut(state, payload) {
    state.accountInformation = payload
  },

  setDepths_mut(state, payload) {
    state.depths = payload
  },

  setStreamingRate_mut(state, payload) {
    state.streamingRate = payload
    window.localStorage.setItem('streamingRate', JSON.stringify(payload))

    this.commit('setNextGetStreamingRate', new Date(payload.expired_at).getTime())
    state.getStreamingRate_apiStatus = 'success'
  },

  setNextGetStreamingRate(state, payload) {
    state.nextGetStreamingRate = payload
    window.localStorage.setItem('nextGetStreamingRate', payload)
  },

  setBalances_mut(state, payload) {
    state.balances = payload
    state.balanceIdr = Number(payload.rdk_balance)
    state.balanceUsd = payload.usd_balance
    state.availableTradeBalanceIdr = payload.available_trade_bal
    state.availableTradeBalanceUsd = payload.available_trade_bal_usd
    state.reservedBalanceIdr = payload.reserved_bal_idr
    state.reservedBalanceUsd = payload.reserved_bal_usd
  },

  setGold_mut(state, payload) {
    const golds = window.structuredClone(state.golds)

    let tempGolds = golds
    if (golds) {
      for (const key in golds) {
        Vue.set(tempGolds, key, {
          ...golds[key],
          ...payload[key]
        })
      }
    } else {
      tempGolds = payload
    }

    state.golds = tempGolds
  },

  setOhlc_mut(state, payload) {
    state.ohlc = {
      ...state.ohlc,
      ...payload
    }
  },

  setGoldBalances_mut(state, payload) {
    state.goldBalance = payload
  },

  setEndOfDayPrices_mut(state, payload) {
    state.endOfDayPrices = payload
  },

  setHoldings_mut(state, payload) {
    state.holdings = payload
  },

  TOGGLE_LOADING(state) {
    state.callingAPI = !state.callingAPI
  },
  TOGGLE_SEARCHING(state) {
    state.searching = (state.searching === '') ? 'loading' : ''
  },

  SET_GEOLOCATION(state, payload) {
    state.geolocation = payload
  },

  SET_USER(state, payload) {
    state.user.email = payload.email
    state.user.full_name = payload.full_name
    state.user.phone_number = payload.phone_number
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  DESTROY_TOKEN(state) {
    state.token = null
  },
  DESTROY_USER(state) {
    state.user.email = null
    state.user.full_name = null
    state.user.phone_number = null
  },
  SET_BALANCE(state, payload) {
    state.balanceIdr = payload.balanceIdr
    state.balanceUsd = payload.balanceUsd
    state.availableTradeBalanceUsd = payload.availableTradeBalanceUsd
    state.availableTradeBalanceIdr = payload.availableTradeBalanceIdr
    state.accountBalanceIdr = payload.accountBalanceIdr
    state.accountBalance = payload.accountBalance
    state.reservedBalance = payload.reservedBalance
    state.reservedBalanceIdr = payload.reservedBalanceIdr
  },
  SET_BALANCE_2(state, payload) {
    state.balanceIdr = payload.balanceIdr
    state.balanceUsd = payload.balanceUsd
  },

  CHANGE_SET_BALANCE_STATUS(state, payload) {
    state.setBalanceStatus = payload
  },
  AUTH_REQUEST(state) {
    state.status = 'loading'
  },
  AUTH_LOGOUT(state, payload) {
    state.status = null
    payload.forEach(item => state[item] = null)
  },

  AUTH_SUCCESS(state, payload) {
    state.bankType = payload.bank_type

    // for login expired in frontend only
    state.loginExpiredTime = Date.now() + state.loginExpiredTimeState

    // for refresh token
    state.refreshTokenTime = Date.now() + state.refreshTokenTimeState

    state.authInfo = payload
    state.userId = payload.user_id
    state.brokerId = payload.broker_id

    state.token = payload.token
    state.bankType = payload.bank_type
  },

  AUTH_CHANGE_STATUS(state, paylod) {
    state.status = paylod
  },

  AUTH_ERROR(state, error) {
    state.status = error
  },

  SET_KINESIS_TOKEN(state, payload) {
    state.kinesisAuth = payload
  },

  SETDEPTHS(state, payload) {
    state.depths = payload
  },
  SET_KINESIS_DEPTH(state, payload) {
    state.kinesisDepth = payload
  },
  SETGOLD(state, payload) {
    state.gold = payload
  },
  SETPRODUCTS(state, payload) {
    state.products = payload
  },
  SETORDERINTRADETICKET(state, payload) {
    state.orderInTradeTicket = payload
  },
  SETACCOUNT(state, payload) {
    state.account = payload.body
  },
  START_TIMER(state) {
    state.timerBarStatus = true
  },
  STOP_TIMER(state) {
    state.timerBarStatus = false
  },

  SET_STREAMING_RATE(state, payload) {
    state.streamingRate = payload
    state.setStreamingRateStatus = 'success'
  },
  SET_STREAMING_RATE_STATUS(state, payload) {
    state.setStreamingRateStatus = payload
  },

  CHANGE_CURRENCY(state, payload) {
    state.currency = payload
  },

  CHANGE_HUB(state, payload) {
    state.hub = payload
  },

  SET_CONTRACTS(state, payload) {
    state.contracts = payload
  },
  CHANGE_TRADING_TYPE(state, payload) {
    state.tradingType = payload
  },
  CHANGE_GET_DEPOSIT_COOLDOWN_STATUS(state, payload) {
    state.getDepositIsCooldown = payload
  },

  SET_HOLDINGS(state, payload) {
    state.holdings = payload
  },

  SET_RESERVED_GOLD(state, payload) {
    state.reservedGold = payload
  },

  SET_GOLD_BALANCE(state, payload) {
    state.goldBalance = payload
  },

  SET_HUBS(state, payload) {
    state.hubs = payload
  },

  SET_OHLC(state, payload) {
    state.gold[payload.index].low = payload.low
    state.gold[payload.index].high = payload.high
    state.gold[payload.index].change = payload.change
  },

  setReportToShow_mut(state, payload) {
    state.reportToShow = payload
    window.localStorage.setItem('tabActiveReports', payload)
  },

  // Api Status =============================================

  changeSetContractsApiStatus_mut(state, payload) {
    state.setContractsApiStatus = payload
  },
  changeSetProductsApiStatus_mut(state, payload) {
    state.setProductsApiStatus = payload
  },
  changeSetAccountInformationApiStatus_mut(state, payload) {
    state.setAccountInformationApiStatus = payload
  },
  changeSetDepthsApiStatus_mut(state, payload) {
    state.setDepthsApiStatus = payload
  },
  changeSetStreamingRateApiStatus_mut(state, payload) {
    state.getStreamingRate_apiStatus = payload
  },
  changeSetBalanceApiStatus_mut(state, payload) {
    state.setBalancesApiStatus = payload
  },
  changeSetGoldBalancesApiStatus_mut(state, payload) {
    state.setGoldBalancesApiStatus = payload
  },
  changeSetHoldingsApiStatus_mut(state, payload) {
    state.setHoldingsApiStatus = payload
  },
  changeSetEndOfDayPricesApiStatus_mut(state, payload) {
    state.setEndOfDayPricesApiStatus = payload
  },

  CHANGE_MARKET_INQUIRY_STATUS(state, status) {
    state.marketInquiryStatus = status
  },
  CHANGE_MARKET_STATUS(state, status) {
    state.marketStatus = status
  },
  updateDataVideo(state, data) {
    state.srcVideo = data
  },
  CHANGE_GET_DETAIL_ORDER_STATUS(state, {status, message = ''}) {
    state.getDetailOrderStatus = status
    state.getDetailOrderStatusMessage = message
  },

  CHANGE_SET_CONTRACTS_STATUS(state, status) {
    state.setContractsStatus = status
  },

  CHANGE_SWAL_SESSION_EXPIRED(state, status) {
    state.swalSessionExpiredOn = status
  },

  changeRefreshTokenFirstTime(state, status) {
    state.isRefreshTokenFirstTime = status
  },

  // for development
  setPnlEtc(state, payload) {
    state.pnlEtc = {
      ...state.pnlEtc,
      ...payload
    }
  },
}
