import axios from 'axios'

export const setAuthorization = (newToken) => {
	axios.defaults.headers.common['Authorization'] = 'Bearer ' + newToken
}

export function abortController() {
	const controller = new AbortController()
	const signal = controller.signal

	return { controller, signal }
}

export default axios.create({
	baseURL: (process.env.NODE_ENV == 'development') ? 'https://api2-dev.jfx.co.id' : 'https://api2-v2.jfx.co.id'
	// headers: {'Authorization': 'Bearer ' + token.tokenValue},
})